import styled from 'styled-components';

import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';

export const Root = styled(NakedButton)`
  border-radius: 8px;
  position: relative;
  &:hover {
    filter: contrast(105%);
  }
`;

export const BottomLabel = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: ${(props) => (props.hasName
    ? props.theme?.palette?.focus
    : 'transparent')
  }; */
  background-color: transparent;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 5px;
  width: 100%;
  height: 40px;
`;

export const NoContactContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 110px;
  background-color: ${(props) => props.theme.palette['background-flat']};
  border-radius: 8px;
`;

export const WithContactContainer = styled.div`
  width: 110px;
  height: 110px;
  background-color: ${(props) => props.theme.palette['background-flat']};
  border-radius: 8px;
  position: relative;
`;

export const ControlButtonsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${(props) => props.theme.palette['background-overlay-dark']};
  border-radius: 8px 8px 0 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
