/* eslint-disable react/jsx-no-bind */
import React, {
  useState,
  useEffect,
  } from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Icon from 'ui/Icon';

import PropertyAddressInput from 'shared/inputs/PropertyAddressInput';

import withNewPropertyFormDefaultValue from 'sections/MLS/hocs/withNewPropertyFormDefaultValue';

import TempSelect from './TempSelect';
import AddContact from './AddContact';
import MLSWebsiteRaw from './MLSWebsite';
import ListedSalePriceInputRaw from './ListedSalePriceInput';
import OnMarketSince from './OnMarketSince';
import MLSNumberRaw from './MLSNumber';
import PropertyTypeSelectRaw from './PropertyTypeSelect';
import SellingAgent from './SellingAgent';
import ListingAgent from './ListingAgent';
import ImagesUpload from './ImagesUpload';
import ListingTypeSelect from './ListingTypeSelect';

import getBookmarkletCode from './getBookmarkletCode';

const MLSWebsite = withNewPropertyFormDefaultValue(MLSWebsiteRaw)('mls_website');
const ListedSalePriceInput = withNewPropertyFormDefaultValue(ListedSalePriceInputRaw)('expected_price');
const MLSNumber = withNewPropertyFormDefaultValue(MLSNumberRaw)('mls_number');
const PropertyTypeSelect = withNewPropertyFormDefaultValue(PropertyTypeSelectRaw)('property_type');

const NewPropertyForm = (props) => {
  const {
    handleChange,
    selectedListingAgent,
    selectedSeller,
    onMarketSince,
    addressId,
  } = props;

  const [myListing, setMyListing] = useState(true);
  const [listingType, setListingType] = useState(0);

  const toggleSection = (value) => {
    setListingType(value);
    setMyListing(!myListing);
  };

  useEffect(() => {
    if (!myListing) {
      handleChange('listing_agent')(null);
      handleChange('seller')(null);
    } else {
      handleChange('listing_agent')(0);
    }
  }, [myListing]);

  return (
    <form>
      <Flex
        flexDirection="column"
        spacing={3}
      >
        <Flex
          justifyContent="space-between"
          fullWidth
        >
          <FlexItem>
            <ListingTypeSelect
              onChange={toggleSection}
              value={listingType}
            />
          </FlexItem>
          <FlexItem>
            <TempSelect onChange={handleChange('temp')} />
          </FlexItem>
        </Flex>

        <Flex
          fullWidth
          flexDirection="column"
          spacing={2}
        >
          <Flex spacing={2}>
            <FlexItem>
              <Icon
                name="check-circle"
                color="focus"
              />
            </FlexItem>
            <FlexItem>
              <Typography
                variant="h3"
                noMargin
              >
                Property Details
              </Typography>
            </FlexItem>
          </Flex>
          <FlexItem fullWidth>
            <PropertyAddressInput
              decorated
              useId
              addressId={addressId}
              handleChange={handleChange('address_id')}
            />
          </FlexItem>
        </Flex>

        <Flex
          fullWidth
          spacing={2}
        >
          <Flex
            fullWidth
            spacing={2}
            flexDirection="column"
          >
            <FlexItem fullWidth>
              <ListedSalePriceInput
                onChange={handleChange('expected_price')}
              />
            </FlexItem>

            <FlexItem fullWidth>
              <MLSWebsite
                icon="globe"
                label="MLS website address"
                onChange={handleChange('mls_website')}
              />
            </FlexItem>
            {
              !myListing
              ? (
                <FlexItem fullWidth>
                  <OnMarketSince
                    label="On market since"
                    value={onMarketSince}
                    handleChange={handleChange('on_market_since')}
                  />
                </FlexItem>
                ) : null
            }
          </Flex>

          <Flex
            fullWidth
            spacing={2}
            flexDirection="column"
          >
            <FlexItem fullWidth>
              <MLSNumber
                icon="home"
                label="MLS number"
                onChange={handleChange('mls_number')}
              />
            </FlexItem>

            <FlexItem fullWidth>
              <PropertyTypeSelect
                handleChange={handleChange('property_type')}
              />
            </FlexItem>
          </Flex>
        </Flex>

        <Flex
          spacing={2}
          fullWidth
          alignItems="center"
          flexDirection="column"
        >
          <Flex
            fullWidth
            spacing={2}
          >
            <Flex spacing={2}>
              <FlexItem>
                <Icon
                  name="user"
                  color="focus"
                />
              </FlexItem>
              <FlexItem>
                <Typography
                  variant="h3"
                  noMargin
                >
                  {
                    myListing
                      ? 'Seller'
                      : 'Listing Agent'
                  }
                </Typography>
              </FlexItem>
            </Flex>
          </Flex>

          <Flex
            spacing={2}
            fullWidth
            alignItems="center"

          >
            <FlexItem fullWidth>
              {
                myListing
                ? (
                  <SellingAgent
                    handleChange={handleChange('seller')}
                    selectedContactId={selectedSeller}
                  />
                  )
                : (
                  <ListingAgent
                    handleChange={handleChange('listing_agent')}
                    selectedContactId={selectedListingAgent}
                  />
                  )
              }
            </FlexItem>
            <AddContact
              onSuccess={
                handleChange(myListing ? 'seller' : 'listing_agent')
              }
            />
          </Flex>
        </Flex>

        <Flex
          fullWidth
          flexDirection="column"
          spacing={2}
        >
          <Flex spacing={2}>
            <FlexItem>
              <Icon
                name="image"
                color="focus"
              />
            </FlexItem>
            <FlexItem>
              <Typography
                variant="h3"
                noMargin
              >
                Property Photos
              </Typography>
            </FlexItem>
          </Flex>
          <FlexItem fullWidth>
            <ImagesUpload
              icon
              label="Drag & drop or click to upload property photos (5 max)"
            />
          </FlexItem>
        </Flex>

        <Flex
          fullWidth
          spacing={2}
          flexDirection="column"
        >
          <Typography noMargin>
            Or drag and drop the button below to your bookmarks bar in your browser to save properties from other sources like Zillow or Trulia:
          </Typography>

          <Flex
            justifyContent="center"
            fullWidth
          >
            <a
              href={getBookmarkletCode()}
              onClick={(e) => { e.preventDefault(); }}
              style={{
                border: 'none',
                display: 'block',
                padding: 10,
                borderRadius: 10,
                background: '#F2F6FE',
                color: '#141736',
                whiteSpace: 'nowrap',
                fontSize: '18px',
                cursor: 'grab',
              }}
            >
              Add to Symplete
            </a>
          </Flex>
        </Flex>
      </Flex>

    </form>
  );
};

NewPropertyForm.defaultProps = {
  selectedListingAgent: null,
  selectedSeller: null,
  onMarketSince: null,
  addressId: null,
};

const {
  func,
  number,
  string,
} = PropTypes;

NewPropertyForm.propTypes = {
  handleChange: func.isRequired,
  selectedSeller: number,
  selectedListingAgent: number,
  onMarketSince: string,
  addressId: number,
};

export default NewPropertyForm;
