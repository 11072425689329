import {
  SET_CRM_SELECTED_LEAD_ROWS_EXCEPTIONS,
} from 'store/actionTypes/crm';

const setSelectedLeadRows = (ids) => ({
  type: SET_CRM_SELECTED_LEAD_ROWS_EXCEPTIONS,
  payload: ids,
});

export default setSelectedLeadRows;
