exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".iconButton_icon-btn__1ELLq,\n.iconButton_icon-btn-white__1c7Ph {\n  width: 34px;\n  height: 34px;\n  border-radius: 100%;\n  background: none;\n  border: none;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.iconButton_icon-btn__1ELLq:focus-visible,\n.iconButton_icon-btn-white__1c7Ph:focus-visible {\n  border: none;\n  outline: none;\n}\n\n.iconButton_icon-btn__1ELLq:hover,\n.iconButton_icon-btn--active__3FvMt {\n  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);\n  background-color: rgba(0, 0, 0, 0.05);\n}\n\n.iconButton_icon-btn-white__1c7Ph:hover,\n.iconButton_icon-btn-white--active__1rqY7 {\n  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);\n  background-color: rgb(255, 255, 255);\n}\n\n.iconButton_icon-btn__1ELLq:active {\n  box-shadow: none;\n  background-color: rgba(0, 0, 0, 0.1);\n}\n\n.iconButton_icon-btn-icon__3iZxk {\n  max-width: 24px;\n  width: 24px;\n  height: auto;\n  object-fit: contain;\n}", ""]);

// exports
exports.locals = {
	"icon-btn": "iconButton_icon-btn__1ELLq",
	"icon-btn-white": "iconButton_icon-btn-white__1c7Ph",
	"icon-btn--active": "iconButton_icon-btn--active__3FvMt",
	"icon-btn-white--active": "iconButton_icon-btn-white--active__1rqY7",
	"icon-btn-icon": "iconButton_icon-btn-icon__3iZxk"
};