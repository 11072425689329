import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedRealtorContactsActions,
} from 'store/actions/realtorContacts';

import {
  optimizedPhoneNumbersActions,
} from 'store/actions/phoneNumbers';

export default createSelector(
  (state) => state.realtorContacts.contacts,
  (state) => state.phoneNumbers.phoneNumbers,
  (_, contactId) => contactId,
  (
    contacts,
    phoneNumbers,
    id,
  ) => {
    const contact = contacts[id];

    if (!contact) {
      if (id) {
        optimizedRealtorContactsActions.getContactById(id);
      }

      return [];
    }

    const contactPhoneNumbersRaw = contact.phone_numbers || [];

    const contactPhoneNumbers = contactPhoneNumbersRaw.reduce((accum, item) => {
      const {
        phone_number_id,
        is_primary,
      } = item;

      const phoneNumber = phoneNumbers[phone_number_id];

      if (!phoneNumber) {
        if (phone_number_id) {
          optimizedPhoneNumbersActions.getPhoneNumberById(phone_number_id);
        }

        return accum;
      }

      accum.push({
        ...phoneNumber,
        phone_number_id,
        is_primary,
      });
      return accum;
    }, []);

    return contactPhoneNumbers;
  },
);
