import { connect } from 'react-redux';

import numeral from 'refactored/utils/numbers';

import {
  createProperty,
  updateProperty,
} from 'store/actions/properties';
import { createAddress } from 'store/actions/addresses';
import {
  createContact,
} from 'store/actions/realtorContacts';
import {
  createPhoneNumber,
} from 'store/actions/phoneNumbers';

import {
  uploadAttachment,
} from 'store/actions/attachmentsV2';

import dataUrlToFile from 'shared/utils/attachments/dataUrlToFile';
import { isEmpty } from 'shared/utility';

import NewPropertyFormScraper from './NewPropertyFormScraper';

const propertyObjectType = 27;

const mapDispatchToProps = (dispatch) => ({
  onSubmit: async (data) => {
    try {
      let addressId;
      let listingAgent;

      if (data.address) {
        addressId = await dispatch(createAddress({ address: data.address }));
      }

      if (data.listingType === 0) {
        listingAgent = 0;
      } else if (data.agentFirstName || data.agentLastName) {
        let agentPhoneNumberId;

        if (data.agentPhone) {
          agentPhoneNumberId = await dispatch(createPhoneNumber(data.agentPhone));
        }

        listingAgent = await dispatch(createContact({
          first_name: data.agentFirstName,
          last_name: data.agentLastName,
          email_primary: data.agentEmail,
          phoneNumbers: agentPhoneNumberId ? [agentPhoneNumberId] : null,
        }));

        if (data.agentAvatar) {
          try {
            const {
              name,
              type,
              fileUrl,
            } = data.agentAvatar;

            const file = await dataUrlToFile(fileUrl, name, type);

            await dispatch(uploadAttachment({
              file,
              objectId: listingAgent,
              objectType: 25,
              attachmentType: 1,
            }));

            URL.revokeObjectURL(fileUrl);
          } catch (err) {
            console.error(err);
          }
        }
      }

      const propertyData = {
        address_id: addressId,
        property_type: data.propertyType,
        expected_price: numeral(data.price).value() || 0,
        mls_website: data.propertyUrl,
        status: 1,
        temp: data.temp,
        listing_status: 1,
        listing_agent: listingAgent,
        bedrooms: data.bedrooms,
        bathrooms: data.bathrooms,
        floor_area: data.floorArea,
        plot_area: data.plotArea,
        property_description: data.description,
      };

      const propertyId = await dispatch(createProperty(propertyData));

      const hasImages = !isEmpty(data.images);
      let mainImageId;

      if (hasImages) {
        const uploadImages = async (index) => {
          const image = data.images[index];

          const {
            name,
            type,
            url,
          } = image;

          const file = await dataUrlToFile(url, name, type);
          const imageData = {
            file,
            objectId: propertyId,
            objectType: propertyObjectType,
            attachmentType: 11,
            isPublic: true,
          };

          const attachment = await dispatch(uploadAttachment(imageData, index === data.images.length - 1));

          if (index === data.mainImageIndex) {
            mainImageId = attachment.attachmentId;
          }
          URL.revokeObjectURL(url);

          const nextIndex = index + 1;
          if (nextIndex < data.images.length) {
            await uploadImages(nextIndex);
          }
        };

        try {
          // doing recursion here because Promise.all is not suitable for images uploading
          // to AWS in this case
          await uploadImages(0);
        } catch (error) {
          console.error(error);
        }
      }

      if (mainImageId) {
        await dispatch(updateProperty(propertyId)({ main_image_id: mainImageId }));
      }

      return propertyId;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
});

export default connect(null, mapDispatchToProps)(NewPropertyFormScraper);
