import requests from 'api/requests';

const deleteDisclosure = (disclosureId) => async (dispatch) => {
  try {
    await requests.realtor.disclosures.deleteDisclosure(disclosureId);
  } catch (error) {
    console.error(error);
  }
};

export default deleteDisclosure;
