import React from 'react';
import PropTypes from 'prop-types';

import { currency } from 'refactored/utils/numbers';

import { Tooltip } from 'antd';

import Typography from 'ui/Typography';

import {
  Root,
  Image,
} from './styledItems';

const PropertyItem = (props) => {
  const {
    address,
    amount,
    image,
  } = props;

  return (
    <Root>
      <Image
        shape="square"
        icon="picture"
        src={image?.url}
      />

      <div>
        <Tooltip title={address}>
          <Typography
            ellipsis
            style={{
              maxWidth: 350,
              marginBottom: 7,
            }}
            weight={500}
            noMargin
          >
            {address}
          </Typography>
        </Tooltip>

        <Typography
          variant="h3"
          tag="p"
          color="selected"
          weight={500}
          noMargin
        >
          {currency.full(amount)}
        </Typography>
      </div>
    </Root>
  );
};

PropertyItem.defaultProps = {
  image: null,
};

const {
  shape,
  string,
  number,
} = PropTypes;

PropertyItem.propTypes = {
  image: shape({
    id: string,
    url: string,
  }),
  address: string.isRequired,
  amount: number.isRequired,
};

export default PropertyItem;
