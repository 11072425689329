exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".emailIntentIcons_svg-wrapper__2ol-x {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 20px;\n  height: 20px;\n}\n\n.emailIntentIcons_intent-pill__NUZ_m {\n  padding: 2px 4px;\n  display: flex;\n  border-radius: 20px;\n  width: -webkit-fit-content;\n  width: -moz-fit-content;\n  width: fit-content;\n  grid-gap: 4px;\n  gap: 4px;\n  justify-content: center;\n  align-items: center;\n}", ""]);

// exports
exports.locals = {
	"svg-wrapper": "emailIntentIcons_svg-wrapper__2ol-x",
	"intent-pill": "emailIntentIcons_intent-pill__NUZ_m"
};