import {
  SET_ORDERS,
} from 'store/actionTypes/orders';

const set = (payload) => ({
  type: SET_ORDERS,
  payload,
});

export default set;
