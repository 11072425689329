import requests from 'api/requests';

import setOrderAttachments from './setOrderAttachments';

const getOrderAttachments = (id) => async (dispatch) => {
  try {
    const response = await requests.attachments.getObjectAttachments(15)(id);
    dispatch(setOrderAttachments(id, response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getOrderAttachments;
