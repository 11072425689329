import {
  SET_CUSTOMER_INVOICES,
} from 'store/actionTypes/customerInvoices';

const setInvoices = (payload) => ({
  type: SET_CUSTOMER_INVOICES,
  payload,
});

export default setInvoices;
