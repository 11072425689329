import requests from 'api/requests';

import getContactsLabels from './getContactsLabels';

const createLabel = ({
  name,
  color,
  description = null,
  object_type = 7, // eslint-disable-line camelcase
}) => async (dispatch) => {
  try {
    const response = await requests.labels.post({
      name,
      color,
      description,
      object_type,
    });

    dispatch(getContactsLabels());
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default createLabel;
