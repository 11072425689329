import React from 'react';
import styles from "./threadModalTopbar.module.css"
import { useCommsHub } from 'refactored/components/shared/providers/data/CommsHub.provider';

const ThreadModalTopBar = ({ thread, email, emails, openThreadClick, closeThreadClick }) => {
    const { sendThreadToClosed } = useCommsHub()
    const onCloseThread = () => {
        closeThreadClick()
        sendThreadToClosed(thread)
    }
    return <div className={styles['thread-modal-topbar']}>
        <div className={styles['thread-modal-topbar-sparks']}>
            ✨
        </div>
        <div className={styles['thread-modal-topbar-main-area']}>
            <h4>
                {email?.clean_sympleteAnalysis?.summary}
            </h4>
            <div className={styles['thread-modal-topbar-white-pill-btn-area']}>
                {
                    (
                        email?.clean_sympleteAnalysis.type == "Both" ||
                        email?.clean_sympleteAnalysis.type == "Business"
                    ) &&
                    <button>Business</button>
                }
                {
                    (
                        email?.clean_sympleteAnalysis.type == "Both" ||
                        email?.clean_sympleteAnalysis.type == "Personal"
                    ) &&
                    <button>Personal</button>
                }
                {
                    !(
                        email?.clean_sympleteAnalysis.type == "Both" ||
                        email?.clean_sympleteAnalysis.type == "Personal" ||
                        email?.clean_sympleteAnalysis.type == "Business"
                    ) &&
                    <button>{email?.clean_sympleteAnalysis.type}</button>
                }
                {
                    email?.clean_sympleteAnalysis.relationship &&
                    <button>{email?.clean_sympleteAnalysis.relationship}</button>
                }
            </div>
            <div className={styles['thread-modal-topbar-secondary-area']}>
                {emails.length > 1 && <span>Email is open</span>}
                <span className={styles['thread-modal-topbar-toggle-area']}>
                    <button active={(!thread?.isClosed).toString()} onClick={openThreadClick}>Open</button>
                    <button active={(!!thread?.isClosed).toString()} onClick={onCloseThread}>Closed</button>
                </span>
            </div>
        </div>
    </div>
}

export default ThreadModalTopBar