import {
  SET_CANCELLED_ORDER_ATTACHMENTS,
} from 'store/actionTypes/ordersCancelled';

const setAttachments = (id, attachments) => ({
  type: SET_CANCELLED_ORDER_ATTACHMENTS,
  payload: {
    id,
    attachments,
  },
});

export default setAttachments;
