exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".cardsColumn_cards-column__3r6yC {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n.cardsColumn_cards-column-title__2_FPO {\n  display: flex;\n  justify-content: center;\n  text-transform: capitalize;\n  font-family: \"Hind-Bold\";\n  font-size: 18px;\n}\n\n.cardsColumn_cards-column-cards-scrolleable__3KxyF {\n  min-width: 22vw;\n  height: 100%;\n  padding: 0px 10px;\n  padding-top: 12PX;\n  scrollbar-width: thin;\n  scrollbar-color: transparent transparent;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  grid-gap: 12px;\n  gap: 12px;\n}", ""]);

// exports
exports.locals = {
	"cards-column": "cardsColumn_cards-column__3r6yC",
	"cards-column-title": "cardsColumn_cards-column-title__2_FPO",
	"cards-column-cards-scrolleable": "cardsColumn_cards-column-cards-scrolleable__3KxyF"
};