import styled from 'styled-components';

export const ModalInnerTitle = styled.div`
  font-family: 'Hind', sans-serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: #171725;
  padding-top: 10px;
  padding-bottom: 7px;
`;

export const StyledLink = styled.div`
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #367bed;
  text-decoration: underline;
`;

export const Spacing = styled.div`
  height: 35px;
`;

export const HorizontalSpacing = styled.div`
  width: 15px;
`;

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
`;

export const StyledButton = styled.button`
  display: inline-block;
  width: 221px;
  height: 60px;
  border-radius: 15px;
  background-color: #000;
  color: #fff;
  padding: 10px 25px;
  cursor: pointer;
  text-align: center;
  font-family: 'Hind', sans-serif;
  font-size: 21px;
  font-weight: 400;
  margin-top: 55px;
  margin-bottom: 0px;
  line-height: 0px;
`;
