import requests from 'api/requests';

import { getContext } from 'shared/utils/index';

const changeLogin = async (login) => {
  await requests.account.changeLogin({
    database: getContext(),
    login,
  });
};

export default changeLogin;
