import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Typography from 'shared/components/Typography';
import { Input } from 'antd';
import { rootClassName } from '../shared';

const { TextArea } = Input;

export default class Textarea extends Component {
  static propTypes = {
    value: PropTypes.string,
    title: PropTypes.string,
    onChange: PropTypes.func,
    editMode: PropTypes.bool,
  }

  renderText = () => {
    const { onChange, value, editMode } = this.props;
    if (editMode) {
      return (
        <TextArea
          onChange={onChange}
          value={value}
          rows={3}
          className={`${rootClassName}__textarea`}
        />
      );
    }
    return (
      <Typography className={`${rootClassName}__textarea_values`}>
        {value}
      </Typography>
    );
  }

  render() {
    const { title } = this.props;
    return (
      <>
        <Typography className={`${rootClassName}__questions_themselves`}>{title}</Typography>
        {
        this.renderText()
      }
      </>
    );
  }

  static defaultProps = {
    value: '',
    title: '',
    editMode: true,
    onChange: () => console.log('onchange'),
  }
}
