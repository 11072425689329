import updateFulfillment from './updateFulfillment';

const updateSelectedFulfillment = (updateData) => async (dispatch, getState) => {
  const {
    fulfillments: {
      selectedFulfillment,
    },
  } = getState();

  await dispatch(updateFulfillment(selectedFulfillment)(updateData));
};

export default updateSelectedFulfillment;
