import numeral from 'numeral';

import formats from '../formats';

export const currency = {
  full: (value) => numeral(value).format(formats.currency.full),
  short: (value) => numeral(value).format(formats.currency.short),
};

export const pretty = {
  int: (value) => numeral(value).format(formats.numbers.pretty.int),
  dec: (value) => numeral(value).format(formats.numbers.pretty.dec),
};

export default numeral;
