exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".emailCardFooter_email-card-footer__3dU5z {\n  display: flex;\n  flex-direction: row;\n  align-items: baseline;\n  justify-content: space-between;\n  margin-top: 5px;\n  max-width: 100%;\n  max-height: 30px;\n}\n\n.emailCardFooter_email-card-link__8lGuY {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n}\n\n.emailCardFooter_email-card-link-container__2Z4D9 {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  border-radius: 20px;\n  padding: 8px;\n  max-width: 130px;\n  max-height: 25px;\n  grid-gap: 4px;\n  gap: 4px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.1098039216);\n}\n\n.emailCardFooter_email-card-link-a-tag__P4-iP {\n  max-width: 100%;\n  color: #141736;\n  font-weight: 500;\n  font-size: 12px;\n}\n\n.emailCardFooter_email-card-types-container__2zbrd {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n}\n\n.emailCardFooter_email-card-items-to-complete__m6M1- {\n  padding-left: 0.6rem;\n  padding-right: 0.6rem;\n  padding-top: 0.2rem;\n  padding-bottom: 0.2rem;\n  display: flex;\n  background-color: #f6f6f6;\n  border-radius: 20px;\n  width: -webkit-fit-content;\n  width: -moz-fit-content;\n  width: fit-content;\n  align-items: center;\n  margin-left: 3px;\n}\n\n.emailCardFooter_email-card-items-to-complete-text__2xkKI {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 22px;\n  height: 22px;\n  color: #367BED;\n  font-size: 15px;\n  font-weight: 500;\n}", ""]);

// exports
exports.locals = {
	"email-card-footer": "emailCardFooter_email-card-footer__3dU5z",
	"email-card-link": "emailCardFooter_email-card-link__8lGuY",
	"email-card-link-container": "emailCardFooter_email-card-link-container__2Z4D9",
	"email-card-link-a-tag": "emailCardFooter_email-card-link-a-tag__P4-iP",
	"email-card-types-container": "emailCardFooter_email-card-types-container__2zbrd",
	"email-card-items-to-complete": "emailCardFooter_email-card-items-to-complete__m6M1-",
	"email-card-items-to-complete-text": "emailCardFooter_email-card-items-to-complete-text__2xkKI"
};