import {
  SET_REALTOR_SELECTED_CONTACT_ROWS,
} from 'store/actionTypes/realtorContacts';

const setSelectedContactRows = (ids) => ({
  type: SET_REALTOR_SELECTED_CONTACT_ROWS,
  payload: ids,
});

export default setSelectedContactRows;
