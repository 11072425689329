import {
  SET_NEW_CONTACT_FORM_FIELD,
} from 'store/actionTypes/crm';

/**
 *
 * @param {Object} payload
 * @param {string} payload.key
 * @param {string} payload.value
 */
const setNewContactFormField = (key) => (value) => ({
  type: SET_NEW_CONTACT_FORM_FIELD,
  payload: {
    key,
    value,
  },
});

export default setNewContactFormField;
