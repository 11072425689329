import React from 'react';
import styles from './toggleButton.module.css';

const ToggleButton = ({ name, children, selectedFilter, onClick }) => {
    const isActive = selectedFilter === name
    const buttonClass = isActive ? styles['toggle-btn--active'] : styles['toggle-btn'];

    return (
        <button className={buttonClass} onClick={onClick}>
            {children}
        </button>
    );
};

export default ToggleButton;
