import React from 'react';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';

import LeadTypeSelect from './LeadTypeSelect';
import TempSelect from './TempSelect';
import NewContactArea from './NewContactArea';

const NewLeadForm = (props) => (
  <form>
    <Flex spacing={4} flexWrap="wrap">
      <Flex
        fullWidth
        justifyContent="space-between"
      >
        <div>
          <Typography variant="title2">
            Type
          </Typography>
          <LeadTypeSelect />
        </div>
        <div>
          <Typography variant="title2">
            Temperature
          </Typography>
          <TempSelect />
        </div>
      </Flex>

      <div>
        <Typography variant="title2">
          Lead details
        </Typography>
        <NewContactArea
          title={null}
        />
      </div>
    </Flex>
  </form>
  );

export default NewLeadForm;
