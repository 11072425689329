import React, {
  useMemo,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import { currency } from 'refactored/utils/numbers';

import Elevation from 'ui/Elevation';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Button from 'ui/Button';
import Typography from 'ui/Typography';

import usePropertyData from 'sections/MLS/hooks/usePropertyData';

import PropertyImageCell from 'shared/components/UI/TableCells/RealtorCells/PropertyImageCell';


const PropertyPreview = (props) => {
  const {
    propertyId,
  } = props;

  const propertyData = usePropertyData(propertyId);

  const address = useMemo(() => propertyData?.address?.address, [
    propertyData?.address_id,
  ]);

  const price = useMemo(() => {
    if (!propertyData?.expected_price) return null;

    return currency.short(propertyData?.expected_price);
  }, [
    propertyData?.expected_price,
  ]);

  const goToProperty = useCallback((event) => {
    event.preventDefault();
    window.open(`/mls/${propertyId}`, '_blank');
  }, [
    propertyId,
  ]);

  return (
    <Elevation rounded="m" hideOverflow>
      <Flex fullWidth spacing={2} alignItems="stretch">
        <FlexItem xs={6}>
          <PropertyImageCell
            propertyId={propertyId}
            rounded={false}
            height="100%"
          />
        </FlexItem>
        <FlexItem xs={12} padding="8px 0 0 0">
          {
            address && (
              <Typography
                variant="title3"
                weight="bold"
              >
                {address}
              </Typography>
            )
          }
          {
            price && (
              <Typography
                color="money"
                weight="bold"
              >
                {price}
              </Typography>
            )
          }
        </FlexItem>
        <FlexItem xs={6} alignSelf="center">
          <Button
            variant="secondary"
            onClick={goToProperty}
          >
            View Details
          </Button>
        </FlexItem>
      </Flex>
    </Elevation>
  );
};

const {
  number,
} = PropTypes;

PropertyPreview.propTypes = {
  propertyId: number.isRequired,
};

export default PropertyPreview;
