const sympleteApiContactToNylasContact = (contact) => {
    const formattedContact = {
        id: contact.id || 0,
        name: `${contact.title_name || ''} ${contact.first_name || ''} ${contact.last_name || ''}`.trim(),
        email: contact.email_primary || '',
        phone: contact.phone_primary ? `+${contact.phone_primary}` : '',
    };

    return formattedContact;
}

export default sympleteApiContactToNylasContact