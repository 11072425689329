exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".emailListItemDropdown_email-list-item-menu-item__v-kzc {\n  width: 100%;\n  padding: 5px 15px;\n  justify-content: flex-start;\n  cursor: pointer;\n  font-family: \"Hind-Regular\";\n  display: flex;\n  grid-gap: 4px;\n  gap: 4px;\n  justify-content: flex-start;\n  align-content: center;\n}\n\n.emailListItemDropdown_email-list-item-menu-item__v-kzc img {\n  height: auto;\n  width: 20px;\n}\n\n.emailListItemDropdown_email-list-item-menu-item__v-kzc:hover {\n  background-color: #eee;\n}\n\n.emailListItemDropdown_email-list-item-menu-item__v-kzc:active {\n  background-color: #ddd;\n  transition: 0.2s;\n}\n\n.emailListItemDropdown_email-list-item-border-box__3X78k {\n  margin: 10px 15px;\n  border-bottom: solid 1px;\n  border-color: #ddd;\n}\n\n.emailListItemDropdown_email-list-item-menu__3YM2m {\n  -webkit-transform: translate(-12px, 34px);\n          transform: translate(-12px, 34px);\n}", ""]);

// exports
exports.locals = {
	"email-list-item-menu-item": "emailListItemDropdown_email-list-item-menu-item__v-kzc",
	"email-list-item-border-box": "emailListItemDropdown_email-list-item-border-box__3X78k",
	"email-list-item-menu": "emailListItemDropdown_email-list-item-menu__3YM2m"
};