/* eslint-disable react/jsx-no-bind */
import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import requests from 'api/requests';

// import EmbedButtons from 'sections/Integrations/components/EmbedButtons';

import AssignContactModal from 'sections/Contacts/modals/AssignContactModal';

import useModal from 'ui/hooks/useModal';

import Flex from 'ui/Flex';
// import Typography from 'ui/Typography';
import Divider from 'ui/Divider';
import FileDropzoneWithThumbs from 'ui/FileDropzoneWithThumbs';
import TextEditor from 'ui/TextEditor';
import Spin from 'ui/Spin';

import EmailSendButton from 'sections/Integrations/modules/EmailSendButton';

import Sender from 'sections/Integrations/modules/EmailComposer/Sender';
import Recipient from 'sections/Integrations/modules/EmailComposer/Recipient';
import Subject from 'sections/Integrations/modules/EmailComposer/Subject';

import getEmail from './getEmail';

import './styles.scss';

const getEmails = (items) => items
  .map(getEmail)
  .filter((item) => !!item);

const EmailComposer = (props) => {
  const {
    emailTo: emailToProps,
    onChangeEmailTo,
    onSend,
    subject: subjectProps,
    replyToMessageId,
    emailFromTokenId: emailFromTokenIdProps,
    elementsToShow,
    uploadAttachment,
    // email forwarding
    attachmentsIds: attachmentsIdsProps,
    message: messageProps,
  } = props;

  const {
    isModalVisible: isModalVisibleEmailTo,
    openModal: openModalEmailTo,
    closeModal: closeModalEmailTo,
  } = useModal();

  const {
    isModalVisible: isModalVisibleCc,
    openModal: openModalCc,
    closeModal: closeModalCc,
  } = useModal();

  const {
    isModalVisible: isModalVisibleBcc,
    openModal: openModalBcc,
    closeModal: closeModalBcc,
  } = useModal();

  const [emailTo, setEmailTo] = useState(emailToProps);
  const [cc, setCc] = useState([]);
  const [bcc, setBcc] = useState([]);

  const [subject, setSubject] = useState(subjectProps);
  const [message, setMessage] = useState(messageProps);
  const [attachments, setAttachments] = useState([]);

  const [emailFromTokenId, setEmailFromTokenId] = useState(emailFromTokenIdProps);
  const [loading, setLoading] = useState(false);

  const reset = () => {
    setMessage('');
    setSubject('');
    setEmailFromTokenId(null);
    setAttachments([]);
  };

  const sendEmail = useCallback(async () => {
    setLoading(true);

    // upload attachments prior to including them in email
    const attachmentsIds = Array.isArray(attachmentsIdsProps) ? [...attachmentsIdsProps] : [];

    if (attachments) {
      const uploadAttachmentsRequests = attachments.map(async (file) => {
        const attachmentData = await uploadAttachment(emailFromTokenId)(file);
        if (attachmentData?.id) {
          attachmentsIds.push(attachmentData.id);
        }
      });
      await Promise.all(uploadAttachmentsRequests);
    }

    const emailData = {
      emailTo: getEmails(emailTo),
      cc: getEmails(cc),
      bcc: getEmails(bcc),

      subject,

      message,
      attachmentsIds,

      tokenId: emailFromTokenId,
      replyToMessageId,
    };

    const sendResponse = await requests.integrations.emails.send(emailData);

    onSend && onSend(emailData, sendResponse); // eslint-disable-line chai-friendly/no-unused-expressions

    reset();
    setLoading(false);
  }, [
    onSend,
    subject,
    message,
    emailTo,
    emailFromTokenId,
    replyToMessageId,
    cc,
    bcc,
    attachments,
    attachmentsIdsProps,
  ]);

  const onSaveEmailTo = useCallback((selectedContacts) => {
    const newEmailTo = [...new Set([...emailTo, ...selectedContacts?.addedContacts])];
    setEmailTo(newEmailTo);
    closeModalEmailTo();
  }, [
    emailTo,
  ]);

  const onRemoveEmailTo = useCallback((contactId) => {
    setEmailTo(emailTo.filter((item) => item !== contactId));
  }, [
    emailTo,
    setEmailTo,
  ]);

  const onSaveCc = useCallback((selectedContacts) => {
    setCc([...new Set([...cc, ...selectedContacts?.addedContacts])]);
    closeModalCc();
  }, [
    cc,
    setCc,
    closeModalCc,
  ]);

  const onRemoveCc = useCallback((contactId) => {
    setCc(cc.filter((item) => item !== contactId));
  }, [
    cc,
    setCc,
  ]);

  const onSaveBcc = useCallback((selectedContacts) => {
    setBcc([...new Set([...bcc, ...selectedContacts?.addedContacts])]);
    closeModalBcc();
  }, [
    bcc,
    setBcc,
    closeModalBcc,
  ]);

  const onRemoveBcc = useCallback((contactId) => {
    setBcc(bcc.filter((item) => item !== contactId));
  }, [
    bcc,
    setBcc,
  ]);

  useEffect(() => {
    onChangeEmailTo(emailTo);
  }, [
    emailTo,
  ]);

  return (
    <TextEditor.ContextProvider
      content={message}
      onChange={setMessage}
    >
      <Spin
        spinning={loading}
        wrapperClassName="email-composer-spin-wrapper"
      >
        <Flex
          flexDirection="column"
          spacing={1}
          alignItems="stretch"
          fullHeight
        >
          {/* <Flex
            fullWidth
            flexDirection="column"
            spacing={1}
          >
            <Typography>
              Embed relevant Symplete objects into this email (coming soon)
            </Typography>
            <EmbedButtons />
          </Flex> */}

          {/* <Divider /> */}

          {
            elementsToShow.includes('sender') && (
              <Sender
                onChange={setEmailFromTokenId}
              />
            )
          }
          {
            elementsToShow.includes('recipient') && (
              <Recipient
                emailTo={emailTo}
                onAddEmailTo={openModalEmailTo}
                onRemoveEmailTo={onRemoveEmailTo}
                //
                cc={cc}
                onAddCc={openModalCc}
                onRemoveCc={onRemoveCc}
                //
                bcc={bcc}
                onAddBcc={openModalBcc}
                onRemoveBcc={onRemoveBcc}
              />
            )
          }
          {
            elementsToShow.includes('subject') && (
              <Subject
                onChange={setSubject}
                value={subject}
              />
            )
          }

          <Divider />

          <Flex
            fullWidth
            fullHeight
            flexDirection="column"
            spacing={2}
          >
            <TextEditor.Content />
            <Flex
              fullWidth
              justifyContent="space-between"
            >
              <TextEditor.Toolbar />
              <EmailSendButton onSend={sendEmail} />
            </Flex>
            {
              elementsToShow.includes('attachments upload') && (
                <FileDropzoneWithThumbs
                  text="Drop a file or click here to attach"
                  onChange={setAttachments}
                  files={attachments}
                  size="s"
                />
              )
            }
          </Flex>
        </Flex>
      </Spin>

      <AssignContactModal
        getContainer
        title="Who is this email for?"
        isVisible={isModalVisibleEmailTo}
        closeModal={closeModalEmailTo}
        onSave={onSaveEmailTo}
        withAddContact
      />

      <AssignContactModal
        getContainer
        title="Choose CC recepients"
        isVisible={isModalVisibleCc}
        closeModal={closeModalCc}
        onSave={onSaveCc}
        withAddContact
      />

      <AssignContactModal
        getContainer
        title="Choose BCC recepients"
        isVisible={isModalVisibleBcc}
        closeModal={closeModalBcc}
        onSave={onSaveBcc}
        withAddContact
      />
    </TextEditor.ContextProvider>
);
};

EmailComposer.defaultProps = {
  onSend: null,
  emailTo: [],
  emailFromTokenId: null,
  subject: '',
  replyToMessageId: null,
  uploadAttachment: () => () => {},
  onChangeEmailTo: () => {},
  attachmentsIds: [],
  message: '',
  elementsToShow: [
    'sender',
    'recipient',
    'subject',
    'attachments upload',
  ],
 };

const {
  arrayOf,
  func,
  string,
  number,
} = PropTypes;

EmailComposer.propTypes = {
  emailTo: arrayOf(number),
  onSend: func,
  subject: string,
  elementsToShow: arrayOf(string),
  emailFromTokenId: number,
  replyToMessageId: string,
  uploadAttachment: func,
  onChangeEmailTo: func,
  attachmentsIds: arrayOf(string),
  message: string,
};

export default EmailComposer;
