import {
  SET_AGREEMENTS,
} from 'store/actionTypes/agreements';

const setAgreements = (payload) => ({
  type: SET_AGREEMENTS,
  payload,
});

export default setAgreements;
