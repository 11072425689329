import React from 'react';
import ContactPillList from './contactPillList/ContactPillList';
import ContactThumbnailSelector from './contactThumbnailSelector/ContactThumbnailSelector';
import StyledAntdModal from 'refactored/components//shared/styledAntd/styledAntdModal';
import useContactPillPicker from './ContactPillPicker.hook';
import SquarePrimaryButton from 'refactored/components//shared/buttons/squarePrimaryButton/SquarePrimaryButton'
import styles from './contactPillPicker.module.css'

const ContactPillPicker = ({ className, placeholder, setContactsCallback, initialSelectedContacts, inReplyToId }) => {
    const {
        selectedContacts,
        isOpenModal,
        toggleModal,
        handleContactRemove,
        handleContactPreselect,
        cleanedContacts,
        stopPropagation,
        handleDone,
        handleModalOpen,
        preselectedContacts,
        wrapperRef,
        top,
        newContactMail,
        setNewContactMail,
        addToNewContacts
    } = useContactPillPicker({ setContactsCallback, initialSelectedContacts, inReplyToId })


    return (
        <div ref={wrapperRef} className={className} onClick={handleModalOpen}>
            <div className={styles['contact-pill-picker']} onClick={stopPropagation}>
                <span className={styles['contact-pill-picker-placeholder']}>{placeholder}</span>
                <ContactPillList selectedContacts={selectedContacts} onContactRemove={handleContactRemove} />
                <StyledAntdModal
                    style={{ top }}
                    visible={isOpenModal} onCancel={() => { toggleModal(false) }} >

                    <div className={styles['contact-pill-picker-header']}>
                        <h1 className={styles['contact-pill-tittle']}>Add new email to send</h1>
                        <SquarePrimaryButton onClick={handleDone}>Done</SquarePrimaryButton>
                    </div>
                    <div className={styles['contact-pill-picker-new-contact']}>
                        <input type="text" placeholder='email@example.com' value={newContactMail} onChange={(e) => {
                            setNewContactMail(e.target.value)
                        }} />
                        <button onClick={() => {
                            addToNewContacts()
                        }}>Add</button>
                    </div>
                    <div>
                        <h1 className={styles['contact-pill-tittle']}>Select contacts</h1>
                    </div>
                    <ContactThumbnailSelector contacts={cleanedContacts} preSelectedContacts={preselectedContacts} onSelect={handleContactPreselect} />
                </StyledAntdModal>
            </div>
        </div>
    );
};

export default ContactPillPicker;
