import { callAlert } from 'ui/Alert';

import requests from 'api/requests';

import setActiveChatId from './setActiveChatId';
import getChats from './getChats';

const setActiveDirectChatWithEmployee = (employeeId) => async (dispatch, getState) => {
  try {
    const {
      chat: {
        direct: {
          employee,
        },
      },
    } = getState();

    if (employee[employeeId]) {
      dispatch(setActiveChatId(employee[employeeId]));

      return null;
    }
    const newChat = await requests.chat.createDirectChat();
    const newChatId = newChat.data;

    await requests.chat.addChatMember(newChatId)({
      userType: 1,
      userId: employeeId,
    });

    await dispatch(getChats());

    dispatch(setActiveChatId(newChatId));

    return newChatId;
  } catch {
    callAlert.error('Error!');
    return null;
  }
};

export default setActiveDirectChatWithEmployee;
