import openModal from './openModal';
import openModalWithContentAction from './openModalWithContentAction';
import closeModal from './closeModal';

import handleModalFormCache from './handleModalFormCache';

export {
  openModal,
  openModalWithContentAction,
  closeModal,
  handleModalFormCache,
};

export default {
  openModal,
  openModalWithContentAction,
  closeModal,
  handleModalFormCache,
};
