import io from 'socket.io-client';

const socket = io(process.env.REACT_APP_OA_API_PATH);

socket.on('disconnect', (reason) => {
  if (reason === 'io server disconnect') {
    socket.connect();
  }

  console.error('Socket io disconnected.');
});

export default socket;
