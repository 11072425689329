import requests from 'api/requests';

const deleteDislike = (dislikeId) => async (dispatch) => {
  try {
    await requests.dislikes.deleteDislike(dislikeId);
  } catch (error) {
    console.error(error);
  }
};

export default deleteDislike;
