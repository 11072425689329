import React, { createContext, useContext, useState } from 'react';
import { createAttachment, getFileList } from 'store/actions/attachments';
import { useDispatch, useSelector } from 'react-redux';
import currentUserSelector from 'store/selectors/users/currentUser';

const ChangeProfilePictureModalContext = createContext();

export const ChangeProfilePictureModalProvider = ({ children }) => {
    const [isChangeProfilePictureModalOpen, setIsChangeProfilePictureModalOpen] = useState(false)
    const [profilePicture, setProfilePicture] = useState()
    const [profilePictureFile, setProfilePictureFile] = useState()
    const user = useSelector(currentUserSelector);
    const dispatch = useDispatch()

    const toggleChangeProfilePictureModal = (isOpen = false) => {
        setIsChangeProfilePictureModalOpen(isOpen)
    }

    const setNewProfilePictureFromFile = (file) => {
        if (file) {
            const reader = new FileReader();

            reader.onload = function (e) {
                setProfilePicture(e.target.result)
            };

            reader.readAsDataURL(file);
            setProfilePictureFile(file);
        } else {
            setProfilePicture(null)
        }
    }

    const afterFileUpload = (res) => {
        //setLoadingStatus(false);

        const sortedByDate = res.sort((a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt));
        const currentImg = sortedByDate[0];

        if (currentImg) {
            if (imageActionHooks && imageActionHooks.onAdd) imageActionHooks.onAdd(currentImg.Location);
        }

        if (handleOnUpload) {
            console.log('uploaded', currentImg)
            //handleOnUpload();
        }
    };

    const saveNewProfilePicture = async () => {
        console.log('saving picture...')
        //setLoadingStatus(true);

        dispatch(createAttachment('employee-logo')(user.EmployeeID)(profilePictureFile, false, true, true));
        dispatch(getFileList(afterFileUpload));
    };

    return (
        <ChangeProfilePictureModalContext.Provider value={{
            saveNewProfilePicture,
            profilePicture,
            isChangeProfilePictureModalOpen,
            toggleChangeProfilePictureModal,
            setNewProfilePictureFromFile
        }}>
            {children}
        </ChangeProfilePictureModalContext.Provider>
    );
}

export const useChangeProfilePictureModal = () => {
    return useContext(ChangeProfilePictureModalContext);
};
