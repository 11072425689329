import { connect } from 'react-redux';

import { currency } from 'refactored/utils/numbers';

import selectors from 'store/selectors';
import { isUndefined } from 'shared/utility';
import CurrentOverview from './CurrentOverview';

const mapStateToProps = (state) => {
  const leadsSummary = selectors.realtorLeads.overallSummary(state);
  const opportunitiesSummary = selectors.realtorOpportunities.overallSummary(state);
  const transactionsSummary = selectors.realtorTransactions.overallSummary(state);

  const values = {
    leads: [
      {
        value: isUndefined(leadsSummary?.new) ? 'N/A' : leadsSummary?.new,
        name: 'New',
      },
      {
        value: isUndefined(leadsSummary?.active) ? 'N/A' : leadsSummary?.active,
        name: 'Active',
      },
    ],
    opportunities: [
      {
        value: isUndefined(opportunitiesSummary?.clients) ? 'N/A' : opportunitiesSummary?.clients,
        name: 'Sellers',
      },
      {
        value: isUndefined(opportunitiesSummary?.prospects) ? 'N/A' : opportunitiesSummary?.prospects,
        name: 'Buyers',
      },
    ],
    offers: [
      {
        value: isUndefined(opportunitiesSummary?.with_offers) ? 'N/A' : opportunitiesSummary?.with_offers,
        name: 'Total',
      },
      {
        value: isUndefined(opportunitiesSummary?.potential_commission)
          ? 'N/A'
          : currency.short(opportunitiesSummary?.potential_commission),
        name: 'Pot. Comm.',
      },
    ],
    transactions: [
      {
        value: isUndefined(transactionsSummary?.total) ? 'N/A' : transactionsSummary?.total,
        name: 'Total',
      },
      {
        value: isUndefined(transactionsSummary?.potential_commission)
          ? currency.short(0)
          : currency.short(transactionsSummary?.potential_commission),
        name: 'Act. Comm.',
      },
    ],
  };

  return {
    values,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentOverview);
