import styled from 'styled-components';

import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';

const signSize = 50;
const stickHeight = 0;

const labelMargin = 15;
const labelHeight = 40;

export const Root = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  margin-top: ${signSize + stickHeight}px;
  margin-bottom: ${labelMargin + labelHeight}px;
`;

export const TopWrapper = styled(NakedButton)`
&& {
  position: absolute;
  bottom: -5px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}
`;

const signBorderColor = '#dfdfdf';

export const Sign = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${signSize}px;
  width: ${signSize}px;
  border: solid 0.5px ${signBorderColor};
  border-radius: 50%;
  background-color: white;
  font-size: ${signSize * 0.5}px;
  cursor: pointer;
  &:hover {
    background-color: #dfdfdf;
  }
`;

const stickWidth = 2;
const stickColor = '#d3dbe4';

export const Stick = styled.div`
  height: ${stickHeight}px;
  width: ${stickWidth}px;
  background-color: ${stickColor};
`;

const dotSize = 10;
const dotColor = '#d3dbe4';

export const Dot = styled.div`
  position: relative;
  height: ${dotSize}px;
  width: ${dotSize}px;
  border-radius: 50%;
  border: solid 2px white;
  background-color: ${dotColor};
  ${(props) => props.isFirst && `
    &::before {
      position: absolute;
      top: 1px;
      right: calc(100% + 2px);
      content: '';
      display: block;
      height: 4px;
      width: 70px;
      background-color: #f0f0f0;
    }
  `}
  ${(props) => props.isLast && `
    &::after {
      position: absolute;
      top: 1px;
      left: calc(100% + 2px);
      content: '';
      display: block;
      height: 4px;
      width: 100px;
      background-color: #f0f0f0;
    }
  `}
`;

export const Label = styled.div`
  position: absolute;
  top: 100%;
  max-width: 100px;
  max-height: ${labelHeight}px;
  margin-top: ${labelMargin}px;
  text-align: center;
`;

export const ItemTitle = styled.div`
  width: 130px;
  text-align: center;
  background-color: ${(props) => (props.isSelected ? props.selectedItemColor : '#c7c9ca')};
  border-radius: 4px;
`;
