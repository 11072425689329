import expandModal from './expandModal';
import shrinkModal from './shrinkModal';
import closeAllModals from './closeAllModals';
import setActiveMeeting from './setActiveMeeting';
import createMeeting from './createMeeting';
import fetchMeetings from './fetchMeetings';
import fetchMeeting from './fetchMeeting';
import updateMeeting from './updateMeeting';
import createDesiredOutcome from './createDesiredOutcome';
import updateDesiredOutcome from './updateDesiredOutcome';
import closeJoinMeetingModal from './closeJoinMeetingModal';
import openMeetingDetailsModal from './openMeetingDetailsModal';
import closeMeetingDetailsModal from './closeMeetingDetailsModal';
import openMeetingAgendaModal from './openMeetingAgendaModal';
import closeMeetingAgendaModal from './closeMeetingAgendaModal';
import showSmallModal from './showSmallModal';
import showLargeModal from './showLargeModal';
import setActiveMeetingParticipants from './setActiveMeetingParticipants';

export {
  showSmallModal,
  showLargeModal,
  expandModal,
  shrinkModal,
  closeAllModals,
  setActiveMeeting,
  createMeeting,
  fetchMeetings,
  fetchMeeting,
  updateMeeting,
  createDesiredOutcome,
  updateDesiredOutcome,
  closeJoinMeetingModal,
  openMeetingDetailsModal,
  closeMeetingDetailsModal,
  openMeetingAgendaModal,
  closeMeetingAgendaModal,
  setActiveMeetingParticipants,
};

export default {
  showSmallModal,
  expandModal,
  shrinkModal,
  closeAllModals,
  setActiveMeeting,
  createMeeting,
  fetchMeetings,
  fetchMeeting,
  updateMeeting,
  createDesiredOutcome,
  updateDesiredOutcome,
  closeJoinMeetingModal,
  openMeetingDetailsModal,
  closeMeetingDetailsModal,
  openMeetingAgendaModal,
  closeMeetingAgendaModal,
  setActiveMeetingParticipants,
};
