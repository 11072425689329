import requests from 'api/requests';

const deleteNotablePoint = (pointId) => async (dispatch) => {
  try {
    await requests.realtor.notablePoints.deleteNotablePoint(pointId);
  } catch (error) {
    console.error(error);
  }
};

export default deleteNotablePoint;
