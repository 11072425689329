import connectReducers from 'store/connectReducers';

import {
  SET_NEW_REALTOR_PROPERTY_FORM_FIELD,
  SET_NEW_REALTOR_PROPERTY_FORM_NESTED_FIELD,
  ADD_NEW_REALTOR_PROPERTY_FORM_LIST_ITEM,
  REMOVE_NEW_REALTOR_PROPERTY_FORM_LIST_ITEM,
  UPDATE_NEW_REALTOR_PROPERTY_FORM_LIST_ITEM,
  RESET_NEW_REALTOR_PROPERTY_FORM,
  SET_NEW_REALTOR_PROPERTY_FORM,
} from 'store/actionTypes/realtorMls';

import {
  setValue,
  setNestedValue,
  addListItem,
  removeListItem,
  updateListItem,
} from 'store/utils';

const initialState = {
  address_id: null,
  expected_price: 0,
  mls_number: null,
  mls_website: null,
  on_market_since: null,
  property_type: null,
  listing_agent: 0,
  newSellerAgent: {
    type: [1],
  },
  reo_bank_owned: 0,
  in_foreclosure: 0,
  short_sale: 0,
  flood_ins_req: 0,
  auction: 0,
  water_shares: 0,
  hud_owned: 0,
  images: [],
  mainImageIndex: 0,
  interested_parties: [],
  temp: null,
  seller: null,

  loading: false,
};

const reducers = {
  [SET_NEW_REALTOR_PROPERTY_FORM_FIELD]: setValue,
  [SET_NEW_REALTOR_PROPERTY_FORM_NESTED_FIELD]: setNestedValue,
  [ADD_NEW_REALTOR_PROPERTY_FORM_LIST_ITEM]: addListItem,
  [REMOVE_NEW_REALTOR_PROPERTY_FORM_LIST_ITEM]: removeListItem,
  [UPDATE_NEW_REALTOR_PROPERTY_FORM_LIST_ITEM]: updateListItem,
  [RESET_NEW_REALTOR_PROPERTY_FORM]: () => initialState,
  [SET_NEW_REALTOR_PROPERTY_FORM]: (state, action) => ({
    ...state,
    ...action.payload,
    images: [],
    mainImageIndex: 0,
    loading: false,
  }),
};

function newPropertyForm(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default newPropertyForm;
