import React from 'react';
import ContactImage from './ContactImage';
import styles from './contactThumbnail.module.css';

const ContactThumbnail = ({ contact, onClick, selected = false }) => {
    const containerClassName = `${styles['contact-thumbnail-container']} ${selected ? styles['contact-thumbnail-container-selected'] : ''}`;

    return (
        <div className={containerClassName} onClick={onClick}>
            <ContactImage imageUrl={contact?.image || ''} altText={contact?.name || ''} />
            <div className={styles['contact-thumbnail-data-container']}>
                <span className={styles['contact-thumbnail-name']}>{`${contact?.name}`}</span>
                <div className={styles['contact-thumbnail-email']}>{`${contact?.email}`}</div>
                <div className={styles['contact-thumbnail-phone']}>{`${contact?.phone}`}</div>
            </div>
        </div>
    );
};

export default ContactThumbnail;

