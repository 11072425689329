exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".profileThumbnail_profile-thumbnail-container__16GCM {\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n  justify-content: center;\n  align-items: center;\n  grid-gap: 4px;\n  gap: 4px;\n  height: 91px;\n  color: #000;\n}\n\n.profileThumbnail_profile-thumbnail-name__2TeFz {\n  padding: 0px;\n  border-radius: 5px;\n  font-family: \"Hind-Bold\";\n  font-weight: 700;\n  line-height: 22px;\n  justify-self: flex-end;\n  align-self: flex-start;\n  text-transform: capitalize;\n  font-size: 30px;\n  cursor: pointer;\n}\n\n.profileThumbnail_profile-thumbnail-email__1Ur2J {\n  margin-top: 10px;\n  width: 100%;\n  border-radius: 5px;\n  align-self: flex-start;\n  font-family: \"Hind-Regular\";\n  font-size: 15px;\n  font-weight: 400;\n  line-height: 16px;\n  letter-spacing: 0px;\n  text-align: left;\n  cursor: pointer;\n  overflow: visible;\n  overflow-wrap: unset;\n  display: flex;\n  justify-content: flex-start;\n  grid-gap: 4px;\n  gap: 4px;\n}", ""]);

// exports
exports.locals = {
	"profile-thumbnail-container": "profileThumbnail_profile-thumbnail-container__16GCM",
	"profile-thumbnail-name": "profileThumbnail_profile-thumbnail-name__2TeFz",
	"profile-thumbnail-email": "profileThumbnail_profile-thumbnail-email__1Ur2J"
};