exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".changePasswordModal_modal-title__1_SwC {\n  font-family: \"Hind-Regular\";\n  font-size: 22px;\n  font-weight: 600;\n  max-width: 70%;\n  margin: 0px;\n  max-height: 64px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.changePasswordModal_change-password-modal__2v5jQ {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}", ""]);

// exports
exports.locals = {
	"modal-title": "changePasswordModal_modal-title__1_SwC",
	"change-password-modal": "changePasswordModal_change-password-modal__2v5jQ"
};