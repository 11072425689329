import styled from 'styled-components';

import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';

export const Tile = styled(NakedButton)`
&& {
  height: 85px;
  width: 85px;
  min-width: 85px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(${(props) => props.thumbUrl});
  background-color: rgba(61, 91, 144, 0.3);
  border: 1px solid ${(props) => props?.theme?.palette?.['input-border']};
  border-radius: 8px;
  cursor: pointer;
  position: relative;
}
`;

export default {
  Tile,
};
