import requests from 'api/requests';

// eslint-disable-next-line consistent-return
const createFulfillmentFromExisting = (fulfillmentId) => (fulfillmentData) => async (dispatch) => {
  try {
    const response = await requests.fulfillments.createFromExisting(fulfillmentId)(fulfillmentData);

    return response.data.fulfillmentId;
  } catch (error) {
    console.info(error);
  }
};

export default createFulfillmentFromExisting;
