import styled, { css } from 'styled-components';

import MaskedInput from 'react-text-mask';
import AutonumericInput from 'react-numeric';

import Typography from 'ui/Typography';
import Button from 'ui/Button';

const getInputHeight = (props) => {
  if (props.isDense) return '28px';
  return '44px';
};

export const Root = styled.div`
  position: relative;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};

  border-radius: 10px;

  /* overflow: hidden; */

  background-color: ${(props) => (props.transparent ? 'transparent' : props.theme?.palette?.background || 'transparent')};
`;

export const Label = styled(Typography)`
  position: absolute;
  pointer-events: none;

  opacity: ${(props) => (props.active ? 1 : 0.4)};

  ${(props) => (props.active
    ? `
        left: 13px;
        top: -9px;
        transition-duration: 0.15s;
        transform: initial;
        z-index: 1;
        padding: 2px 4px;
        background-color: white;
      `
    : `
        left: ${props.icon && props.iconPosition === 'left' ? '33px' : '17px'};
        top: 13px;
        padding-right: 4px;
        transition-duration: 0.15s;
        background-color: white;
      `)
  }
`;

export const HintMessage = styled(Typography)`
  position: absolute;
  left: 17px;
  bottom: -28px;
  z-index: 1000;
`;

const commonInputStyles = css`
  position: relative;
  display: block;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  min-width: 63px;
  ${({ value, autoWidth }) => (autoWidth ? `width: ${49 + String(value).length * 11}px;` : null)};
  height: ${getInputHeight};

  padding-left: ${(props) => (props.icon && props.iconPosition === 'left' ? 38 : 17)}px;
  padding-right: ${(props) => {
    if (props.type === 'number') return 4;

    return (props.icon && props.iconPosition === 'right') || props.withControl ? 38 : 17;
  }}px;

  background-color: rgba(2, 35, 39, 0.04);
  font-size: ${(props) => props.theme?.typography?.fontSize?.body || '14px'};
  color: ${(props) => props.theme?.palette?.[props.textColor || 'main-text'] || 'blue'};

  border-width: 1px;
  border-style: none;
  border-radius: 10px; 
 
  ${(props) => props.error && `color: ${props.theme?.palette?.error || 'red'};`}
 
  ${(props) => props.disabled && `color: ${props.theme?.palette?.disabled || 'gray'};`}

  &:focus,
  &:active {
    outline: none;
    border: solid 1px #ccc;
  };
  ::placeholder {
    font-style: italic;
    color: ${(props) => props.theme?.palette?.placeholder || 'gray'};
  }
`;

export const InputWithMask = styled(MaskedInput)`
  ${commonInputStyles}
`;

export const InputCurrency = styled(AutonumericInput)`
  ${commonInputStyles}
  text-align: right;
`;

export const InputNoMask = styled.input`
  ${commonInputStyles}
  text-align: ${(props) => (props.type === 'number' ? 'right' : 'left')};
  ${(props) => (props.type === 'number' ? `
    &&::-webkit-outer-spin-button,
    &&::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
    padding-right: 32px;
  ` : null)};
  ::placeholder {
    transform: none;
    font-style: normal;
  }
`;

export const InputTextArea = styled.textarea`
  ${commonInputStyles}
  padding-top: 8px;
  padding-bottom: 8px;
  resize: none;
  max-height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    background-color: rgba(128, 128, 128, 0.1);
    border-radius-top-right: 8px;
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(128, 128, 128, 0.2);
    border-radius: 3px;
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: ${(props) => (props.iconPosition === 'right' ? '8px' : 'auto')};
  left: ${(props) => (props.iconPosition === 'left' ? '8px' : 'auto')};
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
`;

export const ControlButtonsWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translate(0, -50%);
`;

export const VisibilityToggleButton = styled(Button)`
  width: auto;
  padding: 0 5px;
`;
