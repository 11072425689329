exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".richTextEditor_btn-container__1HX8a {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n.richTextEditor_btn-container__1HX8a > span {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #f6f6f6;\n  border-radius: 50px;\n  grid-gap: 6px;\n  gap: 6px;\n  padding: 4px 4px;\n}\n\n.richTextEditor_style-btn__1kiJw {\n  background-color: transparent;\n  border-radius: 50px;\n  padding: 4px;\n  outline: none;\n  border: none;\n}\n\n.richTextEditor_style-btn-active__2UDon {\n  background-color: white;\n}\n\n.richTextEditor_style-btn__1kiJw:hover {\n  background-color: white;\n}\n\n.richTextEditor_editor-container__2mz0P {\n  height: 100%;\n  overflow-y: hidden;\n}\n\n.richTextEditor_editor-wrapper__1wFkp {\n  height: min(500px, 100% - 40px);\n  overflow-y: auto;\n}", ""]);

// exports
exports.locals = {
	"btn-container": "richTextEditor_btn-container__1HX8a",
	"style-btn": "richTextEditor_style-btn__1kiJw",
	"style-btn-active": "richTextEditor_style-btn-active__2UDon",
	"editor-container": "richTextEditor_editor-container__2mz0P",
	"editor-wrapper": "richTextEditor_editor-wrapper__1wFkp"
};