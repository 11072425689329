/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundary from 'ui/ErrorBoundary';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Typography from 'ui/Typography';

import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';
import ContactAvatar from 'shared/components/UI/RealtorContactAvatar';

const ContactInfo = (props) => {
  const {
    contactData,
  } = props;

  const {
    name,
    email,
    phone,
    avatarUrl,
  } = contactData;

  return (
    <ErrorBoundary>
      <Flex
        spacing={2}
        flexWrap="wrap"
      >
        <Flex
          fullWidth
          spacing={2}
          flexWrap="wrap"
        >
          <ContactAvatar
            src={avatarUrl}
            label="listing agent"
          />

          <FlexItem flex={1}>
            <Flex
              spacing={1}
              flexWrap="wrap"
            >
              <FlexItem fullWidth>
                <Typography
                  noMargin
                  variant="title1"
                >
                  { name }
                </Typography>
              </FlexItem>

              <FlexItem fullWidth>
                <DecoratedFieldWrapper
                  iconName="mail"
                  iconType="feather"
                  size="sm"
                  variant="focus"
                >
                  <Typography noMargin>
                    {email || '—'}
                  </Typography>
                </DecoratedFieldWrapper>
              </FlexItem>

              <FlexItem fullWidth>
                <DecoratedFieldWrapper
                  iconName="phone"
                  iconType="feather"
                  variant="focus"
                  size="sm"
                >
                  <Typography noMargin>
                    {phone || '—'}
                  </Typography>
                </DecoratedFieldWrapper>
              </FlexItem>
            </Flex>
          </FlexItem>
        </Flex>
      </Flex>
    </ErrorBoundary>
  );
};

ContactInfo.defaultProps = {
  contactData: {},
};

const {
  shape,
} = PropTypes;

ContactInfo.propTypes = {
  contactData: shape({}),
};

export default ContactInfo;
