import React from 'react';

import PropTypes from 'prop-types';

import { currency } from 'refactored/utils/numbers';

import useModal from 'ui/hooks/useModal';
import useAttachments from 'shared/hooks/useAttachments';

import Flex from 'ui/Flex';
import Button from 'ui/Button';
import Typography from 'ui/Typography';

import PreApprovalForm from 'sections/Properties/modules/RequirementsPreApproval/PreApprovalForm';

import withPropertyRequirementData from 'sections/Properties/hocs/withPropertyRequirementData';

const PreApprovalControl = (props) => {
  const {
    propertyRequirement,
    propertyRequirementId,
  } = props;

  const {
    Modal,
    isModalVisible,
    openModal,
    closeModal,
  } = useModal();

  const [preApprovalDocs] = useAttachments(
    28,
    propertyRequirementId,
    20,
    true,
  );

  return (
    <>
      {
        !propertyRequirement?.pre_approval_amount && !preApprovalDocs && (
          <Button
            variant="tertiary"
            onClick={openModal}
            wrapper
          >
            Upload Pre-Approval
          </Button>
        )
      }

      {
        !propertyRequirement?.pre_approval_amount && preApprovalDocs && (
          <Button
            variant="tertiary"
            onClick={openModal}
            wrapper
          >
            Pre-Approval Docs
          </Button>
        )
      }

      {
        propertyRequirement?.pre_approval_amount > 0 && (
          <Flex spacing={1} alignItems="center">
            <Typography noMargin>
              {currency.full(propertyRequirement?.pre_approval_amount)}
            </Typography>
            <Button
              variant="tertiary"
              onClick={openModal}
              icon="external-link"
              wrapper
            />
          </Flex>
        )
      }

      <Modal
        visible={isModalVisible}
        onCancel={closeModal}
        footer={null}
        title="Buyer Pre-Approval"
        width={1000}
      >
        <PreApprovalForm propertyRequirementId={propertyRequirementId} />
      </Modal>
    </>
  );
};

PreApprovalControl.defaultProps = {
  propertyRequirement: null,
};

const {
  number,
  shape,
} = PropTypes;

PreApprovalControl.propTypes = {
  propertyRequirementId: number.isRequired,
  propertyRequirement: shape({
    pre_approval_amount: number,
  }),
};

export default withPropertyRequirementData(PreApprovalControl);
