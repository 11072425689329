import styled from 'styled-components';

import Flex from 'ui/Flex';
import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';

export const Root = styled(Flex)`
`;

export const LinkWrapper = styled(NakedButton)`
&& {
  display: flex;
  align-items: center;
  padding: 7px 16px;
  border-radius: 7px;
  background-color: ${(props) => props?.theme?.palette?.backgroundFlat || 'blue'};
  
}
`;

export const SymCardLogo = styled.span`
  font-family: EstiloText;
  font-weight: 300;
  color: #a1bdcb;
  font-size: 19px;
  cursor: pointer;
`;

export default {
  Root,
  SymCardLogo,
};
