import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { currency } from 'refactored/utils/numbers';

import withTags from 'shared/hocs/withTags';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';

import ContactAvatar from 'sections/Today/modules/ContactAvatar';
import useContactName from 'shared/hooks/contacts/useContactName';

import Button from 'ui/Button';

import {
  DescriptionContainer,
  FullHeightContainer,
  CenteredWrapper,
  FlexContainer,
} from './styledItems';

const MainProspectInfo = (props) => {
  const {
    contactId,
    budgetMax,
    budgetMin,
    viewingsCount,
    variant,
    offers,
    counters,
    onCreateViewingClick,
  } = props;

  const AvatarWithTag = withTags({
    tags: [{
      content: 'PROSPECT',
      color: 'background-app',
    }],
    position: 'bottom',
    fullWidth: true,
    isBordered: false,
    borderRadius: {
      topLeft: 0,
      topRight: 0,
      bottomLeft: 8,
      bottomRight: 8,
    },
  })(ContactAvatar);

  const addViewingButtonClick = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    onCreateViewingClick();
  }, [onCreateViewingClick]);

  const displayedInformations = {
    viewed: (
      <>
        <div>
          <Typography
            variant="body1"
            weight="bold"
            color="success"
            noMargin
          >
            {`${currency.short(budgetMin)} – ${currency.short(budgetMax)}`}
          </Typography>
        </div>

        {viewingsCount ? (
          <DescriptionContainer>
            <Typography
              variant="body1"
              weight={400}
              color="descriptor-text"
              className="description-text"
              noMargin
            >
              {`${viewingsCount} Showing Requests`}
            </Typography>
          </DescriptionContainer>
        ) : (
          <Button
            variant="primary"
            onClick={addViewingButtonClick}
          >
            <Typography
              variant="body1"
              weight={400}
              color="main-text-inversed"
              noMargin
            >
              Arrange Showings
            </Typography>
          </Button>
        )}
      </>
    ),
    offers: (
      <>
        <FlexContainer>
          <FlexContainer>
            <Typography
              variant="title1"
              className="offers-title-text"
              weight={400}
              noMargin
            >
              {counters}
            </Typography>
            <Typography
              variant="body"
              className="offers-description-text"
              color="descriptor-text"
              noMargin
            >
              Counters to review
            </Typography>
          </FlexContainer>

          <FlexContainer>
            <Typography
              variant="title1"
              className="offers-title-text"
              weight={400}
              noMargin
            >
              {offers}
            </Typography>
            <Typography
              variant="body"
              className="offers-description-text"
              color="descriptor-text"
              noMargin
            >
              Offers outstanding
            </Typography>
          </FlexContainer>
        </FlexContainer>
      </>
    ),
  };

  return (
    <Grid
      item
      xs={12}
      container
      spacing={1}
    >
      <Grid item>
        <CenteredWrapper>
          <AvatarWithTag
            contactId={contactId}
          />
        </CenteredWrapper>
      </Grid>
      <Grid
        container
        item
        xs
      >
        <FullHeightContainer>
          <div>
            <Typography
              variant="body1"
              weight={400}
              className="card-text"
              noMargin
            >
              {useContactName(contactId)}
            </Typography>
          </div>

          {displayedInformations[variant]}
        </FullHeightContainer>
      </Grid>
    </Grid>
  );
};

const {
  number,
  string,
  func,
} = PropTypes;

MainProspectInfo.propTypes = {
  contactId: number.isRequired,
  budgetMax: number.isRequired,
  budgetMin: number.isRequired,
  viewingsCount: number.isRequired,
  variant: string.isRequired,
  offers: number.isRequired,
  counters: number.isRequired,
  onCreateViewingClick: func.isRequired,
};

export default MainProspectInfo;
