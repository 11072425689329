import {
  DELETE_EMPLOYEE_TASK_FROM_TASKS_BOARDS,
} from 'store/actionTypes/tasks';
import getEmployeeTasksSummary from './getEmployeeTasksSummary';
import getEmployeeTasksSummaryForPeriod from './getEmployeeTasksSummaryForPeriod';


const deleteEmployeeTaskFromTasksBoards = (taskId, employeeId) => async (
  dispatch,
  getState,
) => {
  const {
    taskPlanner: {
      selectedTimePeriodTop,
      selectedTimePeriodBottom,
    },
  } = getState();

  await dispatch(getEmployeeTasksSummary(employeeId));
  await dispatch(getEmployeeTasksSummaryForPeriod(
    employeeId,
    selectedTimePeriodTop,
  ));
  await dispatch(getEmployeeTasksSummaryForPeriod(
    employeeId,
    selectedTimePeriodBottom,
  ));

  dispatch({
    type: DELETE_EMPLOYEE_TASK_FROM_TASKS_BOARDS,
    employeeId,
    taskId,
  });
};

export default deleteEmployeeTaskFromTasksBoards;
