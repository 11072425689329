import React from 'react';
import moment from 'moment';
import preval from 'preval.macro';

import Typography from 'ui/Typography';

import packageJson from '../../../../package.json';

const buildTimestamp = preval`module.exports = new Date();`;

const VersionNumber = (props) => (
  <span {...props} style={{ color: props.color }}>
    v.
    {' '}
    {packageJson.version}
    {' '}
    released on
    {' '}
    {moment(buildTimestamp).format('MM/DD/YYYY HH:mm')}
  </span>
);

export default VersionNumber;
