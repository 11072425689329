import requests from 'api/requests';

// eslint-disable-next-line consistent-return
const createLead = (leadData) => async (dispatch) => {
  try {
    const response = await requests.crm.leads.create(leadData);

    return response.data.leadId;
  } catch (error) {
    console.error(error);
  }
};

export default createLead;
