const getInitialsFromString = (inputString) => {
    if (inputString == null || typeof inputString !== 'string') return '';

    const words = inputString.trim().split(/\s+/);

    if (words.length === 0 || (words.length === 1 && words[0] === '')) return '';

    const validWords = words.filter(word => word.trim() !== '');
    const initials = validWords.slice(0, 2).map((word) => word.charAt(0).toUpperCase());

    return initials.join('');
}

export default getInitialsFromString;

