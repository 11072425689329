import styled from 'styled-components';
import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';

export const Root = styled.div`
  display: flex;
  width: 100%;
`;

export const Button = styled(NakedButton)`
&& {
  background: ${(props) => (
    props.active ? (props?.theme?.palette.white || 'white'
    ) : (
      props?.theme?.palette['background-flat'] || 'gray')
  )};
  width: auto;
  height: 29px;
  border-radius: 4px;
  border: solid 1px;
  border-color: ${(props) => (
    props.active ? (
      props?.theme?.palette.focus || 'darkblue'
    ) : (
      props?.theme?.palette['background-flat'] || 'gray'
    )
  )};
  display: flex;
  flex: 1 1;
  margin: 0 2px;
  padding-bottom: 5;
  align-items: center;
  justify-content: center;
}
`;

export default {
  Root,
  Button,
};
