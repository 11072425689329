import archive from './archive';
import remove from './remove';

export {
  archive,
  remove,
};

export default {
  archive,
  remove,
};
