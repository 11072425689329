import dayjs from 'dayjs';

import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'

import formats from '../formats';

dayjs.extend(duration)
dayjs.extend(utc)
dayjs.extend(relativeTime)

export const between = (start, end = dayjs(), period = 'day') => dayjs(end).diff(start, period);
export const since = (start, period = 'day') => between(start, dayjs(), period);
export const timeDate = (date) => dayjs(date).format(formats.dateTime.timeDate);
export const fullDate = (date) => dayjs(date).format(formats.dateTime.full);
export const ago = (date) => dayjs.duration(dayjs(date).diff()).humanize(true)
export const datetime = dayjs;

export default dayjs;