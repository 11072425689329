export default {
  // Tickets
  ticket: {
    setAgentNote: '/api/tickets/agentNote'
  },
  // Users
  users: '/api/users',
  user: {
    image: '/api/users/image',
    systemPermission: '/api/users/systemPermission'
  },
  // Other
  comments: '/api/comments',
  labels: '/api/labels',
  logs: '/api/logs',
  queues: '/api/queues',
  subqueues: '/Tickets',
  attachments: '/api/attachments/workspace',
  attachments2objects: '/api/attachments/objects-relations',
  attachFile: '/api/attach-file',
  detachFile: '/api/detach-file',
  activities: '/api/common/activities',
  articles: {
    folderGeneral: '/api/articleFolders',
    articleGeneral: '/api/article'
  },
  v2: {
    queues: '/api/v2/queues',
    tasks: '/api/v2/tasks',
    tasksSummary: '/api/v2/tasksSummary',
    taskWorklogs: '/Worklog/GetAll',
    teams: '/api/v2/teams',
    tickets: '/api/v2/tickets',
    ticketsSummary: '/api/v2/ticketsSummary',
    ticketResolve: '/api/v2/tickets/resolve',
    leadsSummary: '/api/v2/leadsSummary',
    employeeTasksSummaryForPeriod: '/api/v2/employeeTasksSummaryForPeriod'
  },
  chat: {
    general: '/api/chat',
    message: '/api/chatMessage',
    members: '/api/chatMembers',
    socket: '/api/chatSocket',
    userMessage: '/api/userMessages',
    chatInformation: '/api/chatInformation'
  },
  billing: {
    nextYearlyPayment: '/api/company/billing/next-yearly-payment',
    previousMonthlyPayment: '/api/company/billing/previous-monthly-payment',
    nextMonthlyPayment: '/api/company/billing/next-monthly-payment',
    licenseUpdateTotal: '/api/company/billing/license-update-total',
    invoices: '/api/company/billing/invoices'
  },
  employees: {
    general: '/api/common/employees',
    mentions: '/api/common/employees/get-mentions-summary',
    clearMentions: '/api/common/employees/clear-mentions'
  },
  departments: {
    updateForEmployee: '/company/departments/updateForEmployee'
  },
  customers: '/api/customers',
  hr: {
    birthdayIsToday: 'api/hr/employees/have-birthday-today',
    todayEmployeeAttendance: 'api/hr/attendance/attendances/today'
  },
  // shared dictionaries
  dictionary: {
    currencies: 'api/currencies',
    amenities: '/api/realtor/amenities',
    realtorSaleTypes: '/api/realtor/sale-types',
    realtorViewsFromHome: '/api/realtor/views-from-home',
    realtorSpecialConditions: '/api/realtor/special-conditions',
    rejectedPropertiesReasons: '/api/realtor/rejected-properties-reasons',
    repairTypes: '/api/realtor/repair-types',
    personTitles: '/api/person-titles',
    contactLabels: '/api/realtor/contact-labels'
  },
  crm: {
    products: 'api/v2/crm/products',
    sources: 'api/v2/crm/sources',
    sourceTypes: 'api/v2/crm/sourceTypes',
    companies: 'api/v2/crm/companies',
    companiesSummary: 'api/v2/crm/companiesSummary',
    companyChangelog: 'api/v2/crm/companyChangelog',
    contacts: 'api/v2/crm/contacts',
    contactsLabels: 'api/v2/crm/contactsLabels',
    contactChangelog: 'api/v2/crm/contactChangelog',
    contactsSummary: 'api/v2/crm/contactsSummary',
    contactLinkedObjectsSummaries: 'api/v2/crm/contactLinkedObjectsSummaries',
    linkObjectsToContact: 'api/v2/crm/linkObjectsToContact',
    leads: 'api/v2/crm/leads',
    leadsSummary: 'api/v2/crm/leadsSummary',
    leadChangelog: 'api/v2/crm/leadChangelog',
    lead2product: 'api/v2/crm/lead2product',
    leadActions: 'api/v2/crm/leadActions'
  },
  employeesV2: {
    employees: 'api/v2/employees',
    sectionPermissions: 'section-permissions'
  },
  fulfillments: 'api/fulfillments',
  fulfillmentsSummary: 'api/fulfillmentsSummary',
  fulfillmentChangelog: 'api/fulfillmentChangelog',
  fulfillmentLineItemDetails: 'api/fulfillmentLineItemDetails',
  fulfillmentLineItem: 'api/fulfillmentLineItem',
  fulfillmentFromExisting: 'api/fulfillmentFromExisting',
  customerInvoices: 'api/customer-invoices',
  customerEmails: 'api/customer-emails',
  orders: 'api/orders',
  shippedItems: 'api/shipped-items',
  customerPayments: 'api/customer-payments',
  addresses: 'api/addresses',
  servicePeople: '/api/servicepeople',
  lineItems: 'api/line-items',
  skus: 'api/skus',
  customerDigitalAccounts: 'api/customer-digital-accounts',
  dealproducts: 'api/dealproducts',
  deals2products: 'api/deals2products',
  serviceProcesses: 'api/service-processes',
  ordersCancelled: 'api/orders-cancelled',
  creditNotes: 'api/credit-notes',
  refunds: 'api/refunds',
  customerInformed: 'api/customer-informed',
  warehousePackages: 'api/warehouse-packages',
  warehouseOrder: 'api/warehouse-order',
  warehouse: 'api/warehouse',
  agreements: 'api/agreements',

  likes: 'api/likes',
  likesRelations: 'api/likes-relations',
  dislikes: 'api/dislikes',
  dislikesRelations: 'api/dislikes-relations',

  realtor: {
    leads: 'api/realtor/leads',
    leadsObjectsByFilter: 'api/realtor/leads-objects',
    contacts: 'api/realtor/contacts',
    contactsRelations: 'api/realtor/contacts-relations',
    contactsCompleteIdsList: 'api/realtor/contacts-complete-ids-list',
    properties: 'api/realtor/properties',
    propertyRelations: 'api/realtor/property-relations',
    propertyRequirements: 'api/realtor/property-requirements',
    propertyRequirementRelations: 'api/realtor/property-requirement-relations',
    leadsSummary: 'api/realtor/leads-summary',
    leadsRelations: 'api/realtor/leads-relations',
    leadChangelog: 'api/realtor/leadChangelog',
    leadActions: 'api/realtor/leadActions',
    propertiesSummary: 'api/realtor/properties-summary',
    propertyViewings: 'api/realtor/property-viewings',
    propertyViewingsRelations: 'api/realtor/property-viewings-relations',
    clients: 'api/realtor/clients',
    clientsSummary: 'api/realtor/clientsSummary',
    notablePoints: 'api/realtor/notable-points',
    notablePointsRelations: 'api/realtor/notable-points-relations',
    disclosures: 'api/realtor/disclosures',
    disclosuresRelations: 'api/realtor/disclosures-relations',
    prospects: 'api/realtor/prospects',
    prospectsSummary: 'api/realtor/prospectsSummary',
    opportunities: 'api/realtor/opportunities',
    opportunitiesSummary: 'api/realtor/opportunitiesSummary',
    offers: 'api/realtor/offers',
    offerRelations: 'api/realtor/offer-relations',
    opportunitiesOffers: 'api/realtor/opportunities-offers',
    offersByPropertyId: '/api/realtor/get-offers-by-property',
    offersSummary: 'api/realtor/offers-summary',
    shareOffer: 'api/realtor/share-offer',
    specialConditions: 'api/realtor/special-conditions',
    deals: 'api/realtor/deals',
    transactionsSummary: 'api/realtor/deals-summary',
    transactionsRelations: 'api/realtor/deals-relations',
    escrow: 'api/realtor/escrow',
    loans: 'api/realtor/loans',
    matchingProperties: 'api/realtor/matching-properties',
    matchingPropertiesRelations: 'api/realtor/matching-properties-relations',
    inspections: 'api/realtor/inspections',
    inspectionsTypes: 'api/realtor/inspection-types',
    repairRequests: 'api/realtor/repair-requests',
    documentRequests: 'api/realtor/document-requests',
    documentRequestsRelations: 'api/realtor/document-requests-relations',
    addendumChanges: 'api/realtor/addendum-requests',
    addendumChangesRelations: 'api/realtor/addendum-requests-relations',
    unansweredQuestions: 'api/realtor/questions',
    unansweredQuestionsRelations: 'api/realtor/questions-relations',
    extensionRequests: 'api/realtor/extension-requests',
    extensionRequestsRelations: 'api/realtor/extension-requests-relations',
    prioritiesObjects: 'api/realtor/priorities-objects',
    matched: 'api/realtor/matched',
    appCustomizationSettings: 'api/realtor/app-customization-settings',
    navbarSettings: 'api/realtor/navbar-settings',
    shareProperty: 'api/realtor/share-property-data',

    demo: 'api/realtor/demo'
  },

  followUpReminders: {
    general: 'api/follow-up-reminders',
    summary: 'api/follow-up-reminders-summary'
  },
  syncedEvents: {
    general: 'api/synced-events'
  },
  socialIntegrations: {
    facebook: 'api/social-integrations/fb'
  },

  realtorDirectory: {
    find: 'api/realtor-directory/find',
    claim: 'api/realtor-directory/claim-profile'
  },

  calls: {
    outgoingCaller: 'api/calls/outgoing-callers',
    token: 'api/calls/access-token',
    activePhones: 'api/calls/active-phones',
    callRecordings: 'api/calls/call-recordings',
    callLogs: 'api/calls/call-logs',
    saveCall: 'api/calls/save-call'
  },

  phoneNumbers: 'api/phone-numbers',
  emails: 'api/emails',

  dataImport: {
    import: '/api/import',
    upload: '/api/import-upload',
    conflicts: '/api/import-conflicts'
  },

  integrations: {
    icloud: '/api/icloud',
    items: '/api/integrations',
    emails: '/api/integrations/emails',
    threads: '/api/integrations/email-threads',
    contacts: '/api/integrations/contacts',
    calendar: '/api/integrations/calendar',

    emailAttachments: '/api/integrations/emailAttachments',
    emailTemplates: '/api/integrations/email-templates',
    emailCampaigns: '/api/integrations/email-campaigns',
    emailCampaignsSummary: '/api/integrations/email-campaigns-summary',
    emailCampaignsActions: '/api/integrations/email-campaigns-actions',
    emailCampaignSampleMessage: '/api/integrations/email-campaign-sample-message',
    emailCampaignDuplicate: '/api/integrations/email-campaign-duplicate',
  },

  dataScraping: {
    properties: '/api/realtor/property-scraper',
  },

  images: {
    base64: '/api/images/base64',
  },
};
