import {
  SET_CRM_SELECTED_CONTACT,
} from 'store/actionTypes/crm';

const setSelectedContact = (contactId) => ({
  type: SET_CRM_SELECTED_CONTACT,
  payload: contactId,
});

export default setSelectedContact;
