import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { currency } from 'refactored/utils/numbers';

import { useDebouncedCallback } from 'use-debounce';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';

import DetailsSection from 'sections/Leads/components/DetailsSection';
import FormTextfield from 'shared/inputs/FormTextfield';
import DateInput from 'shared/inputs/DateInput';

import withOptionsMenu from 'ui/withOptionsMenu';
import withInlineLabel from 'shared/hocs/withInlineLabel';

const yesNoOptions = [
  {
    value: 0,
    label: 'No',
  },
  {
    value: 1,
    label: 'Yes',
  },
  {
    value: null,
    label: 'N/A',
  },
];

const extraDetailsFields = [
  {
    label: 'Sale Includes:',
    key: 'includes',
    placeholder: 'Gas cook top, fridge',
    type: 'text',
  },
  {
    label: 'Sale Excludes:',
    key: 'excludes',
    placeholder: 'Seller personal items',
    type: 'text',
  },
  {
    label: 'Construction:',
    key: 'construction',
    placeholder: 'Stone, siding, foundation',
    type: 'text',
  },
  {
    label: 'Builder:',
    key: 'builder',
    placeholder: 'Tetris Inc.',
    type: 'text',
  },
];

const additionalFeaturesFields = [
  {
    label: 'Cooling:',
    key: 'cooling',
    placeholder: 'Central Air',
    type: 'text',
  },
  {
    label: 'Heating:',
    key: 'heating',
    placeholder: 'Forced Air',
    type: 'text',
  },
  {
    label: 'Fireplace:',
    key: 'fireplace',
    placeholder: 'Gas',
    type: 'text',
  },
  {
    label: 'Sprinkler:',
    key: 'sprinkler',
    placeholder: 'Auto',
    type: 'text',
  },
  {
    label: 'Roof:',
    key: 'roof',
    placeholder: 'Shingle',
    type: 'text',
  },
  {
    label: 'Garage:',
    key: 'garage',
    placeholder: 'Attached',
    type: 'text',
  },
  {
    label: 'Sewer:',
    key: 'sewer',
    placeholder: 'Connected',
    type: 'text',
  },
  {
    label: 'Pool:',
    key: 'pool',
    placeholder: 'Heated',
    type: 'text',
  },
  {
    label: 'Water:',
    key: 'water',
    placeholder: 'City Served',
    type: 'text',
  },
  {
    label: 'Access:',
    key: 'access',
    placeholder: 'Road',
    type: 'text',
  },
];

const locationDetailsFields = [
  {
    label: 'County:',
    key: 'county',
    placeholder: 'Ventura',
    type: 'text',
  },
  {
    label: 'Area:',
    key: 'area',
    placeholder: 'Agoura Hills, Ventura County',
    type: 'text',
  },
  {
    label: 'Subdivision:',
    key: 'subdivision',
    placeholder: 'Oak Lake',
    type: 'text',
  },
  {
    label: 'School District:',
    key: 'school_district',
    placeholder: 'Oak Park School Districe',
    type: 'text',
  },
  {
    label: 'Grade School:',
    key: 'grade_school',
    placeholder: 'Red Oak',
    type: 'text',
  },
  {
    label: 'Jr High:',
    key: 'jr_high',
    placeholder: 'Pillar Falls',
    type: 'text',
  },
  {
    label: 'Sr High:',
    key: 'sr_high',
    placeholder: 'Oak Park High',
    type: 'text',
  },
];

const detailsFields = [
  {
    label: 'MLS:',
    key: 'mls_number',
    placeholder: '12345',
    type: 'text',
  },
  {
    label: 'Listing Date:',
    key: 'on_market_since',
    placeholder: '11/25/2020',
    type: 'date',
  },
  {
    label: 'Parcel:',
    key: 'parcel',
    placeholder: 'RPT000000000000',
    type: 'text',
  },
  {
    label: 'Zoning:',
    key: 'zoning',
    placeholder: 'Single',
    type: 'text',
  },
  {
    label: 'Class:',
    key: 'class',
    placeholder: 'Residential',
    type: 'text',
  },
  {
    label: 'Land Use:',
    key: 'land_use',
    placeholder: 'Single',
    type: 'text',
  },
  {
    label: 'Legal:',
    key: 'legal',
    placeholder: 'Lot 28, Block 1',
    type: 'text',
  }];

const detailsFields2 = [
  {
    label: 'Assoc. fees:',
    key: 'assoc_fees',
    placeholder: '$120/m',
    type: 'text',
  },
  {
    label: 'Taxes Yr:',
    key: 'taxes_per_year',
    placeholder: '2019 $600',
    type: 'text',
  },
  {
    label: 'Short Sale:',
    key: 'short_sale',
    placeholder: 'No',
    type: 'select',
    options: yesNoOptions,
  },
  {
    label: 'Auction:',
    key: 'auction',
    placeholder: 'No',
    type: 'select',
    options: yesNoOptions,
  },
  {
    label: 'HUD Owned:',
    key: 'hud_owned',
    placeholder: 'No',
    type: 'select',
    options: yesNoOptions,
  },
  {
    label: 'In Foreclosure:',
    key: 'in_foreclosure',
    placeholder: 'No',
    type: 'select',
    options: yesNoOptions,
  },
  {
    label: 'REO/Bank Owned:',
    key: 'reo_bank_owned',
    placeholder: 'No',
    type: 'select',
    options: yesNoOptions,
  },
  {
    label: 'Flood Ins Req:',
    key: 'flood_ins_req',
    placeholder: 'No',
    type: 'select',
    options: yesNoOptions,
  },
  {
    label: 'Water Shares:',
    key: 'water_shares',
    placeholder: 'No',
    type: 'select',
    options: yesNoOptions,
  },
];

const renderField = (data, onChange) => (field) => {
  const {
    type,
    label,
    key,
    placeholder,
    options,
    dateView,
  } = field;

  if (type === 'text') {
    const onChangeDebounced = useDebouncedCallback((value) => {
      const currentValue = data[key] || '';
      if (value !== currentValue) {
        onChange(key)(value);
      }
    }, 4000);

    const onBlur = () => {
      // calls debounced callback if it hasn't fired yet
      onChangeDebounced.flush();
    };

    return (
      <Fragment key={key}>
        <Grid
          item
          xs={3}
          style={{ paddingRight: 30 }}
        >
          <FormTextfield
            variant="inline"
            label={label}
            placeholder={placeholder}
            value={data[key]}
            onChange={onChangeDebounced}
            onBlur={onBlur} // eslint-disable-line react/jsx-no-bind
          />
        </Grid>
      </Fragment>
    );
  }

  if (type === 'select') {
    const Input = withOptionsMenu(FormTextfield);

    return (
      <Fragment key={key}>
        <Grid
          item
          xs={3}
          style={{ paddingRight: 30 }}
        >
          <Input
            variant="inline"
            label={label}
            onChange={onChange(key)}
            placeholder={placeholder}
            value={data[key]}
            options={options}
          />
        </Grid>
      </Fragment>
    );
  }

  if (type === 'date') {
    return (
      <Fragment key={key}>
        <Grid
          item
          xs={3}
          style={{ paddingRight: 30 }}
        >
          <DateInput
            variant="inline"
            view={dateView}
            handleChange={onChange(key)}
            label={label}
            placeholder={placeholder}
            value={data[key]}
            disableFuture
            disableToolbar
            autoOk
          />
        </Grid>
      </Fragment>
    );
  }

  if (type === 'prc/sqft') {
    const Field = withInlineLabel(Typography);

    const { expected_price, floor_area } = data;

    const value = expected_price && floor_area
      ? currency.full(expected_price / floor_area).format('$0,0')
      : 'N/A';

    return (
      <Fragment key={key}>
        <Grid
          item
          xs={3}
          style={{ paddingRight: 30, margin: 'auto 0' }}
        >
          <Field
            variant="inline"
            label={label}
            noMargin
          >
            {value}
          </Field>
        </Grid>
      </Fragment>
    );
  }

  return null;
};

const FullPropertyDetails = (props) => {
  const {
    onChange,
    property,
  } = props;

  return (
    <Grid container spacing={4}>
      {/* property details */}
      <Grid
        style={{
          marginLeft: '34px',
        }}
        item
        xs={12}
      >

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid
              container
              spacing={1}
            >
              {extraDetailsFields.map(renderField(property, onChange))}
            </Grid>

          </Grid>

          <Grid item xs={12} />

          <Grid item xs={12}>
            <Grid
              container
              spacing={1}
            >
              {additionalFeaturesFields.map(renderField(property, onChange))}
            </Grid>
          </Grid>

        </Grid>

      </Grid>
      {/*  */}

      {/* location details */}
      <Grid item xs={12}>

        <DetailsSection
          icon="mapPin"
          title="Location details"
        >
          <Grid
            container
            spacing={2}
            style={{
              display: 'contents',
            }}
          >
            <Grid
              container
              spacing={1}
            >
              {locationDetailsFields.map(renderField(property, onChange))}
            </Grid>
          </Grid>
        </DetailsSection>

      </Grid>
      {/*  */}

      {/* legal & financial */}
      <Grid item xs={12}>
        <DetailsSection
          icon="book"
          title="Legal & Financial"
        >
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Grid
                container
                spacing={1}
              >
                {detailsFields.map(renderField(property, onChange))}
              </Grid>
            </Grid>

            <Grid item xs={12} />

            <Grid item xs={12}>
              <Grid
                container
                spacing={1}
              >
                {detailsFields2.map(renderField(property, onChange))}
              </Grid>
            </Grid>

          </Grid>
        </DetailsSection>
      </Grid>
      {/*  */}
    </Grid>
  );
};

FullPropertyDetails.defaultProps = {
  onChange: () => () => { },
  property: {},
};

const {
  // array,
  // arrayOf,
  // bool,
  // element,
  func,
  // instanceOf,
  // number,
  // object,
  // objectOf,
  // oneOf,
  // oneOfType,
  shape,
  // string,
} = PropTypes;

FullPropertyDetails.propTypes = {
  onChange: func,
  property: shape(),
};

export default FullPropertyDetails;
