import requests from 'api/requests';

import getDealProducts from './getDealProducts';

const createProduct = (dealId) => (productData) => async (dispatch) => {
  try {
    await requests.deals.createProduct(dealId)(productData);
    dispatch(getDealProducts(dealId));
  } catch (error) {
    console.error(error);
  }
};

export default createProduct;
