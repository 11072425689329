exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".contactThumbnail_contact-thumbnail-container__3go6g {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  grid-gap: 8px;\n  gap: 8px;\n  flex-basis: calc(33.33% - 4px);\n  padding: 12px;\n  border-radius: 5px;\n}\n\n.contactThumbnail_contact-thumbnail-container-selected__1_iNS {\n  background-color: rgba(0, 187, 255, 0.2);\n}\n\n.contactThumbnail_contact-thumbnail-data-container__RHIHm {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  width: 140px;\n}\n\n.contactThumbnail_contact-thumbnail-name__3iteY {\n  font-family: \"Hind-Regular\";\n  font-size: 15px;\n  font-weight: 600;\n}\n\n.contactThumbnail_contact-thumbnail-name__3iteY,\n.contactThumbnail_contact-thumbnail-email__y3gp7,\n.contactThumbnail_contact-thumbnail-phone__3pR4g {\n  max-width: 100%;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}", ""]);

// exports
exports.locals = {
	"contact-thumbnail-container": "contactThumbnail_contact-thumbnail-container__3go6g",
	"contact-thumbnail-container-selected": "contactThumbnail_contact-thumbnail-container-selected__1_iNS",
	"contact-thumbnail-data-container": "contactThumbnail_contact-thumbnail-data-container__RHIHm",
	"contact-thumbnail-name": "contactThumbnail_contact-thumbnail-name__3iteY",
	"contact-thumbnail-email": "contactThumbnail_contact-thumbnail-email__y3gp7",
	"contact-thumbnail-phone": "contactThumbnail_contact-thumbnail-phone__3pR4g"
};