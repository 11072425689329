import React, { useRef, useState } from 'react';
import Tooltip from 'ui/Tooltip';
import EmailFileIcon from 'assets/images/email-file-icon.png';
import EmailLinkIcon from 'assets/images/email-link-icon.png';
import Attachment from 'sections/Integrations/modules/EmailItem/Attachment';
import EmailTypeIcons from 'sections/Communications/components/EmailTypeIcons/EmailTypeIcons';
import EmailIntentIcons from 'refactored/components/shared/emailIntentIcons/EmailIntentIcons';
import styles from './emailListItemFooter.module.css';
import EmailListItemDropdown from './EmailListItemDropdown';
import IconButton from 'refactored/components/shared/buttons/iconButton/IconButton';
import DotsIcon from 'assets/images/commsHub/dots.svg';

export const EmailListItemFooter = ({ email }) => {
    const biggestFileName = email?.clean_sympleteAnalysis?.filename || null;
    const emailFilesArray = email?.files || [];
    const sympleteToken = email?.sympleteTokenId || null;
    let fileForAttachment = null;
    const anchorMenuRef = useRef();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    if (biggestFileName && emailFilesArray.length > 0) {
        fileForAttachment = emailFilesArray.find(
            file => file.filename === biggestFileName
        );
    }
    return <div className={styles['email-listitem-footer']} >
        <div className={styles['email-listitem-link']}>
            {/* {email?.clean_sympleteAnalysis?.link ? (
                <Tooltip title={email?.clean_sympleteAnalysis?.link}>
                    <div className={styles['email-listitem-link-container']} >
                        {' '}
                        <img
                            src={EmailLinkIcon}
                            width={20}
                            height={20}
                        />
                        <a className={styles['email-listitem-link-a-tag']}
                            href={email?.clean_sympleteAnalysis?.link}
                            target="_blank"
                            rel="noopener noreferer"
                        >
                            {email?.clean_sympleteAnalysis?.link}
                        </a>
                    </div>
                </Tooltip>
            ) : (
                <div></div>
            )} */}
            {email?.clean_sympleteAnalysis?.filename ? (
                <Attachment
                    tokenId={sympleteToken}
                    attachment={fileForAttachment}
                    EmailFileIcon={EmailFileIcon}
                    filename={email?.clean_sympleteAnalysis?.filename}
                />
            ) : null}
        </div>
        <div>
            {/* {email?.clean_sympleteAnalysis?.intent ? (
                <Tooltip title={email?.clean_sympleteAnalysis?.intent}>
                    {EmailIntentIcons(email?.clean_sympleteAnalysis?.intent)}
                </Tooltip>
            ) : (
                <Tooltip title="Information">
                    {EmailIntentIcons('Information')}
                </Tooltip>
            )} */}
        </div>
        <div
            className={styles['email-listitem-types-container']}
            onClick={() => { }}
        >
            {email?.clean_sympleteAnalysis?.type ? (
                <Tooltip title={email?.clean_sympleteAnalysis?.type}>
                    {EmailTypeIcons(email?.clean_sympleteAnalysis?.type)}
                </Tooltip>
            ) : (
                <></>
            )}
            {/* {email?.clean_sympleteAnalysis?.to_do_items.length > 0 ? (
                <Tooltip title="To Do Items Completed">
                    <div className={styles['email-listitem-items-to-complete']} >
                        <div
                            className={styles['email-listitem-items-to-complete-text']}
                        >
                            {email?.clean_sympleteAnalysis?.to_do_items.length}
                        </div>
                    </div>
                </Tooltip>
            ) : null} */}
        </div>
        <div ref={anchorMenuRef}
            onClick={(e) => {
                e.stopPropagation()
            }}
        >
            <IconButton
                icon={DotsIcon}
                onClick={(e) => {
                    e.stopPropagation()
                    setIsDropdownOpen(true)
                }}
                className={styles['item-list-dropdown-btn']}
            />
            <EmailListItemDropdown
                isOpen={isDropdownOpen}
                onClose={() => {
                    setIsDropdownOpen(false)
                }}
                anchorElement={anchorMenuRef?.current}
                email={email}
            />
        </div>
    </div>
}

export default EmailListItemFooter