exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".threadModalMsgSelector_thread-modal-msg-selector__1dhTo {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  grid-gap: 8px;\n  gap: 8px;\n  padding-top: 12px;\n  font-size: 24px;\n}\n\n.threadModalMsgSelector_thread-modal-msg-selector__1dhTo > button {\n  border-radius: 50px;\n  width: 44px;\n  height: 44px;\n  background-color: #F6F6F6;\n  background-position: center;\n  background-repeat: no-repeat;\n  border: none;\n  outline: none;\n  cursor: pointer;\n}", ""]);

// exports
exports.locals = {
	"thread-modal-msg-selector": "threadModalMsgSelector_thread-modal-msg-selector__1dhTo"
};