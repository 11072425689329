import React from 'react';
import PropTypes from 'prop-types';

import { currency } from 'refactored/utils/numbers';

import withTags from 'shared/hocs/withTags';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';

import ContactAvatar from 'sections/Today/modules/ContactAvatar';
import useContactName from 'shared/hooks/contacts/useContactName';

import TempIcon from 'shared/components/CRM/UI/TempIcon';

import {
  DescriptionContainer,
  FullHeightContainer,
} from './styledItems';

const tagContents = {
  propertyRequirement: 'BUYER',
  property: 'SELLER',
};

const MainLeadInfo = (props) => {
  const {
    contactId,
    temp,
    type,
    expectedPrice,
    budgetMax,
    budgetMin,
    fullCardInformation,
  } = props;

  const AvatarWithTag = withTags({
    tags: [{
      content: tagContents[type],
      color: 'background-app',
    }],
    position: 'bottom',
    fullWidth: true,
    isBordered: false,
    borderRadius: {
      topLeft: 0,
      topRight: 0,
      bottomLeft: 8,
      bottomRight: 8,
    },
  })(ContactAvatar);

  return (
    <Grid
      item
      xs={12}
      container
      spacing={1}
    >
      <Grid item>
        <AvatarWithTag
          contactId={contactId}
        />
      </Grid>
      <Grid
        item
        xs
      >
        <FullHeightContainer>
          <div>
            <Typography
              variant="body1"
              weight={400}
              className="card-text"
              noMargin
            >
              {useContactName(contactId)}
            </Typography>
          </div>

          <DescriptionContainer>
            <Typography
              variant="body1"
              weight="bold"
              color="success"
              className="cost-text"
              noMargin
            >
              {
                type === 'property'
                  ? `
                      ${fullCardInformation
                    ? currency.full(expectedPrice)
                    : currency.short(expectedPrice)
                  }
                    `
                  : `
                      ${fullCardInformation
                    ? currency.full(budgetMin)
                    : currency.short(budgetMin)
                  } – ${fullCardInformation
                    ? currency.full(budgetMax)
                    : currency.short(budgetMax)
                  }
                    `
              }
            </Typography>

            <TempIcon
              size="lg"
              variant={temp}
              className="temp-icon"
            />
          </DescriptionContainer>
        </FullHeightContainer>
      </Grid>
    </Grid>
  );
};

const {
  number,
  string,
  bool,
} = PropTypes;

MainLeadInfo.propTypes = {
  fullCardInformation: bool.isRequired,
  contactId: number.isRequired,
  temp: string.isRequired,
  type: string.isRequired,
  expectedPrice: number.isRequired,
  budgetMax: number.isRequired,
  budgetMin: number.isRequired,
};

export default MainLeadInfo;
