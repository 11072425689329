import {
  SET_SERVICE_PROCESSES,
} from 'store/actionTypes/serviceProcesses';

const set = (payload) => ({
  type: SET_SERVICE_PROCESSES,
  payload,
});

export default set;
