import apiConnector, { endpoints } from 'api';

const closeThread = (threadId) => async (tokenId) => {
  const endpoint = `${endpoints.client.integrations.emails}/${threadId}/close`;

  try {
    const result = await apiConnector.client.post(endpoint, { tokenId });
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default closeThread;
