import React from 'react';

import PropTypes from 'prop-types';

import { datetime } from 'refactored/utils/datetime';
import { between, ago } from 'refactored/utils/datetime';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';

const Expiry = (props) => {
  const {
    date,
  } = props;

  const hourValue = between(datetime(), date, 'hour');

  return (
    <>
      {
        hourValue > 0
          ? (
            <Typography
              variant="cell"
              color="warning"
            >
              {`${hourValue} hours`}
            </Typography>

          )
          : (
            <Flex
              flexDirection="column"
              spacing="1"
            >
              <Typography
                variant="cell"
                color="alert"
              >
                Expired
              </Typography>
              <Typography
                variant="cell2"
                color="alert"
              >
                {ago(date)}
              </Typography>
            </Flex>
          )
      }

    </>
  );
};

Expiry.defaultProps = {
  date: null,
};

const {
  string,
} = PropTypes;

Expiry.propTypes = {
  date: string,
};

export default Expiry;
