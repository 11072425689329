import React from 'react';
import PropTypes from 'prop-types';

import { Form, Input } from 'antd';

import Button from 'ui/Button';

import { isEmpty, get } from 'shared/utility';

import isValidEmail from 'shared/utils/isValidEmail'
import isValidPassword from 'shared/utils/isValidPassword'

import Typography from 'ui/Typography';
import Textfield from 'ui/Textfield';

import ButtonLink from 'shared/components/ButtonLink';
import { questions } from 'shared/modals/GeneralEmployee/2FA/QuestionList';

import { securityQuestionEnum } from 'shared/modals/GeneralEmployee/2FA/shared';

import PinInput from 'react-pin-input';

import './LoginWorkflow.scss';
import urlParser from 'url-parse';
import checkIfAccountExists from 'api/requests/account/checkIfAccountExists';

const signUpLink = process.env.REACT_APP_SYMPLETE_SIGN_UP_LINK;

const rootClass = 'login-workflow';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      twoFactor: {
        answerOne: '',
        answerTwo: '',
        answerThree: '',
        code: ''
      },
      remember: true,
      pastedCode: '',
      email: '',
      password: '',
      canSubmitForm: false,
      errors: []
    };
    this.logInButtonRef = React.createRef();
  }
  /* 2 FACTOR START */

  componentDidMount() {
    const { form } = this.props;
    const parsedUrl = urlParser(window.location.href, true);
    const { user } = parsedUrl.query;

    if (user) {
      form.setFieldsValue({ email: user })
      this.handleEmailChange(user)
      checkIfAccountExists(user).then(res => {
        if (res.data) {
          this.setState({ errors: ['This email already belongs to an account. Please Log In.'] })
        }
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error) {
      this.setState({ errors: ['Your login details are incorrect. Please try again.'] })
    }
  }

  setCode = code => {
    this.setState(
      state => ({
        ...state,
        twoFactor: {
          ...state.twoFactor,
          code: String(code)
        }
      }),
      this.handleAnswersSubmit
    );
  };

  toggleRemember = () => {
    const { remember } = this.state;

    this.setState(state => ({
      ...state,
      remember: !remember
    }));
  };

  getTitleById = (id = '', dictionary = []) => {
    if (!id) {
      return '';
    }

    return dictionary.find(({ id: iterableId }) => iterableId === id).question;
  };

  handleAnswerInput = questionNum => ({ target: { value } }) => {
    this.setState(state => ({
      ...state,
      twoFactor: {
        ...state.twoFactor,
        [`answer${questionNum}`]: value
      }
    }));
  };

  renderInputAndTitle = (questionOneTitle, questionNumString, isError) => {
    const { twoFactor } = this.state;

    const validateStatus = isError ? 'error' : '';

    const helpLabel = isError ? 'Answer is incorrect' : '';

    return (
      <>
        <Typography className={`${rootClass}__questions_title`}>
          {questionOneTitle}
        </Typography>
        <Form.Item validateStatus={validateStatus} help={helpLabel}>
          <Input
            className={`${rootClass}__questions_input_style`}
            name="questionOne"
            onChange={this.handleAnswerInput(questionNumString)}
            value={twoFactor[`answer${questionNumString}`]}
          />
        </Form.Item>
      </>
    );
  };

  getErrorByInputNum = inputNum => {
    let { error } = this.props;

    if (!error || !Array.isArray(error)) {
      return false;
    }

    error = error.find(({ questionNum }) => questionNum === inputNum);
    /* we need to know is the error present or not */
    return !!error;
  };

  onForgotPassword = () => {
    const { form, onForgot } = this.props;

    console.log('clicked')

    const email = form.getFieldValue('email');
    onForgot(email);
  };

  renderQuestionsForm = twoFactor => {
    const questionOneID = get(twoFactor, 'data.questionOneID');
    const questionTwoID = get(twoFactor, 'data.questionTwoID');
    const questionThreeID = get(twoFactor, 'data.questionThreeID');
    const questionOneTitle = this.getTitleById(questionOneID, questions);
    const questionTwoTitle = this.getTitleById(questionTwoID, questions);
    const questionThreeTitle = this.getTitleById(questionThreeID, questions);

    const questionOneError = this.getErrorByInputNum(1);
    const questionTwoError = this.getErrorByInputNum(2);
    const questionThreeError = this.getErrorByInputNum(3);

    return (
      <>
        {this.renderInputAndTitle(
          `1. ${questionOneTitle}`,
          'One',
          questionOneError
        )}
        {this.renderInputAndTitle(
          `2. ${questionTwoTitle}`,
          'Two',
          questionTwoError
        )}
        {this.renderInputAndTitle(
          `3. ${questionThreeTitle}`,
          'Three',
          questionThreeError
        )}
      </>
    );
  };

  validateInput = value => {
    const { pastedCode } = this.state;

    if (pastedCode) return pastedCode;

    const numCode = value.charCodeAt(0);
    const isInteger =
      numCode >= '0'.charCodeAt(0) && numCode <= '9'.charCodeAt(0);

    return isInteger ? value : '';
  };

  renderCodeForm = () => (
    <PinInput
      length={6}
      type="numeric"
      style={{
        paddingBottom: '30px',
        width: '100%'
      }}
      validate={this.validateInput}
      inputStyle={{
        width: '40px',
        height: '63px',
        marginLeft: '7px',
        marginRight: '7px',
        borderRadius: '4px',
        border: 'solid 1px #d9d9d9',
        backgroundColor: '#ffffff'
      }}
      onComplete={this.setCode}
    />
  );

  onPaste = event => {
    event.persist();

    const IEText = event.clipboardData.getData('Text');
    const otherBrowsersText = event.clipboardData.getData('text/plain');
    const value = IEText || otherBrowsersText;

    if (value) {
      // fill inputs DANGEROUS, beacause PinInput is uncontrolled component
      /* let pininput = document.getElementsByClassName('pincode-input-container')[0]
      pininput && pininput.children.forEach((input, index ) => {
        input.value = value[index]
      }) */

      this.setState(
        {
          pastedCode: `${value}`
        },
        this.handleAnswersSubmit
      );
    }
  };

  renderEmailAndPassForm = () => {
    const {
      form: { getFieldDecorator },
      twoFactor,
      externalLogin,
      externalPassword,
    } = this.props;

    /* absent two factor means that login and pass were not successful */
    if (isEmpty(twoFactor)) {
      return (
        <>
          <div
            className="login-form__item"
            data-test="email-field-form-item"
          >
            {getFieldDecorator('email', {
              validateTrigger: 'onBlur',
              initialValue: externalLogin
            })(
              <Textfield
                icon="mail"
                iconPosition="left"
                iconColor="rgba(83, 83, 83, 0.4)"
                iconSize="s"
                name="email"
                placeholder="Email"
                onEnter={this.handleSubmit}
                style={{ paddingBottom: 5 }}
                onChange={this.handleEmailChange}
                className={this.state.errors.length > 0 ? 'input-error' : ''}
              />
            )}
          </div>
          <div
            className="login-form__last-item"
            data-test="password-field-form-item"
          >
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: 'Please input your password!'
                }
              ],
              initialValue: externalPassword
            })(

              <Textfield.Password
                name="password"
                icon="lock"
                iconColor="rgba(83, 83, 83, 0.4)"
                iconSize="s"
                color="grey"
                placeholder="Password"
                onEnter={this.handleSubmit}
                onChange={this.handlePasswordChange}
                className={this.state.errors.length > 0 ? 'input-error' : ''}
              />
            )}
          </div>
          <p onClick={this.onForgotPassword} className='forgot-password-btn'>
            <Typography
              tag="span"
              color="#616161"
              className="login-form__help-label"
            >
              Forgot password?
            </Typography>
          </p>
          <Form.Item>
            <Button
              data-test="log-in-button"
              onClick={this.handleSubmit}
              variant="primary"
              htmlType="submit"
              fullWidth
              buttonRef={this.logInButtonRef}
              disabled={!this.state.canSubmitForm}
            >
              Continue
            </Button>
            <div className="login-form__dont-have-account-text">
              <Typography
                tag="span"
                color={this.props.theme?.colors['form-label-font-color']}
                className="login-form__help-label"
              >
                Don't have an account?{' '}
              </Typography>
              <Typography
                tag="span"
                color="#3B2A80"
                className="login-form__help-label"
              >
                <a
                  data-test="sign-up-button"
                  href={signUpLink}
                  style={{ color: '#3B2A80', fontWeight: 'bold' }}
                  className="login-form__sign-up-link"
                >
                  Sign Up.
                </a>
              </Typography>
            </div>
            <div className="login-form__terms">
              By clicking “Continue” agree to Symplete's
              <a href='https://symplete.com/master-subscription-agreement/'>
                Terms of Service
              </a>
              and
              <a href='https://symplete.com/privacy-policy/'>
                Privacy Policy
              </a>
              .
            </div>
          </Form.Item>
        </>
      );
    }

    const type = get(twoFactor, 'type');
    let twoFactorInputs = null;

    if (type === securityQuestionEnum) {
      twoFactorInputs = this.renderQuestionsForm(twoFactor);
    } else {
      twoFactorInputs = this.renderCodeForm();
    }

    return (
      <div onPaste={this.onPaste}>
        {twoFactorInputs}
        <Button
          onClick={this.handleAnswersSubmit}
          type="primary"
          htmlType="submit"
        >
          Log in
        </Button>
      </div>
    );
  };

  verifyFormFields() {
    const { email, password } = this.state;

    if (!isValidEmail(email) || !isValidPassword(password))
      this.setState({ errors: ['Your login details are incorrect. Please try again.'] })
    else
      this.setState({ errors: [] })

    this.renderError();
  }

  renderError = () => {
    const { errors } = this.state;
    const { error } = this.props;

    if (!errors.length) {
      return null;
    }

    if (error) {
      return <Typography key={error} tag="div" color="error">
        {error}
        <br />
      </Typography>
    }

    const mappedErrors = errors.map((errorString) => (
      <Typography key={errorString} tag="div" color="error">
        {errorString}
        <br />
      </Typography>
    ));

    return <div className='input-warning'>{mappedErrors}</div>;
  };
  clearErrors() {
    this.setState({ errors: [] })
  }

  handleSubmit = event => {
    event.preventDefault();
    this.clearErrors();
    this.logInButtonRef.current.className = `${this.logInButtonRef.current.className} activated`;
    const { form, onSubmit } = this.props;

    form.validateFields((error, values) => {
      if (!error) {
        onSubmit(values);
      } else {
        this.verifyFormFields();
      }
    });
  };

  handleEmailChange = (val) => {
    this.setState({ email: val }, () => {
      this.handleCanSubmitState();
    });
  }
  handlePasswordChange = (val) => {
    this.setState({ password: val }, () => {
      this.handleCanSubmitState();
    });
  }

  handleCanSubmitState = () => {

    const { email, password } = this.state;
    const canSubmitForm = email.trim() !== '' && password.trim() !== '';

    this.clearErrors();
    this.setState({ canSubmitForm });
  }

  render() {
    return (
      <Form className="login-form" data-test="login-form">
        {this.renderError()}
        {this.renderEmailAndPassForm()}
      </Form>
    );
  }
}

LoginForm.defaultProps = {
  error: [],
  twoFactor: {},
  externalLogin: null,
  externalPassword: null
};

const { array, func, object, string } = PropTypes;

LoginForm.propTypes = {
  error: array, // eslint-disable-line react/forbid-prop-types
  form: object.isRequired, // eslint-disable-line react/forbid-prop-types
  onForgot: func.isRequired,
  onSubmit: func.isRequired,
  twoFactor: object, // eslint-disable-line react/forbid-prop-types
  externalLogin: string,
  externalPassword: string
};

export default Form.create({
  name: 'normal_login'
})(LoginForm);
