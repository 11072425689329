exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".contactPillList_contact-pill-list__iXUss {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  grid-gap: 4px;\n  gap: 4px;\n}", ""]);

// exports
exports.locals = {
	"contact-pill-list": "contactPillList_contact-pill-list__iXUss"
};