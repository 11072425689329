import requests from 'api/requests';

import getContactsLabels from './getContactsLabels';

const updateLabel = (labelId) => ({ name, description = null }) => async (dispatch) => {
  try {
    await requests.labels.edit(labelId)(name)(description);

    dispatch(getContactsLabels());
  } catch (error) {
    console.error(error);
  }
};

export default updateLabel;
