import React from 'react';
import PropTypes from 'prop-types';

import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';

import ErrorBoundary from 'ui/ErrorBoundary';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Typography from 'ui/Typography';
import Tooltip from 'ui/Tooltip';
import Icon from 'ui/Icon';

import useContact from 'sections/Contacts/hooks/useContact';
import useContactName from 'shared/hooks/contacts/useContactName';
import useContactDefaultPhoneNumber from 'shared/hooks/contacts/useContactDefaultPhoneNumber';
import useContactDefaultEmail from 'shared/hooks/contacts/useContactDefaultEmail';

import { formatPhoneNumber } from 'shared/utils/phoneNumbers';

import Avatar from './Avatar';

import { Card } from './styledItems';

const ContactItem = (props) => {
  const {
    contactId,
    isSelected,
    onAdd,
    onRemove,
  } = props;

  const contactName = useContactName(contactId);
  const contactDefaultPhoneNumber = useContactDefaultPhoneNumber(contactId);
  const email = useContactDefaultEmail(contactId);

  const formattedPhoneNumber = formatPhoneNumber(contactDefaultPhoneNumber);

  return (
    <ErrorBoundary>
      <NakedButton
        onClick={isSelected ? onRemove : onAdd}
        style={{ width: '100%' }}
      >
        <Card isSelected={isSelected}>
          <Flex
            flexWrap="nowrap"
            spacing={1}
          >
            <Avatar contactId={contactId} />

            <FlexItem
              flex={1}
            >
              <Flex
                flexDirection="column"
                spacing={1}
              >
                <Tooltip title={contactName || ''}>
                  <Typography
                    ellipsis
                    variant="title3"
                    weight={500}
                    noMargin
                    style={{ maxWidth: 100 }}
                  >
                    {contactName || ''}
                  </Typography>
                </Tooltip>

                {
                  !!formattedPhoneNumber && (
                    <Tooltip title={formattedPhoneNumber}>
                      <div>
                        <Flex
                          alignItems="center"
                          spacing={1}
                        >
                          <Icon
                            size="xs"
                            color="main-text"
                            name="phone"
                          />
                          <Typography
                            noMargin
                            ellipsis
                            variant="caption"
                            style={{ maxWidth: 100 }}
                          >
                            {formattedPhoneNumber}
                          </Typography>
                        </Flex>
                      </div>
                    </Tooltip>
                  )
                }
                {
                  !!email && (
                    <Tooltip title={email || ''}>
                      <div>
                        <Flex
                          alignItems="center"
                          spacing={1}
                        >
                          <Icon
                            size="xs"
                            color="main-text"
                            name="mail"
                          />
                          <Typography
                            noMargin
                            ellipsis
                            variant="caption"
                            style={{ maxWidth: 100 }}
                          >
                            {email || ''}
                          </Typography>
                        </Flex>
                      </div>
                    </Tooltip>
                  )
                }

              </Flex>
            </FlexItem>

          </Flex>
        </Card>
      </NakedButton>
    </ErrorBoundary>
  );
};

ContactItem.defaultProps = {
  isSelected: false,
};

const { number, bool, func } = PropTypes;

ContactItem.propTypes = {
  contactId: number.isRequired,
  isSelected: bool,
  onAdd: func.isRequired,
  onRemove: func.isRequired,
};

export default ContactItem;
