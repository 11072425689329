import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';
import Button from 'ui/Button';
import Flex from 'ui/Flex';

import PropertyAddressInput from 'shared/inputs/PropertyAddressInput';

import withNewPropertyFormDefaultValue from 'sections/MLS/hocs/withNewPropertyFormDefaultValue';

import MLSWebsiteRaw from './MLSWebsite';
import ListedSalePriceInputRaw from './ListedSalePriceInput';
import OnMarketSince from './OnMarketSince';
import MLSNumberRaw from './MLSNumber';
import PropertyTypeSelectRaw from './PropertyTypeSelect';
import SellingAgent from './SellingAgent';
import NewContactArea from './NewContactArea';
import ImagesUpload from './ImagesUpload';
// import AssignInterestedParty from './AssignInterestedParty';

const MLSWebsite = withNewPropertyFormDefaultValue(MLSWebsiteRaw)('mls_website');
const ListedSalePriceInput = withNewPropertyFormDefaultValue(ListedSalePriceInputRaw)('expected_price');
const MLSNumber = withNewPropertyFormDefaultValue(MLSNumberRaw)('mls_number');
const PropertyTypeSelect = withNewPropertyFormDefaultValue(PropertyTypeSelectRaw)('property_type');

const NewPropertyForm = (props) => {
  const {
    handleChange,
    handleChangeNested,
    selectedAgent,
    onMarketSince,
    addressId,
  } = props;

  return (
    <form>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <PropertyAddressInput
            decorated
            useId
            addressId={addressId}
            handleChange={handleChange('address_id')}
          />
        </Grid>

        <Grid item xs={12}>
          <MLSWebsite
            icon="globe"
            label="MLS website address"
            onChange={handleChange('mls_website')}
          />
        </Grid>

        <Grid item xs={6}>
          <ListedSalePriceInput
            // decorated
            onChange={handleChange('expected_price')}
          />
        </Grid>
        <Grid item xs={6}>
          <OnMarketSince
            label="On market since"
            // decorated
            // decoratorProps={{ iconName: 'clock' }}
            value={onMarketSince}
            handleChange={handleChange('on_market_since')}
          />
        </Grid>

        <Grid item xs={6}>
          <MLSNumber
            icon="home"
            // decorated
            // decoratorProps={{ iconName: 'home' }}
            label="MLS number"
            onChange={handleChange('mls_number')}
          />
        </Grid>
        <Grid item xs={6}>
          <PropertyTypeSelect
            // decorated
            handleChange={handleChange('property_type')}
          />
        </Grid>

        {/* <Grid item xs={12}>
          <AssignInterestedParty
            decorated
            handleChange={handleChange('interested_parties')}
          />
        </Grid>

*/}

        <Grid item xs={12}>
          <Typography variant="title3">
            Listing agent
          </Typography>
          <Flex spacing={2}>
            {
              !selectedAgent && selectedAgent !== 0 && (
                <Button
                  variant="tertiary"
                  onClick={(event) => {
                    event.preventDefault();
                    handleChange('listing_agent')(0);
}}
                >
                  You
                </Button>
              )
            }
            <div style={{ flex: 1 }}>
              <SellingAgent
                // decorated
                textfieldProps={{
                  icon: 'user',
                }}
                handleChange={handleChange('listing_agent')}
                selectedContactId={selectedAgent}
              />
            </div>
          </Flex>
        </Grid>

        {
          !selectedAgent && selectedAgent !== 0 && (
            <>
              <Grid item xs={12}>
                <NewContactArea
                  title="Or create a new agent"
                  handleChange={handleChangeNested('newSellerAgent')}
                />
              </Grid>
              <Grid item xs={12} />
            </>
          )
        }

        <Grid item xs={12}>
          <ImagesUpload
            // decorated
            label="+ Drag & drop or click to upload property photos here (max 5)"
          />
        </Grid>
      </Grid>
    </form>
  );
};

NewPropertyForm.defaultProps = {
  selectedAgent: null,
  onMarketSince: null,
  addressId: null,
};

const {
  func,
  number,
  string,
} = PropTypes;

NewPropertyForm.propTypes = {
  handleChange: func.isRequired,
  handleChangeNested: func.isRequired,
  selectedAgent: number,
  onMarketSince: string,
  addressId: number,
};

export default NewPropertyForm;
