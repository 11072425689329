exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".senderPillImage_sender-image__4neeZ {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n  height: 20px;\n  min-width: 20px;\n  background-color: #1e9896;\n  border-radius: 100%;\n  align-self: flex-end;\n  border: none;\n  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);\n  cursor: pointer;\n}\n\n.senderPillImage_sender-image-text__8RHdR {\n  color: white;\n  height: 10px;\n  font-size: 10px;\n  line-height: 10px;\n  font-weight: 700;\n  font-family: \"Hind-Bold\";\n}", ""]);

// exports
exports.locals = {
	"sender-image": "senderPillImage_sender-image__4neeZ",
	"sender-image-text": "senderPillImage_sender-image-text__8RHdR"
};