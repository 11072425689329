import {
  SET_FULFILLMENT_SELECTED_ROWS,
} from 'store/actionTypes/fulfillments';

const setSelectedFulfillmentRows = (ids) => ({
  type: SET_FULFILLMENT_SELECTED_ROWS,
  payload: ids,
});

export default setSelectedFulfillmentRows;
