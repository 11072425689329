exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".numeratorColumn_numerator__1GOlA {\n  height: 100%;\n  padding-top: 39px;\n  padding-right: 0px;\n  margin-right: 0px;\n  display: flex;\n  flex-direction: column;\n  justify-content: start;\n  align-items: start;\n  align-content: start;\n  grid-gap: 12px;\n  gap: 12px;\n}\n\n.numeratorColumn_number__qvvEs {\n  height: 180px;\n  width: 100%;\n  font-size: 14px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: end;\n  font-family: \"Hind-Regular\";\n  font-size: 36px;\n  font-weight: 400;\n  line-height: 34px;\n  letter-spacing: 0px;\n  text-align: start;\n  color: #DDDDDD;\n}", ""]);

// exports
exports.locals = {
	"numerator": "numeratorColumn_numerator__1GOlA",
	"number": "numeratorColumn_number__qvvEs"
};