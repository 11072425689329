import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';

import {
  isPlainObject,
  union,
  intersection,
} from 'shared/utility';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import Icon from 'ui/Icon';
import HorizontalDivider from 'ui/HorizontalDivider';
import Tag from 'ui/Tag';

import {
  StyledSelect,
  StyledOption,
  CheckIconContainer,
} from './styledItems';

const groupByCategory = (options) => {
  const optionsByCategory = {};

  options.forEach((option) => {
    const optionCategory = option.category || 'other';

    if (!optionsByCategory[optionCategory]) {
      optionsByCategory[optionCategory] = [];
    }

    optionsByCategory[optionCategory].push(option);

    return optionsByCategory;
  });

  return optionsByCategory;
};

const SelectList = (props) => {
  const {
    label,
    description,
    options,
    selectedValues,
    withPrimaryValue,
    primaryValue,
    groupOptionsByCategory,
    categoriesOrder,
    onSelect,
    onDeselect,
  } = props;

  const [preparedOptions, setPreparedOptions] = useState([]);

  useEffect(() => {
    if (groupOptionsByCategory) {
      setPreparedOptions(groupByCategory(options));
    } else {
      setPreparedOptions(options);
    }
  }, []);

  const handleChange = useCallback((value) => () => {
    if (selectedValues.includes(value)) {
      onDeselect(value);
    } else {
      onSelect(value);
    }
  }, [
    JSON.stringify(selectedValues),
    onSelect,
    onDeselect,
  ]);

  const handlePrimarySelect = useCallback((value) => (event) => {
    onSelect(value, true);
    event.stopPropagation();
  }, [
    onSelect,
  ]);

  const renderOptions = useCallback((items) => {
    if (!Array.isArray(items)) {
      return null;
    }

    const isListEmpty = selectedValues.length === 0;

    return items.map((item) => {
      const isSelected = selectedValues.includes(item.value);
      const isPrimary = item.value === primaryValue;

      return (
        <StyledOption
          key={item.value}
          value={item.value}
          onClick={handleChange(item.value)}
          selected={isSelected}
          isPrimary={isPrimary}
          isListEmpty={isListEmpty}
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="button"
              tag="span"
              noMargin
              ellipsis
              style={{ maxWidth: 260 }}
            >
              {item.label}
            </Typography>

            <Flex
              alignItems="center"
              spacing={2}
            >
              {
                withPrimaryValue && (
                  <>
                    {
                      isPrimary ? (
                        <Tag
                          size="s"
                        >
                          Primary
                        </Tag>
                      ) : (
                        <NakedButton
                          onClick={handlePrimarySelect(item.value)}
                          className="select-list-primary-label-ghost"
                        >
                          <Tag
                            size="s"
                            variant="background-overlay-light"
                            textColor="disabled"
                            border={{ color: 'focus', style: 'dashed' }}
                          >
                            Primary
                          </Tag>
                        </NakedButton>
                      )
                    }
                  </>
                )
              }

              <CheckIconContainer selected={isSelected}>
                <Icon
                  name="check"
                  color="focus"
                />
              </CheckIconContainer>
            </Flex>
          </Flex>
        </StyledOption>
      );
    });
  }, [
    JSON.stringify(selectedValues),
    withPrimaryValue,
    primaryValue,
  ]);

  const renderGroupedOptions = useCallback((groups) => {
    if (!isPlainObject(groups)) {
      return null;
    }

    const keys = Object.keys(groups);
    const sortedKeys = intersection(union(categoriesOrder, keys), keys);

    return sortedKeys.map((key) => (
      <div key={key}>
        <Typography
          variant="title3"
          weight={500}
          color="disabled"
          noMargin
          style={{
            textTransform: 'uppercase',
            marginTop: 20,
          }}
        >
          {key}
        </Typography>
        {
          renderOptions(groups[key])
        }
      </div>
    ));
  }, [
    renderOptions,
    JSON.stringify(categoriesOrder),
  ]);

  return (
    <div>
      <Typography
        variant="title1"
        weight="bold"
      >
        {label}
      </Typography>
      {
        description && (
          <Typography
            variant="body2"
          >
            {description}
          </Typography>
        )
      }
      {
        !groupOptionsByCategory && <HorizontalDivider marginTop={7} marginBottom={5} />
      }

      <StyledSelect>
        {
          groupOptionsByCategory ? renderGroupedOptions(preparedOptions) : renderOptions(preparedOptions)
        }
      </StyledSelect>
    </div>
  );
};

SelectList.defaultProps = {
  label: 'Select',
  description: null,
  selectedValues: [],
  groupOptionsByCategory: false,
  primaryValue: null,
  withPrimaryValue: false,
  categoriesOrder: [],
};

const {
  bool,
  func,
  string,
  number,
  oneOfType,
  arrayOf,
  shape,
  element,
} = PropTypes;

SelectList.propTypes = {
  options: arrayOf(shape({
    value: oneOfType([number, string]).isRequired,
    label: string.isRequired,
    category: string,
  })).isRequired,
  onSelect: func.isRequired,
  onDeselect: func.isRequired,
  label: string,
  selectedValues: arrayOf(oneOfType([number, string])),
  groupOptionsByCategory: bool,
  primaryValue: oneOfType([number, string]),
  withPrimaryValue: bool,
  categoriesOrder: arrayOf(string),
  description: oneOfType([string, element]),
};

export default SelectList;
