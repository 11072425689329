import {
  SET_ORDER_ATTACHMENTS,
} from 'store/actionTypes/orders';

const setOrderAttachments = (id, attachments) => ({
  type: SET_ORDER_ATTACHMENTS,
  payload: {
    id,
    attachments,
  },
});

export default setOrderAttachments;
