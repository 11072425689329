import React from 'react';
import styles from './iconButton.module.css';

const IconButton = ({ icon, isActive = false, variant, onClick = () => { }, className, size }) => {
    const buttonClasses = `
        ${variant === 'white' ? styles['icon-btn-white'] : styles['icon-btn']}
        ${isActive && (variant === 'white' ? styles['icon-btn-white--active'] : styles['icon-btn--active'])}
        ${className}
    `;

    const buttonStyle = size ? { width: size, height: size } : {};

    return (
        <button className={buttonClasses} style={buttonStyle} onMouseDown={onClick}>
            {icon && <img src={icon} alt="Icon" className={styles['icon-btn-icon']} />}
        </button>
    );
};

export default IconButton;
