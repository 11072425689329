exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".cardsGrid_cards-grid__nz1oj {\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  grid-gap: 12px;\n  gap: 12px;\n  align-items: center;\n  padding: 24px 60px;\n}", ""]);

// exports
exports.locals = {
	"cards-grid": "cardsGrid_cards-grid__nz1oj"
};