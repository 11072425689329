import requests from 'api/requests';

// eslint-disable-next-line consistent-return
const createAgreement = (data) => async (dispatch) => {
  try {
    const response = await requests.agreements.create(data);

    return response.data.id;
  } catch (error) {
    console.error(error);
  }
};

export default createAgreement;
