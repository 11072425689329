// import React, {
//   useState,
//   useCallback,
//   useEffect,
// } from 'react';

// import PropTypes from 'prop-types';

// import CurrencyTextField from '@unicef/material-ui-currency-textfield';

// import withFieldDecorator from 'shared/hocs/withFieldDecorator';

// const SalePriceInput = (props) => {
//   const {
//     handleChange,
//     value,
//     label,
//     onBlur,
//     onFocus,
//     error,
//     helperText,
//     variant,
//     disableUnderline,
//     textAlign,
//   } = props;

//   const [currentValue, setCurrentValue] = useState(value);

//   useEffect(() => {
//     setCurrentValue(value);
//   }, [
//     value,
//   ]);

//   const onChange = useCallback((event, newValue) => {
//     setCurrentValue(newValue);
//     handleChange(newValue);
//   }, [
//     handleChange,
//   ]);

//   return (
//     <CurrencyTextField
//       className=""
//       label={label}
//       onChange={onChange}
//       value={currentValue}
//       margin="dense"
//       variant={variant}
//       outputFormat="number"
//       minimumValue={0}
//       currencySymbol="$"
//       fullWidth
//       decimalPlaces={0}
//       onBlur={onBlur}
//       onFocus={onFocus}
//       error={error}
//       helperText={helperText}
//       InputProps={{
//         disableUnderline,
//       }}
//       textAlign={textAlign}
//     />
//   );
// };

// SalePriceInput.defaultProps = {
//   value: null,
//   label: 'Sale price expected',
//   onBlur: () => {},
//   onFocus: () => {},
//   error: false,
//   helperText: '',
//   variant: 'outlined',
//   disableUnderline: false,
//   textAlign: 'right',
// };

// const {
//   func,
//   number,
//   string,
//   bool,
// } = PropTypes;

// SalePriceInput.propTypes = {
//   handleChange: func.isRequired,
//   value: number,
//   label: string,
//   onBlur: func,
//   onFocus: func,
//   error: bool,
//   helperText: string,
//   variant: string,
//   disableUnderline: bool,
//   textAlign: string,
// };

// const decoratorProps = {
//   iconName: 'tag',
// };

// export default withFieldDecorator(decoratorProps)(SalePriceInput);

import CurrencyInput from 'ui/CurrencyInput';

export default CurrencyInput;
