exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".emailCompanyMailIcon_email-company-mail-icon__qzzYe {\n  height: 16px;\n  width: 16px;\n  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);\n  background-color: white;\n  background-position: center center;\n  background-repeat: no-repeat;\n  border-radius: 24px;\n}", ""]);

// exports
exports.locals = {
	"email-company-mail-icon": "emailCompanyMailIcon_email-company-mail-icon__qzzYe"
};