import {
  SET_SELECTED_ADDRESS,
} from 'store/actionTypes/addresses';

const setSelected = (id) => ({
  type: SET_SELECTED_ADDRESS,
  payload: id,
});

export default setSelected;
