import styled from 'styled-components';

import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';

export const Root = styled(NakedButton)`
  && {
    width: 100%;
    padding: 7px 10px;
    justify-content: flex-start;
    &:hover {
      background-color: ${(props) => props?.theme?.palette?.['background-flat'] || 'red'};
    }
    &:active {
      background-color: ${(props) => props?.theme?.palette?.['background-hover'] || 'green'};
      transition: 0.2s
    }
  }
`;

export default {
  Root,
};
