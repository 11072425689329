import { apiConnector } from 'shared/API'; // eslint-disable-line import/no-cycle
import { callAlert } from 'ui/Alert';
import requests from 'api/requests';
import {
  SET_TICKET_EMPLOYEES,
  SET_TICKET_EMPLOYEES_SUCCESS,
  SET_TICKET_EMPLOYEES_ERROR,
  SET_TICKET_CATEGORY,
  SET_TICKET_CATEGORY_SUCCESS,
  SET_TICKET_CATEGORY_ERROR,
  SET_TICKET_AGENT_NOTE,
} from 'store/actionTypes/ticket-details-modal';
import { // eslint-disable-line import/no-cycle
  ticketsStatus,
} from './tickets';

export const setTicketEmployees = () => ({ type: SET_TICKET_EMPLOYEES });

export const setTicketEmployeesSuccess = (config, data) => (dispatch, getState) => {
  dispatch({ type: SET_TICKET_EMPLOYEES_SUCCESS, config, data });
  ticketsStatus(dispatch, getState);
  // dispatch(getTickets());
};

export const setTicketEmployeesError = () => ({ type: SET_TICKET_EMPLOYEES_ERROR });

export const setTicketCategory = (ticketId, data) => (dispatch) => {
  const updateURL = `/api/tickets/updateCategory/${ticketId}`;
  dispatch({ type: SET_TICKET_CATEGORY });
  apiConnector(updateURL, 'PUT', data)
    .then((ticket) => dispatch(setTicketCategorySuccess(ticket)))
    .catch((err) => dispatch(setTicketCategoryError()));
};

export const setTicketAgentNote = (ticketID, newAgentNote) => (dispatch) => {
  try {
    requests.tickets.editAgentNote(ticketID, newAgentNote);

    dispatch({
      type: SET_TICKET_AGENT_NOTE,
      newAgentNote,
    });
  } catch {
    callAlert.error('An error occurred when changing the agent\'s note!');
  }
};

export const setTicketCategorySuccess = (ticket) => ({ type: SET_TICKET_CATEGORY_SUCCESS, ticket });

export const setTicketCategoryError = () => ({ type: SET_TICKET_CATEGORY_ERROR });
