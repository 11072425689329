import {
  SET_SELECTED_SERVICE_PROCESS,
} from 'store/actionTypes/serviceProcesses';

const setSelected = (id) => ({
  type: SET_SELECTED_SERVICE_PROCESS,
  payload: id,
});

export default setSelected;
