import {
  SET_CRM_SELECTED_CONTACT_ROWS,
} from 'store/actionTypes/crm';

const setSelectedContactRows = (ids) => ({
  type: SET_CRM_SELECTED_CONTACT_ROWS,
  payload: ids,
});

export default setSelectedContactRows;
