import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';

import SearchItemProspect from 'shared/components/UI/SearchItemProspect';
import SearchCategoryBlock from 'shared/components/UI/SearchCategoryBlock';

import withProspectData from 'sections/Prospects/hocs/withProspectData';

const SearchItemProspectModule = withProspectData(SearchItemProspect);

const Item = ({ prospect, handleClick }) => (handleClick
  ? (
    <NakedButton
      onClick={handleClick(prospect.id)}
      key={prospect.id}
    >
      <SearchItemProspectModule
        prospectId={prospect.id}
      />
    </NakedButton>
  )
  : (
    <Link
      to={`/prospects/${prospect.id}`}
      key={prospect.id}
    >
      <SearchItemProspectModule
        prospectId={prospect.id}
      />
    </Link>
  )
);

const ProspectsSearch = (props) => {
  const [searchResult, setSearchResult] = useState([]);

  const {
    search,
    onSearch,
    onSelect,
  } = props;

  useEffect(() => {
    const fetchData = async () => {
      const data = await onSearch(search);
      setSearchResult(data);
    };

    fetchData();
  }, [search]);

  const handleClick = onSelect
    ? useCallback((prospectId) => (event) => {
      event.preventDefault();
      onSelect(30)(prospectId);
    })
    : null;

  return (
    <SearchCategoryBlock
      title="prospects"
      iconName="star"
    >
      {
        searchResult && searchResult.map((prospect) => (
          <Item
            key={prospect?.id}
            prospect={prospect}
            handleClick={handleClick}
          />
        ))
      }
    </SearchCategoryBlock>
  );
};

ProspectsSearch.defaultProps = {
  search: '',
};

const {
  func,
  string,
} = PropTypes;

ProspectsSearch.propTypes = {
  search: string,
  onSearch: func.isRequired,
};

export default ProspectsSearch;
