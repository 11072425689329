import { useState, useEffect } from 'react';
import getAllContacts from 'api/requests/realtor/contacts/get';
import useIntegrations from 'sections/Integrations/hooks/useIntegrations';

const useContacts = () => {
    const { integrationsByScope } = useIntegrations();
    const [tokenId, setTokenId] = useState(null);
    const defaultFilters = {
        offset: 0,
        limit: 10,
        sorty_by: "id",
        sort_direction: "desc"
    };
    const filters = { ...defaultFilters };
    const [contacts, setContacts] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const getContacts = async (contactFilters) => {
        setIsLoading(true);
        setError(null);

        try {
            console.log('contactFilters not working:', contactFilters)
            const response = await getAllContacts(contactFilters);
            const rawContacts = response.data.items

            setContacts(rawContacts);
        } catch (newError) {
            setError(newError);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getContacts(filters);
    }, [
        JSON.stringify(filters),
    ]);


    useEffect(() => {
        if (!integrationsByScope?.email) return;
        setTokenId(integrationsByScope.email[0]?.id || null);
    }, [integrationsByScope]);

    return {
        contacts,
        isLoading,
        error,
        getContacts,
    };
};

export default useContacts;
