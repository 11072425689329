import {
  SET_PROPERTY_REQUIREMENTS,
} from 'store/actionTypes/property-requirements';

const setPropertyRequirements = (requirements) => ({
  type: SET_PROPERTY_REQUIREMENTS,
  payload: requirements,
});

export default setPropertyRequirements;
