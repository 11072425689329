import React from 'react';
import { ThreadModalProvider } from './modals/ThreadModal.provider';
import { CommsHubProvider } from './data/CommsHub.provider';
import { ChangeProfilePictureModalProvider } from './modals/ChangeProfilePictureModal.provider';
import IntegrationsProvider from 'sections/Integrations/IntegrationsProvider';

const combineProviders = (...providers) => ({ children }) =>
    providers.reduceRight((child, Provider) => <Provider>{child}</Provider>, children);

const GlobalProviders = combineProviders(
    ChangeProfilePictureModalProvider,
    ThreadModalProvider,
    CommsHubProvider,
    IntegrationsProvider
)

export default GlobalProviders;
