import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ProfileThumbnail from 'refactored/components/shared/profileThumbnail/ProfileThumbnail';
import IconButton from 'refactored/components/shared/buttons/iconButton/IconButton';
import HomeIcon from 'assets/images/commsHub/home-icon.svg';
import ContactsIcon from 'assets/images/commsHub/contacts-icon.svg';
import CheckIcon from 'assets/images/commsHub/check-icon.svg';
import BellIcon from 'assets/images/commsHub/bell-icon.svg';
import PhoneIcon from 'assets/images/commsHub/phone-icon.svg';
import styles from './navbar.module.css';
import useCommsHubNavbar from './Navbar.hook';
import { openModal } from 'store/actions/modals';
import context from 'sections/Integrations/context';

const CommsHubNavbar = () => {
    const dispatch = useDispatch()
    const { location, showNotifications, showTodos } = useCommsHubNavbar();
    const { scopes } = useContext(context);
    useEffect(() => {
        const timer = setTimeout(() => {
            if (!scopes?.google?.has('email')) {
                dispatch(
                    openModal('onboardingModal')({}, {
                        isVisible: true,
                        firstLogin: true
                    })
                );
            }
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={styles['navbar']}>
            <div className={styles['navbar-profile-thumbnail-area']}>
                <ProfileThumbnail />
            </div>
            <title className={styles['navbar-symplete-title']}>symplete</title>
            <div className={styles['navbar-icon-btns-area']}>
                <Link to="/">
                    <IconButton icon={HomeIcon} isActive={location.pathname === '/'} />
                </Link>
                <Link to="/todo">
                    <IconButton icon={CheckIcon} isActive={location.pathname === '/check'} onClick={showTodos} />
                </Link>
                <Link to="/contacts">
                    <IconButton icon={ContactsIcon} isActive={location.pathname === '/contacts'} />
                </Link>
                <Link to="/notify">
                    <IconButton icon={BellIcon} isActive={location.pathname === '/bell'} onClick={showNotifications} />
                </Link>
                <Link to="/calls">
                    <IconButton icon={PhoneIcon} isActive={location.pathname === '/phone'} />
                </Link>
            </div>
        </div >
    );
};

export default CommsHubNavbar;