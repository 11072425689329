import {
  SET_CREDIT_NOTE_ATTACHMENTS,
} from 'store/actionTypes/creditNotes';

const setAttachments = (id, attachments) => ({
  type: SET_CREDIT_NOTE_ATTACHMENTS,
  payload: {
    id,
    attachments,
  },
});

export default setAttachments;
