import addContactRelations from './addContactRelations';

const addSelectedContactRelations = (relationType) => (updateData) => async (dispatch, getState) => {
  const {
    realtorContacts: {
      selectedContact,
    },
  } = getState();

  dispatch(addContactRelations(relationType)(selectedContact)(updateData));
};

export default addSelectedContactRelations;
