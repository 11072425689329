/* eslint-disable camelcase, react/jsx-no-bind */

import React, { useState } from 'react';

import PropTypes from 'prop-types';
import datetime, { between } from 'refactored/utils/datetime';

import { useForm } from 'react-hook-form';

import { noOp } from 'shared/utility';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Button from 'ui/Button';
import Divider from 'ui/Divider';

import useProperty from 'sections/Properties/hooks/useProperty';

import SubTitle from './SubTitle';

import OfferAmount from './OfferAmount';
import FinanceType from './FinanceType';

import Expiry from './Expiry';
import ClosingDate from './ClosingDate';

import Contingencies from './Contingencies';
import ContingencyDate from './ContingencyDate';

import Notes from './Notes';

import Agreement from './Agreement';

import {
  BackgroundHighlight,
  EqualityHighlight,
} from './styledItems';

import PreviousOfferForm from './PreviousOfferForm';

const CounterOfferForm = (props) => {
  const {
    propertyId,
    currentCounter,
    offerData,
    onSubmit,
  } = props;

  const property = useProperty(propertyId);

  const {
    expected_price,
    listing_agent,
  } = property;

  const representing = listing_agent === 0
    ? 'Seller'
    : 'Buyer';

  const {
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {

      // details
      offerAmount: offerData?.amount || expected_price || 0,
      expiresIn: 24,
      closesIn: between(datetime(), offerData?.closing_date, 'day') || 21,
      contingenciesIn: between(datetime(), offerData?.contingency_period_end, 'day') || 21,
      financeType: offerData.financing_type || 1,
      notes: offerData.note || null,

      // contingencies
      contingencyInspection: offerData.contingency_inspection || false,
      contingencyAppraisal: offerData.contingency_appraisal || false,
      contingencyLoan: offerData.contingency_loan || false,

      // agreement
      agreementFiles: null,
      agreementUrl: null,
    },
  });

  const [equality, setEquality] = useState({
    amount: false,
    expiresIn: false,
    closing_date: false,
    contingency_period_end: false,
    financing_type: false,
    contingency_inspection: false,
    contingency_appraisal: false,
    contingency_loan: false,
  });

  const checkEquality = (propertyName, value) => {
    if (offerData[propertyName] === value) {
      setEquality({
        ...equality,
        [propertyName]: false,
      });
      return;
    }

    setEquality({
      ...equality,
      [propertyName]: true,
    });
  };

  return (
    <Flex
      fullWidth
      flexWrap="wrap"
      spacing={1}
    >

      <Flex
        fullWidth
        spacing={2}
        fullHeight
        alignItems="stretch"
      >
        <Flex
          fullWidth
          flexDirection="column"
          spacing={3}
        >
          <PreviousOfferForm
            representing={representing}
            previousNumber={currentCounter - 1}
            offerData={offerData}
            equality={equality}
          />
        </Flex>

        <FlexItem>
          <Divider vertical />
        </FlexItem>

        <Flex
          fullWidth
          flexDirection="column"
          spacing={3}
        >
          <Flex
            fullWidth
            justifyContent="space-between"
          >
            <Typography variant="h2">
              {`Counter ${currentCounter} | ${representing}`}
            </Typography>

            <Flex spacing={2}>
              <Button
                variant="primary"
                onClick={handleSubmit(onSubmit)}
              >
                Log Counter
              </Button>
            </Flex>
          </Flex>

          <Flex
            fullWidth
            spacing={3}
          >
            <Flex
              fullWidth
              flexDirection="column"
              spacing={2}
            >
              <EqualityHighlight show={equality.amount}>
                <SubTitle>Purchase Price</SubTitle>
              </EqualityHighlight>
              <OfferAmount
                control={control}
                checkEquality={checkEquality}
              />
            </Flex>

            <Flex
              fullWidth
              flexDirection="column"
              spacing={2}
            >
              <SubTitle>Offer Expiry</SubTitle>
              <Expiry
                control={control}
                checkEquality={checkEquality}
              />
            </Flex>
          </Flex>

          <Flex
            fullWidth
            spacing={3}
          >
            <Flex
              fullWidth
              flexDirection="column"
              spacing={2}
            >
              <EqualityHighlight show={equality.financing_type}>
                <SubTitle>Financing</SubTitle>
              </EqualityHighlight>
              <FinanceType
                control={control}
                checkEquality={checkEquality}
              />
            </Flex>

            <Flex
              fullWidth
              flexDirection="column"
              spacing={2}
            >

              <SubTitle>Closing Date</SubTitle>

              <BackgroundHighlight
                spacing={1}
                alignItems="center"
                fullWidth
              >
                <ClosingDate
                  control={control}
                  checkEquality={checkEquality}
                />
              </BackgroundHighlight>
            </Flex>
          </Flex>

          <Flex fullWidth>
            <Flex
              fullWidth
              flexDirection="column"
              spacing={2}
            >
              <EqualityHighlight
                show={
                  equality.contingency_appraisal
                  || equality.contingency_inspection
                  || equality.contingency_loan
                }
              >
                <SubTitle>Contingencies</SubTitle>
              </EqualityHighlight>
              <Flex
                spacing={2}
                alignItems="center"
              >
                <Contingencies
                  control={control}
                  checkEquality={checkEquality}
                />
              </Flex>
            </Flex>
          </Flex>

          <Flex fullWidth>
            <Flex
              fullWidth
              flexDirection="column"
              spacing={2}
            >
              <SubTitle>Contingency Period</SubTitle>

              <ContingencyDate
                control={control}
                checkEquality={checkEquality}
              />
            </Flex>
          </Flex>

          <Flex fullWidth>
            <Flex
              fullWidth
              flexDirection="column"
              spacing={2}
            >
              <SubTitle>Upload Revised Purchase Agreement</SubTitle>
              <Agreement control={control} />
            </Flex>
          </Flex>

          <Flex fullWidth>
            <Flex
              fullWidth
              flexDirection="column"
              spacing={2}
            >
              <SubTitle>Additional Offer Details</SubTitle>
              <Notes control={control} />
            </Flex>
          </Flex>

        </Flex>

      </Flex>

    </Flex>
  );
};

CounterOfferForm.defaultProps = {
  onSubmit: noOp,
  currentCounter: null,
  offerData: null,
};

const {
  func,
  number,
  shape,
} = PropTypes;

CounterOfferForm.propTypes = {
  propertyId: number.isRequired,
  onSubmit: func,
  currentCounter: number,
  offerData: shape({}),
};

export default CounterOfferForm;
