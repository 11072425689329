import requests from 'api/requests';

const removeFulfillmentTopLevelLineItem = (fulfillmentId) => (productId) => async (dispatch) => {
  try {
    await requests.fulfillments.removeTopLevelLineItem(fulfillmentId)(productId);
  } catch (error) {
    console.error(error);
  }
};

export default removeFulfillmentTopLevelLineItem;
