import React from 'react';
import getInitialsFromString from 'refactored/utils/getInitialsFromString';
import styles from './contactImage.module.css';

const ContactImage = ({ imageUrl, altText, onClick }) => {
    const bgStyle = { backgroundImage: `url(${imageUrl})`, };

    return (
        <div style={bgStyle} className={styles['contact-image']} onClick={onClick}>
            <span className={styles['contact-image-text']}>
                {imageUrl ? null : altText ? getInitialsFromString(altText) : null}
            </span>
        </div>
    );
};

export default ContactImage;
