import requests from 'api/requests';
import { SET_MEETINGS } from 'store/actionTypes/video-chat';

const fetchMeetings = () => async (dispatch) => {
  try {
    const response = await requests.meetings.getMeetings();

    dispatch({
      type: SET_MEETINGS,
      payload: response.data,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export default fetchMeetings;
