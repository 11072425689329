import React from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from 'shared/utility';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Typography from 'ui/Typography';

import LeadTypeSelect from './LeadTypeSelect';
import TempSelect from './TempSelect';
import SearchContactInput from './SearchContactInput';
import NewContactArea from './NewContactArea';
import SellerSection from './SellerSection';
import BuyerSection from './BuyerSection';

const sections = {
  1: <SellerSection />,
  2: <BuyerSection />,
};

const NewLeadForm = (props) => {
  const {
    selectedTypes,
    selectedContact,
  } = props;

  return (
    <form>
      <Flex spacing={4} flexWrap="wrap">
        <Flex
          fullWidth
          justifyContent="space-between"
        >
          <div>
            <Typography variant="title2">
              Type
            </Typography>
            <LeadTypeSelect />
          </div>
          <div>
            <Typography variant="title2">
              Temperature
            </Typography>
            <TempSelect />
          </div>
        </Flex>

        {
          isEmpty(selectedContact) && (
            <div>
              <Typography variant="title2">
                Create a new contact
              </Typography>
              <NewContactArea
                title={null}
              />
            </div>
          )
        }

        <FlexItem fullWidth>
          <Typography variant="title2">
            {
              isEmpty(selectedContact)
                ? 'Or choose from existing contacts'
                : 'Selected contact'
            }
          </Typography>
          <SearchContactInput />
        </FlexItem>

        {
          selectedTypes
            .sort((a, b) => a - b)
            .map((type) => (
              <>
                {sections[type]}
              </>
            ))
        }
      </Flex>
    </form>
  );
};

const {
  number,
  arrayOf,
  string,
  shape,
} = PropTypes;

NewLeadForm.propTypes = {
  selectedContact: shape({
    id: number,
    first_name: string,
    last_name: string,
    email_primary: string,
  }).isRequired,
  selectedTypes: arrayOf(number).isRequired,
};

export default NewLeadForm;
