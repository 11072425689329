import { connect } from 'react-redux';

import { currency } from 'refactored/utils/numbers';

import selectors from 'store/selectors';

import {
  getOverallSummary,
} from 'store/actions/realtorOffers';

import KPIBar from 'ui/KPIBar';

const mapStateToProps = (state) => {
  const summary = selectors.realtorOffers.overallSummary(state);

  return {
    values: {
      need_review: summary?.need_review || 0,
      expiring_today: summary?.expiring_today || 0,
      expiring_this_week: summary?.expiring_this_week || 0,
      potential_commission: currency.full(summary?.potential_commission || 0),
    },
    indicators: [
      {
        key: 'need_review',
        title: 'Need Review',
        iconName: 'users',
        flex: 1.5,
      },
      {
        key: 'expiring_today',
        title: 'Expiring Today',
        iconName: 'investment',
        iconType: 'custom',
        flex: 1.5,
      },
      {
        key: 'expiring_this_week',
        title: 'Expiring This Week',
        iconName: 'briefcase',
        flex: 1.5,
      },
      {
        key: 'potential_commission',
        title: 'Potential Commission',
        iconName: 'repeat',
        flex: 2.5,
      },
    ],
  };
};

const mapDispatchToProps = {
  getData: getOverallSummary,
};

export default connect(mapStateToProps, mapDispatchToProps)(KPIBar);
