import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';

import { useDebounce } from 'use-debounce';

import PropTypes from 'prop-types';

import Grid from 'ui/Grid';
import Button from 'ui/Button';
import SearchInput from 'shared/components/UI/SearchInput';
import MasterSearchDropdown from 'shared/components/UI/MasterSearchDropdown';

import ContactsSearch from 'shared/modules/ContactsSearch';
import PropertiesSearch from 'shared/modules/PropertiesSearch';
import LeadsSearch from 'shared/modules/LeadsSearch';
import ClientsSearch from 'shared/modules/ClientsSearch';
import ProspectsSearch from 'shared/modules/ProspectsSearch';
import TransactionsSearch from 'shared/modules/TransactionsSearch';

import SearchCategoriesSelector from './SearchCategoriesSelector';

import { ScrollContainer } from './styledItems';

const initialFilters = [
  'contacts',
  'properties',
  'leads',
  'clients',
  'prospects',
  'transactions',
];

const RealtorMasterSearch = (props) => {
  const {
    onSelect,
    pathname,
    defaultInputStyle,
    filterCategories,
    onClose,
    isSearchInputVisible,
    focusOnRender,
    withPrependButton,
    onPrependButtonClick,
  } = props;

  const popperEl = useRef(null);
  const inputEl = useRef(null);
  const prependButtonEl = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [activeFilters, setActiveFilters] = useState(filterCategories || initialFilters);
  const [input, setInput] = useState(null);

  const [searchQuery] = useDebounce(input, 1000);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    if (onClose) {
      onClose();
    }
  }, [
    onClose,
  ]);

  useEffect(() => {
    handleClose();
  }, [
    pathname,
  ]);

  const handleClick = useCallback((event) => {
    const isOutside = !popperEl?.current?.popper.contains(event.target)
      && !inputEl?.current?.contains(event.target)
      && !prependButtonEl?.current?.contains(event.target);

    if (isOutside) {
      handleClose();
    }
  }, [
    handleClose,
  ]);

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [
    handleClick,
  ]);

  const onFocus = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const toggleFilter = useCallback((filterKey) => {
    if (activeFilters.includes(filterKey)) {
      setActiveFilters((keys) => keys.filter((key) => key !== filterKey));
    } else {
      setActiveFilters((keys) => [...keys, filterKey]);
    }
  }, [activeFilters]);

  const onInput = useCallback((value) => {
    setInput(value);
  }, []);

  const handleSelect = onSelect
    ? useCallback((type) => (id) => {
      onSelect(type)(id);
      handleClose();
    }, [
      onSelect,
      handleClose,
    ])
    : null;

  const showContacts = useMemo(() => activeFilters.includes('contacts'), [
    activeFilters,
  ]);
  const showLeads = useMemo(() => activeFilters.includes('leads'), [
    activeFilters,
  ]);
  const showProperties = useMemo(() => activeFilters.includes('properties'), [
    activeFilters,
  ]);
  const showClients = useMemo(() => activeFilters.includes('clients'), [
    activeFilters,
  ]);
  const showProspects = useMemo(() => activeFilters.includes('prospects'), [
    activeFilters,
  ]);
  const showTransactions = useMemo(() => activeFilters.includes('transactions'), [
    activeFilters,
  ]);

  return (
    <>
      {
        withPrependButton && (
          <Button
            variant="tertiary"
            icon="search"
            iconColor="black"
            iconSize="l"
            onClick={onPrependButtonClick}
            buttonRef={prependButtonEl}
          />
        )
      }
      {
        isSearchInputVisible && (
          <SearchInput
            onChange={onInput}
            onFocus={onFocus}
            ref={inputEl}
            defaultStyle={defaultInputStyle}
            focusOnRender={focusOnRender}
          />
        )
      }

      <MasterSearchDropdown
        anchorEl={anchorEl}
        ref={popperEl}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid item xs={12}>
            <SearchCategoriesSelector
              categories={filterCategories}
              activeCategories={activeFilters}
              onChange={toggleFilter}
            />
          </Grid>

          <ScrollContainer
            item
            container
            spacing={2}
          >
            {
              showContacts && (
                <Grid item xs={12}>
                  <ContactsSearch
                    search={searchQuery}
                    onSelect={handleSelect}
                  />
                </Grid>
              )
            }

            {
              showProperties && (
                <Grid item xs={12}>
                  <PropertiesSearch search={searchQuery} />
                </Grid>
              )
            }

            {
              showLeads && (
                <Grid item xs={12}>
                  <LeadsSearch
                    search={searchQuery}
                    onSelect={handleSelect}
                  />
                </Grid>
              )
            }

            {
              showClients && (
                <Grid item xs={12}>
                  <ClientsSearch
                    search={searchQuery}
                    onSelect={handleSelect}
                  />
                </Grid>
              )
            }

            {
              showProspects && (
                <Grid item xs={12}>
                  <ProspectsSearch
                    search={searchQuery}
                    onSelect={handleSelect}
                  />
                </Grid>
              )
            }

            {
              showTransactions && (
                <Grid item xs={12}>
                  <TransactionsSearch search={searchQuery} />
                </Grid>
              )
            }
          </ScrollContainer>
        </Grid>
      </MasterSearchDropdown>
    </>
  );
};

RealtorMasterSearch.defaultProps = {
  defaultInputStyle: false,
  filterCategories: null,
  onSelect: null,
  onClose: null,
  isSearchInputVisible: true,
  focusOnRender: false,
  withPrependButton: false,
  onPrependButtonClick: null,
};

const {
  func,
  arrayOf,
  string,
  bool,
} = PropTypes;

RealtorMasterSearch.propTypes = {
  pathname: string.isRequired,
  // onChange: func.isRequired,
  defaultInputStyle: bool,
  filterCategories: arrayOf(string),
  onSelect: func,
  onClose: func,
  isSearchInputVisible: bool,
  focusOnRender: bool,
  withPrependButton: bool,
  onPrependButtonClick: func,
};

export default RealtorMasterSearch;
