exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".cardsList_cards-list__bD-HU {\n  display: flex;\n  flex-direction: column;\n  grid-gap: 0px;\n  gap: 0px;\n  justify-content: flex-start;\n}", ""]);

// exports
exports.locals = {
	"cards-list": "cardsList_cards-list__bD-HU"
};