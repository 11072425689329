import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import withFieldDecorator from 'shared/hocs/withFieldDecorator';

import TextField from '@material-ui/core/TextField';

const FormTextfield = (props) => {
  const {
    defaultValue,
    helperText,
    label,
    onChange,
    onFocus,
    onBlur,
    type,
    inputProps,
    value,
    multiline,
    disabled,
    className,
    inputRef,
    error,
  } = props;

  return (
    <TextField
      defaultValue={defaultValue}
      className={classnames({
        [className]: className,
      })}
      inputRef={inputRef}
      error={error}
      inputProps={inputProps}
      fullWidth
      disabled={disabled}
      helperText={helperText}
      label={label}
      margin="dense"
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      type={type}
      value={value}
      variant="outlined"
      multiline={multiline}
    />
  );
};

FormTextfield.defaultProps = {
  defaultValue: null,
  helperText: null,
  label: null,
  type: 'text',
  value: null,
  multiline: false,
  disabled: false,
  error: false,
  className: null,
  inputRef: null,
  inputProps: {},
  onFocus: () => {},
  onBlur: () => {},
};

const {
  bool,
  func,
  string,
  oneOfType,
  shape,
  instanceOf,
  object,
} = PropTypes;

FormTextfield.propTypes = {
  defaultValue: string,
  disabled: bool,
  error: bool,
  className: string,
  onFocus: func,
  onBlur: func,
  inputRef: oneOfType([
    func,
    shape({ current: instanceOf(Element) }),
  ]),
  helperText: string,
  inputProps: object, // eslint-disable-line react/forbid-prop-types
  label: string,
  onChange: func.isRequired,
  type: string,
  value: string,
  multiline: bool,
};

const decoratorProps = {
  iconName: 'edit',
};

export default withFieldDecorator(decoratorProps)(FormTextfield);
