import { SET_ACTIVE_CHAT_ID } from 'store/actionTypes/chat';

const setActiveChatId = (id) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_CHAT_ID,
    payload: id,
  });
};

export default setActiveChatId;
