import {
  RESET_REALTOR_MATCHING_PROPERTIES_BY_FILTER,
  RESET_REALTOR_MATCHING_PROPERTIES_COUNT_BY_FILTER,
} from 'store/actionTypes/realtorMatchingProperties';

const resetFilterBytId = (filterId) => (dispatch) => {
  dispatch({
    type: RESET_REALTOR_MATCHING_PROPERTIES_BY_FILTER,
    payload: {
      filterId,
    },
  });

  dispatch({
    type: RESET_REALTOR_MATCHING_PROPERTIES_COUNT_BY_FILTER,
    payload: {
      filterId,
    },
  });
};

export default resetFilterBytId;
