import React from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';

// Define el componente StyledAntdModal con styled-components
export const StyledAntdModal = styled(({ width, ...props }) => <Modal footer={null} width={width || "max-content"} {...props} />)`
  .ant-modal-content {
    border-radius: 20px;
    box-shadow: none;
    border: 4px solid transparent;
  }

  .ant-modal-body {
    padding: 30px;
  }

  .ant-modal-close {
    top: 0px;
    right: -32px;
    background: white;
    border-radius: 100%;
    height: 24px;
    width: 24px;
    .ant-modal-close-x {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px;
      height: auto;
      width: auto;

      &:hover {
        opacity: 0.7;
      }
    }

    .anticon {
      svg {
        color: #a1bdcb;
      }
    }
  }

  .ant-modal-header {
    border-bottom: none;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding-right: 70px; 
  }

  .ant-modal-footer {
    border-top: none; 
  } 
  &.ant-modal {
    pointer-events: all;   
    min-width: ${({ width }) => width || '90vw'};  
    max-width: ${({ width }) => width || '90vw'};  
  } 
`;

export default StyledAntdModal;
