// TODO Rewrite as a functional component

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Textfield from 'ui/Textfield';
import InlineInput from 'ui/InlineInput';

import { debounce } from 'shared/utility';

import { getPlacePredictions } from 'shared/utils';

import Option from './Option';

import './PlacesAutocomplete.scss';

const rootClass = 'places-autocomplete';

/* eslint-disable react/prop-types */
const InlineInputComponent = React.forwardRef((props, ref) => {
  const {
    defaultValue,
    onChange,
    placeholder,
    inputProps,
  } = props;

  const [value, setValue] = React.useState(null);

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = React.useCallback((event) => {
    onChange(event.target.value);
    setValue(event.target.value);
  }, [onChange]);

  return (
    <div ref={ref}>
      <InlineInput
        {...inputProps} // eslint-disable-line react/jsx-props-no-spreading
        onChange={handleChange}
        value={value}
        placeholder={placeholder}
        style={{ width: '100%' }}
      />
    </div>
  );
});
/* eslint-enable react/prop-types */

class PlacesAutocomplete extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      selectedValue: props.selectedValue,
      search: props.selectedValue,
    };

    this.placesService = null;
    this.search = debounce(this.searchPlaces, 300);
  }

  componentDidMount() {
    // eslint-disable-next-line no-undef
    this.placesService = new google.maps.places.AutocompleteService(document.createElement('div'));
  }

  componentDidUpdate(prevProps) {
    const { selectedValue } = this.props;
    if (selectedValue && (selectedValue !== prevProps.selectedValue)) {
      this.setState({
        dataSource: [],
        selectedValue,
        search: selectedValue,
      });
    }
  }

  onSearch = (value) => {
    // this serves to enable adding arbitrary addresses alongside google search results
    const dataSource = [...this.state.dataSource];

    dataSource[0] = {
      formatted_address: value,
      newItem: true,
    };

    this.setState({
      search: value,
      dataSource,
    });
    //

    this.search(value);

    const { onSearch } = this.props;

    if (onSearch) {
      onSearch(value);
    }
  };

  onSelect = (event, newLocation) => {
    this.setState({
      search: newLocation?.formatted_address,

      selectedValue: newLocation || {
        id: '',
        formatted_address: '',
        lat: '',
        lng: '',
      },
    });

    const { onSelect } = this.props;

    if (onSelect) {
      onSelect(newLocation);
    }
  };

  onChange = (value) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  };

  onBlur = () => {
    const {
      onBlur,
      reset,
    } = this.props;

    const {
      selectedValue,
    } = this.state;

    if (onBlur) {
      onBlur(selectedValue);
    }

    if (reset) {
      this.setState({
        selectedValue: {
          id: '',
          formatted_address: '',
          lat: '',
          lng: '',
        },
        dataSource: [],
      });
    }

    this.setState({
      // search: this.props.selectedValue,
      search: this.props.selectedValue,
    });
  };

  searchPlaces = (value) => {
    const request = {
      input: value,
    };

    getPlacePredictions(
      this.placesService,
      request,
      (results) => {
        // eslint-disable-next-line camelcase
        const dataSource = [{
          formatted_address: this.state.search,
          newItem: true,
        }];

        results.forEach((result, index) => {
          const { description, place_id, structured_formatting } = result;

          dataSource.push({
            formatted_address: description,
            id: place_id,
            main_text: structured_formatting.main_text,
            secondary_text: structured_formatting.secondary_text,
          });
        });

        dataSource.push({ lastRow: true, formatted_address: 'powered by Google' });

        this.setState({
          dataSource,
        });
      },
    );
  };

  handleInput = (value) => {
    const { onInput } = this.props;

    this.onSearch(value);
    // this.handleSearchDebounce(value);

    if (onInput) {
      onInput(value);

      this.setState({
        selectedValue: {
          id: '',
          formatted_address: value,
          lat: '',
          lng: '',
        },
      });
    }
  }

  renderInput = (params) => {
    const {
      label,
      placeholder,
      focusOnRender,
      inline,
      defaultValue,
    } = this.props;

    const {
      search,
    } = this.state;

    if (inline) {
      return (
        <InlineInputComponent
          {...params} // eslint-disable-line react/jsx-props-no-spreading
          ref={params.InputProps.ref}
          placeholder={placeholder}
          type="text"
          onChange={this.handleInput}
          defaultValue={defaultValue}
        />
      );
    }

    return (
      <Textfield
        {...params} // eslint-disable-line react/jsx-props-no-spreading
        ref={params.InputProps.ref}
        label={(search && search?.length > 0) ? label : placeholder}
        onChange={this.handleInput}
        icon="map-pin"
        focusOnRender={focusOnRender}
      />
    );
  };

  getOptionLabel = (option) => option.formatted_address || '';

  getOptionDisabled = (option) => option.lastRow;

  render() {
    const {
      className,
      size,
    } = this.props;

    const {
      search,
    } = this.state;

    const {
      dataSource,
    } = this.state;

    return (
      <div className={classnames(
        rootClass,
        {
          [className]: className,
        },
      )}
      >
        <Autocomplete
          freeSolo
          autoComplete
          filterSelectedOptions
          onChange={this.onSelect}
          onBlur={this.onBlur}
          options={dataSource}
          getOptionLabel={this.getOptionLabel}
          renderInput={this.renderInput}
          value={{ formatted_address: search || null }}
          defaultValue={{ formatted_address: search || null }}
          filterOptions={(nonFiltered, params) => nonFiltered} // eslint-disable-line react/jsx-no-bind
          renderOption={Option}
          getOptionDisabled={this.getOptionDisabled}
          size={size}
        />
      </div>
    );
  }
}

PlacesAutocomplete.defaultProps = {
  className: '',
  inputClassName: '',
  placeholder: '',
  value: '',
  selectedValue: {
    id: '',
    formatted_address: '',
    lat: '',
    lng: '',
  },
  autoFocus: false,
  label: '',
  variant: 'outlined',
  onInput: undefined,
  size: 'medium',
  defaultValue: null,
  inline: false,
  createOnBlur: false,
};

const {
  bool,
  func,
  shape,
  string,
} = PropTypes;

PlacesAutocomplete.propTypes = {
  autoFocus: bool,
  className: string,
  inputClassName: string,
  onBlur: func.isRequired,
  onChange: func.isRequired,
  onSearch: func.isRequired,
  onSelect: func.isRequired,
  placeholder: string,
  reset: func.isRequired,
  value: string,
  selectedValue: shape({
    id: string,
    formatted_address: string,
    lat: string,
    lng: string,
  }),
  label: string,
  variant: string,
  onInput: func,
  size: string,
  defaultValue: string,
  inline: bool,
};

export default PlacesAutocomplete;
