import {
  SET_REALTOR_CLIENT_SELECTED_VIEW,
} from 'store/actionTypes/realtorClients';

const setSelectedView = (view) => ({
  type: SET_REALTOR_CLIENT_SELECTED_VIEW,
  payload: view,
});

export default setSelectedView;
