export const FULFILLMENT_TYPES = {
  1: 'tangible goods',
  2: 'digital',
  3: 'service',
};

export const STATUSES_INFO = {
  0: {
    title: 'new',
    progress: '0%',
  },
  1: {
    title: 'agreement',
    progress: '15%',
  },
  2: {
    title: 'invoice',
    progress: '30%',
  },
  4: {
    title: 'payment received',
    progress: '45%',
  },
  8: {
    title: 'order',
    progress: '60%',
  },
  16: {
    title: 'shipped',
    progress: '75%',
  },
  32: {
    title: 'delivered',
    progress: '90%',
  },
  64: {
    title: 'feedback received',
    progress: '90%',
  },
  128: {
    title: 'completed',
    progress: '100%',
  },
};

export const LINE_ITEMS_STATUSES_INFO = {
  0: {
    title: 'New',
    weight: 0,
    stepName: 'new',
    iconName: null,
    tooltip: 'New',
  },
  1: {
    title: 'Payment Received',
    weight: 1,
    stepName: 'payment',
    iconName: 'dollar-sign',
    tooltip: 'Payment Received',
  },
  2: {
    title: 'Boxed',
    weight: 2,
    stepName: 'packaged',
    iconName: 'box',
    tooltip: 'Boxed',
  },
  4: {
    title: 'Shipped',
    weight: 4,
    stepName: 'shipping',
    iconName: 'shipping-fast',
    tooltip: 'Item Shipped',
  },
  8: {
    title: 'Delivery Confirmed',
    weight: 8,
    stepName: 'delivery',
    iconName: 'dolly',
    tooltip: 'Delivery Confirmed',
  },
  16: {
    title: 'Feedback',
    weight: 16,
    stepName: 'feedback',
    iconName: 'star',
    tooltip: 'Feedback',
  },
};

export default {
  FULFILLMENT_TYPES,
  STATUSES_INFO,
  LINE_ITEMS_STATUSES_INFO,
};
