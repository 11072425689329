exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".emailCardHeader_email-card-header__1YGXQ {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  overflow: hidden;\n}\n\n.emailCardHeader_email-circle-profile-picture__1hEaO {\n  min-width: 54px;\n  min-height: 54px;\n  border-radius: 50%;\n  object-fit: cover;\n  border: 2px solid #ffffff;\n  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);\n  margin-bottom: 10px;\n  color: #ffffff;\n  background-color: #1e9896;\n  position: relative;\n}\n\n.emailCardHeader_email-card-profile-area__2hiKd {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  overflow: hidden;\n}\n\n.emailCardHeader_email-card-profile-names__kcR3n {\n  display: inline-block;\n  padding-right: 6px;\n  padding-left: 4px;\n}\n\n.emailCardHeader_email-card-sender__sO5sG {\n  font-weight: 500;\n  font-size: 16px;\n  color: #141726;\n  overflow: ellipsis;\n}\n\n.emailCardHeader_email-card-time-received__1NBqD {\n  font-weight: 400;\n  font-size: 13px;\n  color: #81828c;\n}\n\n.emailCardHeader_email-circle-profile-picture__1hEaO > :first-child {\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  -webkit-transform: translate(-50%, -50%);\n          transform: translate(-50%, -50%);\n}\n\n.emailCardHeader_email-circle-profile-picture__1hEaO > :last-child {\n  position: absolute;\n  right: 0px;\n  bottom: 0px;\n}", ""]);

// exports
exports.locals = {
	"email-card-header": "emailCardHeader_email-card-header__1YGXQ",
	"email-circle-profile-picture": "emailCardHeader_email-circle-profile-picture__1hEaO",
	"email-card-profile-area": "emailCardHeader_email-card-profile-area__2hiKd",
	"email-card-profile-names": "emailCardHeader_email-card-profile-names__kcR3n",
	"email-card-sender": "emailCardHeader_email-card-sender__sO5sG",
	"email-card-time-received": "emailCardHeader_email-card-time-received__1NBqD"
};