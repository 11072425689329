import React, {
  useCallback,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import getFeatureVersion from 'shared/utils/getFeatureVersion';

import Button from 'ui/Button';
import Modal from 'ui/Modal';

import NewPropertyForm from 'sections/MLS/forms/NewPropertyForm';
import NewPropertyFormV2 from 'sections/Properties/forms/NewPropertyFormV2';

const NewPropertyModal = (props) => {
  const {
    theme,
    isVisible,
    closeModal,
    saveProperty,
    onlySave,
    getCachedData,
  } = props;

  const history = useHistory();

  useEffect(() => {
    if (isVisible) {
      getCachedData();
    }
  }, [
    isVisible,
  ]);

  const saveAndOpenProperty = useCallback(async () => {
    const propertyId = await saveProperty();

    history.push(`/mls/${propertyId}`);
  }, [
    saveProperty,
  ]);

  const version = getFeatureVersion('new property form');

  return (
    <Modal
      theme={theme}
      width={720}
      visible={isVisible}
      okText="Save"
      onCancel={closeModal}
      destroyOnClose
      title="New Property"
      footer={[
        <Button
          onClick={closeModal}
          variant="secondary"
        >
          Cancel
        </Button>,
        <Button
          variant="primary"
          onClick={saveProperty}
        >
          Save
        </Button>,
        onlySave
          ? null
          : (
            <Button
              variant="primary"
              onClick={saveAndOpenProperty}
            >
              Save & Open Property
            </Button>
            ),
      ]}
    >
      {
        version === 1
        ? (
          <NewPropertyForm />
          )
        : (
          <NewPropertyFormV2 />
          )
      }
    </Modal>
  );
};

NewPropertyModal.defaultProps = {
  isVisible: false,
  theme: null,
  saveProperty: () => {},
  onlySave: false,
};

const {
  bool,
  func,
  shape,
} = PropTypes;

NewPropertyModal.propTypes = {
  isVisible: bool,
  theme: shape(),
  closeModal: func.isRequired,
  saveProperty: func,
  onlySave: bool,
  getCachedData: func.isRequired,
};

export default NewPropertyModal;
