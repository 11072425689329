import updateFulfillmentLineItems from './updateFulfillmentLineItems';

const updateSelectedFulfillmentLineItems = (productId) => (updateData) => async (dispatch, getState) => {
  const {
    fulfillments: {
      selectedFulfillment,
    },
  } = getState();

  await dispatch(updateFulfillmentLineItems(selectedFulfillment)(productId)(updateData));
};

export default updateSelectedFulfillmentLineItems;
