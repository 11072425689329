import uploadAgreementFiles from './uploadAgreementFiles';
import uploadAgreementUrl from './uploadAgreementUrl';
import getClientId from './getClientId';
import getProspectId from './getProspectId';

export {
  uploadAgreementFiles,
  uploadAgreementUrl,
  getClientId,
  getProspectId,
};

export default uploadAgreementFiles;
