import React from 'react';

import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';
import CheckboxLabelled from 'ui/CheckboxLabelled';
import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';

import FormTextfield from 'shared/inputs/FormTextfield';
import EmailInput from 'shared/inputs/EmailInput';
import PhoneInput from 'shared/inputs/PhoneInput';
import PersonTitleSelect from 'shared/inputs/PersonTitleSelect';
import DateTimeInput from 'shared/inputs/DateTimeInput';

import SourceSelect from 'sections/Leads/forms/NewLeadForm/SourceSelect';

const NewContactArea = (props) => {
  const {
    handleChange,
    onInvalidField,
    title,
    values,
    emailError,
    phoneError,
    firstNameError,
    withSalutations,
  } = props;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <DecoratedFieldWrapper
          // iconName="notNull"
          noMargin
        >
          <Grid container spacing={2}>
            {
              title && (
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    tag="p"
                  >
                    {title}
                  </Typography>
                </Grid>
              )
            }
            {
              withSalutations && (
                <Grid item xs={2}>
                  <PersonTitleSelect
                    value={`${values?.personTitle || values?.person_title || 1}`}
                    handleChange={handleChange('person_title')}
                  />
                </Grid>
              )
            }
            <Grid item xs={4}>
              <FormTextfield
                label="First Name"
                onChange={handleChange('first_name')}
                error={firstNameError}
                value={values?.first_name || null}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextfield
                label="Last Name"
                onChange={handleChange('last_name')}
                value={values?.last_name || null}
              />
            </Grid>
          </Grid>
        </DecoratedFieldWrapper>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <EmailInput
              // decorated
              decoratorProps={{ noMargin: true }}
              handleChange={handleChange('email_primary')}
              onInvalidEmail={onInvalidField('email_primary')}
              error={emailError}
              value={values?.email_primary || null}
            />
          </Grid>

          <Grid item xs={6}>
            <PhoneInput
              // decorated
              decoratorProps={{ noMargin: true }}
              handleChange={handleChange('phone_primary')}
              onInvalidPhone={onInvalidField('phone_primary')}
              error={phoneError}
              value={values?.phone_primary || null}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <DecoratedFieldWrapper
          // iconName="search"
          variant="highlight"
          noMargin
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SourceSelect />
            </Grid>
            <Grid item xs={6}>
              <DateTimeInput
                handleChange={handleChange('first_contact_date')}
                value={values?.first_contact_date || null}
              />
            </Grid>
            <Grid item xs={6}>
              <CheckboxLabelled
                onChange={handleChange('is_past_client')}
                labelText="Past Client"
                value={values?.is_past_client || null}
              />
            </Grid>
          </Grid>
        </DecoratedFieldWrapper>
      </Grid>

    </Grid>
  );
};

NewContactArea.defaultProps = {
  handleChange: (fieldName) => () => {},
  onInvalidField: (fieldName) => () => {},
  title: 'Or create a new contact',
  emailError: null,
  phoneError: null,
  firstNameError: null,
};

const {
  func,
  string,
  bool,
} = PropTypes;

NewContactArea.propTypes = {
  handleChange: func,
  onInvalidField: func,
  title: string,
  emailError: string,
  phoneError: string,
  firstNameError: string,
  withSalutations: bool.isRequired,
};

export default NewContactArea;
