/* eslint-disable */
// Exposit
// WHOLE FILE IGNORED, TOO MANY PRE-EXISTING ERRORS
// TODO - fix lint errors

import React, { Component } from 'react';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
} from 'antd';
import urlParser from 'url-parse';
import { isMobile } from 'react-device-detect';
import Typography from 'ui/Typography';

import VersionNumber from 'shared/modules/VersionNumber';

import RecoverPassword from './RecoverPassword';
import ChangePassword from './ChangePassword';
import CaptchaModal from './CaptchaModal';
import Logo from './Logo';
import LoginWorkflow from './LoginWorkflow';
import backgroundImage from 'assets/images/login/login-image.png';

import './Login.scss';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMobileWarning: isMobile,
    };
  }

  componentWillMount() {
    this.handleURLData();
  }

  handleURLData = () => {
    const {
      setUserReset,
    } = this.props;

    const parsedUrl = urlParser(window.location.href, true);

    const {
      action,
      resetKey,
      user,
      tempPass,
    } = parsedUrl.query;

    if (action === 'restore') {
      setUserReset({
        email: user,
        resetKey,
      });
    }
  }

  onAfterCaptchaLogin = () => {
    const {
      form,
      loginRequest,
    } = this.props;

    form.validateFields((err, values) => {
      if (!err) {
        loginRequest({ ...values, shouldUnfreezeIP: true });
      }
    });
  };

  toggleMobileWarning = (visible) => {
    let showModal = visible;

    if (typeof showModal === 'undefined') {
      const { showMobileWarning } = this.state;
      showModal = !showMobileWarning;
    }

    this.setState({ showMobileWarning: showModal });
  };

  handleWarningButtonClick = () => {
    this.toggleMobileWarning(false);
  };

  renderMobileWarningFooter = () => (
    <>
      <Button
        type="primary"
        onClick={this.handleWarningButtonClick}
      >
        OK
      </Button>
    </>
  );

  render() {
    const { showMobileWarning } = this.state;

    const footer = this.renderMobileWarningFooter();

    return (
      <div className="app-wrapper">
        <Row type="flex" className="login">
          <Col
            span={12}
            className="login__screen login__screen--bg login__screen--left"
            style={{
              backgroundImage: `url('${backgroundImage}')`,
              backgroundPosition: 'right center',
              backgroundRepeat: 'no-repeat',
            }}
          />
          <Col span={12} className="login__screen login__screen--right">
            <LoginWorkflow />
            <div style={{ position: 'absolute', bottom: 10, right: 20 }}>
              <VersionNumber color="#d7d7d7" />
            </div>
          </Col>
        </Row>

        <RecoverPassword />

        <ChangePassword internal={false} />

        <CaptchaModal loginOnSuccess={this.onAfterCaptchaLogin} />

        <Modal
          visible={showMobileWarning}
          centered
          title={null}
          footer={footer}
        >
          <Typography color="additional">
            We’re working on a great mobile experience.
            For now, Symplete is best used on desktop.
          </Typography>
        </Modal>
      </div>
    );
  }
}

export default Form.create()(Login);
