// TOD0 - refactor
// TODO - fix dependency cycles
// eslint-disable-next-line import/no-cycle
import {
  apiConnector,
  getConnector,
  tokenFetch as fetch,
} from 'shared/API';

import {
  GET_TEAM_SETUP_LIST,
  GET_TEAM_SETUP_LIST_SUCCESS,
  GET_TEAM_SETUP_LIST_ERROR,
  GET_TEAM_CATEGORIES,
  GET_TEAM_CATEGORIES_SUCCESS,
  GET_TEAM_CATEGORIES_ERROR,
  GET_TEAM_SUBCATEGORIES,
  GET_TEAM_SUBCATEGORIES_SUCCESS,
  GET_TEAM_SUBCATEGORIES_ERROR,
  ADD_TEAM,
  ADD_TEAM_SUCCESS,
  ADD_TEAM_ERROR,
  DELETE_TEAM,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_ERROR,
  ADD_CATEGORY,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_ERROR,
  EIDT_CATEGORY,
  EDIT_CATEGORY_SUCCESS,
  EIDT_CATEGORY_ERROR,
  ADD_SUBCATEGORY,
  ADD_SUBCATEGORY_SUCCESS,
  ADD_SUBCATEGORY_ERROR,
  EDIT_SUBCATEGORY,
  EDIT_SUBCATEGORY_SUCCESS,
  EDIT_SUBCATEGORY_ERROR,
  DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_ERROR,
  DELETE_SUBCATEGORY,
  DELETE_SUBCATEGORY_SUCCESS,
  DELETE_SUBCATEGORY_ERROR,
  OPEN_MODAL,
  HIDE_MODAL,
  SET_ACTIVE_TEAM,
  GET_ACTIVE_TEAM,
  GET_TEAM_DATA,
  GET_TEAM_DATA_SUCCESS,
  GET_TEAM_DATA_ERROR,
  SET_TEAM_MEMBERS,
  SET_TEAM_MEMBERS_SUCCESS,
  SET_TEAM_MEMBERS_ERROR,
  GET_TEAM_PERMISSIONS,
  GET_TEAM_PERMISSIONS_SUCCESS,
  GET_TEAM_PERMISSIONS_ERROR,
  UPDATE_TASK_TEAM,
} from 'store/actionTypes/team-setup';

import {
  getTeamById,
} from 'store/actions/teamsV2';

// eslint-disable-next-line import/no-cycle
import checkLoggedEmployeePermissions from 'shared/utils/checkLoggedEmployeePermissions';

import { url } from 'paths';

import { callAlert } from 'ui/Alert';

export function getTeamSetupList() {
  return {
    type: GET_TEAM_SETUP_LIST,
  };
}

export function getTeamSetupListSuccess(list) {
  return {
    type: GET_TEAM_SETUP_LIST_SUCCESS,
    teamSetupList: list,
  };
}

export function getTeamSetupListError() {
  return {
    type: GET_TEAM_SETUP_LIST_ERROR,
  };
}

export function getTeamCategories() {
  return {
    type: GET_TEAM_CATEGORIES,
  };
}

export function getTeamCategoriesSuccess(id, categories) {
  return {
    type: GET_TEAM_CATEGORIES_SUCCESS,
    teamId: id,
    teamCategories: categories,
  };
}

export function getTeamCategoriesError() {
  return {
    type: GET_TEAM_CATEGORIES_ERROR,
  };
}

export function getTeamSubCategories() {
  return {
    type: GET_TEAM_SUBCATEGORIES,
  };
}

export function getTeamSubCategoriesSuccess(id, categoryId, subCategories) {
  return {
    type: GET_TEAM_SUBCATEGORIES_SUCCESS,
    teamId: id,
    categoryId,
    teamSubCategories: subCategories,
  };
}

export function getTeamSubCategoriesError() {
  return {
    type: GET_TEAM_SUBCATEGORIES_ERROR,
  };
}

export function addTeam(team) {
  return {
    type: ADD_TEAM,
    team,
  };
}

export function addTeamSuccess(team) {
  return {
    type: ADD_TEAM_SUCCESS,
    newTeam: team,
  };
}

export function addTeamError() {
  return {
    type: ADD_TEAM_ERROR,
  };
}

export function deleteTeam() {
  return {
    type: DELETE_TEAM,
  };
}

export function deleteTeamSuccess(teamId) {
  return {
    type: DELETE_TEAM_SUCCESS,
    teamId,
  };
}

export function deleteTeamError() {
  return {
    type: DELETE_TEAM_ERROR,
  };
}

export function addCategory(category) {
  return {
    type: ADD_CATEGORY,
    category,
  };
}

export function addCategorySuccess(teamId, category) {
  return {
    type: ADD_CATEGORY_SUCCESS,
    teamId,
    newCategory: category,
  };
}

export function addCategoryError() {
  return {
    type: ADD_CATEGORY_ERROR,
  };
}

export function editCategory() {
  return {
    type: EIDT_CATEGORY,
  };
}

export function editCategorySuccess(teamId, categoryId, category) {
  return {
    type: EDIT_CATEGORY_SUCCESS,
    teamId,
    categoryId,
    editCategory: category,
  };
}

export function editCategoryError() {
  return {
    type: EIDT_CATEGORY_ERROR,
  };
}

export function addSubCategory(subcategory) {
  return {
    type: ADD_SUBCATEGORY,
    subcategory,
  };
}

export function addSubCategorySuccess(teamId, categoryId, subCategory) {
  return {
    type: ADD_SUBCATEGORY_SUCCESS,
    teamId,
    categoryId,
    newSubCategory: subCategory,
  };
}

export function addSubCategoryError() {
  return {
    type: ADD_SUBCATEGORY_ERROR,
  };
}

export function editSubCategory() {
  return {
    type: EDIT_SUBCATEGORY,
  };
}

// TODO - refactor
// eslint-disable-next-line max-params
export function editSubCategorySuccess(
  teamId,
  categoryId,
  subCategoryId,
  subCategory,
) {
  return {
    type: EDIT_SUBCATEGORY_SUCCESS,
    teamId,
    categoryId,
    subCategoryId,
    editSubCategory: subCategory,
  };
}

export function editSubCategoryError() {
  return {
    type: EDIT_SUBCATEGORY_ERROR,
  };
}

export function deleteCategory() {
  return {
    type: DELETE_CATEGORY,
  };
}

export function deleteCategorySuccess(teamId, category) {
  return {
    type: DELETE_CATEGORY_SUCCESS,
    teamId,
    categoryId: category,
  };
}

export function deleteCategoryError() {
  return {
    type: DELETE_CATEGORY_ERROR,
  };
}

export function deleteSubCategory() {
  return {
    type: DELETE_SUBCATEGORY,
  };
}

export function deleteSubCategorySuccess(teamId, categoryId, subCategory) {
  return {
    type: DELETE_SUBCATEGORY_SUCCESS,
    teamId,
    categoryId,
    subCategoryId: subCategory,
  };
}

export function deleteSubCategoryError() {
  return {
    type: DELETE_SUBCATEGORY_ERROR,
  };
}

export function openModal() {
  return {
    type: OPEN_MODAL,
  };
}

export function hideModal() {
  return {
    type: HIDE_MODAL,
  };
}

export function setActiveTeam(activeTeam) {
  return {
    type: SET_ACTIVE_TEAM,
    activeTeam,
  };
}

export function getActiveTeam(activeTeam) {
  return {
    type: GET_ACTIVE_TEAM,
    activeTeam,
  };
}

export function getTeamData() {
  return { type: GET_TEAM_DATA };
}

export function getTeamDataSuccess(teamId, members, tasks) {
  return {
    type: GET_TEAM_DATA_SUCCESS,
    teamId,
    members,
    tasks,
  };
}
export function getTeamDataError() {
  return { type: GET_TEAM_DATA_ERROR };
}

export const setTeamManagersApi = (teamId, managers = []) => async (dispatch) => {
  const endpointUrl = `/api/teams/updateTeamManagers/${teamId}`;
  try {
    await apiConnector(endpointUrl, 'PUT', { userList: managers });
    dispatch(getTeamById(teamId));
    dispatch(setTeamMembersSuccess());
  } catch (errors) {
    dispatch(setTeamMembersError());
  }
};

export const setTeamMembersApi = (teamId, members = []) => async (dispatch) => {
  const endpointUrl = `/api/teams/updateTeamMembers/${teamId}`;

  try {
    await apiConnector(endpointUrl, 'PUT', { userList: members });
    dispatch(getTeamById(teamId));
    dispatch(setTeamMembersSuccess());
  } catch (errors) {
    dispatch(setTeamMembersError());
  }
};

export const setTeamMembers = () => ({ type: SET_TEAM_MEMBERS });

export const setTeamMembersSuccess = (teamId, members) => (dispatch) => {
  dispatch({
    type: SET_TEAM_MEMBERS_SUCCESS,
    teamId,
    members,
  });
  dispatch(getTeamPermissions());
};

export const setTeamMembersError = () => ({ type: SET_TEAM_MEMBERS_ERROR });

export const getTeamPermissions = () => (dispatch) => {
  dispatch({ type: GET_TEAM_PERMISSIONS });
  getConnector('/api/tasks/getEmployeeTeam')
    .then((permissions) => dispatch(getTeamPermissionsSuccess(permissions)))
    .catch((err) => dispatch(getTeamPermissionsError()));
};

export const getTeamPermissionsSuccess = (permissions) => ({
  type: GET_TEAM_PERMISSIONS_SUCCESS,
  permissions,
});

export const getTeamPermissionsError = () => ({
  type: GET_TEAM_PERMISSIONS_ERROR,
});

export const updateTaskTeam = (payload) => (dispatch, getState) => ({
  type: UPDATE_TASK_TEAM,
  payload,
});

export const getTeams = () => async (dispatch, isStandard) => {
  try {
    dispatch(getTeamSetupList());

    const response = await apiConnector('/Tasks/ReturnAllTeams', 'GET');
    const {
      teams,
    } = response;

    dispatch(
      getTeamSetupListSuccess(
        teams.map((team) => ({
          ...team,
          categories: [],
          members: [],
          tasks: [],
        })),
      ),
    );

    dispatch(updateTeamsData(teams));
  } catch (error) {
    dispatch(getTeamSetupListError());
  }
};

const updateTeamsData = (teams) => (dispatch, getState) => {
  const {
    auth: {
      loggedUser: {
        EmployeeID,
      },
    },
  } = getState();
  const loggedUserId = EmployeeID;
  const hasAccessToOverview = checkLoggedEmployeePermissions.isManager('Tasks');

  if (hasAccessToOverview) {
    const teamsId = [];
    const teamDataTab = [];

    teams.forEach((team) => {
      const { TeamID } = team;
      teamsId.push(TeamID);

      const teamData = fetch(
        `${url}/api/teams/${TeamID}`,
        {},
        true,
      ).then((res) => res.json());

      teamDataTab.push(teamData);
      dispatch(getTeamData());
    });

    Promise.all(teamDataTab)
      .then((teamData) => teamData.forEach((team) => {
        const { TeamID, employees, tasks } = team;
        dispatch(getTeamDataSuccess(TeamID, employees, tasks));
      }))
      .catch((err) => {
        dispatch(getTeamDataError());
      });
  } else {
    const teamsId = [];
    const teamDataTab = [];

    teams.forEach((team) => {
      const { TeamID } = team;
      teamsId.push(TeamID);

      const teamData = fetch(
        `${url}/api/teams/${TeamID}`,
        {},
        true,
      ).then((res) => res.json());

      teamDataTab.push(teamData);
      dispatch(getTeamData());
    });

    Promise.all(teamDataTab)
      .then((teamData) => teamData.forEach((team) => {
        const { TeamID, employees, tasks } = team;
        let isTeamManager = false;
        employees.forEach((employee) => {
          const isLoggedUserTeamManager = employee.EmployeeID === loggedUserId
            && !!employee.team2employee.TeamManager;
          if (isLoggedUserTeamManager) {
            isTeamManager = true;
          }
        });
        if (isTeamManager) {
          dispatch(getTeamDataSuccess(TeamID, employees, tasks));
        }
      }))
      .catch((err) => {
        dispatch(getTeamDataError());
      });
  }
};

export const getDeleteTeamFromApi = (id) => async (dispatch) => {
  const deleteURL = '/Tasks/DeleteTeam';
  dispatch(deleteTeam());

  try {
    await apiConnector(deleteURL, 'POST', { team: { TeamID: id } });
    dispatch(deleteTeamSuccess(id));
  } catch (error) {
    console.error(error);
    dispatch(deleteTeamError());
  }
};

export const getTeamsFromApi = (isStandard = true) => (dispatch) => getTeams(
  dispatch,
  isStandard,
);

export const addTeamApi = (team) => (dispatch) => {
  dispatch(addTeam());
  fetch(`${url}/Tasks/AddTeam`, {
    method: 'POST',
    body: JSON.stringify({ team }),
  })
    .then((res) => res.json())
    .then((json) => {
      if (json.err && json.err.code) {
        callAlert.error('This name is already in use');
      }
    })
    .catch((err) => dispatch(addTeamError()));
};
