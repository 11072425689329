import requests from 'api/requests';

import { callAlert } from 'ui/Alert';

import getLeadById from 'store/actions/realtorLeads/getLeadById';

const deleteProperty = (propertyId, leadId = null) => async (dispatch) => {
  try {
    await requests.realtor.properties.deleteProperty(propertyId);

    callAlert.success('Deleted successfully');

    if (leadId) {
      dispatch(getLeadById(leadId));
    }
  } catch (error) {
    console.error(error);
    callAlert.error('Could not delete');
  }
};

export default deleteProperty;
