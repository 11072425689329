import colors from './colors.constants';

export * from './comments-module.constants';
export * from './auth.constants';
export * from './customer.constants';
export * from './common.constants';
export * from './tickets.constants';
export * from './tasks.constants';
export * from './navigation.constants';
export * from './icons.constants';
export * from './logs.constants';
export * from './license-management.constants';
export * from './hr.constants';
export * from './tz.constants';
export * from './error.constants';
export * from './sales.constants';
export * from './billing.constants';
export * from './service.constants';

export {
  colors,
};
