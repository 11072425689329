import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import Button from 'ui/Button';

const CommonTools = (props) => {
  const {
    onNewEvent,
  } = props;

  return (
    <Button
      variant="primary"
      icon="plus"
      onClick={onNewEvent}
    >
      New Entry
    </Button>
  );
};

CommonTools.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

CommonTools.propTypes = {
  onNewEvent: func.isRequired,
};

export default CommonTools;
