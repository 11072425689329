import React from 'react';
import styles from './numeratorColumn.module.css';

const NumeratorColumn = (props) => {
  const {limit} = props
  let rowList = [];
  for (let i = 1; i <= limit; i++) {
    rowList.push(i);
  }
    return (
        <div className={styles['numerator']}>
            {rowList.map((number) => (
                <div key={number} className={styles['number']}>
                    {number}
                </div>
            ))}
        </div>
    );
};

export default NumeratorColumn
