import requests from 'api/requests';

const removeFulfillmentLineItem = (fulfillmentId) => (lineItemId) => async (dispatch) => {
  try {
    await requests.fulfillments.removeLineItem(fulfillmentId)(lineItemId);
  } catch (error) {
    console.error(error);
  }
};

export default removeFulfillmentLineItem;
