import React from 'react';
import PropTypes from 'prop-types';

import { currency } from 'refactored/utils/numbers';

import Typography from 'ui/Typography';

const PriceRangeDisplay = (props) => {
  const {
    from,
    to,
    typographyProps,
  } = props;

  const fromValue = from && from > 0
    ? currency.short(from)
    : null;

  const toValue = to && to > 0
    ? currency.short(to)
    : null;

  return (
    <Typography
      color="money"
      variant="title2"
      tag="span"
      noMargin
      weight="bold"
      {...typographyProps}
    >
      {!toValue && fromValue && 'from '}

      {fromValue}
      {!fromValue && toValue && 'to '}

      {fromValue && toValue && '–'}

      {toValue}

      {!fromValue && !toValue && 'Not set'}
    </Typography>
  );
};

PriceRangeDisplay.defaultProps = {
  from: null,
  to: null,
  typographyProps: {},
};

const {
  number,
  shape,
} = PropTypes;

PriceRangeDisplay.propTypes = {
  from: number,
  to: number,
  typographyProps: shape({}),
};

export default PriceRangeDisplay;
