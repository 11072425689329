import React from 'react';

import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';
import CheckboxLabelled from 'ui/CheckboxLabelled';
import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';

import FormTextfield from 'shared/inputs/FormTextfield';
import EmailInput from 'shared/inputs/EmailInput';
import PhoneInput from 'shared/inputs/PhoneInput';
import PersonTitleSelect from 'shared/inputs/PersonTitleSelect';

const NewContactArea = (props) => {
  const {
    handleChange,
    onInvalidField,
    title,
    values,
    emailError,
    phoneError,
    firstNameError,
    contactData,
    restrictContactChange,
    withSalutations,
  } = props;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <DecoratedFieldWrapper
          // iconName="notNull"
          noMargin
        >
          <Grid container spacing={2}>
            {
              title && (
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    tag="p"
                  >
                    {title}
                  </Typography>
                </Grid>
              )
            }
            <Grid item xs={12}>
              <CheckboxLabelled
                onChange={handleChange('is_past_client')}
                labelText="Past Client"
              />
            </Grid>
            {
              withSalutations && (
                <Grid item xs={2}>
                  <PersonTitleSelect
                    value={`${values?.personTitle}` || `${contactData.personTitle}` || '1'}
                    handleChange={handleChange('person_title')}
                    disabled={restrictContactChange}
                  />
                </Grid>
              )
            }
            <Grid item xs={4}>
              <FormTextfield
                label="First Name"
                onChange={handleChange('first_name')}
                error={firstNameError}
                defaultValue={contactData.personFirstName}
                disabled={restrictContactChange}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextfield
                label="Last Name"
                onChange={handleChange('last_name')}
                defaultValue={contactData.personLastName}
                disabled={restrictContactChange}
              />
            </Grid>
          </Grid>
        </DecoratedFieldWrapper>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <EmailInput
              // decorated
              decoratorProps={{ noMargin: true }}
              handleChange={handleChange('email_primary')}
              onInvalidEmail={onInvalidField('email_primary')}
              error={emailError}
              defaultValue={contactData.personEmail}
              disabled={restrictContactChange}
            />
          </Grid>

          <Grid item xs={6}>
            <PhoneInput
              // decorated
              decoratorProps={{ noMargin: true }}
              handleChange={handleChange('phone_primary')}
              onInvalidPhone={onInvalidField('phone_primary')}
              error={phoneError}
              defaultValue={contactData.personPhone}
              disabled={restrictContactChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

NewContactArea.defaultProps = {
  handleChange: (fieldName) => () => {},
  onInvalidField: (fieldName) => () => {},
  title: 'Or create a new contact',
  emailError: null,
  phoneError: null,
  firstNameError: null,
  restrictContactChange: false,
  contactData: {},
};

const {
  func,
  string,
  bool,
  shape,
} = PropTypes;

NewContactArea.propTypes = {
  handleChange: func,
  onInvalidField: func,
  title: string,
  emailError: string,
  phoneError: string,
  firstNameError: string,
  restrictContactChange: bool,
  contactData: shape({
    personTitle: string,
    personFirstName: string,
    personLastName: string,
    personEmail: string,
    personPhone: string,
  }),
};

export default NewContactArea;
