import React from 'react';

import { currency } from 'refactored/utils/numbers';

import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import useProperty from 'sections/Properties/hooks/useProperty';
import useAddress from 'sections/Addresses/hooks/useAddress';

import { Root, Image } from './styledItems';

const PropertyTab = (props) => {
  const {
    propertyId,
    image,
  } = props;

  const property = useProperty(propertyId);
  const address = useAddress(property?.address_id);

  return (
    <Root>
      <Flex
        alignItems="center"
        justifyContent="flex-start"
        spacing={2}
        fullWidth
      >
        <FlexItem
          xs={8}
        >
          <Image
            src={image}
          />

        </FlexItem>
        <FlexItem
          xs={16}
          justifyContent="flex-start"
          fullWidth
        >
          <Flex
            flexDirection="column"
            spacing={1}
            fullWidth
          >
            <Flex
              fullWidth
            >
              <Typography
                noMargin
                align="left"
              >
                {address}
              </Typography>

            </Flex>

            <Typography
              noMargin
              align="left"
            >
              {currency.full(property.expected_price)}
            </Typography>

          </Flex>
        </FlexItem>
      </Flex>
    </Root>
  );
};

PropertyTab.defaultProps = {
  propertyId: null,
  image: null,
};

const {
  number,
  string,
} = PropTypes;

PropertyTab.propTypes = {
  image: string,
  propertyId: number,
};

export default PropertyTab;
