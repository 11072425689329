import { callAlert } from 'ui/Alert';

import requests from 'api/requests';

import { SET_NEW_UNREAD_MESSAGE_COUNT } from 'store/actionTypes/chat';

const getUserUnreadMessage = () => async (dispatch) => {
  try {
    const result = await requests.chat.getUserUnreadMessage();

    const {
      data,
    } = result;

    dispatch({
      type: SET_NEW_UNREAD_MESSAGE_COUNT,
      payload: {
        newMessageCount: data.length,
      },
    });
  } catch {
    callAlert.error('Error!');
  }
};

export default getUserUnreadMessage;
