import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';

import Typography from 'ui/Typography';
import Tooltip from 'ui/Tooltip';
import Tag from 'ui/Tag';
import Icon from 'ui/Icon';
import Flex from 'ui/Flex';

const getLabelNameById = (list, id) => {
  const label = list.find((item) => item.id === id);
  return label?.name;
};

const GroupedLabels = (props) => {
  const {
    labelsIds,
    editable,
    openEditModal,
    allContactLabels,
  } = props;

  const getTooltipContent = useCallback(() => (
    <Flex
      flexDirection="column"
      spacing={2}
      style={{ padding: 10 }}
    >
      {
        labelsIds.map((labelId) => (
          <Tag>
            <Flex spacing={1} alignItems="center">
              <Typography
                variant="tag"
                color="contrast"
                weight="regular"
                noWrap
                style={{ textTransform: 'capitalize' }}
              >
                {getLabelNameById(allContactLabels, labelId) || ''}
              </Typography>

              {
                editable && (
                  <NakedButton
                    style={{ display: 'flex' }}
                    onClick={openEditModal}
                  >
                    <Icon
                      name="x"
                      color="contrast"
                    />
                  </NakedButton>
                )
              }
            </Flex>
          </Tag>
        ))
      }
    </Flex>
  ), [
    JSON.stringify(labelsIds),
    JSON.stringify(allContactLabels),
  ]);

  return (
    <Tooltip title={getTooltipContent()} placement="bottom">
      <div>
        <Tag
          typographyProps={{
            weight: 'regular',
          }}
        >
          {`${labelsIds.length} more`}
        </Tag>
      </div>
    </Tooltip>
  );
};

GroupedLabels.defaultProps = {
  labelsIds: [],
};

const {
  number,
  arrayOf,
  bool,
  func,
  shape,
} = PropTypes;

GroupedLabels.propTypes = {
  labelsIds: arrayOf(number),
  allContactLabels: arrayOf(shape({})).isRequired,
  editable: bool.isRequired,
  openEditModal: func.isRequired,
};

export default GroupedLabels;
