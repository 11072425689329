export const BILLING_PERIOD_TYPES = {
  monthly: 1,
  yearly: 2,
};

export const INVOICE_STATUSES = {
  pending: 'pending',
  error: 'error',
  paid: 'paid',
  notBillable: 'not billable',
};

export default {
  BILLING_PERIOD_TYPES,
  INVOICE_STATUSES,
};
