import { connect } from 'react-redux';

import { closeModal } from 'store/actions/modals';

import { changePassword } from 'store/actions/auth';

import { editGeneral } from 'store/actions/MyCompany';

import { getUser } from 'store/actions/users';

import requests from 'api/requests';

import {
  updateContact,
  getContactsForFilter,
  getContactById
} from 'store/actions/realtorContacts';

import { uploadAttachment } from 'store/actions/attachmentsV2';

import { createAttachment } from 'store/actions/attachments';

import { getLoggedUserProfile } from 'store/actions/realtorDirectory';

import stableStringify from 'json-stable-stringify';

import OnboardingWizard from './OnboardingWizard';

const mapStateToProps = state => {
  const {
    modals: { onboardingModal },
    auth: {
      loggedUser: {
        Name,
        Surname,
        Email,
        WorkPhone,
        CompanyName,
        EmployeeID,
        isAccountCreator,
        RealtorID,
        WebsiteUrl,
        LinkedinUrl,
        TwitterUrl
      }
    }
    // realtorDirectory: {
    //   userProfile,
    // },
  } = state;

  const isVisible = onboardingModal?.isVisible;
  const firstLogin = onboardingModal?.contentOptions?.firstLogin;

  return {
    isVisible,
    firstLogin,
    firstName: Name,
    lastName: Surname,
    email: Email,
    phone: WorkPhone,
    companyName: CompanyName,
    userId: EmployeeID,
    realtorId: RealtorID,
    isAccountCreator,
    websiteUrl: WebsiteUrl,
    linkedinUrl: LinkedinUrl,
    twitterUrl: TwitterUrl,
    // foundInRealtorDirectory: !!userProfile.id,
    foundInRealtorDirectory: false
  };
};

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal('onboardingModal')()),
  changePassword: async newPassword => {
    await dispatch(changePassword(newPassword, true));
  },
  changeCompanyName: companyName => dispatch(editGeneral(companyName)),
  changeAllUserInformation: contactId => employeeId => async contactForm => {
    await Promise.all([
      requests.employees.updateEmployeeInfo(employeeId, {
        Name: contactForm.firstName,
        Surname: contactForm.lastName,
        Email: contactForm.email,
        WorkPhone: contactForm.phone,
        Login: contactForm.email,
        RealtorID: contactForm.realtorId,
        WebsiteUrl: contactForm.websiteUrl,
        LinkedinUrl: contactForm.linkedinUrl,
        TwitterUrl: contactForm.twitterUrl
      }),
      requests.employees.updateEmployeeInfoInGlobal({
        RealtorID: contactForm.realtorId,
        FirstName: contactForm.firstName,
        LastName: contactForm.lastName,
        Email: contactForm.email,
        PhoneNumber: contactForm.phone
      }),
      dispatch(
        updateContact(contactId)({
          first_name: contactForm.firstName,
          last_name: contactForm.lastName,
          email_primary: contactForm.email,
          phone_primary: contactForm.phone,
          website_url: contactForm.websiteUrl,
          linkedin_url: contactForm.linkedinUrl,
          twitter_url: contactForm.twitterUrl
        })
      )
    ]);
  },

  updateContactAvatar: contactId => async file => {
    await dispatch(
      uploadAttachment(
        {
          file,
          objectId: contactId,
          objectType: 25,
          attachmentType: 1
        },
        false
      )
    );
  },

  updateEmployeeAvatar: employeeId => async file => {
    await dispatch(createAttachment('employee-logo')(employeeId)(file, false));
  },

  refreshEmployee: employeeId => {
    dispatch(getUser('employee')(employeeId));
  },

  refreshContact: contactId => {
    dispatch(getContactById(contactId));
  },

  getContactIdByEmail: async email => {
    const contactIds = await dispatch(
      getContactsForFilter(
        stableStringify({
          email_primary: email
        })
      )({
        email_primary: email
      })(0, 1)
    );

    return contactIds[0]?.id;
  },

  getRealtorDirectoryProfile: () => {
    dispatch(getLoggedUserProfile());
  }
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,

  changeUserInformation: contactId => contactForm => {
    dispatchProps.changeAllUserInformation(contactId)(stateProps.userId)(
      contactForm
    );
  },

  updateAvatar: contactId => async file => {
    await Promise.all([
      dispatchProps.updateContactAvatar(contactId)(file),
      dispatchProps.updateEmployeeAvatar(stateProps.userId)(file)
    ]);
  },

  refreshUserData: contactId => {
    dispatchProps.refreshEmployee(stateProps.userId);

    dispatchProps.refreshContact(contactId);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(OnboardingWizard);
