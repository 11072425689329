/* eslint-disable camelcase */

import React from 'react';

import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Button from 'ui/Button';
import Divider from 'ui/Divider';

import SubTitle from 'sections/Offers/forms/NewOfferForm/SubTitle';

import OfferAmount from 'sections/Offers/forms/NewOfferForm/OfferAmount';
import FinanceType from 'sections/Offers/forms/NewOfferForm/FinanceType';

import Expiry from 'sections/Offers/forms/NewOfferForm/Expiry';
import ClosingDate from 'sections/Offers/forms/NewOfferForm/ClosingDate';

import Contingencies from 'sections/Offers/forms/NewOfferForm/Contingencies';
import ContingencyDate from 'sections/Offers/forms/NewOfferForm/ContingencyDate';
import YourDetails from 'sections/Offers/forms/NewOfferForm/YourDetails';
import BuyerDetails from 'sections/Offers/forms/NewOfferForm/BuyerDetails';

import Notes from 'sections/Offers/forms/NewOfferForm/Notes';

import Agreement from 'sections/Offers/forms/NewOfferForm/Agreement';

import { BackgroundHighlight } from 'sections/Offers/forms/NewOfferForm/styledItems';

import onSubmit from './submitForm';

const OfferForm = (props) => {
  const {
    propertyData,
  } = props;

  const {
    price,
  } = propertyData;

  const {
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {

      propertyId: propertyData.id,

      // details
      offerAmount: price || 0,
      expiresIn: 24,
      closesIn: 21,
      contingenciesIn: 21,
      financeType: 1,
      notes: null,

      // external user details
      firstName: null,
      lastName: null,
      phone: null,
      email: null,
      broker: null,

      // external buyer details
      buyerFirstName: null,
      buyerLastName: null,

      // contingencies
      contingencyInspection: false,
      contingencyAppraisal: false,
      contingencyLoan: false,

      // agreement
      agreementFiles: null,
      agreementUrl: null,
    },
  });

  return (
    <Flex
      fullWidth
      flexWrap="wrap"
      spacing={1}
    >
      <Flex
        fullWidth
        justifyContent="space-between"
      >
        <Typography variant="h1">
          Submit an Offer
        </Typography>

        <Flex spacing={2}>
          <Button
            variant="primary"
            onClick={handleSubmit(onSubmit)}
          >
            Submit Offer
          </Button>
        </Flex>
      </Flex>

      <Flex
        fullWidth
        spacing={2}
        fullHeight
        alignItems="stretch"
      >
        <Flex
          fullWidth
          flexDirection="column"
          spacing={3}
        >
          <Flex
            fullWidth
            spacing={3}
          >
            <Flex
              fullWidth
              flexDirection="column"
            >
              <SubTitle>Offer Details</SubTitle>
              <OfferAmount control={control} />
            </Flex>

            <Flex
              fullWidth
              flexDirection="column"
            >
              <SubTitle>Offer Expiry</SubTitle>
              <Expiry control={control} />
            </Flex>
          </Flex>

          <Flex
            fullWidth
            spacing={3}
          >
            <Flex
              fullWidth
              flexDirection="column"
            >
              <SubTitle>Financing</SubTitle>
              <FinanceType control={control} />
            </Flex>

            <Flex
              fullWidth
              flexDirection="column"
            >
              <SubTitle>Closing Date</SubTitle>
              <BackgroundHighlight
                spacing={1}
                alignItems="center"
                fullWidth
              >
                <ClosingDate control={control} />
              </BackgroundHighlight>
            </Flex>
          </Flex>

          <Flex fullWidth>
            <Flex
              fullWidth
              flexDirection="column"
            >
              <SubTitle>Contingencies</SubTitle>
              <Flex
                spacing={2}
                alignItems="center"
              >
                <Contingencies control={control} />
              </Flex>
            </Flex>
          </Flex>

          <Flex fullWidth>
            <Flex
              fullWidth
              flexDirection="column"
            >
              <SubTitle>Contingency Period</SubTitle>

              <ContingencyDate control={control} />
            </Flex>
          </Flex>

          <Flex fullWidth>
            <Flex
              fullWidth
              flexDirection="column"
            >
              <SubTitle>Upload Purchase Agreement</SubTitle>
              <Agreement control={control} />
            </Flex>
          </Flex>
        </Flex>

        <FlexItem>
          <Divider vertical />
        </FlexItem>

        <Flex
          fullWidth
          flexDirection="column"
          spacing={3}
        >
          <Flex fullWidth>
            <Flex
              fullWidth
              flexDirection="column"
            >
              <SubTitle>Your Details</SubTitle>
              <YourDetails control={control} />
            </Flex>
          </Flex>

          <Flex fullWidth>
            <Flex
              fullWidth
              flexDirection="column"
            >
              <SubTitle>Buyer Details</SubTitle>
              <BuyerDetails control={control} />
            </Flex>
          </Flex>

          <Flex fullWidth>
            <Flex
              fullWidth
              flexDirection="column"
            >
              <SubTitle>Additional Offer Details</SubTitle>
              <Notes control={control} />
            </Flex>
          </Flex>
        </Flex>

      </Flex>

    </Flex>
  );
};

const {
  shape,
} = PropTypes;

OfferForm.propTypes = {
  propertyData: shape({}).isRequired,
};

export default OfferForm;
