exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".board_board__K7ZiO {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  /* padding: 0px 24px; */\n}\n\n.board_board-canban__2Xfjp {\n  width: 100%;\n  border-radius: 5px;\n  display: flex;\n  justify-content: space-around;\n  padding: 12px 0px;\n  /* viewport height - navbar - toolbar */\n  max-height: calc(100vh - 115px - 46px);\n  overflow-y: scroll;\n}\n\n.board_board-canban__2Xfjp:hover {\n  scrollbar-color: auto auto;\n}\n\n.board_board-canban__2Xfjp::-webkit-scrollbar {\n  width: 6px;\n}\n\n.board_board-canban__2Xfjp::-webkit-scrollbar-thumb {\n  background-color: transparent;\n  border-radius: 5px;\n}\n\n.board_board-canban__2Xfjp:hover::-webkit-scrollbar-thumb {\n  background-color: transparent;\n}\n\n.board_board-canban__2Xfjp::-webkit-scrollbar-track {\n  background-color: transparent;\n  border-radius: 5px;\n}\n\n.board_board-canban__2Xfjp::-webkit-scrollbar-track-piece {\n  background-color: transparent;\n  border-radius: 5px;\n}", ""]);

// exports
exports.locals = {
	"board": "board_board__K7ZiO",
	"board-canban": "board_board-canban__2Xfjp"
};