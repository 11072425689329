import React, {
  useCallback,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import { isEmpty } from 'shared/utility';

import { useHistory } from 'react-router-dom';

import Button from 'ui/Button';

import validate from 'shared/utils/validators';

import NewLeadForm from 'sections/Leads/forms/NewLeadForm';

import Modal from 'ui/Modal';

const NewLeadFormModal = (props) => {
  const {
    isVisible,
    createLead,
    closeModal,
    setNotValidField,
    mainEmail,
    personFirstName,
    selectedContact,
    doesPhoneExist,
    doesEmailExist,
    getLeads,
    pageNumber,
    pageSize,
    theme,
    getCachedData,
  } = props;

  const history = useHistory();

  useEffect(() => {
    if (isVisible) {
      getCachedData();
    }
  }, [
    isVisible,
  ]);

  const saveAndOpenLead = useCallback(async () => {
    const isEmailValid = mainEmail ? validate.email(mainEmail) : true;
    const isNewUser = isEmpty(selectedContact);

    if (isNewUser && (doesPhoneExist || doesEmailExist)) {
      return;
    }

    if (isNewUser && !personFirstName) {
      setNotValidField('isFirstNameValid');
      return;
    }

    if (isNewUser && !isEmailValid) {
      setNotValidField('isEmailValid');
      return;
    }

    const id = await createLead();
    await getLeads(pageNumber, pageSize);

    if (id) {
      closeModal(false);
      history.push(`/leads/${id}`);
    }
  }, [
    mainEmail,
    personFirstName,
    selectedContact,
    doesPhoneExist,
    doesEmailExist,
  ]);

  const saveLead = useCallback(async () => {
    const isEmailValid = mainEmail ? validate.email(mainEmail) : true;
    const isNewUser = isEmpty(selectedContact);

    if (isNewUser && (doesPhoneExist || doesEmailExist)) {
      return;
    }

    if (isNewUser && !personFirstName) {
      setNotValidField('isFirstNameValid');
      return;
    }

    if (isNewUser && !isEmailValid) {
      setNotValidField('isEmailValid');
      return;
    }

    await createLead();
    await getLeads(pageNumber, pageSize);
    closeModal(false);
  }, [
    mainEmail,
    personFirstName,
    selectedContact,
    doesPhoneExist,
    doesEmailExist,
  ]);

  return (
    <Modal
      theme={theme}
      width={760}
      visible={isVisible}
      okText="Save"
      onCancel={closeModal}
      title="New Lead"
      destroyOnClose
      footer={[
        <Button
          variant="secondary"
          onClick={closeModal}
        >
          Cancel
        </Button>,
        <Button
          variant="primary"
          onClick={saveLead}
        >
          Save
        </Button>,
        <Button
          variant="primary"
          onClick={saveAndOpenLead}
        >
          Save & Open Lead
        </Button>,
      ]}

    >
      <NewLeadForm />
    </Modal>
  );
};

NewLeadFormModal.defaultProps = {
  isVisible: false,
  theme: null,
};

const {
  bool,
  func,
  string,
  shape,
  number,
} = PropTypes;

NewLeadFormModal.propTypes = {
  isVisible: bool,
  createLead: func.isRequired,
  closeModal: func.isRequired,
  setNotValidField: func.isRequired,
  mainEmail: string.isRequired,
  personFirstName: string.isRequired,
  getLeads: func.isRequired,
  pageNumber: number.isRequired,
  pageSize: number.isRequired,
  selectedContact: shape({
    id: number,
    first_name: string,
    last_name: string,
    email_primary: string,
  }).isRequired,
  doesPhoneExist: bool.isRequired,
  doesEmailExist: bool.isRequired,
  theme: shape(),
  getCachedData: func.isRequired,
};

export default NewLeadFormModal;
