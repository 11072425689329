import {
  SET_SELECTED_FULFILLMENT,
} from 'store/actionTypes/fulfillments';

const setSelectedFulfillment = (fulfillmentId) => ({
  type: SET_SELECTED_FULFILLMENT,
  payload: fulfillmentId,
});

export default setSelectedFulfillment;
