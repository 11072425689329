import updateLead from './updateLead';

const updateSelectedLead = (updateData) => async (dispatch, getState) => {
  const {
    crmLeads: {
      selectedLead,
    },
  } = getState();

  dispatch(updateLead(selectedLead)(updateData));
};

export default updateSelectedLead;
