import {
  SET_TASK_PLANNER_BACKLOG_FILTER,
} from 'store/actionTypes/tasks';

const setTaskPlannerBacklogFilter = (filterItem) => ({
  type: SET_TASK_PLANNER_BACKLOG_FILTER,
  payload: filterItem,
});

export default setTaskPlannerBacklogFilter;
