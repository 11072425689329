import { callAlert } from 'ui/Alert';

import requests from 'api/requests';

import { SET_NEW_UNREAD_MESSAGE_COUNT } from 'store/actionTypes/chat';

const markMessageAsRead = (chatMessageID) => async (dispatch, getState) => {
  try {
    const {
      chat: {
        activeChatId,
        newMessageCount,
      },
    } = getState();

    await requests.chat.markMessageAsRead(activeChatId)(chatMessageID);

    dispatch({
      type: SET_NEW_UNREAD_MESSAGE_COUNT,
      payload: {
        newMessageCount: newMessageCount <= 0 ? 0 : newMessageCount - 1,
      },
    });
  } catch {
    callAlert.error('Error!');
  }
};

export default markMessageAsRead;
