import requests from 'api/requests';

import getFulfillmentById from './getFulfillmentById';

const updateFulfillment = (fulfillmentId) => (updateData) => async (dispatch) => {
  try {
    await requests.fulfillments.update(fulfillmentId)(updateData);

    dispatch(getFulfillmentById(fulfillmentId));
  } catch (error) {
    console.error(error);
  }
};

export default updateFulfillment;
