import React from 'react';
import GoogleIcon from 'assets/images/commsHub/google-icon.svg';
import MicrosoftIcon from 'assets/images/commsHub/microsoft-icon.svg';
import styles from './emailCompanyIcon.module.css';

const EmailCompanyIcon = ({ emailCompany = 'google', altText }) => {
    const companyIcon = emailCompany == 'google' ? GoogleIcon : MicrosoftIcon
    const bgStyle = { backgroundImage: `url(${companyIcon})`, };

    return (
        <span style={bgStyle} className={styles['email-company-icon']}>
        </span>
    );
};

export default EmailCompanyIcon;
