/* eslint-disable camelcase */
import React from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import Typography from 'ui/Typography';

import FormTextfield from 'shared/inputs/FormTextfield';
import EmailInput from 'shared/inputs/EmailInput';
import PhoneInput from 'shared/inputs/PhoneInput';
import PersonTitleSelect from 'shared/inputs/PersonTitleSelect';
import DateTimeInput from 'shared/inputs/DateTimeInput';

import CreateContactButton from './CreateContactButton';

const NewContactArea = (props) => {
  const {
    handleChange,
    onInvalidField,
    areaTitle,
    contact,
    setContactAfterCreation,
  } = props;

  const {
    title,
    first_name,
    last_name,
    email_primary,
    phone_primary,
    first_contact_date,
  } = contact;

  return (
    <Flex
      spacing={2}
      flexWrap="wrap"
    >
      <FlexItem fullWidth>
        {
          areaTitle && (
            <Typography
              variant="title3"
            >
              {areaTitle}
            </Typography>
          )
        }
        <Flex spacing={1}>
          <PersonTitleSelect
            value={title}
            handleChange={handleChange('title')}
          />
          <FormTextfield
            label="First Name"
            value={first_name}
            onChange={handleChange('first_name')}
          />
          <FormTextfield
            label="Last Name"
            value={last_name}
            onChange={handleChange('last_name')}
          />
        </Flex>
      </FlexItem>

      <FlexItem xs={12}>
        <EmailInput
          value={email_primary}
          handleChange={handleChange('email_primary')}
          onInvalidEmail={onInvalidField}
        />
      </FlexItem>

      <FlexItem xs={12}>
        <PhoneInput
          value={phone_primary}
          handleChange={handleChange('phone_primary')}
          onInvalidPhone={onInvalidField}
        />
      </FlexItem>

      <FlexItem xs={12}>
        <DateTimeInput
          value={first_contact_date}
          handleChange={handleChange('first_contact_date')}
        />
      </FlexItem>

      <FlexItem fullWidth>
        <CreateContactButton
          setContactAfterCreation={setContactAfterCreation}
        />
      </FlexItem>
    </Flex>
  );
};

NewContactArea.defaultProps = {
  areaTitle: 'Or create a new contact',
  contact: {},
  setContactAfterCreation: true,
};

const {
  func,
  string,
  shape,
  bool,
} = PropTypes;

NewContactArea.propTypes = {
  handleChange: func.isRequired,
  onInvalidField: func.isRequired,
  areaTitle: string,
  contact: shape({
    title: string,
    first_name: string,
    last_name: string,
    email_primary: string,
    phone_primary: string,
    first_contact_date: string,
  }),
  setContactAfterCreation: bool,
};

export default NewContactArea;
