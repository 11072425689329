import openLogOfferModal from './openLogOfferModal';
import closeLogOfferModal from './closeLogOfferModal';
import openLogOfferModalUniversal from './openLogOfferModalUniversal';
import closeLogOfferModalUniversal from './closeLogOfferModalUniversal';

export {
  openLogOfferModal,
  closeLogOfferModal,
  openLogOfferModalUniversal,
  closeLogOfferModalUniversal,
};
