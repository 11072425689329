import React from 'react';
import DetailedSenderPill from '../../../senderPillDetailed/SenderPillDetailed';
import styles from './threadModalCurrentMail.module.css';
import formatTimestamp from 'refactored/utils/formatTimeStampToDate';
import getImportanceLabel from 'refactored/utils/getImportanceLabel';
import IconButton from 'refactored/components/shared/buttons/iconButton/IconButton';
import ReplyIcon from 'assets/images/commsHub/reply-arrow.svg';
import ReplyAllIcon from 'assets/images/commsHub/reply-all-arrow.svg';
import ForwardArrowIcon from 'assets/images/commsHub/forward-arrow.svg';
import TrashCanIcon from 'assets/images/commsHub/trash-can-icon.svg';
import EmailFileIcon from 'assets/images/email-file-icon.png';
import Attachment from 'refactored/components/shared/attachmentPill/Attachment';
import { useThreadModal } from 'refactored/components/shared/providers/modals/ThreadModal.provider';
import SenderPillDetailedExtraCount from 'refactored/components/shared/senderPillDetailed/SenderPillDetailedExtraCount';

const ThreadModalCurrentMail = ({ email }) => {
    const { setReply, setReplyAll, setForward, deleteCurrentEmailFromThread } = useThreadModal();

    const ccContacts = email?.cc ? [...email?.cc] : []
    const ccPillArr = ccContacts.length > 2 ? ccContacts.slice(0, 2) : ccContacts
    const ccDropdownArr = ccContacts.length > 2 ? ccContacts.slice(2) : []

    return (
        <div className={styles['thread-modal-current-mail']}>
            <div className={styles['thread-modal-current-mail-subject-area']}>
                <h4 title={email?.subject}>{email?.subject}</h4>
                <span>{email?.clean_sympleteAnalysis?.importance || '0'} - {getImportanceLabel(email?.clean_sympleteAnalysis?.importance || 0)}</span>
            </div>
            <div>{formatTimestamp(email?.date)}</div>
            <div className={styles['thread-modal-current-mail-recipients']}>
                {email?.from.map((fromContact) =>
                    <DetailedSenderPill key={fromContact.id} contact={fromContact} />
                )}
                <span>
                    cc:
                    {ccPillArr?.map((fromContact) =>
                        <DetailedSenderPill key={fromContact.id} contact={fromContact} onlyImage={true} />
                    )}
                    {ccDropdownArr.length > 0 &&
                        <SenderPillDetailedExtraCount
                            extraContacts={ccDropdownArr}
                        />
                    }
                </span>
                <span>
                    <IconButton icon={ReplyIcon} onClick={setReply} />
                    <IconButton icon={ReplyAllIcon} onClick={setReplyAll} />
                    <IconButton icon={ForwardArrowIcon} onClick={setForward} />
                    <IconButton icon={TrashCanIcon} onClick={deleteCurrentEmailFromThread} />
                </span>
            </div>
            <div className={styles['attatchment-container']}>
                {email?.files.map((file) => (
                    <Attachment
                        key={file.id}
                        tokenId={email?.sympleteTokenId}
                        attachment={file}
                        EmailFileIcon={EmailFileIcon}
                        filename={file.filename}
                        isWidthContent={true}
                    />
                ))}
            </div>
            <div className={styles['thread-modal-current-mail-msg-area']} dangerouslySetInnerHTML={{ __html: email?.body }} />
        </div>
    );
};

export default ThreadModalCurrentMail;
