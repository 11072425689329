import selectors from 'store/selectors'; // eslint-disable-line import/no-cycle
import createFulfillment from './createFulfillment';

const createFulfillmentFromNewFulfillmentForm = () => async (dispatch, getState) => {
  const fulfillmentData = selectors.fulfillments.preparedFormData(getState());
  try {
    const fulfillmentId = await dispatch(createFulfillment(fulfillmentData));
    return fulfillmentId;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default createFulfillmentFromNewFulfillmentForm;
