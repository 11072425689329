import {
  SET_NEW_REALTOR_LEAD_FORM_FIELD,
} from 'store/actionTypes/realtorLeads';

const setNewInspectionFormField = (key) => (value) => ({
  type: SET_NEW_REALTOR_LEAD_FORM_FIELD,
  payload: {
    key,
    value,
  },
});

export default setNewInspectionFormField;
