/* eslint-disable react/jsx-no-bind */

import React from 'react';
import PropTypes from 'prop-types';

import datetime from 'refactored/utils/datetime';

import { useController } from 'react-hook-form';

import OfferDateInput from 'sections/Offers/forms/NewOfferForm/OfferDateInput';

const Expiry = (props) => {
  const {
    control,
    checkEquality,
  } = props;

  const {
    field: {
      onChange,
      value,
    },
  } = useController({
    name: 'expiresIn',
    control,
  });

  const handleChange = (val) => {
    onChange(val);
    checkEquality('expiresIn', datetime().add(val, 'hour').format());
  };

  return (
    <OfferDateInput
      defaultValue={value}
      onChange={handleChange}
      max={Infinity}
    />
  );
};

const {
  shape,
  func,
} = PropTypes;

Expiry.propTypes = {
  control: shape({}).isRequired,
  checkEquality: func.isRequired,
};

export default Expiry;
