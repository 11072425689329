/* eslint-disable react/jsx-no-bind */

import React, { useState } from 'react';

import PropTypes from 'prop-types';

import datetime from 'refactored/utils/datetime';

import Flex from 'ui/Flex';
import Textfield from 'ui/Textfield';
import Typography from 'ui/Typography';

import { pluralize } from 'shared/utils/strings';

const formats = {
  day: 'MMMM D, YYYY',
  hour: 'h:mm MMMM D, YYYY',
};

const OfferDateInput = (props) => {
  const {
    type,
    max,
    defaultValue,
    disabled,
    presetDate,
    onChange,
  } = props;

  const [value, setValue] = useState(defaultValue);
  const [date, setDate] = useState(datetime().add(defaultValue, type).format(formats[type]));

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange(newValue);

    if (type === 'hour') {
      setDate(datetime().add(newValue, type).format(formats.hour));
    } else {
      setDate(datetime().add(newValue, type).format(formats.day));
    }
  };

  return (
    <Flex
      spacing={1}
      alignItems="baseline"
    >
      <Textfield
        type="number"
        onChange={handleChange}
        autoWidth
        min={0}
        max={max}
        value={value}
        disabled={disabled}
      />
      <Typography noMargin>{pluralize(value, type)}</Typography>
      <Typography
        noMargin
        color="descriptor-text"
      >
        {presetDate ? datetime(presetDate).format(formats[type]) : date}
      </Typography>
    </Flex>
  );
};

OfferDateInput.defaultProps = {
  type: 'hour',
  max: 24,
  defaultValue: 24,
  disabled: false,
  presetDate: null,
};

const {
  number,
  bool,
  string,
  func,
} = PropTypes;

OfferDateInput.propTypes = {
  type: string,
  max: number,
  defaultValue: number,
  disabled: bool,
  presetDate: string,
  onChange: func.isRequired,
};

export default OfferDateInput;
