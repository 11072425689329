/* eslint-disable react/jsx-no-bind */

import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'ui/Icon';

import {
  Root,
  LinkWrapper,
} from './styledItems';

const Links = (props) => {
  const {
    links,
  } = props;

  const handleClickLink = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <Root
        spacing={1}
        alignItems="center"
      >
        {
          links.map((item) => {
            const {
              name,
              url,
            } = item;

            let icon = name;

            if (name === 'website') icon = 'globe';

            return (
              <LinkWrapper onClick={url ? () => handleClickLink(url) : null}>
                <Icon
                  name={icon}
                  color={url ? 'main-text' : 'placeholder'}
                  size="l"
                />
              </LinkWrapper>
            );
          })
        }
      </Root>
    </>
  );
};

Links.defaultProps = {
  links: [],
};

const {
  arrayOf,
  shape,
  string,
} = PropTypes;

Links.propTypes = {
  links: arrayOf(shape({
    name: string,
    url: string,
  })),
};

export default Links;
