export const intents = [
    'Information',
    'Solution',
    'Feedback',
    'Feedback Request',
    'Confirmation Required',
    'Support Request',
    'Problem Report',
    'Complaint',
    'Intro/Networking',
    'Invitation',
    'Newsletter/Promo',
    'Sale/Offer',
    'New Lead',
    'Existing Client',
    'Other',
    'Spam'
];

export default intents