import React, {
  useEffect,
  useState,
} from 'react';

import { useLocation } from 'react-router-dom';

import requests from 'api/requests';

import useLocalStorageItem from 'shared/hooks/useLocalStorageItem';
import updateLocalStorage from 'shared/utils/updateLocalStorageWithCustomEvent';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Elevation from 'ui/Elevation';

import ExternalPage from 'shared/components/ExternalPage';

import ExternalPropertyOverview from './modules/ExternalPropertyOverview';
import ExternalContactOverview from './modules/ExternalContactOverview';
import OfferForm from './modules/OfferForm/OfferForm';

const getExternalToken = (pathname) => {
  if (!pathname) return null;

  const token = pathname.split('/').pop();

  return token;
};

const ExternalOffersV2 = () => {
  const [propertyData, setPropertyData] = useState(null);
  const [contactData, setContactData] = useState(null);
  const [propertyImages, setPropertyImages] = useState(null);

  const [loading, setLoading] = useState(true);

  const externalToken = useLocalStorageItem('externalToken');
  const location = useLocation();

  useEffect(() => {
    const token = getExternalToken(location.pathname);
    updateLocalStorage('set', 'externalToken', token);
  }, [
    location?.pathname,
  ]);

  useEffect(() => {
    if (externalToken) {
      const getPropertyData = async () => {
        const response = await requests.external.realtor.offers.get();

        setPropertyData(response.data.propertyData);
        setContactData(response.data.contactData);
        setPropertyImages(response.data.propertyImages);

        setLoading(false);
      };
      getPropertyData();
    }
  }, [
    externalToken,
  ]);

  return (
    <ExternalPage
      appbarContent={(
        <a
          href="https://symplete.com"
          style={{ color: 'white' }}
          target="_blank"
          rel="noreferrer"
        >
          What is Symplete?
        </a>
      )}
    >

      {
        loading
          ? ''
          : (
            <Flex
              fullWidth
              spacing={2}
              flexWrap="wrap"
              alignItems="stretch"
            >
              <FlexItem xs={16}>
                <Elevation
                  rounded="s"
                  padding="m"
                  fullHeight
                >
                  <ExternalPropertyOverview
                    propertyData={propertyData}
                    propertyImages={propertyImages}
                  />
                </Elevation>
              </FlexItem>

              <FlexItem xs={8}>
                <Elevation
                  rounded="s"
                  padding="m"
                  fullHeight
                >
                  <ExternalContactOverview
                    contactData={contactData}
                  />
                </Elevation>
              </FlexItem>

              <FlexItem fullWidth>
                <Elevation
                  rounded="s"
                  padding="m"
                >
                  <OfferForm propertyData={propertyData} />
                </Elevation>
              </FlexItem>
            </Flex>
        )
      }
    </ExternalPage>
  );
};

export default ExternalOffersV2;
