export const sales = { // eslint-disable-line import/prefer-default-export
  LEAD_STATUS_ID: {
    NEW_ANALYSIS: 1,
    DETERMINE_DECISION_MAKER: 2,
    PITCH: 3,
    SUBMITTED_PROPOSAL: 4,
    NEGOTIATE: 5,
    CLOSING: 6,
    SALE_AGREED: 7,
    WON: 8,
    LOST: 9,
    ARCHIVED: 10,
  },
};
