import { useRef, useEffect, useState } from 'react';
import useContacts from 'refactored/hooks/useContacts';
import sympleteApiContactToNylasContact from 'refactored/utils/sympleteApiContactToNylasContact';

const useContactPillPicker = ({ setContactsCallback = () => { }, initialSelectedContacts = [] }) => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const { contacts } = useContacts()
    const [cleanedContacts, setCleanedContacts] = useState([]);
    const toggleModal = (isOpen) => {
        if (isOpen == undefined) setIsOpenModal(!isOpenModal);
        else setIsOpenModal(!!isOpen);
    };
    const [selectedContacts, setSelectedContacts] = useState([...initialSelectedContacts]);
    const [preselectedContacts, setPreselectedContacts] = useState([]);
    const handleContactPreselect = (contact) => {
        if (preselectedContacts.includes(contact)) {
            const updatedContacts = preselectedContacts.filter((c) => c !== contact);
            setPreselectedContacts(updatedContacts);
            return;
        }
        setPreselectedContacts([...preselectedContacts, contact]);
    };
    const handleContactRemove = (contact) => {
        const updatedContacts = selectedContacts.filter((c) => c !== contact);
        setSelectedContacts(updatedContacts);
        setContactsCallback(updatedContacts);
    };
    const preselectedToSelected = () => {
        setSelectedContacts([...preselectedContacts])
        setContactsCallback([...preselectedContacts]);
    }
    const selectedToPreselected = () => {
        setPreselectedContacts([...selectedContacts])
    }

    const stopPropagation = (e) => {
        e.stopPropagation();
    }
    const handleDone = () => {
        preselectedToSelected();
        toggleModal(false)
    }
    const handleModalOpen = () => {
        selectedToPreselected();
        toggleModal(true)
    }

    useEffect(() => {
        const mappedContacts = contacts?.map(sympleteApiContactToNylasContact) || []
        setCleanedContacts(mappedContacts)
    }, [contacts])

    const wrapperRef = useRef(null);
    const [top, setTop] = useState('0px');

    useEffect(() => {
        const getParentPosition = () => {
            if (wrapperRef.current) {
                const rect = wrapperRef.current.getBoundingClientRect();
                return rect.top + window.scrollY;
            }
            return 0;
        };

        if (isOpenModal) {
            const top = getParentPosition() + 40;
            setTop(`${top}px`);
        }
    }, [isOpenModal]);


    const [newContactMail, setNewContactMail] = useState('');

    const addToNewContacts = () => {
        const newContactObject = {
            id: parseInt(Math.random() * 10000).toString(),
            name: newContactMail,
            email: newContactMail,
            phone: ''
        }
        setCleanedContacts([...cleanedContacts, newContactObject])
        setPreselectedContacts([...preselectedContacts, newContactObject])
    }

    return {
        isOpenModal,
        toggleModal,
        handleContactRemove,
        handleContactPreselect,
        selectedContacts,
        preselectedContacts,
        cleanedContacts,
        stopPropagation,
        preselectedToSelected,
        selectedToPreselected,
        handleDone,
        handleModalOpen,
        wrapperRef,
        top,
        newContactMail,
        setNewContactMail,
        addToNewContacts
    };
};

export default useContactPillPicker;
