import React from 'react';
import ContactPill from './ContactPill';
import { Menu, Dropdown } from 'antd';
import styles from './contactPill.module.css';

const ContactPillExtraCount = ({ extraContacts, onContactRemove, }) => {
    const menu = (
        <Menu> {extraContacts?.map((contact, index) => (
            <Menu.Item key={'contact-menu-item' + index}>
                <ContactPill key={'contact-' + index} altText={contact.name} contact={contact} flat={true} onContactRemove={() => onContactRemove(contact)} />
            </Menu.Item>
        ))}
        </Menu>
    )
    return (
        <Dropdown
            overlay={menu}
        >
            <div className={styles['small-pill']}>
                <span className={styles['extra-count']}>
                    + {extraContacts?.length}
                </span>
            </div>
        </Dropdown>
    );
};

export default ContactPillExtraCount;
