import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import datetime from 'refactored/utils/datetime';

import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import Flex from 'ui/Flex';
import Textfield from 'ui/Textfield';
import Typography from 'ui/Typography';
import Button from 'ui/Button';

import { noOp } from 'shared/utility';

const viewToFormat = {
  date: undefined,
  year: 'YYYY',
};

const DateInput = (props) => {
  const {
    handleChange,
    value,
    label,
    placeholder,
    variant,
    view,
    onBlur,
    onFocus,
    error,
    helperText,
    onReset,
    disabled,
    customFormat,
  } = props;

  const [dateValue, setDateValue] = useState(value ? datetime(value, viewToFormat[view]) : null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setDateValue(value ? datetime(value, viewToFormat[view]) : null);
  }, [
    value,
    view,
  ]);

  const onChange = useCallback((dateTime) => {
    const formattedValue = datetime(dateTime).format(viewToFormat[view]);
    setDateValue(datetime(dateTime, viewToFormat[view]));
    handleChange(formattedValue || null);
  }, [
    handleChange,
    view,
  ]);

  const handleOnFocus = useCallback((event) => {
    setAnchorEl(event.currentTarget);
    onFocus(event);
  }, [
    onFocus,
  ]);

  /* eslint-disable react/prop-types */
  const renderInlineInput = useCallback((inputProps) => {
    const format = customFormat || (view === 'year' ? 'YYYY' : 'MM/DD/YYYY');
    const formattedValue = dateValue ? datetime(dateValue).format(format) : null;

    return (
      <NakedButton
        onClick={(event) => { // eslint-disable-line react/jsx-no-bind
          handleOnFocus(event);
          inputProps.onClick();
        }}
      >
        <Typography
          color={formattedValue ? 'main-text' : 'placeholder'}
          fontStyle={formattedValue ? 'normal' : 'italic'}
          noMargin
        >
          {formattedValue || placeholder}
        </Typography>
      </NakedButton>
    );
  }, [
    dateValue,
    label,
    view,
    placeholder,
    customFormat,
  ]);
  /* eslint-enable */

  /* eslint-disable react/prop-types */
  const renderInput = useCallback((inputProps) => {
    const format = view === 'year' ? 'YYYY' : 'MM/DD/YYYY';
    const formattedValue = dateValue ? datetime(dateValue).format(format) : null;

    return (
      <Textfield
        label={label}
        placeholder={placeholder}
        type="text"
        onClick={inputProps.onClick}
        value={formattedValue}
        onChange={inputProps.onChange}
        onFocus={inputProps.onFocus}
        icon="calendar"
        hint={helperText}
        error={error}
        disabled={disabled}
      />
    );
  }, [
    dateValue,
    label,
    view,
    placeholder,
    helperText,
    error,
    disabled,
  ]);
  /* eslint-enable */

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Flex
        spacing={1}
        alignItems="center"
      >
        <DatePicker
          {...props} // eslint-disable-line react/jsx-props-no-spreading
          variant="inline"
          views={view === 'year' ? ['year'] : undefined}
          label={variant === 'inline' ? undefined : label}
          value={dateValue}
          onChange={onChange}
          margin="dense"
          showTodayButton
          TextFieldComponent={variant === 'inline' ? renderInlineInput : renderInput}
          PopoverProps={{
            anchorEl,
          }}
          onBlur={onBlur}
          onFocus={handleOnFocus}
          autoOk
        />
        {
          onReset && (
            <Button
              icon="x"
              onClick={onReset}
              variant="tertiary"
              small
            />
          )
        }
      </Flex>
    </MuiPickersUtilsProvider>
  );
};

DateInput.defaultProps = {
  value: null,
  label: 'First contact date',
  variant: undefined,
  view: 'date',
  placeholder: null,
  onBlur: noOp,
  onFocus: noOp,
  error: false,
  helperText: '',
  onReset: null,
  customFormat: null,
  disabled: false,
};

const {
  bool,
  func,
  number,
  oneOf,
  oneOfType,
  string,
} = PropTypes;

DateInput.propTypes = {
  handleChange: func.isRequired,
  value: oneOfType([string, number]),
  label: string,
  placeholder: string,
  variant: oneOf(['inline', undefined]),
  view: oneOf(['year', 'date']),
  onBlur: func,
  onFocus: func,
  error: bool,
  helperText: string,
  onReset: func,
  customFormat: string,
  disabled: bool,
};

export default DateInput;
