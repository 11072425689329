import { createSelector } from 'reselect';

import selectors from 'store/selectors'; // eslint-disable-line import/no-cycle

const defaultAttributes = ['title', 'firstName', 'lastName'];

export default createSelector(
  (state) => state.realtorContacts.contacts,
  (state) => state.appCustomization.settings,
  (state) => state.dictionaryV2.personTitles,
  (state) => state.myCompany.employees,
  (state) => state.auth.loggedUser.EmployeeID,
  (_, contactId) => contactId,
  (_, contactId, params) => params,
  (
    contacts,

    settings,
    personTitles,

    employees,
    EmployeeID,

    id,
    {
      attributes = defaultAttributes,
      returnValue = 'string',
    } = {},
  ) => {
    //
    const contact = selectors.realtorContacts.byId(
      { realtorContacts: { contacts } },
      id,
    );
    const currentUser = selectors.users.currentUser(
      {
        myCompany: { employees },
        auth: {
          loggedUser: { EmployeeID },
        },
      },
    );
    const appSettings = selectors.appCustomization.settings(
      {
        appCustomization: { settings },
      },
    );
    const titles = selectors.dictionary.personTitles(
      {
        dictionaryV2: { personTitles },
      },
    );
    //

    const contactObj = id === 0 ? currentUser : contact;

    const firstName = contactObj.first_name || '';
    const lastName = contactObj.last_name || '';
    const titleId = contactObj.title;

    let title = '';

    const withSalutations = !!appSettings.salutations_on;

    if (withSalutations && titleId) {
      const titleObj = titles.find((item) => item.id === titleId);
      title = titleObj?.name || '';
    }

    const contactNameAttributes = {
      title,
      titleId,
      firstName,
      lastName,
    };

    const fullNameArray = [];

    attributes.forEach((item) => {
      if (contactNameAttributes[item]) {
        fullNameArray.push(contactNameAttributes[item]);
      }
    });

    const fullName = fullNameArray.join(' ');

    if (returnValue === 'object') {
      return { ...contactNameAttributes, fullName };
    }

    return fullName;
  },
);
