import React from 'react';
import PropTypes from 'prop-types';

import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';

import Grid from 'ui/Grid';
import Typography from 'ui/Typography';

import Avatar from './Avatar';

import {
  Card,
} from './styledItems';

const ContactItem = (props) => {
  const {
    contactId,
    fullName,
    // oneContactSelect,
    // isEmptyContactSelector,
    isSelected,
    onAdd,
    onRemove,
  } = props;

  return (
    <NakedButton
      onClick={isSelected ? onRemove : onAdd}
      style={{ width: '100%' }}
    >
      <Card isSelected={isSelected}>
        <Grid
          container
          wrap="nowrap"
          spacing={1}
        >
          <Grid item>
            <Avatar contactId={contactId} />
          </Grid>

          <Grid
            item
            container
            direction="column"
            justify="space-around"
          >
            <Grid item>
              <Typography
                ellipsis
                variant="body2"
                weight={500}
                noMargin
                color={isSelected ? 'main-text-inversed' : 'main-text'}
                style={{ maxWidth: 100 }}
              >
                {fullName}
              </Typography>
            </Grid>

            {/* <Grid
              item
              container
              justify="flex-end"
            >
              <Grid item>
                <Typography
                  variant="body2"
                  color={isSelected ? 'selected' : 'link'}
                  noMargin
                >
                  {
                    isSelected
                      ? oneContactSelect
                        ? 'selected'
                        : 'added'
                      : oneContactSelect
                        ? 'select'
                        : '+ add'
                  }
                </Typography>
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
      </Card>
    </NakedButton>
  );
};

ContactItem.defaultProps = {
  isSelected: false,
};

const {
  number,
  string,
  bool,
  func,
} = PropTypes;

ContactItem.propTypes = {
  contactId: number.isRequired,
  fullName: string.isRequired,
  // oneContactSelect: bool.isRequired,
  // isEmptyContactSelector: bool.isRequired,
  isSelected: bool,
  onAdd: func.isRequired,
  onRemove: func.isRequired,
};

export default ContactItem;
