import React, { useState, useCallback } from 'react';

import PropTypes from 'prop-types';

import ThemeContext from 'ui/ThemeProvider/context';

import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';

import { defaultTheme } from 'shared/themes/defaultTheme';

import { AntdThemeProvider } from './styledItems';

const getMuiTheme = theme =>
  createTheme({
    palette: {
      primary: {
        main: theme.palette.highlight
      }
    },
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: theme.palette.focus,
          color: theme.palette['main-text-inversed']
        }
      },
      MuiPopover: {
        paper: {
          borderRadius: 10,
          boxShadow: theme.shadows.dropdown
        }
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor: theme.palette.focus,
          borderRadius: 10
        },
        day: {
          '&:hover': {
            backgroundColor: theme.palette['background-flat'],
            borderRadius: 10
          }
        }
      },
      PrivateTabIndicator: {
        colorSecondary: {
          backgroundColor: theme.palette['background-flat']
        }
      },
      MuiPickersClock: {
        clock: {
          backgroundColor: theme.palette['background-flat']
        }
      }
    }
  });

const ThemeProvider = ({ children, theme }) => {
  const [currentTheme, setTheme] = useState(theme);

  const handleNavColorChange = useCallback(
    value => {
      setTheme(defaultTheme);
    },
    [setTheme]
  );

  return (
    <ThemeContext.Provider value={{ handleNavColorChange }}>
      <StyledThemeProvider theme={currentTheme}>
        <MuiThemeProvider theme={getMuiTheme(currentTheme)}>
          <AntdThemeProvider theme={currentTheme}>{children}</AntdThemeProvider>
        </MuiThemeProvider>
      </StyledThemeProvider>
    </ThemeContext.Provider>
  );
};

ThemeProvider.defaultProps = {
  theme: defaultTheme
};

const { element, shape } = PropTypes;

ThemeProvider.propTypes = {
  children: element.isRequired,
  theme: shape()
};


export default ThemeProvider;
