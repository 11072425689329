const colors = {
  blue: '#367BED',

  green: '#25C86A',
  paleGreen: '#40B99A',

  lighterPaleGreen: '#64c9af',

  'lime-green': '#1FBB06',
  limeGreen: '#1FBB06',

  orange: '#FFCC00',
  red: '#FC5838',

  cheriseRed: '#DB3850',
  'cherise-red': '#DB3850',

  mediumPurple: '#884ada',
  'medium-purple': '#884ada',

  'lightest-gray': '#FCFCFC',
  'lightest-grey': '#FCFCFC',
  lightestGray: '#FCFCFC',
  lightestGrey: '#FCFCFC',

  'light-grey': '#F8F9FA',
  'light-gray': '#F8F9FA',
  lightGray: '#F8F9FA',
  lightGrey: '#F8F9FA',

  'bluish-gray': '#F2F6FE',
  'bluish-grey': '#F2F6FE',
  bluishGray: '#F2F6FE',
  bluishGrey: '#F2F6FE',

  'dark-bluish-gray': '#D8E5FF',
  'dark-bluish-grey': '#D8E5FF',
  darkBluishGray: '#D8E5FF',
  darkBluishGrey: '#D8E5FF',

  'deep-blue': '#141736',
  'deep-blue-transparent': 'rgba(20, 23, 54, 0.4)',
  deepBlue: '#141736',
  deepBlueTransparent: 'rgba(20, 23, 54, 0.4)',

  gray: '#c1c1c1',
  grey: '#c1c1c1',

  'dark-gray': '#616161',
  'dark-grey': '#616161',
  darkGray: '#616161',
  darkGrey: '#616161',

  darkerGray: '#9395A3',
  darkerGrey: '#9395A3',

  white: '#ffffff',

  violet: '#9013FE',
  watermelon: '#FF526B',
  malibu: '#77a8f8',
  serenade: '#fff5e6',

  'electric-violet': '#C200E3',
  electricViolet: '#C200E3',

  turquoise: '#50E3C2',
  pictonBlue: '#2EBEE6',
  picasso: '#FFEB99',


  'black-transparent': 'rgba(0, 0, 0, 0.87)',
  'black-transparent-1': 'rgba(0, 0, 0, 0.87)',
  'black-transparent-2': 'rgba(0, 0, 0, 0.47)',
  'black-transparent-3': 'rgba(0, 0, 0, 0.17)',
  blackTransparent: 'rgba(0, 0, 0, 0.87)',
  blackTransparent1: 'rgba(0, 0, 0, 0.87)',
  blackTransparent2: 'rgba(0, 0, 0, 0.47)',
  blackTransparent3: 'rgba(0, 0, 0, 0.17)',

  'white-transparent': 'rgba(255, 255, 255, 0.07)',
  'white-transparent-1': 'rgba(255, 255, 255, 0.07)',
  'white-transparent-2': 'rgba(255, 255, 255, 0.27)',
  whiteTransparent: 'rgba(255, 255, 255, 0.07)',
  whiteTransparent1: 'rgba(255, 255, 255, 0.07)',
  whiteTransparent2: 'rgba(255, 255, 255, 0.27)',

  // Navigation colors
  defaultNav: {
    primary: '#141736',
    secondary: '#232744',
    tertiary: '#323651',
    divider: '#65687C',
    activeFont: '#81B0FD',
  },

  midBlueNav: {
    primary: '#0271A4',
    secondary: '#232744',
    tertiary: '#12678E',
    divider: '#4D8DAA',
    activeFont: '#81B0FD',
  },

  greenNav: {
    primary: '#248A3D',
    secondary: '#19622B',
    tertiary: '#296D39',
    divider: '#5E916B',
    activeFont: '#6BD886',
  },

  purpleNav: {
    primary: '#3634A3',
    secondary: '#262574',
    tertiary: '#35347D',
    divider: '#67669D',
    activeFont: '#7D7BEB',
  },

  redNav: {
    primary: '#B2023A',
    secondary: '#7F0129',
    tertiary: '#881238',
    divider: '#A54D69',
    activeFont: '#FF829C',
  },

  violetNav: {
    primary: '#8944AB',
    secondary: '#61307A',
    tertiary: '#6C3E83',
    divider: '#906EA2',
    activeFont: '#CF81F7',
  },

  tealNav: {
    primary: '#008199',
    secondary: '#005C6D',
    tertiary: '#126777',
    divider: '#4D8D99',
    activeFont: '#95DFED',
  },

  lightBlueNav: {
    primary: '#4387F5',
    secondary: '#2F60AE',
    tertiary: '#3D6BB3',
    divider: '#6D90C6',
    activeFont: '#64C2F0',
  },

  info: '#d4f4e2',
  infoText: '#0cc164',

  // NEW PALETTE COLORS -----------------------------------
  purple: '#3B2A80',
  'purple-highlight': '#5f5ab9',
  grey: '#616161',
  'light-grey': '#B8B8B8',
  white: '#ffffff',

};

const palette = {
  // Symplete UI kit semantic colors
  focus: colors.blue,

  'focus-inversed': colors.malibu,
  focusInversed: colors.malibu,

  success: colors.green,
  warning: colors.orange,
  alert: colors.red,

  'alert-hover': colors.watermelon,
  alertHover: colors.watermelon,

  disabled: colors.gray,

  'under-construction': colors.serenade,
  underConstruction: colors.serenade,

  'main-text': colors['deep-blue'],
  'main-text-inversed': colors.white,
  'main-text-inverse': colors.white,
  mainText: colors['deep-blue'],
  mainTextInversed: colors.white,
  mainTextInverse: colors.white,

  'descriptor-text': colors['dark-gray'],
  descriptorText: colors['dark-gray'],

  placeholder: colors.grey,
  empty: colors.gray,

  // backgrounds
  background: colors.white,

  'background-hover': colors['lightest-gray'],
  backgroundHover: colors['lightest-gray'],

  'background-app': colors['deep-blue'],
  backgroundApp: colors['deep-blue'],

  'background-overlay-light': colors['white-transparent'],
  backgroundOverlayLight: colors['white-transparent'],

  'background-overlay-dark': colors['black-transparent-3'],
  backgroundOverlayDark: colors['black-transparent-3'],

  'background-flat': colors['bluish-gray'],
  backgroundFlat: colors['bluish-gray'],

  'background-flat-dark': colors['dark-bluish-gray'],
  backgroundFlatDark: colors['dark-bluish-gray'],

  'placeholder-background': colors['dark-bluish-grey'],
  placeholderBackground: colors['dark-bluish-grey'],

  'background-warning': colors.serenade,
  backgroundWarning: colors.serenade,

  emptyBackground: colors.bluishGray,
  backgroundEmpty: colors.bluishGray,
  //

  divider: colors.darkBluishGray,

  'divider-light': colors['white-transparent-2'],
  dividerLight: colors['white-transparent-2'],

  //
  new: colors.watermelon,
  followup: colors.violet,
  //

  // roles
  // buyer: colors.watermelon,
  buyer: colors.orange,
  // seller: colors.violet,
  seller: colors.blue,
  // 'buyer/seller': `linear-gradient(90deg, ${colors.watermelon} 0%, ${colors.violet} 100%)`,
  // buyerSeller: `linear-gradient(90deg, ${colors.watermelon} 0%, ${colors.violet} 100%)`,
  'buyer/seller': colors.green,
  buyerSeller: colors.green,
  'seller/buyer': colors.green,
  sellerBuyer: colors.green,

  'client buyer': colors.cheriseRed,
  'client seller': colors.mediumPurple,

  leadTag: colors.red,

  'past client': colors['dark-gray'],
  pastClient: colors['dark-gray'],

  vip: colors.green,

  'listing agent': colors.paleGreen,
  listingAgent: colors.paleGreen,
  //

  purchasedOpportunity: colors.turquoise,

  // property statuses
  available: colors.green,
  'under offer': colors.violet,
  'off market': colors.gray,
  'in transaction': colors.watermelon,
  sold: colors.malibu,
  //

  // property other parameters
  'my listing': colors.violet,
  myListing: colors.violet,

  featured: colors['deep-blue'],

  'off-market listing': colors['dark-gray'],
  offMarketListing: colors['dark-gray'],

  reo: colors.green,

  delimiter: colors['dark-bluish-gray'],
  'input-border': colors['dark-bluish-gray'],
  inputBorder: colors['dark-bluish-gray'],

  // Legacy semantic colors
  default: colors['deep-blue'],
  primary: colors['purple'],
  additional: colors['dark-gray'],

  ghost: colors['dark-gray'],

  link: colors.blue,
  error: colors.red,
  highlight: colors.blue,
  attention: colors.red,
  hover: colors['light-gray'],
  contrast: colors.white,
  selected: colors.green,

  money: colors.green,

  cold: colors.blue,
  warm: colors.orange,
  hot: colors.red,

  // placeholder: colors['black-transparent-2'],
  border: colors['dark-bluish-grey'],

  calendarShowings: colors['deep-blue-transparent'],
  calendarListingPitches: colors.red,
  calendarFollowUpReminders: colors.blue,
  calendarClosingEscrows: colors.violet,
  calendarContingenciesDue: colors.orange,

  // days
  weekend: colors.red,

  // contact types/roles
  agentContact: colors['dark-bluish-grey'],
  serviceContact: colors['dark-gray'],
  clientContact: colors['lime-green'],

  // likelyhood
  veryLikely: colors.green,
  likely: colors['electric-violet'],
  unlikely: colors.blue,

  avatarIcon: colors.blue,
  avatarBackground: colors.bluishGray,
  iconBackground: colors.bluishGray,

  dialButton: colors.paleGreen,
  dialButtonHover: colors.lighterPaleGreen,

  // sentiment
  positive: colors.green,
  neutral: colors.darkerGray,
  negative: colors.red,

  info: colors.info,

  'info-text': colors.infoText,
  infoText: colors.infoText,

  // progress
  progressNew: colors.picasso,
  progressInProgress: colors.pictonBlue,
  progressDone: colors.turquoise,

  // email templates
  emailTemplateSender: colors.cheriseRed,
  emailTemplateRecipient: colors.pictonBlue,
  emailTemplateMisc: colors.turquoise,
  emailTemplateProperty: colors.violet,


  // NEW PALETTE VALUES ------------------------------------------
  primary: colors['purple'],
  'primary-font-color': colors['white'],
  'primary-hovered': colors['purple-highlight'],
  'primary-disabled': colors['white'],
  'form-label-color': colors['grey'],
  'primary-disabled-font': colors['light-grey'],
  'primary-disabled-border': colors['light-grey'],

  // Navigation colors
  navColorPrimary: colors['white'],
  navColorSecondary: 'white',
  navColorTertiary: 'green',
  navColorDivider: 'blue',
  navColorActiveFont: colors['grey'],
};

const shadows = {
  modal: '0 4px 8px 2px rgba(9, 30, 66, 0.25), 0 0 1px 0 rgba(9, 30, 66, 0.31)',
  elevated: '0 2px 9px 0 rgba(9, 55, 83, 0.1)',
  button: '0 2px 9px 0 rgba(0, 0, 0, 0.1)',
  dropdown: '0 4px 8px 2px rgba(9, 55, 83, 0.1), 0 0 1px 0 rgba(9, 55, 83, 0.3)',
};

const fonts = {
  default: 'Roboto, Helvetica, Arial, sans-serif',
  primary: 'Roboto, Helvetica, Arial, sans-serif',
  secondary: 'Lato, Helvetica, Arial, sans-serif',
};

const fontWeights = {
  light: 300,
  regular: 400,
  bold: 500,
  black: 700,
};

const typography = {
  fontFamily: {
    default: fonts.default,
    primary: fonts.primary,
    secondary: fonts.secondary,

    h1: fonts.primary,
    heading: fonts.primary,

    h2: fonts.primary,
    title1: fonts.primary,

    h3: fonts.primary,
    title2: fonts.primary,
    button: fonts.primary,

    h4: fonts.primary,
    title3: fonts.primary,

    tableHeader: fonts.primary,

    h5: fonts.primary,
    title4: fonts.primary,
    caption: fonts.primary,
    caption1: fonts.primary,
    subtitle: fonts.primary,
    subtitle1: fonts.primary,

    h6: fonts.primary,
    caption2: fonts.primary,
    subtitle2: fonts.primary,

    body: fonts.primary,
    body1: fonts.primary,
    body2: fonts.primary,

    link: fonts.primary,

    tag: fonts.primary,
    tag2: fonts.primary,

    chip: fonts.primary,
    label: fonts.primary,

    cellLarge: fonts.primary,
    cell: fonts.primary,
    cell2: fonts.primary,
  },

  fontSize: {
    default: '15px',

    h1: '34px',
    heading: '34px',

    h2: '24px',
    title1: '24px',

    h3: '18px',
    title2: '18px',
    button: '18px',
    cellLarge: '18px',

    h4: '15px',
    title3: '15px',

    h5: '13px',
    title4: '13px',
    caption: '13px',
    caption1: '13px',
    subtitle: '13px',
    subtitle1: '13px',
    tableHeader: '13px',

    h6: '10px',
    caption2: '10px',
    subtitle2: '10px',

    body: '15px',
    body1: '15px',

    body2: '13px',

    link: '15px',
    cell: '15px',

    label: '13px',
    tag: '13px',

    cell2: '12px',

    tag2: '10px',
    chip: '13px',
  },

  lineHeight: {
    default: '15px',

    h1: '37px',
    heading: '37px',

    h2: '26px',
    title1: '26px',

    h3: '24px',
    title2: '24px',

    h4: '22px',
    title3: '22px',
    button: '22px',
    tableHeader: '22px',

    h5: '15px',
    title4: '15px',
    caption: '15px',
    caption1: '15px',
    subtitle: '15px',
    subtitle1: '15px',

    h6: '12px',
    caption2: '15px',
    subtitle2: '12px',

    body: '17px',
    body1: '17px',

    body2: '15px',

    cellLarge: '20px',

    link: '17px',
    cell: '17px',

    label: '15px',
    tag: '15px',

    cell2: '13px',

    tag2: '11px',
    chip: '15px',
  },

  fontWeight: {
    default: fontWeights.regular,

    h1: fontWeights.bold,
    heading: fontWeights.bold,

    h2: fontWeights.bold,
    title1: fontWeights.bold,

    h3: fontWeights.bold,
    title2: fontWeights.bold,

    h4: fontWeights.regular,
    title3: fontWeights.regular,
    button: fontWeights.regular,

    h5: fontWeights.regular,
    title4: fontWeights.regular,
    caption: fontWeights.regular,
    caption1: fontWeights.regular,
    subtitle: fontWeights.regular,
    subtitle1: fontWeights.regular,

    tableHeader: fontWeights.bold,

    h6: fontWeights.regular,
    caption2: fontWeights.regular,
    subtitle2: fontWeights.regular,

    body: fontWeights.regular,
    body1: fontWeights.regular,
    body2: fontWeights.regular,

    cellLarge: fontWeights.regular,
    cell: fontWeights.regular,
    cell2: fontWeights.regular,

    link: fontWeights.regular,
    label: fontWeights.regular,

    tag: fontWeights.bold,
    tag2: fontWeights.bold,

    chip: fontWeights.regular,
  },

  marginBottom: {
    default: '11px',

    h1: '20px',
    heading: '20px',
    h2: '18px',
    title1: '18px',
    h3: '16px',
    title2: '16px',
    button: '0',
    h4: '10px',
    title3: '10px',
    h5: '12px',
    title4: '12px',
    caption: '12px',
    caption1: '12px',
    subtitle: '12px',
    subtitle1: '12px',
    h6: '11px',
    caption2: '11px',
    subtitle2: '11px',

    body: '11px',
    body1: '11px',
    body2: '8px',

    label: '11px',
    link: '11px',

    cellLarge: '0',
    cell: '0',
    cell2: '0',

    tag: '0',
    tag2: '0',
    chip: '0',
  },

  textDecoration: {
    default: 'none',
    link: 'underline',
  },

  fontStyle: {
    default: 'normal',
  },

};

const iconsType = 'feather';

const iconSizes = {
  xxxs: 8,
  '3xs': 8,

  xxs: 12,
  '2xs': 12,

  xs: 16,

  sm: 20,
  s: 20,

  m: 24,
  md: 24,

  l: 28,
  lg: 28,

  xl: 32,

  xxl: 64,
  '2xl': 64,
};

export const theme = {
  colors,
  palette,
  shadows,
  fonts,
  fontWeights,
  typography,
  iconsType,
  iconSizes,
};

export default theme;
