/* eslint-disable */
// Exposit
// WHOLE FILE IGNORED, TOO MANY PRE-EXISTING ERRORS
// TODO - fix lint errors

import moment from 'moment'
import { currency } from 'refactored/utils/numbers';

import { callAlert } from 'ui/Alert';
import 'antd/es/message/style/index';
import { apiConnector } from 'shared/API';

import { sales as SALES_CONSTANT } from 'constants';

export const SET_LEAD_COMPANIES = 'SET_LEAD_COMPANIES';
export const SET_LEAD_TYPES = 'SET_LEAD_TYPES';
export const SET_LEAD_STATUSES = 'SET_LEAD_STATUSES';

export const LEADS_GET_LIST_SUCCESS = 'LEADS_GET_LIST_SUCCESS';
export const LEADS_GET_LIST_START = 'LEADS_GET_LIST_START';
export const LEADS_GET_LIST_ERROR = 'LEADS_GET_LIST_ERROR';

export const LEADS_LIST_CLEAR = 'LEADS_LIST_CLEAR';

export const SHOW_NEW_LEAD_MODAL = 'SHOW_NEW_LEAD_MODAL';
export const SHOW_PITCH_MODAL = 'SHOW_PITCH_MODAL';
export const SHOW_COMPANY_DETAILS_MODAL = 'SHOW_COMPANY_DETAILS_MODAL';
export const SHOW_LOGGING_CONTACT_DETAILS_MODAL = 'SHOW_LOGGING_CONTACT_DETAILS_MODAL';
export const SHOW_CONTACTS_OVERVIEW_MODAL = 'SHOW_CONTACTS_OVERVIEW_MODAL';

export const LEADS_GET_DETAILS = 'LEADS_GET_DETAILS';

export const LEADS_COMPANY_UPDATE = 'LEADS_COMPANY_UPDATE';
export const LEADS_CONTACT_UPDATE = 'LEADS_CONTACT_UPDATE';

export const LEADS_SET_ASSIGNEE_START = 'LEADS_SET_ASSIGNEE_START';
export const LEADS_SET_ASSIGNEE_SUCCESS = 'LEADS_SET_ASSIGNEE_SUCCESS';
export const LEADS_SET_ASSIGNEE_ERROR = 'LEADS_SET_ASSIGNEE_ERROR';

export const CHANGE_SAVED_STATUS = 'CHANGE_SAVED_STATUS';
export const CLEAR_SAVED_STATUS = 'CLEAR_SAVED_STATUS';

export const GET_LEADS_DASHBOARD_INFORMATION = 'GET_LEADS_DASHBOARD_INFORMATION';

export const ADD_TUTORIAL_LEAD = 'ADD_TUTORIAL_LEAD';
export const UPDATE_LEAD = 'UPDATE_LEAD';

//util function
const getProblemManagerLeadsCount = (allManagerLead, leadsProblemsComments) => {
  let problemManagerLeadsCount = 0
  allManagerLead.forEach((verifiableLead) => {
    let isProblemManagerLead = false;
    leadsProblemsComments.forEach((comment) => {
      if (Number(comment.CommentedObjectID) === verifiableLead.LeadID) {
        isProblemManagerLead = true;
      }
    });
    if (isProblemManagerLead) {
      problemManagerLeadsCount += 1;
    }
  });
  return problemManagerLeadsCount
}
const getAllOtherWidgetInformation = (leadsList, allManagerLead, leadsProblemsComments, EmployeeID) => {
  const {
    NEW_ANALYSIS,
    PITCH,
    NEGOTIATE,
    CLOSING,
    WON,
    LOST,
    ARCHIVED,
  } = SALES_CONSTANT.LEAD_STATUS_ID;


  let likelyCloseLeadsCost = 0;
  let likelyCloseLeadsCount = 0;
  let newAnalysisStatusLeadsCount = 0;
  let problemLeadsCount = 0;
  let totalDealsCount = 0;
  let unassignedLeadsCount = 0;
  let totalDealsManagerCount = 0;
  const hightLevelConfidence = 80;
  const forbiddenLeadStatusID = [CLOSING, WON, LOST, ARCHIVED];
  const allowedLeadStatusID = [NEW_ANALYSIS, PITCH, NEGOTIATE];


  leadsList.forEach((element) => {
    let isInvitedInLead = false;
    let isProblemLead = false;
    allManagerLead.forEach((managerLead) => {
      if ((managerLead.LeadID === element.LeadID)
        && (!forbiddenLeadStatusID.includes(element.LeadStatusID))) {
        totalDealsManagerCount += 1;
      }
      if (managerLead.LeadID === element.LeadID && element.Employees.length === 0) {
        unassignedLeadsCount += 1;
      }
    });
    element.Employees.forEach((employee) => {
      if (employee.EmployeeID === EmployeeID) {
        isInvitedInLead = true;
      }
    });
    if (isInvitedInLead) {
      leadsProblemsComments.forEach((comment) => {
        if (Number(comment.CommentedObjectID) === element.LeadID) {
          isProblemLead = true;
        }
      });
      if (isProblemLead) {
        problemLeadsCount += 1;
      }
      if (element.Confidence >= hightLevelConfidence) {
        likelyCloseLeadsCost += element.Value;
        likelyCloseLeadsCount += 1;
      }
      if (element.LeadStatusID === 1) {
        newAnalysisStatusLeadsCount += 1;
      }
      if (allowedLeadStatusID.includes(element.LeadStatusID)) {
        totalDealsCount += 1;
      }
    }
  });
  return {
    totalDealsManagerCount,
    likelyCloseLeadsCost,
    likelyCloseLeadsCount,
    newAnalysisStatusLeadsCount,
    problemLeadsCount,
    totalDealsCount,
    unassignedLeadsCount,
  }
}
//

export const setLeadWonLostStatus = (data = {}) => async (dispatch) => {
  try {
    const { result } = await apiConnector(
      '/api/leads/setLeadWonLost',
      'POST', {
      ...data,
    },
    );

    dispatch({
      type: LEADS_GET_DETAILS,
      result,
    });
  } catch (e) {
    callAlert.error('e: ', e.message);
  }
};

export const onPitchProgressChange = (LeadID, statusesHistoryRecord, leadStatusID, comment = '') => async (dispatch) => {
  /* todo: write start/end actions */

  try {
    const { result } = await apiConnector(
      '/api/leads/changePitchProgress',
      'POST', {
      LeadID,
      statusesHistoryRecord,
      leadStatusID,
      comment,
    },
    );

    dispatch({
      type: LEADS_GET_DETAILS,
      result,
    });
  } catch (e) {
    console.error(e.message);
  }
};

export const addLeadOffer = (offer) => async (dispatch) => {
  await apiConnector(
    '/api/offerDetails/add',
    'POST', {
    ...offer,
  },
  );
};

export const updateLeadOffer = (leadOfferDetailsArray, leadTitle, LeadID) => async (dispatch) => {
  const { result } = await apiConnector(
    '/api/offerDetails/update',
    'POST',
    {
      leadOfferDetailsArray,
      leadTitle,
      LeadID,
    },
  );

  dispatch({
    type: LEADS_GET_DETAILS,
    result,
  });
};

const getLeadsSuccess = (leads) => ({
  result: leads,
  type: LEADS_GET_LIST_SUCCESS,
});

export const setAssignee = (leadId, employeeId) => async (dispatch, getState) => {
  dispatch({
    type: LEADS_SET_ASSIGNEE_START,
  });

  try {
    dispatch({
      type: LEADS_SET_ASSIGNEE_SUCCESS,
    });

    const { Name = '', Surname = '' } = getState().auth.loggedUser;
    const userFullName = `${Name} ${Surname}`;

    const { result } = await apiConnector(
      '/api/leads/setAssignee',
      'POST', {
      EmployeesIDs: [employeeId],
      leadId,
      userFullName,
    },
    );

    dispatch(getLeadsSuccess(result));

    const pitch = result.find((lead) => lead.LeadID.toString() === leadId);
    if (pitch) {
      callAlert.success(`You have been assigned ${pitch.Title}. Good luck!`);
    }
  } catch (e) {
    dispatch({
      type: LEADS_SET_ASSIGNEE_ERROR,
    });
  }
};



export const getLeadDashboardInf = () => async (dispatch, getState) => {
  const {
    auth: {
      loggedUser: {
        EmployeeID,
      }
    },
    leads: {
      leadsList,
      userMeetingsToday,
    },
  } = getState();

  const countUserMeetingsToday = userMeetingsToday.length

  const result = await apiConnector(
    '/api/leads/get-lead-dashboard-information',
    'POST',
    {
      EmployeeID
    }
  );

  const {
    leadsProblemsComments,
    likelyCloseLeadsSumValue,
    allManagerLead,
  } = result

  const minimalNotValidateCost = 9999;

  const problemManagerLeadsCount = getProblemManagerLeadsCount(allManagerLead, leadsProblemsComments);


  const allOtherInformation = getAllOtherWidgetInformation(leadsList, allManagerLead, leadsProblemsComments, EmployeeID)

  const {
    totalDealsManagerCount,
    likelyCloseLeadsCost,
    likelyCloseLeadsCount,
    newAnalysisStatusLeadsCount,
    problemLeadsCount,
    totalDealsCount,
    unassignedLeadsCount,
  } = allOtherInformation

  const otherInformation = [
    {
      value: countUserMeetingsToday,
      description: 'Meetings Today',
      managerViewValue: likelyCloseLeadsSumValue > minimalNotValidateCost
        ? currency.short(likelyCloseLeadsSumValue)
        : `$${likelyCloseLeadsSumValue === null ? 0 : likelyCloseLeadsSumValue}`,
      managerViewDescription: 'Likely To Close',
    },
    {
      value: newAnalysisStatusLeadsCount,
      description: 'New Analysis',
      managerViewValue: unassignedLeadsCount,
      managerViewDescription: 'Unassigned Leads',
    },
    {
      value: problemLeadsCount,
      description: 'Problems',
      managerViewValue: problemManagerLeadsCount,
      managerViewDescription: 'Problems',
    },
    {
      value: totalDealsCount,
      description: 'Total Deals',
      managerViewValue: totalDealsManagerCount,
      managerViewDescription: 'Total Deals',
    },
  ];

  dispatch({
    type: GET_LEADS_DASHBOARD_INFORMATION,
    allLeadTeamCount: result.allLeadTeamCount,
    revenueWonValueThisMonth: result.revenueWonValueThisMonth,
    salesWidgetOtherInformation: otherInformation,
    likelyCloseLeadsCost: likelyCloseLeadsCost,
    likelyCloseLeadsCount: likelyCloseLeadsCount,
  })
}

export const getLeadsList = () => async (dispatch) => {
  dispatch({
    type: LEADS_GET_LIST_START,
  });

  try {
    const { result } = await apiConnector(
      '/api/leads/all',
      'GET',
    );

    dispatch(getLeadsSuccess(result));
  } catch (e) {
    callAlert.error(`Get leads: ${e.message}`);
    dispatch({
      type: LEADS_GET_LIST_ERROR,
    });
  }
};

export const clearLeadsList = () => ({
  type: LEADS_LIST_CLEAR,
});

export const getLeadCompaniesSuccess = (companies) => (dispatch) => {
  dispatch({
    type: SET_LEAD_COMPANIES,
    companies,
  });
};

export const getLeadCompanies = () => async (dispatch) => {
  try {
    apiConnector(
      '/api/leads/get-companies',
      'GET',
    )
      .then((res) => {
        dispatch(getLeadCompaniesSuccess(res.result));
      });
  } catch {
  }
};

export const NEW_LEAD_ALERT_SHOW_ACTION = 'NEW_LEAD_ALERT_SHOW_ACTION'
export const newLeadShowAlert = (newLeadAssigned) => {
  return {
    type: NEW_LEAD_ALERT_SHOW_ACTION,
    newLeadAssigned: newLeadAssigned,
  }
}

export const NEW_LEAD_ALERT_HIDE_ACTION = 'NEW_LEAD_ALERT_HIDE_ACTION'
export const newLeadHideAlert = () => ({
  type: NEW_LEAD_ALERT_HIDE_ACTION
})

export const ADD_NEW_LEAD = 'ADD_NEW_LEAD';
export const addNewLead = (
  values,
) => async (dispatch) => {
  try {
    return apiConnector('/api/leads/create', 'POST', {
      values,
    }).then((res) => {
      if (!res.result.CompanyID) {
        dispatch(onCompanyInfoChange({
          name: 'Title',
          value: res.result.CompanyName,
          leadId: res.result.LeadID,
        }));
      }
      dispatch(getLeadsList());
      dispatch(newLeadShowAlert(res.result.Employees))
      setTimeout(() => dispatch(newLeadHideAlert()), 6000)
      return res?.result?.LeadID;
    });
  } catch (e) {
    callAlert.error(e.message);
  }
};

export const handleNewLeadModal = (visible = false) => ({
  type: SHOW_NEW_LEAD_MODAL,
  visible,
});

export const handlePitchDetailsModal = (visible = false, LeadID = 0) => async (dispatch) => {
  const showModal = () => dispatch({
    type: SHOW_PITCH_MODAL,
    LeadID,
    visible,
  });

  if (LeadID === 0) {
    // No need to prepare data because no lead is specified. Just show modal.
    showModal();
    return;
  }

  try {
    apiConnector(
      '/api/leads/get-pitch-details',
      'POST', {
      LeadID,
    },
    )
      .then(({ result = {} }) => {
        // Prepare data before actually showing the modal.
        dispatch({
          type: LEADS_GET_DETAILS,
          result,
        });
        showModal();
      });
  } catch (e) {
    callAlert.error(`Get leads: ${e.message}`);
    dispatch({
      type: LEADS_GET_LIST_ERROR,
    });
  }
};

export const resolveLeadDetails = (LeadID = 0) => async (dispatch) => {
  if (LeadID !== 0) {
    try {
      apiConnector(
        '/api/leads/get-pitch-details',
        'POST',
        {
          LeadID,
        },
      )
        .then(({ result = {} }) => {
          dispatch({
            type: LEADS_GET_DETAILS,
            result,
          });
        });
    } catch (e) {
      callAlert.error(`Get leads: ${e.message}`);
      dispatch({
        type: LEADS_GET_LIST_ERROR,
      });
    }
  }
};

export const handleLoggingContactModal = (visible = false, contactLogId = 0) => ({
  type: SHOW_LOGGING_CONTACT_DETAILS_MODAL,
  contactLogId,
  visible,
});

export const handleContactsOverviewModal = (visible = false) => ({
  type: SHOW_CONTACTS_OVERVIEW_MODAL,
  visible,
});


export const getLeadTypesSuccess = (leadTypes) => (dispatch) => {
  dispatch({
    type: SET_LEAD_TYPES,
    leadTypes,
  });
};

export const getLeadTypes = () => async (dispatch) => {
  try {
    apiConnector(
      '/api/leads/get-types',
      'GET',
    )
      .then((res) => {
        dispatch(getLeadTypesSuccess(res.result));
      });
  } catch {
  }
};

export const getLeadStatusesSuccess = (leadStatuses) => (dispatch) => {
  dispatch({
    type: SET_LEAD_STATUSES,
    leadStatuses,
  });
};

export const getLeadStatuses = () => async (dispatch) => {
  try {
    apiConnector(
      '/api/leads/get-statuses',
      'GET',
    )
      .then((res) => {
        dispatch(getLeadStatusesSuccess(res.result));
      });
  } catch {
  }
};

export const setLeadStatus = (LeadID, LeadStatusID) => async (dispatch) => {
  try {
    await apiConnector(
      '/api/leads/set-statuses',
      'POST', {
      LeadStatusID,
      LeadID,
    },
    )

    dispatch(updateLeadLocal(LeadID)({ LeadStatusID }))
    // .then(({ result }) => {
    //   dispatch({
    //     type: LEADS_GET_LIST_SUCCESS,
    //     result,
    //   });
    // });
  } catch (e) {
    callAlert.error(`Get leads: ${e.message}`);
    dispatch({
      type: LEADS_GET_LIST_ERROR,
    });
  }
};

export const updateLeadLocal = (leadId) => (leadParams) => ({
  type: UPDATE_LEAD,
  payload: {
    leadId,
    leadParams,
  }
})

export const clearSavedStatusAction = () => ({
  type: CLEAR_SAVED_STATUS,
})

export const setSavedStatusAction = (data) => ({
  type: CHANGE_SAVED_STATUS,
  payload: data,
})

export const onCompanyInfoChange = ({
  name, value, CompanyID = 0, ContactID = 0, leadId = 0,
}, onCreated) => async (dispatch, getState) => {
  if (!name) return null;

  const LeadID = leadId ? leadId : getState().leads.LeadID;

  try {
    dispatch(setSavedStatusAction('saving...'))
    apiConnector(
      '/api/leads/update-company',
      'POST',
      {
        name,
        LeadID,
        CompanyID,
        value,
        ContactID,
      },
    )
      .then(({ result }) => {
        setTimeout(() => dispatch(setSavedStatusAction('changes saved.')), 500)
        const {
          leadDetails,
          company,
        } = result;

        dispatch({
          type: LEADS_COMPANY_UPDATE,
          result: { leadDetails },
        });

        if (onCreated && company) {
          onCreated(company.CompanyID);
        }

        setTimeout(() => dispatch(clearSavedStatusAction()), 5000)
      })
  } catch (e) {
    callAlert.error('Error updating company');
    dispatch({
      type: LEADS_GET_LIST_ERROR,
    });
    dispatch(clearSavedStatusAction())
  }
};

export const handleCompanyLocationUpdate = (payload, onCreated) => async (dispatch) => {
  const endpoint = '/api/leads/update-company-location'

  try {
    const response = await apiConnector(
      endpoint,
      'POST',
      payload,
    )

    const {
      result
    } = response

    const {
      CompanyID
    } = result

    if (onCreated && CompanyID) {
      onCreated(CompanyID);
    }
  }
  catch (error) {
    callAlert.error('Error updating company location');
  }
};

export const onContactInfoChange = ({
  name, value, SalesContactsID = 0, forceDecisionMaker = false, CompanyID = 0,
}, onCreated) => (dispatch, getState) => {
  if (!name) return null;

  const { LeadID } = getState().leads;

  apiConnector(
    '/api/leads/update-contact',
    'POST',
    {
      name,
      LeadID,
      SalesContactsID,
      value,
      forceDecisionMaker,
      CompanyID,
    },
  )
    .then(({ result }) => {
      const {
        leadDetails = {},
        contact,
      } = result;
      dispatch({
        type: LEADS_CONTACT_UPDATE,
        result: { leadDetails },
      });

      if (onCreated && contact) {
        onCreated(contact.SalesContactsID);
      }
    }).catch(() => {
      callAlert.error('Error updating contact');
    });
};

export const handleUnbindContact = (LeadID, SalesContactsID) => (dispatch) => {
  apiConnector(
    '/api/leads/unbind-contact',
    'POST',
    {
      LeadID,
      SalesContactsID,
    },
  )
    .then(({ result }) => {
      dispatch({
        type: LEADS_CONTACT_UPDATE,
        result,
      });
    }).catch((e) => {
      callAlert.error('Error unbinding contact :', e.message);
    });
};

export const updateLead = (LeadID, name, value, comment) => (dispatch) => {
  apiConnector(
    '/api/leads/update-lead',
    'POST',
    {
      LeadID,
      name,
      value,
      comment,
    },
  )
    .then(({ result }) => {
      dispatch({
        type: LEADS_GET_DETAILS,
        result,
      });
    })
    .catch((e) => {
      callAlert.error(`Get leads: ${e.message}`);
      dispatch({
        type: LEADS_GET_LIST_ERROR,
      });
    });
};

export const updateLeadDecisions = (LeadID, link, decisions) => (dispatch) => {
  apiConnector(
    '/api/leads/update-lead-decisions',
    'POST', {
    LeadID,
    link,
    decisions,
  },
  )
    .then(({ result }) => {
      dispatch({
        type: LEADS_GET_DETAILS,
        result,
      });
    })
    .catch((e) => {
      callAlert.error(`Get leads: ${e.message}`);
      dispatch({
        type: LEADS_GET_LIST_ERROR,
      });
    });
};

export const updateLeadDocument = (payload) => async (dispatch) => {
  try {
    const {
      LeadsDocumentsID, title, description, isLatest, LeadID,
    } = payload;
    apiConnector(
      '/api/leads/update-lead-doc',
      'POST',
      {
        LeadsDocumentsID,
        title,
        description,
        isLatest,
        LeadID,
      },
    )
      .then(() => {
        dispatch(resolveLeadDetails(LeadID));
      });
  } catch (e) {
    callAlert.error(`Get leads: ${e.message}`);
    dispatch({
      type: LEADS_GET_LIST_ERROR,
    });
  }
};

// update lead stuff
export const updateLeadUsers = (LeadID, Employees = []) => async (dispatch) => {
  try {
    apiConnector(
      '/api/leads/update-users',
      'PUT', {
      LeadID,
      Employees,
    },
    )
      .then(({ result }) => {
        dispatch({
          type: LEADS_GET_DETAILS,
          result,
        });
        dispatch(getLeadsList());
      });
  } catch (e) {
    callAlert.error(`Get leads: ${e.message}`);
    dispatch({
      type: LEADS_GET_LIST_ERROR,
    });
  }
};

export const UPDATE_CONTACT_LOG = 'UPDATE_CONTACT_LOG';

export const updateContactLog = (data) => async (dispatch) => {
  try {
    apiConnector(
      '/api/leads/update-contactlogs',
      'POST', {
      ID: data.id,
      EmployeeID: data.employeeId,
      Duration: data.duration,
      CreationTime: data.creationTime,
      Notes: data.notes,
      Type: 'lead',
      ContactType: data.contactType,
      Sentimait: data.sentimait,
      LeadID: data.leadId,
      Title: data.title,
      IsAcknowledged: data.isAcknowledged,
      AcknowledgedUserID: data.acknowledgedUserId,
    },
    )
      .then(({ result }) => {
        dispatch(getContactLogs(data.leadId));
      });
  } catch (e) {
    callAlert.error(`updateContactLog: ${e.message}`);
  }
};

export const DELETE_CONTACT_LOG = 'DELETE_CONTACT_LOG';

export const deleteContactLog = (data) => async (dispatch) => {
  try {
    apiConnector(
      '/api/leads/delete-contactlogs',
      'POST', {
      ID: data.id,
      LeadID: data.LeadID,
      Title: data.Title,
    },
    )
      .then(({ result }) => {
        dispatch(getContactLogs(data.LeadID));
      });
  } catch (e) {
    callAlert.error(`deleteContactLog: ${e.message}`);
  }
};

export const GET_CONTACT_LOGS = 'GET_CONTACT_LOGS';

export const getContactLogs = (leadId) => async (dispatch) => {
  try {
    apiConnector(
      `/api/leads/get-contactslogs/${leadId}`,
      'GET',
    )
      .then(({ result }) => {
        dispatch({
          type: GET_CONTACT_LOGS,
          log: result,
        });
      });
  } catch (e) {
    callAlert.error(`getContactLogs: ${e.message}`);
  }
};

export const UPDATE_LEAD_MEETING = 'UPDATE_LEAD_MEETING';
export const updateLeadMeeting = (payload, cb) => (dispatch) => {
  apiConnector(
    '/api/leads/meetings/update',
    'POST',
    payload,
  )
    .then(({ result }) => {
      dispatch({
        type: UPDATE_LEAD_MEETING,
        result,
      });
      callAlert.success('Meeting successfully created');
      if (cb) {
        cb();
      }
    })
    .catch((e) => {
      callAlert.error('Error update meeting');
    });
};

export const SET_USER_MEETINGS = 'SET_USER_MEETINGS';
export const SET_WEEK_USER_MEETINGS = 'SET_WEEK_USER_MEETINGS';

export const getAllUserMeetings = (DateStart = null, DateEnd = null) => (dispatch, getState) => {
  const {
    auth: {
      loggedUser: {
        EmployeeID,
      },
    },
  } = getState();

  apiConnector(
    '/api/leads/meetings/getAll',
    'POST',
    {
      EmployeeID,
      DateStart,
      DateEnd,
    },
  )
    .then(({ result }) => {
      if (DateStart && DateEnd) {
        // range
        dispatch({
          type: SET_USER_MEETINGS,
          result,
        });
      } else {
        // week
        dispatch({
          type: SET_WEEK_USER_MEETINGS,
          result,
        });
      }
    })
    .catch((e) => {
      callAlert.error('Error getting meetings');
    });
};

export const SET_DAY_USER_MEETINGS = 'SET_DAY_USER_MEETINGS';
export const getTodayUserMeetings = (DateStart = moment().startOf('day'), DateEnd = moment().endOf('day')) => async (dispatch, getState) => {
  try {
    const {
      auth: {
        loggedUser: {
          EmployeeID,
        },
      },
    } = getState();

    const response = await apiConnector(
      '/api/leads/meetings/getAll',
      'POST',
      {
        EmployeeID,
        DateStart,
        DateEnd,
      },
    )

    dispatch({
      type: SET_DAY_USER_MEETINGS,
      result: response.result,
    });
  } catch (error) {
    callAlert.error('Error getting meetings');
  }
};

export const SET_SALESPERSON_ID = 'SET_SALESPERSON_ID';

export const selectSalesPerson = (id, fullName) => async (dispatch) => {
  dispatch({
    type: SET_SALESPERSON_ID,
    payload: {
      id,
      fullName,
    },
  });
};


export const deleteLeadMeeting = (MeetingID, LeadID) => (dispatch) => {
  apiConnector(
    '/api/leads/meetings/delete',
    'POST',
    {
      MeetingID,
      LeadID,
    },
  )
    .then(({ result }) => {
      dispatch({
        type: UPDATE_LEAD_MEETING,
        result,
      });
      callAlert.success('Meeting successfully deleted');
    }).catch((e) => {
      callAlert.error('Error delete meeting');
    });
};


export const toggleMeetingRemind = (LeadID, joinTableID, Remind) => (dispatch) => {
  apiConnector(
    '/api/leads/meetings/remind',
    'POST',
    {
      joinTableID,
      Remind,
      LeadID,
    },
  )
    .then(({ result }) => {
      dispatch({
        type: UPDATE_LEAD_MEETING,
        result,
      });
      callAlert.success('Reminder update successfully');
    })
    .catch((e) => {
      callAlert.error('Reminder not updated');
    });
};

export const addTutorialLead = () => ({
  type: ADD_TUTORIAL_LEAD,
});
