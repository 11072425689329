import { useState, useEffect } from 'react';
import requests from 'api/requests';
import { last } from 'shared/utility';
import useIntegrations from 'sections/Integrations/hooks/useIntegrations';
import removeDuplicates from 'refactored/utils/removeDuplicates';

const useThreads = () => {
    const { integrationsByScope } = useIntegrations();
    const [tokenId, setTokenId] = useState(null);
    const [filters, setFilters] = useState({
        limit: 10,
        offset: 0,
        mailbox: 'inbox',
        search: null
    });
    const [threads, setThreads] = useState({
        closed: [],
        deleted: [],
        inProgress: [],
        read: [],
        sent: [],
        unread: [],
        count: 0
    });
    const [threadsMetrics, setThreadsMetrics] = useState({
        new: { icon: '🚨', count: 0, label: 'New' },
        sent: { icon: '📤', count: 0, label: 'Sent' },
        deleted: { icon: '🗃️', count: 0, label: 'Deleted' }
    });
    const [threadsTypesMetrics, setThreadsTypesMetrics] = useState({
        business: { icon: '💼', count: 0, label: 'Business' },
        personal: { icon: '🌴', count: 0, label: 'Personal' }
    });
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const [readedEmails, setReadedEmails] = useState([]);
    const [unreadedEmails, setUnreadedEmails] = useState([]);
    const [inProgressEmails, setInProgressEmails] = useState([]);
    const [sentEmails, setSentEmails] = useState([]);
    const [businessEmails, setBusinessEmails] = useState([]);
    const [personalEmails, setPersonalEmails] = useState([]);
    const [closedEmails, setClosedEmails] = useState([]);
    const [deletedEmails, setDeletedEmails] = useState([]);
    const [emailRowCount, setEmailRowCount] = useState(10);

    const showTenMore = () => {
        setFilters((prev) => ({
            ...prev,
            limit: prev.limit + 10,
            offset: prev.offset + 10,
        }));
    };

    const getThreads = async (mailFilters) => {
        setIsLoading(true);
        setError(null);

        try {
            const response = await requests.integrations.emails.threads(mailFilters);

            setThreads(prev => ({
                ...prev,
                closed: [...(prev.closed || []), ...(response?.data?.threads?.closed || [])],
                deleted: [...(response?.data?.threads?.deleted || [])],
                inProgress: [...(prev.inProgress || []), ...(response?.data?.threads?.inProgress || [])],
                read: [...(prev.read || []), ...(response?.data?.threads?.read || [])],
                sent: [...(response?.data?.threads?.sent || [])],
                unread: [...(prev.unread || []), ...(response?.data?.threads?.unread || [])],
                count: prev.count + response?.data?.count,
                prevUnreadCount: prev.unread.length,
                prevReadCount: prev.read.length,
                prevInProgressCount: prev.inProgress.length,
                prevClosedCount: prev.closed.length
            }));
        } catch (newError) {
            setError(newError);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchMessages = async (threadList, targetState) => {
        const loadingList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => ({ isLoading: true }));
        targetState((prevMessages) => [...prevMessages.filter((prevMsg) => !prevMsg.isLoading), ...loadingList]);

        const promises = threadList.map(async (thread) => {
            const messageId = last(thread?.message_ids);
            const response = await requests.integrations.emails.message(messageId)(tokenId);
            return response.data?.message;
        });

        try {
            const results = await Promise.all(promises);
            results.sort((a, b) => b.clean_sympleteAnalysis.importance - a.clean_sympleteAnalysis.importance);

            setBusinessAndPersonalEmails(results);

            targetState((prevMessages) => [
                ...prevMessages.filter((prevMsg) => !prevMsg.isLoading),
                ...results.filter((newMsg) => !prevMessages.some((prevMsg) => prevMsg.id === newMsg.id)),
            ]);
        } catch (error) {
            console.error("Error fetching emails:", error);
        }
    };

    const setBusinessAndPersonalEmails = (emails) => {
        let tempBusinessEmails = []
        let tempPersonalEmails = []
        emails.forEach((email) => {
            const emailType = email.clean_sympleteAnalysis.type
            if (emailType === "Business" || emailType === "Both") {
                tempBusinessEmails.push(email)
            };
            if (emailType === "Personal" || emailType === "Both") {
                tempPersonalEmails.push(email)
            };
        })

        setBusinessEmails((prev) => {
            const uniqueBusinessEmails = removeDuplicates([...prev, ...tempBusinessEmails], 'id')
            const businessEmailsSorted = uniqueBusinessEmails.sort((a, b) => b.clean_sympleteAnalysis.date - a.clean_sympleteAnalysis.date);
            return businessEmailsSorted
        });
        setPersonalEmails((prev) => {
            const uniquePersonalEmails = removeDuplicates([...prev, ...tempPersonalEmails], 'id')
            const personalEmailsSorted = uniquePersonalEmails.sort((a, b) => b.clean_sympleteAnalysis.date - a.clean_sympleteAnalysis.date);
            return personalEmailsSorted
        });
    }

    const sendThreadToClosed = (thread) => {
        const emailToMove = [
            ...readedEmails,
            ...unreadedEmails,
            ...inProgressEmails,
            ...sentEmails,
            ...businessEmails,
            ...personalEmails
        ].find(email => email.thread_id === thread.id);
        const updatedReadedEmails = readedEmails.filter(email => email.thread_id !== thread.id);
        setReadedEmails(updatedReadedEmails);
        const updatedUnreadedEmails = unreadedEmails.filter(email => email.thread_id !== thread.id);
        setUnreadedEmails(updatedUnreadedEmails);
        const updatedInProgressEmails = inProgressEmails.filter(email => email.thread_id !== thread.id);
        setInProgressEmails(updatedInProgressEmails);
        const updatedSentEmails = sentEmails.filter(email => email.thread_id !== thread.id);
        setSentEmails(updatedSentEmails);
        const updatedBusinessEmails = businessEmails.filter(email => email.thread_id !== thread.id);
        setBusinessEmails(updatedBusinessEmails);
        const updatedPersonalEmails = personalEmails.filter(email => email.thread_id !== thread.id);
        setPersonalEmails(updatedPersonalEmails);
        if (emailToMove) setClosedEmails(prevClosedEmails => [...prevClosedEmails, emailToMove]);
    }

    const deleteEmailFromThread = (email) => {
        for (let threadKey in threads) {
            let thread = threads[threadKey];
            for (let i = 0; i < thread.length; i++) {
                let threadMessageIds = thread[i].message_ids;
                const index = threadMessageIds.indexOf(email.id);
                if (index !== -1) {
                    threadMessageIds.splice(index, 1);
                    break;
                }
            }
        }
    };

    useEffect(() => {
        if (!(tokenId && threads)) return
        const readedThreads = threads.read.slice(threads.prevReadCount) || [];
        const unreadedThreads = threads.unread.slice(threads.prevUnreadCount) || [];
        const inProgressThreads = threads.inProgress.slice(threads.prevInProgressCount) || [];
        const closedThreads = threads.closed.slice(threads.prevClosedCount) || [];
        const sentThreads = threads.sent || [];
        const deletedThreads = threads.deleted || [];

        fetchMessages(readedThreads, setReadedEmails);
        fetchMessages(unreadedThreads, setUnreadedEmails);
        fetchMessages(inProgressThreads, setInProgressEmails);
        fetchMessages(closedThreads, setClosedEmails);
        fetchMessages(sentThreads, setSentEmails);
        fetchMessages(deletedThreads, setDeletedEmails);

    }, [tokenId, threads]);

    useEffect(() => {
        if (!tokenId) return
        getThreads({ ...filters, tokenId });

        const loadingList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => ({ isLoading: true }));
        setReadedEmails((prevMessages) => [...prevMessages.filter((prevMsg) => !prevMsg.isLoading), ...loadingList]);
        setUnreadedEmails((prevMessages) => [...prevMessages.filter((prevMsg) => !prevMsg.isLoading), ...loadingList]);
        setInProgressEmails((prevMessages) => [...prevMessages.filter((prevMsg) => !prevMsg.isLoading), ...loadingList]);
        setClosedEmails((prevMessages) => [...prevMessages.filter((prevMsg) => !prevMsg.isLoading), ...loadingList]);

    }, [tokenId, filters]);

    useEffect(() => {
        if (!integrationsByScope?.email) return
        setTokenId(integrationsByScope.email[0]?.id || null);
    }, [integrationsByScope]);

    useEffect(() => {
        setThreadsTypesMetrics({
            ...threadsTypesMetrics,
            business: {
                ...threadsTypesMetrics.business,
                count: businessEmails.length
            },
            personal: {
                ...threadsTypesMetrics.personal,
                count: personalEmails.length
            }
        })
    }, [businessEmails, personalEmails])

    useEffect(() => {
        setThreadsMetrics((prev) => {
            const newCount =
                (readedEmails.length || 0) +
                (unreadedEmails.length || 0) +
                (inProgressEmails.length || 0) +
                (closedEmails.length || 0);

            return {
                ...prev,
                new: {
                    ...prev.new,
                    count: newCount,
                },
                sent: {
                    ...prev.sent,
                    count: sentEmails.length || 0,
                },
                deleted: {
                    ...prev.deleted,
                    count: deletedEmails.length || 0,
                },
            };
        });
    }, [readedEmails, unreadedEmails, inProgressEmails, closedEmails, sentEmails, deletedEmails])

    useEffect(() => {
        const maxLength = Math.max(
            readedEmails.length,
            unreadedEmails.length,
            inProgressEmails.length,
            closedEmails.length,
            deletedEmails.length
        );
        setEmailRowCount(maxLength);
    }, [
        readedEmails,
        unreadedEmails,
        inProgressEmails,
        closedEmails,
        deletedEmails
    ])

    return {
        threads,
        isLoading,
        error,
        getThreads,
        readedEmails,
        unreadedEmails,
        inProgressEmails,
        closedEmails,
        sentEmails,
        deletedEmails,
        businessEmails,
        personalEmails,
        threadsMetrics,
        threadsTypesMetrics,
        sendThreadToClosed,
        deleteEmailFromThread,
        filters,
        showTenMore,
        emailRowCount
    };
};

export default useThreads;
