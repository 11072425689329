import {
  SET_NEW_CONTACT_FORM_ASSIGNED_USERS,
} from 'store/actionTypes/crm';

/**
 *
 * @param {Array<number>} payload
 */
const setNewContactFormAssignedUsers = (payload) => ({
  type: SET_NEW_CONTACT_FORM_ASSIGNED_USERS,
  payload,
});

export default setNewContactFormAssignedUsers;
