import styled from 'styled-components';

export const StyledModal = styled.div`
  flex: 1;
  width: 650px;
  padding: 15px;
  border-radius: 10px;
  background-color: ${props => props.theme?.palette?.['background-flat']};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-items: center;
`;

export const ModalTitleText = styled.div`
  font-weight: 600;
  font-size: 31px;
  line-height: 33px;
  font-family: 'Hind', sans-serif;
  margin-left: 10px;
  color: white;
  letter-spacing: 4px;
`;

export const ModalSubtitleText = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  font-family: 'Hind', sans-serif;
  margin-left: 10px;
  color: white;
  letter-spacing: 0.5px;
`;

export const ModalText = styled.div`
  font-weight: 400;
  font-size: 27px;
  line-height: 33px;
  font-family: 'Hind', sans-serif;
  margin-left: 20px;
`;

export const ModalInnerTitle = styled.div`
  font-family: 'Hind', sans-serif;
  font-weight: 700;
  font-size: 34px;
  line-height: 36px;
  color: #171725;
  padding-top: 35px;
  padding-bottom: 7px;
`;

export const ItemWrapper = styled.div`
  flex: 1;
  width: 330px;
  padding: 15px;
  border-radius: 10px;
  background-color: '#fff';
  display: flex;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 12px;
  cursor: pointer;
`;

export const ItemWrapperDisabled = styled.div`
  flex: 1;
  width: 330px;
  padding: 15px;
  border-radius: 10px;
  background-color: '#fff';
  display: flex;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 12px;
  cursor: not-allowed;
`;

export const Label = styled.div`
  position: absolute;
  top: 40px;
  left: 100px;
  transform: translate(-50%);
  width: 153px;
  height: 30px;
  background-color: '#fff';
  color: black;
  padding: 5px 10px;
  border-radius: 100px;
  font-size: 13px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  ${ItemWrapperDisabled}:hover & {
    opacity: 1;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding-top: 30px;
`;

export const StyledButton = styled.button`
  display: inline-block;
  width: 120px;
  height: 40px;
  background-color: #000;
  color: #fff;
  padding: 10px 25px;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  margin-left: 45px;
  font-size: 15px;
  line-height: 0px;
`;
