import { connect } from 'react-redux';

import {
  setNewPropertyViewingNestedFormField,
} from 'store/actions/newRealtorPropertyViewingForm';

import NewContactArea from './NewContactArea';

const mapStateToProps = (state) => {
  const {
    newPropertyViewingForm: {
      newContact,
    },
  } = state;

  return {
    contact: newContact,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onInvalidField: (property, value) => {
    dispatch(setNewPropertyViewingNestedFormField('newContactFieldsValidators')(property)(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewContactArea);
