import styled from 'styled-components';

import { lighten } from 'polished';

import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';

const getRootPadding = (props) => {
  if (!props.hasText || props.wrapper) {
    return 0;
  }

  if (props.dense) {
    return '6px 12px';
  }

  return '10px 16px';
};
const getFontColor = (type) => (props) => {
  if (type === 'disabled') {
    if (props.variant === 'primary') {
      return props.theme?.palette?.['primary-disabled-font'];
    }
  }
}
const getBorderColor = (type) => (props) => {
  if (type === 'disabled') {
    if (props.variant === 'primary') {
      return props.theme?.palette?.['primary-disabled-border'];
    }
  }
}
const getBackground = (type) => (props) => {
  if (type === 'regular') {
    if (props.variant === 'primary') {
      return props.theme?.palette?.primary;
    }
    if (props.variant === 'secondary') {
      return props.theme?.palette?.background || '#ffffff';
    }
    if (props.variant === 'tertiary') {
      // return props.theme?.palette?.background;
      return 'transparent';
    }
    if (props.variant === 'cancel') {
      return props.theme?.palette?.alert;
    }
    if (props.variant === 'flat') {
      return props.theme?.palette?.backgroundFlat;
    }

    if (props.variant === 'positive') {
      return lighten(0.4, props.theme?.palette?.positive);
    }
    if (props.variant === 'neutral') {
      return lighten(0.3, props.theme?.palette?.neutral);
    }
    if (props.variant === 'negative') {
      return lighten(0.3, props.theme?.palette?.negative);
    }
  }

  if (type === 'hover') {
    if (props.variant === 'primary') {
      return props.theme?.palette?.['primary-hovered'];
    }
    if (props.variant === 'secondary') {
      return props.theme?.palette?.['background-hover'];
    }
    if (props.variant === 'tertiary') {
      return 'transparent';
    }
    if (props.variant === 'cancel') {
      return props.theme?.palette?.['alert-hover'];
    }
    if (props.variant === 'flat') {
      return props.theme?.palette?.backgroundFlat;
    }

    if (props.variant === 'positive') {
      return lighten(0.45, props.theme?.palette?.positive);
    }
    if (props.variant === 'neutral') {
      return lighten(0.35, props.theme?.palette?.neutral);
    }
    if (props.variant === 'negative') {
      return lighten(0.35, props.theme?.palette?.negative);
    }
  }

  if (type === 'disabled') {
    if (props.variant === 'primary') {
      return props.theme?.palette?.['primary-disabled'];
    }
    if (props.variant === 'secondary') {
      return props.theme?.palette?.disabled;
    }
    if (props.variant === 'tertiary') {
      return 'transparent';
    }
    if (props.variant === 'cancel') {
      return props.theme?.palette?.disabled;
    }
    if (props.variant === 'flat') {
      return props.theme?.palette?.disabled;
    }
  }
  return 'blue';
};

const getShadow = (props) => {
  if (props.variant === 'secondary') {
    if (!props.disabled) {
      return props.theme?.shadows?.button;
    }
  }

  return 'none';
};

const getWidth = (props) => {
  if (props.fullWidth) return '100%';

  if (!props.hasText) return '44px';

  return 'auto';
};

const getHeight = (props) => {
  if (props.wrapper) return 'auto';

  if (props.dense) return '36px';

  if (!props.hasText) return '44px';

  // return 'auto';
  return '44px';
};

const getJustify = (props) => {
  if (props.centered) return 'center';
  if (!props.hasText || !props.icon) return 'center';
  return 'space-between';
};

const getIconOffset = (props) => {
  const margin = props.iconPosition === 'left'
    ? 'margin-right'
    : 'margin-left';

  if (props.iconOffset || props.iconOffset === 0) return `${margin}: ${props.iconOffset}px;`;

  return props.hasText
    ? `${margin}: 8px;`
    : '';
};

export const Root = styled(NakedButton)`
  && {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: ${(getJustify)};
    width: ${getWidth};
    height: ${getHeight};
    box-shadow: ${getShadow};
    padding: ${getRootPadding};
    border-radius: 10px;
    color: ${(props) => getFontColor('regular')(props)};
    background: ${(props) => getBackground('regular')(props)};
    &:hover {
      background: ${(props) => getBackground('hover')(props)};
    }
    &:disabled {
      background: ${(props) => getBackground('disabled')(props)};
      border: solid 1px ${(props) => getBorderColor('disabled')(props)};
    }
    &:active, &.activated {
      box-shadow: none;
      transform: scale(0.99);
      transform-origin: center;
      transition-duration: 0.3s;
    }
    ${(props) => props.iconPosition === 'right' && 'flex-direction: row-reverse;'}
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  ${(props) => (getIconOffset(props))}
  justify-content: center;
  align-items: center;
`;

export default {
  Root,
  IconWrapper,
};
