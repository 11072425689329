import requests from 'api/requests';

import getFulfillmentById from './getFulfillmentById';

const updateFulfillmentLineItems = (fulfillmentId) => (productId) => (updateData) => async (dispatch) => {
  try {
    await requests.fulfillments.updateLineItems(fulfillmentId)(productId)(updateData);

    dispatch(getFulfillmentById(fulfillmentId));
  } catch (error) {
    console.error(error);
  }
};

export default updateFulfillmentLineItems;
