import React from 'react';
import styles from './primaryButton.module.css';

const PrimaryButton = ({ children, onClick }) => {
    return (
        <button className={styles['primary-btn']} onClick={onClick}>
            {children}
        </button>
    );
};

export default PrimaryButton;
