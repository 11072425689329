import {
  SET_FULFILLMENT_DISPLAY_TYPE,
} from 'store/actionTypes/fulfillments';

const setFulfillmentsDisplayType = (displayType) => (dispatch) => {
  dispatch({
    type: SET_FULFILLMENT_DISPLAY_TYPE,
    displayType,
  });
};

export default setFulfillmentsDisplayType;
