import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Confetti from 'react-confetti';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Typography from 'ui/Typography';
import Modal from 'ui/Modal';


import IntegrationsProductivity from 'sections/Integrations/modules/IntegrationsProductivity';
import IntegrationsTable from 'sections/Integrations/modules/IntegrationsTable';
import EmailConnectModal from 'components/OnboardingWizard/EmailConnectModal/EmailConnectModal';

import SuccessImg from 'assets/images/connection-success.png';
import ErrorImg from 'assets/images/connection-error.png';
import TelephoneImg from 'assets/images/telephone.png';
import EmailImg from 'assets/images/email.png';

import {
  ModalInnerTitle,
  Background,
  StyledButton,
  StyledLink,
  Spacing,
  HorizontalSpacing
} from './styledItems';
import noOp from 'shared/utility/noOp';

const ErrorModal = props => {
  const {
    isVisible,
    closeModal,
    setHideErrorModal,
    setShowEmailConnectModal
  } = props;
  // console.log('ErrorModal', props);
  // TODO add contact support icons on modal
  // try again btn opens EmailConnectModal here
  return (
    <Background
      onClick={() => {
        setHideErrorModal(true);
        setShowEmailConnectModal(true);
      }}
    >
      <Modal
        destroyOnClose
        width={500}
        visible={isVisible}
        onCancel={closeModal}
        footer={null}
        closable={false}
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Flex flexDirection="row">
            <ModalInnerTitle>There was a connection error.</ModalInnerTitle>
          </Flex>
          <Flex flexDirection="row" spacing={2}>
            <Typography
              variant="title2"
              weight="normal"
              align="center"
              marginBottom={10}
            >
              Please try again or use a different account.
            </Typography>
          </Flex>
          <Flex flexDirection="row" spacing={2}>
            <Spacing></Spacing>
          </Flex>
          <Flex flexDirection="row" spacing={4}>
            <img
              src={ErrorImg}
              width={257}
              height={183}
              alt="Connection Error"
            />
          </Flex>
          <Flex flexDirection="row">
            <StyledButton
              onClick={() => {
                setHideErrorModal(true);
                setShowEmailConnectModal(true);
              }}
            >
              Try again
            </StyledButton>
          </Flex>
          <Flex flexDirection="row" spacing={2}>
            <Spacing></Spacing>
          </Flex>
          <Flex flexDirection="row" spacing={2}>
            <Typography
              variant="title2"
              weight="normal"
              align="center"
              marginBottom={10}
            >
              If you need assistance, please contact support.
            </Typography>
          </Flex>
          <Flex
            flexDirection="row"
            spacing={1}
            alignItems="center"
            justifyContent="center"
          >
            <img src={EmailImg} width={20} height={20} alt="Email" />
            <StyledLink href="mailto:support@symplete.com">
              support@symplete.com
            </StyledLink>
            <HorizontalSpacing />
            <img src={TelephoneImg} width={20} height={20} alt="Telephone" />
            <StyledLink href="tel:+8053092548">(888) 664 1952</StyledLink>
          </Flex>
        </Flex>
      </Modal>
    </Background>
  );
};
const SuccessModal = props => {
  const {
    isVisible,
    setHideSuccessModal,
    closeModal,
    windowWidth,
    windowHeight,
    recycle
  } = props;
  return (
    <Confetti width={windowWidth} height={windowHeight} recycle={recycle}>
      <Background onClick={() => setHideSuccessModal(true)}>
        <Modal
          destroyOnClose
          width={600}
          visible={isVisible}
          onCancel={closeModal}
          footer={null}
          closable={false}
        >
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            onClick={() => setHideSuccessModal(true)}
          >
            <Flex flexDirection="row">
              <ModalInnerTitle>You're all set!</ModalInnerTitle>
            </Flex>
            <Flex flexDirection="row">
              <Typography
                variant="title2"
                weight="normal"
                align="center"
                noMargin
              >
                You have successfully connected your account.
              </Typography>
            </Flex>
            <Flex flexDirection="row">
              <img
                src={SuccessImg}
                width={281}
                height={255}
                alt="Google logo"
              />
            </Flex>
          </Flex>
        </Modal>
      </Background>
    </Confetti>
  );
};

const Integrations = props => {
  const location = useLocation();
  // console.log('location', location);
  const queryParams = new URLSearchParams(location.search);
  const isCustomScopeIntegration = queryParams.size > 0 ? true : false;
  // console.log('queryParams', queryParams);
  // console.log('queryParams size', queryParams.size);
  // console.log('typeof queryParams size', typeof queryParams.size);
  // console.log('isCustomScopeIntegration', isCustomScopeIntegration);
  const successValue = queryParams.get('success');
  const showSuccessModal = successValue === 'true' ? true : false;
  // console.log('showSuccessModal', showSuccessModal);
  const showErrorModal = successValue === 'false' ? true : false;
  // console.log('showErrorModal', showErrorModal);
  const [hideSuccessModal, setHideSuccessModal] = useState(false);
  const [hideErrorModal, setHideErrorModal] = useState(false);
  // const queryScope = queryParams.get('scope');
  // console.log('queryScope', queryScope);
  const [showEmailConnectModal, setShowEmailConnectModal] = useState(false);

  const [recycle, setRecycle] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRecycle(false);
    }, 4000);
    const timeout2 = setTimeout(() => {
      setHideSuccessModal(true);
    }, 4000);

    return () => {
      clearTimeout(timeout);
      clearTimeout(timeout2);
    };
  }, []);

  return (
    <>
      <Flex flexDirection="column" spacing={4}>
        <Typography>
          Connect your existing services to Symplete to get even more out of our
          account.
        </Typography>

        <FlexItem fullWidth>
          <Typography variant="title3">Productivity</Typography>

          <IntegrationsProductivity />
        </FlexItem>

        <FlexItem fullWidth>
          <Typography variant="title2">Active Integrations</Typography>

          <IntegrationsTable />
        </FlexItem>
      </Flex>
      {!isCustomScopeIntegration ? null : isCustomScopeIntegration &&
        showSuccessModal ? (
        <SuccessModal
          isVisible={showSuccessModal && !hideSuccessModal}
          setHideSuccessModal={setHideSuccessModal}
          windowWidth={window.innerWidth}
          windowHeight={window.innerHeight}
          recycle={recycle}
        />
      ) : isCustomScopeIntegration && showErrorModal ? (
        <ErrorModal
          isVisible={!showSuccessModal && !hideErrorModal}
          setHideErrorModal={setHideErrorModal}
          setShowEmailConnectModal={setShowEmailConnectModal}
        />
      ) : null}
      {showEmailConnectModal && (
        <Background>
          <Modal
            destroyOnClose
            width={735}
            visible={showEmailConnectModal}
            onCancel={noOp}
            footer={null}
            closable={false}
            justifyFooterContent="space-between"
            alignFooterItems="center"
            className="onboarding-wizard"
            bodyStyle={{
              backgroundColor: '#3c2b84',
              borderRadius: '10px'
            }}
          >
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <EmailConnectModal />
            </Flex>
          </Modal>
        </Background>
      )}
    </>
  );
};

export default Integrations;
