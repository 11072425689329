import React from 'react';
import PropTypes from 'prop-types';

import Textfield from 'ui/Textfield';

const ContactFirstNameInput = (props) => {
  const {
    handleChange,
    error,
    value,
  } = props;

  return (
    <Textfield
      label="First Name"
      onChange={handleChange}
      error={error}
      value={value}
    />
  );
};

ContactFirstNameInput.defaultProps = {
  error: null,
  value: undefined,
};

const {
  func,
  string,
} = PropTypes;

ContactFirstNameInput.propTypes = {
  handleChange: func.isRequired,
  error: string,
  value: string,
};

export default ContactFirstNameInput;
