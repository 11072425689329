import requests from 'api/requests';

import getDealProducts from './getDealProducts';

const removeProduct = (dealId) => (relationId) => async (dispatch) => {
  try {
    await requests.deals.removeProduct(relationId);

    await dispatch(getDealProducts(dealId));
  } catch (error) {
    console.error(error);
  }
};

export default removeProduct;
