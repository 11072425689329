import BaseApiConnector from 'api/connectors/BaseApiConnector';

const OAApiUrl = process.env.REACT_APP_OA_API_PATH;

class OAApiConnector extends BaseApiConnector {
  constructor() {
    super(OAApiUrl);
  }
}

export default OAApiConnector;
