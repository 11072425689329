import requests from 'api/requests';

import getContactById from './getContactById';

const createContact = (contactData) => async (dispatch) => {
  try {
    const response = await requests.crm.contacts.create(contactData);

    dispatch(getContactById(response.data.contactId));

    return response.data.contactId;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default createContact;
