import React from 'react';
import styles from '../board.module.css'; // Asegúrate de importar tu estilo
import NumeratorColumn from '../numeratorColumn/NumeratorColumn';
import CardsColumn from '../cardsColumn/CardsColumn';
import CardsGrid from '../cardsGrid/CardsGrid';
import { useCommsHub } from 'refactored/components/shared/providers/data/CommsHub.provider';

const GridView = ({ selectedFilter }) => {
    const { emailRowCount, filters, unreadedEmails, readedEmails, inProgressEmails, closedEmails, sentEmailsSorted, deletedEmailsSorted, businessEmails, personalEmails, showTenMore } = useCommsHub();
    return (
        <>
            {selectedFilter === "new" &&
                <div className={styles['board-canban']}>
                    <NumeratorColumn limit={emailRowCount} />
                    <CardsColumn id={'new'} title={'New'} emails={unreadedEmails} filters={filters} showTenMore={showTenMore} />
                    <CardsColumn id={'read'} title={'Read'} emails={readedEmails} filters={filters} showTenMore={showTenMore} />
                    <CardsColumn id={'inProgress'} title={'In Progress'} emails={inProgressEmails} filters={filters} showTenMore={showTenMore} />
                    <CardsColumn id={'closed'} title={'Closed'} emails={closedEmails} filters={filters} showTenMore={showTenMore} />
                </div>
            }
            {selectedFilter === "sent" &&
                <CardsGrid emails={sentEmailsSorted} />
            }
            {selectedFilter === "deleted" &&
                <CardsGrid emails={deletedEmailsSorted} />
            }
            {selectedFilter === "business" &&
                <CardsGrid emails={businessEmails} />
            }
            {selectedFilter === "personal" &&
                <CardsGrid emails={personalEmails} />
            }
        </>
    );
};

export default GridView;