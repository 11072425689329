import React from 'react';
import { useSelector } from 'react-redux';
import EmailCompanyMailIcon from '../emailCompanyMailIcon/EmailCompanyMailIcon';
import currentUserSelector from 'store/selectors/users/currentUser';
import SenderPillImage from './SenderPillImage';
import styles from './senderPill.module.css';

const SenderPill = () => {
    const user = useSelector(currentUserSelector);
    return (
        <div className={styles['sender-pill-wrapper']}>
            <SenderPillImage imageUrl={user?.avatar} altText={user?.fullName} />
            <EmailCompanyMailIcon />
            <span className={styles['email-text']}> {`${user?.email}`}</span>
        </div>
    );
};

export default SenderPill;