var escape = require("../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;600;700&display=swap);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);", ""]);

// module
exports.push([module.id, ":root {\n  --common-padding: 60px;\n  --navbar-min-height: 60px;\n  --common-bg-color: #fff;\n  --common-primary-color: #3B2A80;\n}\n\n@font-face {\n  font-family: \"Estilo\";\n  src: url(" + escape(require("./assets/fonts/Estilo.otf")) + ") format(\"opentype\");\n}\n@font-face {\n  font-family: \"Hind-Bold\";\n  src: url(" + escape(require("./assets/fonts/Hind/Hind-Bold.ttf")) + ") format(\"opentype\");\n}\n@font-face {\n  font-family: \"Hind-SemiBold\";\n  src: url(" + escape(require("./assets/fonts/Hind/Hind-SemiBold.ttf")) + ") format(\"opentype\");\n}\n@font-face {\n  font-family: \"Hind-Regular\";\n  src: url(" + escape(require("./assets/fonts/Hind/Hind-Regular.ttf")) + ") format(\"opentype\");\n}\nbody {\n  margin: 0;\n  padding: 0;\n  font-family: \"Hind-Regular\", Roboto, Helvetica, Arial, sans-serif;\n  background-color: transparent !important;\n}\n\n@media screen and (max-width: 1300px) {\n  body {\n    zoom: 80%;\n  }\n}", ""]);

// exports
