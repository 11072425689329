import { connect } from 'react-redux';

import { isBoolean } from 'shared/utility';

import {
  closeNewContactModal,
} from 'store/actions/newRealtorContactModal';

import {
  createContactFromNewContactForm,
} from 'store/actions/realtorContacts';

import {
  setNewContactNestedFormField,
  setNewContactForm,
} from 'store/actions/newRealtorContactForm';

import { handleModalFormCache } from 'store/actions/modals';

import handleDrafts from 'shared/hooks/useModalsDrafts/handleDrafts';

import NewContactModal from './NewContactModal';

const mapStateToProps = (state, ownProps) => {
  const {
    realtorNewContactModal: {
      isVisible,
    },
    realtorNewContactForm: {
      mainEmail,
      personFirstName,
      fieldsValidators: {
        doesPhoneExist,
        doesEmailExist,
      },
    },
  } = state;

  return {
    isVisible: isBoolean(ownProps.isVisible) ? ownProps.isVisible : isVisible,
    mainEmail,
    personFirstName,
    doesPhoneExist,
    doesEmailExist,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  closeModal: (shouldCache = true) => {
    dispatch(
      handleModalFormCache('realtorNewContactModal', 'realtorNewContactForm')(shouldCache ? 'set' : 'clear'),
    );

    if (ownProps.closeModal) {
      ownProps.closeModal();
    } else {
      dispatch(closeNewContactModal());
    }
  },
  createContact: async () => {
    const id = await dispatch(createContactFromNewContactForm());

    if (ownProps?.onSuccess) {
      ownProps.onSuccess(id);
    }

    return id;
  },
  setNotValidField: (fieldName) => dispatch(setNewContactNestedFormField('fieldsValidators')(fieldName)(false)),
  getContacts: (page, pageSize) => {

  },
  getCachedData: () => {
    const cachedData = handleDrafts.get('realtorNewContactModal');
    if (cachedData) {
      dispatch(setNewContactForm(cachedData));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewContactModal);
