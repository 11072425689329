import React from 'react';

const EmailIntentIcons = intent => {
  // console.log('received Intent: ', intent);
  switch (intent) {
    case 'Information':
      return (
        <div
          style={{
            paddingLeft: '0.6rem',
            paddingRight: '0.6rem',
            paddingTop: '0.2rem',
            paddingBottom: '0.2rem',
            display: 'flex',
            backgroundColor: '#A8DAFC',
            borderRadius: '20px',
            marginBottom: '40px',
            width: 'fit-content',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '20px',
              height: '20px'
            }}
          >
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.916504 11.0003C0.916504 5.40866 5.40817 0.916992 10.9998 0.916992C16.5915 0.916992 21.0832 5.40866 21.0832 11.0003C21.0832 16.592 16.5915 21.0837 10.9998 21.0837C5.40817 21.0837 0.916504 16.592 0.916504 11.0003ZM2.75 11C2.75 15.5833 6.41667 19.25 11 19.25C15.5833 19.25 19.25 15.5833 19.25 11C19.25 6.41667 15.5833 2.75 11 2.75C6.41667 2.75 2.75 6.41667 2.75 11ZM11.9163 11.0007V14.6673C11.9163 15.2173 11.5497 15.584 10.9997 15.584C10.4497 15.584 10.083 15.2173 10.083 14.6673V11.0007C10.083 10.4507 10.4497 10.084 10.9997 10.084C11.5497 10.084 11.9163 10.4507 11.9163 11.0007ZM11.9165 7.33366C11.9165 7.05866 11.8248 6.87533 11.6415 6.69199C11.4582 6.50866 11.0915 6.41699 10.8165 6.41699C10.7638 6.46971 10.7414 6.49211 10.7144 6.50162C10.6945 6.50866 10.6721 6.50866 10.6332 6.50866C10.5873 6.50866 10.5644 6.53158 10.5415 6.55449C10.5186 6.57741 10.4957 6.60033 10.4498 6.60033L10.3582 6.69199C10.1748 6.87533 10.0832 7.05866 10.0832 7.33366C10.0832 7.60866 10.1748 7.79199 10.3582 7.97533C10.3582 7.97533 10.3582 8.06699 10.4498 8.06699C10.4957 8.06699 10.5186 8.08991 10.5415 8.11283C10.5644 8.13574 10.5873 8.15866 10.6332 8.15866C10.6859 8.21137 10.7083 8.23377 10.7352 8.24329C10.7551 8.25033 10.7775 8.25033 10.8165 8.25033H10.9998C11.2748 8.25033 11.4582 8.15866 11.6415 7.97533C11.8248 7.79199 11.9165 7.60866 11.9165 7.33366Z"
                fill="#1871AC"
              />
            </svg>
          </div>
        </div>
      );
    case 'Solution':
      return (
        <div
          style={{
            paddingLeft: '0.6rem',
            paddingRight: '0.6rem',
            paddingTop: '0.2rem',
            paddingBottom: '0.2rem',
            display: 'flex',
            backgroundColor: '#DCD2F8',
            borderRadius: '20px',
            marginBottom: '40px',
            width: 'fit-content',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '20px',
              height: '20px'
            }}
          >
            <svg
              width="22"
              height="21"
              viewBox="0 0 22 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.3181 7.5292C20.6835 7.62072 20.9576 7.80375 21.0489 8.16983C21.1403 8.44438 21.0489 8.81046 20.7748 8.99349L16.5725 13.1118L17.5774 18.969C17.6688 19.3351 17.4861 19.7011 17.212 19.8842C17.0293 19.9757 16.8466 20.0672 16.6639 20.0672H16.2071L10.9998 17.3217L5.79259 20.0672C5.42717 20.2503 5.06175 20.1587 4.78768 19.9757C4.51362 19.7927 4.33091 19.4266 4.42226 19.0605L5.42717 13.2033L1.22483 9.08501C0.950762 8.90197 0.859407 8.5359 0.950762 8.16983C1.13347 7.80375 1.40754 7.5292 1.77296 7.5292L7.52834 6.61401L10.1776 1.39746C10.4517 0.756835 11.548 0.756835 11.822 1.39746L14.4713 6.70553L20.3181 7.5292ZM14.654 13.02C14.654 12.7455 14.7453 12.4709 14.9281 12.1963L18.2168 8.9932L13.7404 8.35257C13.375 8.35257 13.1009 8.16953 13.0096 7.89498L10.9998 3.86816L8.98996 7.98649C8.80725 8.16953 8.53319 8.44409 8.25912 8.44409L3.78271 9.08471L7.0715 12.1963C7.25421 12.4709 7.34557 12.7455 7.34557 13.02L6.52337 17.5044L10.543 15.3995C10.8171 15.2164 11.0911 15.2164 11.3652 15.3995L15.3848 17.5044L14.654 13.02Z"
                fill="#5F41B2"
              />
            </svg>
          </div>
        </div>
      );
    case 'Feedback':
      return (
        <div
          style={{
            paddingLeft: '0.6rem',
            paddingRight: '0.6rem',
            paddingTop: '0.2rem',
            paddingBottom: '0.2rem',
            display: 'flex',
            backgroundColor: '#ABE9E7',
            borderRadius: '20px',
            marginBottom: '40px',
            width: 'fit-content',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '20px',
              height: '20px'
            }}
          >
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.503 8.43366C20.0389 7.88366 19.3891 7.42533 18.6465 7.33366H18.1823H13.9123V4.58366C13.9123 2.56699 12.2414 0.916992 10.1992 0.916992C9.8279 0.916992 9.45659 1.10033 9.36376 1.46699L5.92916 9.16699H3.70131C2.12326 9.16699 0.916504 10.3587 0.916504 11.917V18.3337C0.916504 19.892 2.12326 21.0837 3.70131 21.0837H16.9756C18.368 21.0837 19.4819 20.0753 19.7604 18.792L21.06 10.542C21.1528 9.80866 20.9671 9.07533 20.503 8.43366ZM5.55776 19.25H3.70122C3.14426 19.25 2.77295 18.8833 2.77295 18.3333V11.9167C2.77295 11.3667 3.14426 11 3.70122 11H5.55776V19.25ZM16.9756 19.2503C17.4397 19.2503 17.811 18.9753 17.9038 18.517L19.2963 10.1753C19.2963 9.99199 19.2963 9.71699 19.1106 9.53366C18.9249 9.35033 18.7393 9.16699 18.4608 9.16699H18.2752H12.984C12.4271 9.16699 12.0557 8.80033 12.0557 8.25033V4.58366C12.0557 3.75866 11.4988 3.11699 10.7562 2.84199L7.41439 10.267V19.2503H16.9756Z"
                fill="#578887"
              />
            </svg>
          </div>
        </div>
      );
    case 'Feedback Request':
      return (
        <div
          style={{
            paddingLeft: '0.6rem',
            paddingRight: '0.6rem',
            paddingTop: '0.2rem',
            paddingBottom: '0.2rem',
            display: 'flex',
            backgroundColor: '#FFBDD3',
            borderRadius: '20px',
            marginBottom: '40px',
            width: 'fit-content',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '20px',
              height: '20px'
            }}
          >
            <svg
              width="22"
              height="21"
              viewBox="0 0 22 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.0003 20.2077C16.592 20.2077 21.0837 15.716 21.0837 10.1243C21.0837 4.53268 16.592 0.0410156 11.0003 0.0410156C5.40866 0.0410156 0.916992 4.53268 0.916992 10.1243C0.916992 15.716 5.40866 20.2077 11.0003 20.2077ZM11.1836 11.8667C11.5503 11.7751 14.5753 10.6751 14.5753 8.29174C14.5753 6.73341 13.5669 5.35841 12.1003 4.90007C10.1753 4.16674 8.06693 5.17507 7.42526 7.10007C7.33359 7.55841 7.51693 8.10841 7.97526 8.10841C8.43359 8.29174 8.98359 8.01674 9.16693 7.55841C9.53359 6.64174 10.6336 6.09174 11.5503 6.45841C12.2836 6.73341 12.7419 7.37507 12.7419 8.20007C12.7419 9.20841 11.1836 9.94174 10.6336 10.1251C10.1753 10.3084 9.90026 10.8584 10.0836 11.3167C10.1753 11.6834 10.5419 11.9584 10.9086 11.9584C11.0003 11.9584 11.0919 11.9584 11.1836 11.8667ZM19.25 10.125C19.25 5.54167 15.5833 1.875 11 1.875C6.41667 1.875 2.75 5.54167 2.75 10.125C2.75 14.7083 6.41667 18.375 11 18.375C15.5833 18.375 19.25 14.7083 19.25 10.125ZM11.4579 13.9743C11.5495 13.9743 11.6412 14.066 11.6412 14.066C11.7329 14.1577 11.8245 14.2493 11.7329 14.341C11.8245 14.4327 11.8245 14.616 11.8245 14.7077C11.8245 14.9827 11.7329 15.166 11.5495 15.3493C11.3662 15.5327 11.1829 15.6243 10.9079 15.6243C10.6329 15.6243 10.4495 15.5327 10.2662 15.3493C10.0829 15.166 9.99121 14.9827 9.99121 14.7077C9.99121 14.6003 10.0227 14.5243 10.0487 14.4614C10.0672 14.417 10.0829 14.379 10.0829 14.341C10.0829 14.2494 10.1745 14.1577 10.2662 14.066L10.2662 14.066L10.2662 14.066C10.3579 13.9743 10.4495 13.8827 10.5412 13.8827C10.7245 13.791 10.9079 13.791 11.0912 13.791C11.1439 13.8437 11.1663 13.8661 11.1933 13.8756C11.2132 13.8827 11.2356 13.8827 11.2745 13.8827C11.3204 13.8827 11.3433 13.9056 11.3662 13.9285C11.3891 13.9514 11.412 13.9743 11.4579 13.9743Z"
                fill="#EC3570"
              />
            </svg>
          </div>
        </div>
      );
    case 'Confirmation Required':
      return (
        <div
          style={{
            paddingLeft: '0.6rem',
            paddingRight: '0.6rem',
            paddingTop: '0.2rem',
            paddingBottom: '0.2rem',
            display: 'flex',
            backgroundColor: '#ded2ff',
            borderRadius: '20px',
            marginBottom: '40px',
            width: 'fit-content',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '20px',
              height: '20px'
            }}
          >
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.0832 11.0003V10.1753C21.0832 9.62533 20.7165 9.25866 20.1665 9.25866C19.6165 9.25866 19.2498 9.62533 19.2498 10.1753V11.0003C19.2498 15.5837 15.5832 19.2503 10.9998 19.2503C6.4165 19.2503 2.74984 15.5837 2.74984 11.0003C2.74984 6.41699 6.4165 2.75033 10.9998 2.75033C12.1915 2.75033 13.2915 3.02533 14.3915 3.48366C14.8498 3.66699 15.3998 3.48366 15.5832 3.02533C15.7665 2.56699 15.5832 2.01699 15.1248 1.83366C13.8415 1.19199 12.3748 0.916992 10.9998 0.916992C5.40817 0.916992 0.916504 5.40866 0.916504 11.0003C0.916504 16.592 5.40817 21.0837 10.9998 21.0837C16.5915 21.0837 21.0832 16.592 21.0832 11.0003ZM20.4413 2.10898C20.808 1.74232 21.358 1.74232 21.7247 2.10898C22.0913 2.47565 22.0913 3.02565 21.7247 3.39232L11.6413 13.4757C11.458 13.659 11.183 13.7507 10.9997 13.7507C10.8163 13.7507 10.5413 13.659 10.358 13.4757L7.60801 10.7257C7.24134 10.359 7.24134 9.80898 7.60801 9.44232C7.97467 9.07565 8.52467 9.07565 8.89134 9.44232L10.9997 11.5507L20.4413 2.10898Z"
                fill="#8962f8"
              />
            </svg>
          </div>
        </div>
      );
    case 'Support Request':
      return (
        <div
          style={{
            paddingLeft: '0.6rem',
            paddingRight: '0.6rem',
            paddingTop: '0.2rem',
            paddingBottom: '0.2rem',
            display: 'flex',
            backgroundColor: '#FDDFD8',
            borderRadius: '20px',
            marginBottom: '40px',
            width: 'fit-content',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '20px',
              height: '20px'
            }}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.99984 0.666992C4.4165 0.666992 0.666504 4.41699 0.666504 9.00033V10.667V14.0003V14.8337C0.666504 16.2503 1.74984 17.3337 3.1665 17.3337H3.99984C5.4165 17.3337 6.49984 16.2503 6.49984 14.8337V12.3337C6.49984 10.917 5.4165 9.83366 3.99984 9.83366H2.33317V9.00033C2.33317 5.33366 5.33317 2.33366 8.99984 2.33366C12.6665 2.33366 15.6665 5.33366 15.6665 9.00033V9.83366H13.9998C12.5832 9.83366 11.4998 10.917 11.4998 12.3337V14.8337C11.4998 16.2503 12.5832 17.3337 13.9998 17.3337H14.8332C16.2498 17.3337 17.3332 16.2503 17.3332 14.8337V14.0003V10.667V9.00033C17.3332 4.41699 13.5832 0.666992 8.99984 0.666992ZM3.99967 11.5C4.49967 11.5 4.83301 11.8333 4.83301 12.3333V14.8333C4.83301 15.3333 4.49967 15.6667 3.99967 15.6667H3.16634C2.66634 15.6667 2.33301 15.3333 2.33301 14.8333V14V11.5H3.99967ZM14.8332 15.6667C15.3332 15.6667 15.6665 15.3333 15.6665 14.8333V14V11.5H13.9998C13.4998 11.5 13.1665 11.8333 13.1665 12.3333V14.8333C13.1665 15.3333 13.4998 15.6667 13.9998 15.6667H14.8332Z"
                fill="#E0694E"
              />
            </svg>
          </div>
        </div>
      );
    case 'Problem Report':
      return (
        <div
          style={{
            paddingLeft: '0.6rem',
            paddingRight: '0.6rem',
            paddingTop: '0.2rem',
            paddingBottom: '0.2rem',
            display: 'flex',
            backgroundColor: '#FFD6D9',
            borderRadius: '20px',
            marginBottom: '40px',
            width: 'fit-content',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '20px',
              height: '20px'
            }}
          >
            <svg
              width="22"
              height="20"
              viewBox="0 0 22 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.83341 18.7995C2.29175 19.0745 2.75008 19.1662 3.20841 19.1662H18.7917C19.5251 19.1662 20.1667 18.8912 20.6251 18.3412C21.1751 17.8829 21.4501 17.1495 21.4501 16.4162C21.4501 15.9579 21.3584 15.4995 21.0834 15.0412L13.3834 2.11621C12.9251 1.47454 12.3751 1.01621 11.6417 0.832878C10.9084 0.649544 10.1751 0.741211 9.53341 1.10788C9.16675 1.29121 8.80008 1.65788 8.61675 2.02454L0.82508 15.0412C0.0917463 16.3245 0.55008 18.0662 1.83341 18.7995ZM2.47491 15.9583L10.1749 3.03332C10.2666 2.84999 10.3582 2.75832 10.5416 2.66665C10.9999 2.48332 11.5499 2.57499 11.8249 3.03332L19.5249 15.9583C19.6166 16.1417 19.6166 16.2333 19.6166 16.4167C19.6166 16.6917 19.5249 16.875 19.3416 17.0583C19.1582 17.2417 18.9749 17.3333 18.6999 17.3333H3.20824C3.11658 17.3333 2.93324 17.3333 2.84158 17.2417C2.38324 16.9667 2.19991 16.4167 2.47491 15.9583ZM11.9167 10.9162V7.24954C11.9167 6.69954 11.5501 6.33288 11.0001 6.33288C10.4501 6.33288 10.0834 6.69954 10.0834 7.24954V10.9162C10.0834 11.4662 10.4501 11.8329 11.0001 11.8329C11.5501 11.8329 11.9167 11.4662 11.9167 10.9162ZM11.9167 14.5829C11.9167 14.8579 11.8251 15.0412 11.6417 15.2245C11.4584 15.4079 11.2751 15.4995 10.9084 15.4995C10.6334 15.4995 10.4501 15.4079 10.2667 15.2245C10.0834 15.0412 9.99175 14.8579 9.99175 14.5829C9.99175 14.4755 10.0232 14.3995 10.0493 14.3366C10.0677 14.2922 10.0834 14.2542 10.0834 14.2162C10.0834 14.1245 10.1751 14.0329 10.2667 13.9412L10.2667 13.9412L10.2668 13.9412C10.3584 13.8495 10.4501 13.7579 10.5417 13.7579C10.7251 13.6662 10.9084 13.6662 11.0917 13.6662C11.1445 13.7189 11.1669 13.7413 11.1938 13.7508C11.2137 13.7579 11.2361 13.7579 11.2751 13.7579C11.3209 13.7579 11.3438 13.7808 11.3667 13.8037C11.3897 13.8266 11.4126 13.8495 11.4584 13.8495C11.5501 13.8495 11.6417 13.9412 11.6417 13.9412L11.6418 13.9412C11.7334 14.0329 11.8251 14.1245 11.8251 14.2162C11.9167 14.3079 11.9167 14.4912 11.9167 14.5829Z"
                fill="#DF1125"
              />
            </svg>
          </div>
        </div>
      );
    case 'Complaint':
      return (
        <div
          style={{
            paddingLeft: '0.6rem',
            paddingRight: '0.6rem',
            paddingTop: '0.2rem',
            paddingBottom: '0.2rem',
            display: 'flex',
            backgroundColor: '#fdccb7',
            borderRadius: '20px',
            marginBottom: '40px',
            width: 'fit-content',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '20px',
              height: '20px'
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.833496 9.99967C0.833496 15.083 4.91683 19.1663 10.0002 19.1663C15.0835 19.1663 19.1668 15.083 19.1668 9.99967C19.1668 4.91634 15.0835 0.833008 10.0002 0.833008C4.91683 0.833008 0.833496 4.91634 0.833496 9.99967ZM2.5 10C2.5 5.83333 5.83333 2.5 10 2.5C14.1667 2.5 17.5 5.83333 17.5 10C17.5 14.1667 14.1667 17.5 10 17.5C5.83333 17.5 2.5 14.1667 2.5 10ZM10.8335 9.99967V6.66634C10.8335 6.16634 10.5002 5.83301 10.0002 5.83301C9.50016 5.83301 9.16683 6.16634 9.16683 6.66634V9.99967C9.16683 10.4997 9.50016 10.833 10.0002 10.833C10.5002 10.833 10.8335 10.4997 10.8335 9.99967ZM10.7502 13.6663C10.7502 13.7497 10.6668 13.833 10.5835 13.9163C10.4168 14.083 10.2502 14.1663 9.91683 14.1663C9.66683 14.1663 9.50016 14.083 9.3335 13.9163C9.16683 13.7497 9.0835 13.583 9.0835 13.333C9.0835 13.2354 9.11209 13.1663 9.13578 13.1091C9.15253 13.0687 9.16683 13.0342 9.16683 12.9997C9.16683 12.9163 9.25016 12.833 9.3335 12.7497C9.5835 12.4997 9.91683 12.4163 10.2502 12.583C10.2918 12.583 10.3127 12.6038 10.3335 12.6247C10.3543 12.6455 10.3752 12.6663 10.4168 12.6663C10.5002 12.6663 10.5835 12.7497 10.5835 12.7497C10.6252 12.7913 10.646 12.833 10.6668 12.8747C10.6877 12.9163 10.7085 12.958 10.7502 12.9997C10.8335 13.083 10.8335 13.2497 10.8335 13.333C10.8335 13.3747 10.8127 13.4372 10.7918 13.4997C10.771 13.5622 10.7502 13.6247 10.7502 13.6663Z"
                fill="#FD7036"
              />
            </svg>
          </div>
        </div>
      );
    case 'Intro/Networking':
      return (
        <div
          style={{
            paddingLeft: '0.6rem',
            paddingRight: '0.6rem',
            paddingTop: '0.2rem',
            paddingBottom: '0.2rem',
            display: 'flex',
            backgroundColor: '#afdbff',
            borderRadius: '20px',
            marginBottom: '40px',
            width: 'fit-content',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '20px',
              height: '20px'
            }}
          >
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.933 13.9743C13.5747 13.3327 14.4913 12.8743 15.4997 12.8743C17.5163 12.8743 19.1663 14.5244 19.1663 16.541C19.1663 18.5577 17.5163 20.2077 15.4997 20.2077C13.483 20.2077 11.833 18.5577 11.833 16.541C11.833 16.3577 11.8559 16.1973 11.8788 16.0368C11.9018 15.8764 11.9247 15.716 11.9247 15.5327L7.06634 12.691C6.42467 13.3327 5.50801 13.791 4.49967 13.791C2.48301 13.791 0.833008 12.141 0.833008 10.1243C0.833008 8.10768 2.48301 6.45768 4.49967 6.45768C5.50801 6.45768 6.42467 6.91602 7.06634 7.55768L12.0163 4.71602C12.0006 4.65311 11.9849 4.59289 11.9696 4.53445C11.8959 4.25229 11.833 4.01144 11.833 3.70768C11.833 1.69102 13.483 0.0410156 15.4997 0.0410156C17.5163 0.0410156 19.1663 1.69102 19.1663 3.70768C19.1663 5.72435 17.5163 7.37435 15.4997 7.37435C14.4913 7.37435 13.5747 6.91602 12.8413 6.27435L7.98301 9.11602C7.99871 9.17881 8.0144 9.23891 8.02964 9.29725L8.0297 9.29748L8.02973 9.29758C8.10343 9.57975 8.16634 9.82059 8.16634 10.1243C8.16634 10.3077 8.14342 10.4681 8.12051 10.6285C8.09759 10.7889 8.07467 10.9494 8.07467 11.1327L12.933 13.9743ZM17.3327 3.70833C17.3327 2.7 16.5077 1.875 15.4993 1.875C14.491 1.875 13.666 2.7 13.666 3.70833C13.666 4.71667 14.491 5.54167 15.4993 5.54167C16.5077 5.54167 17.3327 4.71667 17.3327 3.70833ZM4.49935 11.9577C3.49102 11.9577 2.66602 11.1327 2.66602 10.1243C2.66602 9.11602 3.49102 8.29102 4.49935 8.29102C5.50768 8.29102 6.33268 9.11602 6.33268 10.1243C6.33268 11.1327 5.50768 11.9577 4.49935 11.9577ZM13.666 16.5404C13.666 17.5487 14.491 18.3737 15.4993 18.3737C16.5077 18.3737 17.3327 17.5487 17.3327 16.5404C17.3327 15.532 16.5077 14.707 15.4993 14.707C14.8577 14.707 14.216 15.0737 13.941 15.6237C13.7577 15.8987 13.666 16.1737 13.666 16.5404Z"
                fill="#1EA5FC"
              />
            </svg>
          </div>
        </div>
      );
    case 'Invitation':
      return (
        <div
          style={{
            paddingLeft: '0.6rem',
            paddingRight: '0.6rem',
            paddingTop: '0.2rem',
            paddingBottom: '0.2rem',
            display: 'flex',
            backgroundColor: '#ABEBDA',
            borderRadius: '20px',
            marginBottom: '40px',
            width: 'fit-content',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '20px',
              height: '20px'
            }}
          >
            <svg
              width="21"
              height="19"
              viewBox="0 0 21 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.4375 0.75C4.9875 0.75 3.0625 2.675 3.0625 5.125C3.0625 7.575 4.9875 9.5 7.4375 9.5C9.8875 9.5 11.8125 7.575 11.8125 5.125C11.8125 2.675 9.8875 0.75 7.4375 0.75ZM14.875 17.375V15.625C14.875 13.175 12.95 11.25 10.5 11.25H4.375C1.925 11.25 0 13.175 0 15.625V17.375C0 17.9 0.35 18.25 0.875 18.25C1.4 18.25 1.75 17.9 1.75 17.375V15.625C1.75 14.1375 2.8875 13 4.375 13H10.5C11.9875 13 13.125 14.1375 13.125 15.625V17.375C13.125 17.9 13.475 18.25 14 18.25C14.525 18.25 14.875 17.9 14.875 17.375ZM4.8125 5.125C4.8125 6.6125 5.95 7.75 7.4375 7.75C8.925 7.75 10.0625 6.6125 10.0625 5.125C10.0625 3.6375 8.925 2.5 7.4375 2.5C5.95 2.5 4.8125 3.6375 4.8125 5.125ZM18.375 7.75H20.125C20.65 7.75 21 8.1 21 8.625C21 9.15 20.65 9.5 20.125 9.5H18.375V11.25C18.375 11.775 18.025 12.125 17.5 12.125C16.975 12.125 16.625 11.775 16.625 11.25V9.5H14.875C14.35 9.5 14 9.15 14 8.625C14 8.1 14.35 7.75 14.875 7.75H16.625V6C16.625 5.475 16.975 5.125 17.5 5.125C18.025 5.125 18.375 5.475 18.375 6V7.75Z"
                fill="#5B7971"
              />
            </svg>
          </div>
        </div>
      );
    case 'Newsletter/Promo':
      return (
        <div
          style={{
            paddingLeft: '0.6rem',
            paddingRight: '0.6rem',
            paddingTop: '0.2rem',
            paddingBottom: '0.2rem',
            display: 'flex',
            backgroundColor: '#ffe0c1',
            borderRadius: '20px',
            marginBottom: '40px',
            width: 'fit-content',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '20px',
              height: '20px'
            }}
          >
            <svg
              width="16"
              height="22"
              viewBox="0 0 16 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.3333 7.31809C15.3333 3.29314 12.0333 0 7.99996 0C3.96663 0 0.666626 3.29314 0.666626 7.31809C0.666626 9.69647 1.76663 11.8004 3.50829 13.0811L2.49996 20.948C2.40829 21.3139 2.59163 21.6798 2.86663 21.8628C3.14163 22.0457 3.50829 22.0457 3.87496 21.8628L7.99996 19.3929L12.125 21.8628C12.2166 21.9543 12.4 21.9543 12.5833 21.9543C12.7666 21.9543 12.95 21.8628 13.0416 21.7713C13.3166 21.5884 13.5 21.2225 13.4083 20.8565L12.4 13.0811C14.2333 11.8004 15.3333 9.69647 15.3333 7.31809ZM2.50012 7.31767C2.50012 4.29896 4.97512 1.8291 8.00012 1.8291C11.0251 1.8291 13.5001 4.29896 13.5001 7.31767C13.5001 10.3364 11.0251 12.8062 8.00012 12.8062C4.97512 12.8062 2.50012 10.3364 2.50012 7.31767ZM8.45852 17.563L11.3919 19.3011L10.7502 14.0869C9.92519 14.4528 9.00852 14.6358 8.00019 14.6358C6.99185 14.6358 6.07519 14.4528 5.25019 14.0869L4.60852 19.3011L7.54185 17.563C7.81685 17.3801 8.18352 17.3801 8.45852 17.563Z"
                fill="#fd820b"
              />
            </svg>
          </div>
        </div>
      );
    case 'Sale/Offer':
      return (
        <div
          style={{
            paddingLeft: '0.6rem',
            paddingRight: '0.6rem',
            paddingTop: '0.2rem',
            paddingBottom: '0.2rem',
            display: 'flex',
            backgroundColor: '#D4DCFF',
            borderRadius: '20px',
            marginBottom: '40px',
            width: 'fit-content',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '20px',
              height: '20px'
            }}
          >
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.83196 1.83333H0.916667C0.410406 1.83333 0 1.42293 0 0.916667C0 0.410406 0.410406 0 0.916667 0H4.58333C5.02024 0 5.39643 0.308353 5.48217 0.736764L6.25206 4.58333H21.0833C21.6584 4.58333 22.0915 5.10678 21.9838 5.67172L20.5158 13.3694C20.2563 14.676 19.0976 15.6087 17.7833 15.5833L8.8909 15.5832C7.55905 15.6087 6.40041 14.676 6.14116 13.3707L4.60972 5.71927C4.60351 5.69396 4.59835 5.66824 4.59429 5.64215L3.83196 1.83333ZM6.4165 19.2518C6.4165 20.3527 7.14924 21.0867 8.24834 21.0867C9.34744 21.0867 10.0802 20.3527 10.0802 19.2518C10.0802 18.1509 9.34744 17.417 8.24834 17.417C7.14924 17.417 6.4165 18.1509 6.4165 19.2518ZM16.5 19.2503C16.5 20.3503 17.2327 21.0837 18.3318 21.0837C19.4309 21.0837 20.1637 20.3503 20.1637 19.2503C20.1637 18.1503 19.4309 17.417 18.3318 17.417C17.2327 17.417 16.5 18.1503 16.5 19.2503ZM7.93924 13.0126L6.61914 6.41699H19.9755L18.7164 13.0194C18.6312 13.4481 18.245 13.759 17.801 13.7505L8.87347 13.7503C8.41196 13.759 8.02575 13.4481 7.93924 13.0126Z"
                fill="#1e538f"
              />
            </svg>
          </div>
        </div>
      );
    case 'New Lead':
      return (
        <div
          style={{
            paddingLeft: '0.6rem',
            paddingRight: '0.6rem',
            paddingTop: '0.2rem',
            paddingBottom: '0.2rem',
            display: 'flex',
            backgroundColor: '#91daf4',
            borderRadius: '20px',
            marginBottom: '40px',
            width: 'fit-content',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '20px',
              height: '20px'
            }}
          >
            <svg
              width="14"
              height="23"
              viewBox="0 0 14 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.29134 10.2083H7.91634V5.625H11.583C12.133 5.625 12.4997 5.25833 12.4997 4.70833C12.4997 4.15833 12.133 3.79167 11.583 3.79167H7.91634V1.04167C7.91634 0.491667 7.54967 0.125 6.99967 0.125C6.44967 0.125 6.08301 0.491667 6.08301 1.04167V3.79167H4.70801C2.41634 3.79167 0.583008 5.625 0.583008 7.91667C0.583008 10.2083 2.41634 12.0417 4.70801 12.0417H6.08301V16.625H1.49967C0.949674 16.625 0.583008 16.9917 0.583008 17.5417C0.583008 18.0917 0.949674 18.4583 1.49967 18.4583H6.08301V21.2083C6.08301 21.7583 6.44967 22.125 6.99967 22.125C7.54967 22.125 7.91634 21.7583 7.91634 21.2083V18.4583H9.29134C11.583 18.4583 13.4163 16.625 13.4163 14.3333C13.4163 12.0417 11.583 10.2083 9.29134 10.2083ZM4.70768 10.2083C3.42435 10.2083 2.41602 9.2 2.41602 7.91667C2.41602 6.63333 3.42435 5.625 4.70768 5.625H6.08268V10.2083H4.70768ZM7.91602 16.6243H9.29102C10.5743 16.6243 11.5827 15.616 11.5827 14.3327C11.5827 13.0493 10.5743 12.041 9.29102 12.041H7.91602V16.6243Z"
                fill="#0f6d8e"
              />
            </svg>
          </div>
        </div>
      );
    case 'Existing Client':
      return (
        <div
          style={{
            paddingLeft: '0.6rem',
            paddingRight: '0.6rem',
            paddingTop: '0.2rem',
            paddingBottom: '0.2rem',
            display: 'flex',
            backgroundColor: '#bfccf0',
            borderRadius: '20px',
            marginBottom: '40px',
            width: 'fit-content',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '20px',
              height: '20px'
            }}
          >
            <svg
              width="22"
              height="20"
              viewBox="0 0 22 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.79167 0.833008C5.225 0.833008 3.20833 2.84967 3.20833 5.41634C3.20833 7.98301 5.225 9.99967 7.79167 9.99967C10.3583 9.99967 12.375 7.98301 12.375 5.41634C12.375 2.84967 10.3583 0.833008 7.79167 0.833008ZM15.5833 18.2497V16.4163C15.5833 13.8497 13.5667 11.833 11 11.833H4.58333C2.01667 11.833 0 13.8497 0 16.4163V18.2497C0 18.7997 0.366667 19.1663 0.916667 19.1663C1.46667 19.1663 1.83333 18.7997 1.83333 18.2497V16.4163C1.83333 14.858 3.025 13.6663 4.58333 13.6663H11C12.5583 13.6663 13.75 14.858 13.75 16.4163V18.2497C13.75 18.7997 14.1167 19.1663 14.6667 19.1663C15.2167 19.1663 15.5833 18.7997 15.5833 18.2497ZM5.0415 5.41602C5.0415 6.97435 6.23317 8.16602 7.7915 8.16602C9.34984 8.16602 10.5415 6.97435 10.5415 5.41602C10.5415 3.85768 9.34984 2.66602 7.7915 2.66602C6.23317 2.66602 5.0415 3.85768 5.0415 5.41602ZM20.4417 6.60801C20.8083 6.24134 21.3583 6.24134 21.725 6.60801C22.0917 6.97467 22.0917 7.52467 21.725 7.89134L18.0583 11.558C17.875 11.7413 17.6 11.833 17.4167 11.833C17.2333 11.833 16.9583 11.7413 16.775 11.558L14.9417 9.72467C14.575 9.35801 14.575 8.80801 14.9417 8.44134C15.3083 8.07467 15.8583 8.07467 16.225 8.44134L17.4167 9.63301L20.4417 6.60801Z"
                fill="#4662b2"
              />
            </svg>
          </div>
        </div>
      );
    case 'Other':
      return (
        <div
          style={{
            paddingLeft: '0.6rem',
            paddingRight: '0.6rem',
            paddingTop: '0.2rem',
            paddingBottom: '0.2rem',
            display: 'flex',
            backgroundColor: '#ecf2d7',
            borderRadius: '20px',
            marginBottom: '40px',
            width: 'fit-content',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '20px',
              height: '20px'
            }}
          >
            <svg
              width="18"
              height="4"
              viewBox="0 0 18 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.75 2.00033C0.75 0.991992 1.575 0.166992 2.58333 0.166992C3.59167 0.166992 4.41667 0.991992 4.41667 2.00033C4.41667 3.00866 3.59167 3.83366 2.58333 3.83366C1.575 3.83366 0.75 3.00866 0.75 2.00033ZM10.8333 2.00033C10.8333 3.00866 10.0083 3.83366 9 3.83366C7.99167 3.83366 7.16667 3.00866 7.16667 2.00033C7.16667 0.991992 7.99167 0.166992 9 0.166992C10.0083 0.166992 10.8333 0.991992 10.8333 2.00033ZM15.4167 0.166992C14.4083 0.166992 13.5833 0.991992 13.5833 2.00033C13.5833 3.00866 14.4083 3.83366 15.4167 3.83366C16.425 3.83366 17.25 3.00866 17.25 2.00033C17.25 0.991992 16.425 0.166992 15.4167 0.166992Z"
                fill="#999d8c"
              />
            </svg>
          </div>
        </div>
      );
    case 'Spam':
      return (
        <div
          style={{
            paddingLeft: '0.6rem',
            paddingRight: '0.6rem',
            paddingTop: '0.2rem',
            paddingBottom: '0.2rem',
            display: 'flex',
            backgroundColor: '#e8e8e8',
            borderRadius: '20px',
            marginBottom: '40px',
            width: 'fit-content',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '20px',
              height: '20px'
            }}
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="#4a4a4a"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.5832 10.417C11.9165 10.7503 11.9165 11.2503 11.5832 11.5837C11.4165 11.7503 11.2498 11.8337 10.9998 11.8337C10.7498 11.8337 10.5832 11.7503 10.4165 11.5837L5.99984 7.16699L1.58317 11.5837C1.4165 11.7503 1.24984 11.8337 0.999837 11.8337C0.749837 11.8337 0.583171 11.7503 0.416504 11.5837C0.0831706 11.2503 0.0831706 10.7503 0.416504 10.417L4.83317 6.00033L0.416504 1.58366C0.0831706 1.25033 0.0831706 0.750326 0.416504 0.416992C0.749837 0.0836589 1.24984 0.0836589 1.58317 0.416992L5.99984 4.83366L10.4165 0.416992C10.7498 0.0836589 11.2498 0.0836589 11.5832 0.416992C11.9165 0.750326 11.9165 1.25033 11.5832 1.58366L7.1665 6.00033L11.5832 10.417Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
      );
    default:
      return (
        <div
          style={{
            paddingLeft: '0.6rem',
            paddingRight: '0.6rem',
            paddingTop: '0.2rem',
            paddingBottom: '0.2rem',
            display: 'flex',
            backgroundColor: '#9ED3FE',
            borderRadius: '20px',
            marginBottom: '40px',
            width: 'fit-content',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '20px',
              height: '20px'
            }}
          >
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.916504 11.0003C0.916504 5.40866 5.40817 0.916992 10.9998 0.916992C16.5915 0.916992 21.0832 5.40866 21.0832 11.0003C21.0832 16.592 16.5915 21.0837 10.9998 21.0837C5.40817 21.0837 0.916504 16.592 0.916504 11.0003ZM2.75 11C2.75 15.5833 6.41667 19.25 11 19.25C15.5833 19.25 19.25 15.5833 19.25 11C19.25 6.41667 15.5833 2.75 11 2.75C6.41667 2.75 2.75 6.41667 2.75 11ZM11.9163 11.0007V14.6673C11.9163 15.2173 11.5497 15.584 10.9997 15.584C10.4497 15.584 10.083 15.2173 10.083 14.6673V11.0007C10.083 10.4507 10.4497 10.084 10.9997 10.084C11.5497 10.084 11.9163 10.4507 11.9163 11.0007ZM11.9165 7.33366C11.9165 7.05866 11.8248 6.87533 11.6415 6.69199C11.4582 6.50866 11.0915 6.41699 10.8165 6.41699C10.7638 6.46971 10.7414 6.49211 10.7144 6.50162C10.6945 6.50866 10.6721 6.50866 10.6332 6.50866C10.5873 6.50866 10.5644 6.53158 10.5415 6.55449C10.5186 6.57741 10.4957 6.60033 10.4498 6.60033L10.3582 6.69199C10.1748 6.87533 10.0832 7.05866 10.0832 7.33366C10.0832 7.60866 10.1748 7.79199 10.3582 7.97533C10.3582 7.97533 10.3582 8.06699 10.4498 8.06699C10.4957 8.06699 10.5186 8.08991 10.5415 8.11283C10.5644 8.13574 10.5873 8.15866 10.6332 8.15866C10.6859 8.21137 10.7083 8.23377 10.7352 8.24329C10.7551 8.25033 10.7775 8.25033 10.8165 8.25033H10.9998C11.2748 8.25033 11.4582 8.15866 11.6415 7.97533C11.8248 7.79199 11.9165 7.60866 11.9165 7.33366Z"
                fill="#467DD8"
              />
            </svg>
          </div>
        </div>
      );
  }
};

export default EmailIntentIcons;
