import React from 'react';
import Spin from 'ui/Spin';
import styles from './threadModalMsgSelector.module.css'
import leftChevron from 'assets/images/commsHub/left-chevron.svg';
import rightChevron from 'assets/images/commsHub/right-chevron.svg';

const ThreadModalMsgSelector = ({ currentMailIndex, maxMailIndex, onPrevClick, onNextClick, isLoading }) => {
    const leftChevronStyle = { backgroundImage: `url(${leftChevron})` };
    const rightChevronStyle = { backgroundImage: `url(${rightChevron})` };

    return <div className={styles['thread-modal-msg-selector']}>
        {isLoading && <Spin className={styles['email-card-spinner']} />}
        {!isLoading && <>
            {currentMailIndex !== 0 && <button style={leftChevronStyle} onClick={onPrevClick}></button>}
            <span>
                {currentMailIndex + 1} of {maxMailIndex + 1}
            </span>
            {currentMailIndex !== maxMailIndex && <button style={rightChevronStyle} onClick={onNextClick}></button>}
        </>}
    </div>
}

export default ThreadModalMsgSelector