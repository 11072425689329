/* eslint-disable */
//TODO fix eslint error
import React, { Component, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { logInSuccess } from 'store/actions/auth';

export function withAuth(WrappedComponent) {
  class Auth extends Component {
    render() {
      const {
        history: {
          location: { pathname }
        },
        isAuthenticated
      } = this.props;

      const queryParams = new URLSearchParams(location.search);
      const tokenValue = queryParams.get('token');
      if (tokenValue) {
        localStorage.setItem('token', tokenValue);
        logInSuccess();
      }

      return isAuthenticated || tokenValue ? (
        <WrappedComponent {...this.props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              linkFrom: pathname
            }
          }}
        />
      );
    }
  }
  return connect(state => ({
    isAuthenticated: state.auth.isAuthenticated
  }))(Auth);
}

export function withoutAuth(WrappedComponent) {
  class Auth extends Component {
    render() {
      const {
        isAuthenticated,
        location: { state }
      } = this.props;

      return isAuthenticated ? (
        <Redirect to={state ? state.linkFrom : '/'} />
      ) : (
        <WrappedComponent {...this.props} />
      );
    }
  }
  return connect(state => ({
    isAuthenticated: state.auth.isAuthenticated
  }))(Auth);
}
