import React from 'react';
import PropTypes from 'prop-types';

import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';

import ClickAwayListener from 'ui/ClickAwayListener/index';
import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import Textfield from 'ui/Textfield';

import ContactTitleSelect from 'shared/components/UI/ContactTitleSelect';

import useEditMode from 'ui/hooks/useEditMode';

import useContactName from 'shared/hooks/contacts/useContactName';
import Tooltip from 'ui/Tooltip/index';

const buttonStyles = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: '100%',
  textAlign: 'left',
};

const ContactNameInput = (props) => {
  const {
    defaultView,
    withSalutations,
    onChange,
    displayPlaceholder,
    contactId,
  } = props;

  const {
    mode,
    setEditMode,
    setDisplayMode,
  } = useEditMode(defaultView);

  const {
    titleId,
    firstName,
    lastName,
    fullName,
  } = useContactName(contactId, { returnValue: 'object' });

  if (mode === 'display') {
    const displayName = fullName.trim() === ''
      ? displayPlaceholder
      : fullName;

    return (
      <Tooltip
        title={displayName}
        placement="left"
      >
        <NakedButton
          onClick={setEditMode}
          style={buttonStyles}
        >
          <Typography
            noMargin
            ellipsis
            variant="title2"
          >
            {displayName}
          </Typography>
        </NakedButton>
      </Tooltip>
    );
  }

  return (
    <ClickAwayListener onClickAway={setDisplayMode}>
      <div>
        <Flex
          spacing={1}
          alignItems="baseline"
        >
          {
            withSalutations && (
              <ContactTitleSelect
                value={titleId}
                handleChange={onChange('title')}
              />
            )
          }
          <Textfield
            value={firstName}
            onChange={onChange('first_name')}
            label="First Name"
          />
          <Textfield
            value={lastName}
            onChange={onChange('last_name')}
            label="Last Name"
          />
        </Flex>
      </div>
    </ClickAwayListener>
  );
};

ContactNameInput.defaultProps = {
  defaultView: 'edit',
  withSalutations: false,
  displayPlaceholder: '_______',
};

const {
  bool,
  func,
  number,
  oneOf,
  string,
} = PropTypes;

ContactNameInput.propTypes = {
  defaultView: oneOf(['display', 'edit']),
  withSalutations: bool,
  onChange: func.isRequired,
  displayPlaceholder: string,
  contactId: number.isRequired,
};

export default ContactNameInput;
