import { connect } from 'react-redux';

import { debounce } from 'shared/utility';

import {
  updateContact,
} from 'store/actions/realtorContacts';

import selectors from 'store/selectors';

import ContactInfoConcise from './ContactInfoConcise';

const mapStateToProps = (state, ownProps) => {
  const {
    contactId,
  } = ownProps;

  const contact = selectors.realtorContacts.byId(state, contactId);
  const settings = selectors.appCustomization.settings(state);

  return {
    contactId: contact?.id,
    type: contact?.type,
    isVip: !!contact?.is_vip,
    isPastClient: !!contact?.is_past_client,
    labels: contact?.labels,
    withSalutations: !!settings.salutations_on,
  };
};

export default connect(mapStateToProps)(ContactInfoConcise);
