exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".threadModal_thread-modal__2CqNA {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n  grid-gap: 8px;\n  gap: 8px;\n}\n\n.threadModal_thread-modal__2CqNA > :first-child {\n  flex: 1 1 100%;\n}\n\n.threadModal_thread-modal__2CqNA > :not(:first-child) {\n  flex-grow: 0;\n  flex-shrink: 1;\n  flex-basis: calc(50% - 8px);\n  max-width: calc(50% - 8px);\n}\n\n.threadModal_thread-modal-spinner-area__8pDmo {\n  min-width: 100%;\n  min-height: 56vh;\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n}", ""]);

// exports
exports.locals = {
	"thread-modal": "threadModal_thread-modal__2CqNA",
	"thread-modal-spinner-area": "threadModal_thread-modal-spinner-area__8pDmo"
};