/* eslint-disable consistent-return */
import datetime from 'refactored/utils/datetime';

import requests from 'api/requests';
import { callAlert } from 'ui/Alert/Alert';

import uploadAttachments from './uploadAttachments';

const addDays = (days) => datetime().add(days, 'day').format();
const addHours = (hours) => datetime().add(hours, 'hour').format();

const onSubmit = async (formData) => {
  const {
    propertyId,

    offerAmount,
    expiresIn,
    closesIn,
    contingenciesIn,
    financeType,
    notes,

    contingencyInspection,
    contingencyAppraisal,
    contingencyLoan,
    agreementFiles,

    firstName,
    lastName,
    phone,
    email,
    broker,

    buyerFirstName,
    buyerLastName,

  } = formData;

  const offerData = {

    type: 1,

    buyer_contact_id: null,
    buyer_agent_contact_id: null,

    realtor_client_id: null,

    seller_contact_id: null,
    seller_agent_contact_id: null,

    realtor_prospect_id: null,

    realtor_property_id: propertyId,

    closing_date: addDays(closesIn),
    contingency_period_end: addDays(contingenciesIn),
    expiry_date: addHours(expiresIn),

    amount: offerAmount,

    contingency_inspection: contingencyInspection,
    contingency_appraisal: contingencyAppraisal,
    contingency_loan: contingencyLoan,

    financing_type: financeType,

    ex_submission_first_name: firstName,
    ex_submission_last_name: lastName,
    ex_submission_phone: phone,
    ex_submission_email: email,
    ex_submission_broker: broker,

    ex_submission_buyer_first_name: buyerFirstName,
    ex_submission_buyer_last_name: buyerLastName,

    note: notes,
  };

  try {
    const response = await requests.external.realtor.offers.create(offerData);

    if (agreementFiles) {
      await uploadAttachments(response.data)(agreementFiles);
    }

    callAlert.success('Thank you! Your offer has been submitted.');
    return response.data;
  } catch (error) {
    callAlert.error('Something went wrong!');
  }
};

export default onSubmit;
