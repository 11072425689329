import React from 'react';
import PropTypes from 'prop-types';

import { currency } from 'refactored/utils/numbers';

import Skeleton from '@material-ui/lab/Skeleton';

import Typography from 'ui/Typography';

import {
  Root,
  TopWrapper,
  Dot,
  Stick,
  Label,
  ItemTitle,
} from './styledItems';

const TimelineItem = (props) => {
  const {
    label,
    dotId,
    isFirst,
    isLast,
    type,
    price,
    isSelected,
    anchorSelector,
    userRole,
    counterNumber,
    onClick,
    selectedItemColor,
  } = props;

  return (
    <Root data-anchor={anchorSelector}>
      <TopWrapper onClick={onClick}>
        {
          type === 'cancelled' && (
            <ItemTitle isSelected selectedItemColor="#ff526b">
              <Typography
                variant="subtitle2"
                tag="span"
                weight={500}
                color="contrast"
                noMargin
                style={{ textTransform: 'uppercase' }}
              >
                offer cancelled
              </Typography>
            </ItemTitle>
          )
        }
        {
          type === 'declined' && (
            <ItemTitle isSelected selectedItemColor="#ff526b">
              <Typography
                variant="subtitle2"
                tag="span"
                weight={500}
                color="contrast"
                noMargin
                style={{ textTransform: 'uppercase' }}
              >
                {`${userRole} | ${counterNumber ? `Counter ${counterNumber}` : 'Original'}`}
                <br />
                declined
              </Typography>
            </ItemTitle>
          )
        }
        {
          type === 'accepted' && (
            <ItemTitle isSelected selectedItemColor="#2ecc71">
              <Typography
                variant="subtitle2"
                tag="span"
                weight={500}
                color="contrast"
                noMargin
                style={{ textTransform: 'uppercase' }}
              >
                {`${userRole} | ${counterNumber ? `Counter ${counterNumber}` : 'Original'}`}
                <br />
                accepted
              </Typography>
            </ItemTitle>
          )
        }
        {
          type === 'loading' && (
            <>
              <Skeleton
                width={70}
                height={20}
              // style={{ marginBottom: -2 }}
              />
              <Skeleton
                width={100}
                height={20}
              />
            </>
          )
        }
        {
          !type && (
            <>
              <Typography
                variant="title2"
                tag="p"
                color={isSelected ? 'success' : 'disabled'}
                noMargin
                style={{ marginBottom: 5 }}
              >
                {
                  price && currency.full(price)
                }
              </Typography>
              <ItemTitle
                isSelected={isSelected}
                selectedItemColor={selectedItemColor}
              >
                <Typography
                  variant="subtitle2"
                  tag="span"
                  weight={500}
                  color="contrast"
                  noMargin
                  style={{ textTransform: 'uppercase' }}
                >
                  {`${userRole} | ${counterNumber ? `Counter ${counterNumber}` : 'Original'}`}
                </Typography>
              </ItemTitle>
            </>
          )
        }
        {/* <Stick /> */}
      </TopWrapper>
      <Dot
        isFirst={isFirst}
        isLast={isLast}
        id={dotId}
      />
      {
        label && (
          <Label>
            {type === 'loading'
              ? <Skeleton width={40} />
              : (
                <Typography
                  variant="caption"
                  noMargin
                >
                  {label}
                </Typography>
              )}
          </Label>
        )
      }
    </Root>
  );
};

TimelineItem.defaultProps = {
  label: null,
  isFirst: false,
  isLast: false,
  isSelected: false,
  counterNumber: null,
  selectedItemColor: '#1584ff',
  type: null,
};

const {
  bool,
  number,
  string,
  func,
} = PropTypes;

TimelineItem.propTypes = {
  label: string,
  dotId: string.isRequired,
  isFirst: bool,
  isLast: bool,
  price: number.isRequired,
  isSelected: bool,
  anchorSelector: string.isRequired,
  userRole: string.isRequired,
  counterNumber: number,
  onClick: func.isRequired,
  selectedItemColor: string,
  type: string,
};

export default TimelineItem;
