import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';

import SearchCategoryBlock from 'shared/components/UI/SearchCategoryBlock';
import SearchItemLead from 'shared/modules/SearchItemLead';

import withLeadData from 'sections/Leads/hocs/withLeadData';

const ItemContent = withLeadData(SearchItemLead);

const Item = ({ lead, handleClick }) => (handleClick
  ? (
    <NakedButton
      onClick={handleClick(lead.id)}
    >
      <ItemContent
        objectId={lead.id}
      />
    </NakedButton>
  )
  : (
    <Link
      to={`/leads/${lead.id}`}
    >
      <ItemContent
        objectId={lead.id}
      />
    </Link>
  ));

const LeadsSearch = (props) => {
  const [searchResult, setSearchResult] = useState([]);

  const {
    search,
    onSearch,
    onSelect,
  } = props;

  useEffect(() => {
    const fetchData = async () => {
      const data = await onSearch(search);
      setSearchResult(data);
    };

    fetchData();
  }, [search]);

  const handleClick = onSelect
    ? useCallback((leadId) => (event) => {
      event.preventDefault();
      onSelect(26)(leadId);
    })
    : null;

  return (
    <SearchCategoryBlock
      title="leads"
      iconName="users"
    >
      {
        searchResult && searchResult.map((lead) => (
          <Item
            key={lead?.id}
            lead={lead}
            handleClick={handleClick}
          />
        ))
      }
    </SearchCategoryBlock>
  );
};

LeadsSearch.defaultProps = {
  search: '',
};

const {
  func,
  string,
} = PropTypes;

LeadsSearch.propTypes = {
  search: string,
  onSearch: func.isRequired,
};

export default LeadsSearch;
