import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  optimizedRealtorContactsActions,
} from 'store/actions/realtorContacts';

import {
  setFilterItem,
} from 'store/actions/realtorContactsFilter';

import ContactsList from './ContactsList';

const mapStateToProps = (state) => {
  const {
    realtorContacts: {
      contactsByFilterCount,
    },
  } = state;

  const {
    filterId,
  } = selectors.realtorContacts.contactsFilter(state);

  const contacts = selectors.realtorContacts.contactsList(state, filterId);
  const itemsCount = contactsByFilterCount[filterId];

  return {
    contacts,
    hasMore: itemsCount
      ? contacts.length < itemsCount
      : false,
    loadMore: () => {
      optimizedRealtorContactsActions.getContactsForStoreFilter(contacts.length || 0, 30);
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearSearch: () => {
    dispatch(setFilterItem('search')(null));
  },
  setContactDealRole: (dealRole) => {
    dispatch(setFilterItem('dealRole')(dealRole));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactsList);
