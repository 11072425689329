import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';
import FilterChip from 'shared/components/UI/FilterChip';

import {
  Root,
  Title,
  ResetButton,
} from './styledItems';

const ActiveFilters = (props) => {
  const {
    activeFilters,
    onDelete,
    onClear,
  } = props;

  if (activeFilters.length < 1) {
    return null;
  }

  const handleDelete = useCallback((key) => () => {
    onDelete(key);
  });

  return (
    <Root>
      <Title>Filter:</Title>
      {
        activeFilters.map((item) => (
          <FilterChip
            title={item.title}
            value={item.value}
            onDelete={onDelete ? handleDelete(item.key) : null}
          />
        ))
      }
      <NakedButton onClick={onClear}>
        <ResetButton>
          Reset Filter
        </ResetButton>
      </NakedButton>
    </Root>
  );
};

ActiveFilters.defaultProps = {
  activeFilters: [],
};

const {
  arrayOf,
  func,
  number,
  oneOfType,
  shape,
  string,
} = PropTypes;

ActiveFilters.propTypes = {
  activeFilters: arrayOf(shape({
    key: oneOfType([string, number]),
    value: oneOfType([string, number]),
    title: string,
  })),
  onDelete: func.isRequired,
  onClear: func.isRequired,
};

export default ActiveFilters;
