import {
  DELETE_TASK_FROM_BACKLOG_FILTERED,
} from 'store/actionTypes/tasks';

const deleteTaskFromBacklogFiltered = (taskId) => async (
  dispatch,
  getState,
) => {
  const {
    tasksV2: {
      activeTaskPlannerBacklogFilter,
    },
  } = getState();

  dispatch({
    type: DELETE_TASK_FROM_BACKLOG_FILTERED,
    taskId,
    activeTaskPlannerBacklogFilter,
  });
};

export default deleteTaskFromBacklogFiltered;
