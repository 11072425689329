exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".toolbarDisplayOptions_toolbar-display-options-container__3otrC {\n  display: flex;\n  flex-direction: row;\n  grid-gap: 12px;\n  gap: 12px;\n  justify-content: space-evenly;\n  padding: 6px 6px;\n  border-radius: 100px;\n  background: #F6F6F6;\n}", ""]);

// exports
exports.locals = {
	"toolbar-display-options-container": "toolbarDisplayOptions_toolbar-display-options-container__3otrC"
};