import {
  CHAT_SELECT_EMPLOYEE_MODAL_VISIBLE_ON,
  CHAT_SELECT_EMPLOYEE_MODAL_VISIBLE_OFF,
} from 'store/actionTypes/chat';

export const showSelectEmployeeModal = () => (dispatch) => {
  dispatch({
    type: CHAT_SELECT_EMPLOYEE_MODAL_VISIBLE_ON,
  });
};

export const hideSelectEmployeeModal = () => (dispatch) => {
  dispatch({
    type: CHAT_SELECT_EMPLOYEE_MODAL_VISIBLE_OFF,
  });
};
