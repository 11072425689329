import { callAlert } from 'ui/Alert';

import { SET_NEW_UNREAD_MESSAGE_COUNT } from 'store/actionTypes/chat';

const addUnreadMessageNotification = (chatId) => async (dispatch, getState) => {
  try {
    const {
      chat: {
        activeChatId,
        newMessageCount,
      },
    } = getState();

    if (activeChatId !== chatId) {
      dispatch({
        type: SET_NEW_UNREAD_MESSAGE_COUNT,
        payload: {
          newMessageCount: newMessageCount + 1,
        },
      });
    }
  } catch {
    callAlert.error('Error!');
  }
};

export default addUnreadMessageNotification;
