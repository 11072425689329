import React, { useEffect, useContext } from 'react';
import ThemeContext from 'ui/ThemeProvider/context';
import getPreferredNavbarColor from 'shared/utils/navbarSettings/getNavbarColor';

const NavColor = () => {
  const { handleNavColorChange } = useContext(ThemeContext);

  useEffect(() => {
    async function fetchColor() {
      const preferredColor = await getPreferredNavbarColor();
      handleNavColorChange(preferredColor);
    }
    fetchColor();
  }, []);

  return (
    <div />
  );
};

export default NavColor;
