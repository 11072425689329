import React from 'react';
import PropTypes from 'prop-types';

import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';

import noOp from 'shared/utils/noOp';

import ContactAvatar from 'shared/modules/RealtorContactAvatar';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import Button from 'ui/Button';

import {
  Root,
} from './styledItems';

const KeyPeopleItem = (props) => {
  const {
    contactId,
    onAdd,
    onRemove,
    onClick,
    label,
    name,
  } = props;

  return (
    <Root
      justifyContent="space-between"
      alignItems="center"
      fullWidth
    >
      <Flex
        spacing={2}
        alignItems="center"
      >
        {
          contactId && (
            <ContactAvatar
              contactId={contactId}
              size="sm"
            />
          )
        }
        <div>
          {
            contactId && name && (
              <NakedButton onClick={onClick}>
                <Typography
                  variant="title2"
                  tag="h3"
                  weight="bold"
                  noMargin
                >
                  {name}
                </Typography>
              </NakedButton>
            )
          }
          {
            label && (
              <Typography
                color="focus"
                noMargin
              >
                {label}
              </Typography>
            )
          }
        </div>
      </Flex>
      {
        contactId && (
          <Button
            icon="x"
            onClick={onRemove}
            variant="tertiary"
          />
        )
      }
      {
        !contactId && (
          <Button
            icon="plus"
            onClick={onAdd}
            variant="tertiary"
          />
        )
      }
    </Root>
  );
};

KeyPeopleItem.defaultProps = {
  contactId: null,
  onAdd: noOp,
  onClick: noOp,
  onRemove: noOp,
  label: null,
};

const {
  func,
  number,
  string,
} = PropTypes;

KeyPeopleItem.propTypes = {
  contactId: number,
  onAdd: func,
  onClick: func,
  onRemove: func,
  label: string,
};

export default KeyPeopleItem;
