import updateDealProduct from './updateDealProduct';

const getProducts = (relationId) => (updateData) => async (dispatch, getState) => {
  const {
    leads: {
      LeadID: selectedDealId,
    },
  } = getState();

  try {
    await dispatch(updateDealProduct(selectedDealId)(relationId)(updateData));
  } catch (error) {
    console.error(error);
  }
};

export default getProducts;
