import {
  SET_CRM_SELECTED_LEAD_ROWS,
} from 'store/actionTypes/crm';

const setSelectedLeadRows = (ids) => ({
  type: SET_CRM_SELECTED_LEAD_ROWS,
  payload: ids,
});

export default setSelectedLeadRows;
