import styled from 'styled-components';

import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';

import Elevation from 'ui/Elevation';

export const InnerWrapper = styled(Elevation)`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 24px 32px;
  background-color: ${(props) => props.theme?.palette?.[props.disabled ? 'background' : 'background-flat']};
  border: 1px solid;
  border-color: ${(props) => (props.selected ? props.theme?.palette?.focus : 'transparent')};
`;

export const ButtonWrapper = styled(NakedButton)`
  && {
    width: 100%;
    text-align: left;
  }
`;
