import openNewProspectModal from './openNewProspectModal';
import closeNewProspectModal from './closeNewProspectModal';
import setNewProspectModalPropertyRequirement from './setNewProspectModalPropertyRequirement';
import setNewProspectModalAttachments from './setNewProspectModalAttachments';

export {
  openNewProspectModal,
  closeNewProspectModal,
  setNewProspectModalPropertyRequirement,
  setNewProspectModalAttachments,
};
