import {
  SET_SELECTED_CUSTOMER_PAYMENT,
} from 'store/actionTypes/customerPayments';

const setSelected = (id) => ({
  type: SET_SELECTED_CUSTOMER_PAYMENT,
  payload: id,
});

export default setSelected;
