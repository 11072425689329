import requests from 'api/requests';

import getById from './getPropertyViewingById';

const overwritePropertyViewingRelations = (relationType) => (viewingId) => (data) => async (dispatch) => {
  try {
    await requests.realtor.propertyViewings.overwriteRelations(relationType)(viewingId)(data);

    dispatch(getById(viewingId));
  } catch (error) {
    console.error(error);
  }
};

export default overwritePropertyViewingRelations;
