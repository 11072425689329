import createDealProduct from './createDealProduct';

const createProduct = (productData) => async (dispatch, getState) => {
  const {
    leads: {
      LeadID: selectedDealId,
    },
  } = getState();

  try {
    dispatch(createDealProduct(selectedDealId)(productData));
  } catch (error) {
    console.error(error);
  }
};

export default createProduct;
