exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".senderPillImageDetailed_detailed-sender-image__18Cog {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n  height: 38px;\n  min-width: 38px;\n  background-color: #1e9896;\n  border-radius: 100%;\n  align-self: flex-end;\n  border: none;\n  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);\n  cursor: pointer;\n}\n\n.senderPillImageDetailed_detailed-sender-image-text__1Ccxr {\n  color: white;\n  height: 10px;\n  font-size: 14px;\n  line-height: 10px;\n  font-weight: 700;\n  font-family: \"Hind-Bold\";\n}", ""]);

// exports
exports.locals = {
	"detailed-sender-image": "senderPillImageDetailed_detailed-sender-image__18Cog",
	"detailed-sender-image-text": "senderPillImageDetailed_detailed-sender-image-text__1Ccxr"
};