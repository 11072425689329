import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import * as Sentry from '@sentry/react';

import ErrorBoundary from 'components/ErrorBoundary';
import InternalError from 'components/ErrorPages/InternalError';

import App from './App';

// const facebookKey = process.env.REACT_APP_FB_VERIFICATION_KEY

const Root = (props) => {
  const [globalError, setGlobalError] = useState(null);

  useEffect(() => {
    window.addEventListener('unhandledrejection', (error) => {
      console.info('Unhandled rejection in root:', error);

      if (error?.reason?.message === 'URI error' && error?.reason?.sourceURL?.includes('maps.googleapis')) { // hotfix for google maps error
        return;
      }

      if (error?.reason?.stack?.includes('maps.googleapis')) {
        return;
      }

      /* eslint-disable max-len */
      if ( // hotfix for microphone permissions
        ((error?.reason?.message === 'Permission denied' || error?.reason?.message === 'Permission dismissed') && error?.reason?.name === 'NotAllowedError')
        || (error?.reason?.message === 'No permission to use requested device' && error?.reason?.name === 'SecurityError')
      ) {
        return;
      }
      /* eslint-enable max-len */

      setGlobalError(error);

      if (process.env.REACT_APP_SENTRY_DSN) {
        Sentry.captureException(error, {
          tags: {
            section: 'system-wide',
          },
        });
      }
    });
  }, []);

  const resetGlobalError = useCallback(() => {
    setGlobalError(null);
  });

  if (globalError) {
    return (
      <InternalError
        onAfterReload={resetGlobalError}
      />
    );
  }

  return (
    <>
      <Helmet>
        {/* {
          facebookKey && (
            <meta
              name="facebook-domain-verification"
              content={facebookKey}
            />
          )
        } */}
      </Helmet>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </>
  );
};

Root.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

Root.propTypes = {

};

export default Root;
