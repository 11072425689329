import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import currentUserSelector from 'store/selectors/users/currentUser';
import ProfileImage from './ProfileImage';
import styles from './profileThumbnail.module.css';
import ProfileDropdown from 'refactored/components/shared/profileDropdown/ProfileDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useChangeProfilePictureModal } from 'refactored/components/shared/providers/modals/ChangeProfilePictureModal.provider';

const ProfileThumbnail = () => {
    const user = useSelector(currentUserSelector);
    const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
    const toggleProfileModal = () => { setIsProfileModalOpen(!isProfileModalOpen) };
    const anchorMenuRef = useRef()
    const { toggleChangeProfilePictureModal } = useChangeProfilePictureModal();

    return (
        <div className={styles['profile-thumbnail-container']}>
            <ProfileImage imageUrl={user?.avatar}
                altText={user?.fullName}
                onClick={() => {
                    toggleChangeProfilePictureModal(true)
                }}
            />
            <span className={styles['profile-thumbnail-name']} onClick={toggleProfileModal}>{`${user?.fullName}`}</span>
            <div className={styles['profile-thumbnail-email']} onClick={toggleProfileModal} ref={anchorMenuRef} >
                {`${user?.email}`}
                <FontAwesomeIcon icon="angle-down" />
            </div>
            <ProfileDropdown isOpen={isProfileModalOpen} onClose={toggleProfileModal} anchorElement={anchorMenuRef.current} />
        </div>
    );
};

export default ProfileThumbnail;
