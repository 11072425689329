import React from 'react';
import Flex from 'ui/Flex';
import Spin from 'ui/Spin';
import styles from './emailCard.module.css';
import intents from 'refactored/constants/email/intents';
import relationships from 'refactored/constants/email/relationships';
import types from 'refactored/constants/email/types';
import sentiments from 'refactored/constants/email/sentiments';
import EmailCardHeader from './EmailCardHeader';
import EmailCardFooter from './EmailCardFooter';
import { useThreadModal } from '../../../shared/providers/modals/ThreadModal.provider';
const EmailCard = ({
    toggleAIViewer = () => { },
    hasBeenAnalyzed = false,
    hasAnalyzedAllEmails = false,
    email,
    isLoading = false
}) => {
    const { openThreadModalByEmail } = useThreadModal();

    if (!email || email.isLoading) return (
        <div className={styles['email-card-placeholder']}>
            <Spin className={styles['email-card-spinner']} />
        </div>
    )

    if (!isLoading && !email) return (
        <Flex fullWidth justifyContent="center">
            <h1>No email data for that message id</h1>
            <h3>Please try reloading this page</h3>
        </Flex>
    )

    if ((!isLoading && email) || hasBeenAnalyzed || hasAnalyzedAllEmails) {
        const receivedEmailImportance = email?.clean_sympleteAnalysis?.importance;
        if (receivedEmailImportance < 0) {
            email.clean_sympleteAnalysis.importance = 20;
        } else if (receivedEmailImportance > 100) {
            email.clean_sympleteAnalysis.importance = 85;
        }

        const receivedEmailType = email?.clean_sympleteAnalysis?.type;
        if (!types.includes(receivedEmailType) && email?.clean_sympleteAnalysis) {
            email.clean_sympleteAnalysis.type = 'Both';
        }
        const receivedEmailIntent = email?.clean_sympleteAnalysis?.intent;
        if (!intents.includes(receivedEmailIntent) && email?.clean_sympleteAnalysis) {
            email.clean_sympleteAnalysis.intent = 'Information';
        }
        const receivedEmailRelationship =
            email?.clean_sympleteAnalysis?.relationship;

        if (!relationships.includes(receivedEmailRelationship) && email?.clean_sympleteAnalysis) {
            email.clean_sympleteAnalysis.relationship = 'Other';
        }

        const receivedEmailSentiment = email?.clean_sympleteAnalysis?.sentiment;
        if (!sentiments.includes(receivedEmailSentiment) && email?.clean_sympleteAnalysis) { email.clean_sympleteAnalysis.sentiment = 'Neutral'; }

        return (
            <div className={styles['email-card']} onClick={() => {
                openThreadModalByEmail(email)
            }}>
                <EmailCardHeader email={email} />
                <div className={styles['email-card-summary']}
                    onClick={() => { }/* toggleAIViewer(email) */}
                >
                    {email?.clean_sympleteAnalysis?.summary}
                </div>
                <EmailCardFooter email={email} />
            </div>
        );
    }
    return (
        <Flex fullWidth>
            <div>
                Something went wrong while trying to render email
            </div>
        </Flex>
    );
};

export default EmailCard;