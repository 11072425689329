import { callAlert } from 'ui/Alert';

import requests from 'api/requests';

import { SET_CHATS } from 'store/actionTypes/chat';

const getChats = () => async (dispatch) => {
  try {
    const chats = await requests.chat.getChats();

    dispatch({
      type: SET_CHATS,
      payload: chats.data,
    });
  } catch {
    callAlert.error('Error!');
  }
};

export default getChats;
