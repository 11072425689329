exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".contactPillPicker_contact-pill-picker__lyolX {\n  max-width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  position: relative;\n}\n\n.contactPillPicker_contact-pill-picker-placeholder__1ebMO {\n  margin-right: 6px;\n  pointer-events: none;\n  color: #777;\n}\n\n.contactPillPicker_contact-pill-picker-header__345Eq {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n}\n\n.contactPillPicker_contact-pill-tittle__3dIV_ {\n  font-family: \"Hind-Regular\";\n  font-size: 24px;\n  font-weight: 700;\n  line-height: 36px;\n  letter-spacing: 0px;\n}\n\n.contactPillPicker_contact-pill-picker-new-contact__3p3ZL {\n  display: flex;\n  align-items: center;\n  margin-bottom: 24px;\n}\n\n.contactPillPicker_contact-pill-picker-new-contact__3p3ZL input[type=text] {\n  border: none;\n  outline: none;\n  padding: 8px 12px;\n  font-size: 16px;\n  border-radius: 4px;\n  background: rgba(2, 35, 39, 0.0392156863);\n  margin-right: 8px;\n  width: min(350px, 70vw);\n  box-shadow: none;\n}\n\n.contactPillPicker_contact-pill-picker-new-contact__3p3ZL input[type=text]::-webkit-input-placeholder {\n  color: rgba(0, 0, 0, 0.5);\n}\n\n.contactPillPicker_contact-pill-picker-new-contact__3p3ZL input[type=text]::placeholder {\n  color: rgba(0, 0, 0, 0.5);\n}\n\n.contactPillPicker_contact-pill-picker-new-contact__3p3ZL button {\n  background-color: #3C2B84;\n  color: #fff;\n  border: none;\n  padding: 8px 16px;\n  font-size: 16px;\n  border-radius: 44px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.contactPillPicker_contact-pill-picker-new-contact__3p3ZL button:hover {\n  background-color: #512da8;\n}", ""]);

// exports
exports.locals = {
	"contact-pill-picker": "contactPillPicker_contact-pill-picker__lyolX",
	"contact-pill-picker-placeholder": "contactPillPicker_contact-pill-picker-placeholder__1ebMO",
	"contact-pill-picker-header": "contactPillPicker_contact-pill-picker-header__345Eq",
	"contact-pill-tittle": "contactPillPicker_contact-pill-tittle__3dIV_",
	"contact-pill-picker-new-contact": "contactPillPicker_contact-pill-picker-new-contact__3p3ZL"
};