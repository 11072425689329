import React, { useState, useCallback } from 'react';

import {
  Grid,
  Tabs,
  Tab,
} from '@material-ui/core';

import {
  TabContext,
} from '@material-ui/lab';

import TabLabel from 'shared/components/UI/TabLabel';

import CurrentOverview from 'sections/Today/components/CurrentOverview';
import TodayTab from 'sections/Today/components/TodayTab';
import ToDoTab from 'sections/Today/components/ToDoTab';

import OurThemeProvider from 'shared/themes/OurThemeProvider';
import withTheme from 'shared/hocs/withTheme';

import { defaultTheme } from 'shared/themes/defaultTheme';
import theme from './theme';

import {
  Root,
  StyledTabPanel,
  OverviewContainer,
  ContentContainer,
} from './styledItems';

const Today = () => {
  const Theme = withTheme(defaultTheme, theme)(OurThemeProvider);

  const [tab, setTab] = useState('today');
  const handleTabChange = useCallback((event, value) => {
    setTab(value);
  }, []);

  return (
    <Theme>
      <Root>
        <Grid
          container
          spacing={3}
          alignContent="flex-start"
          style={{ height: 'inherit' }}
        >
          {/* <OverviewContainer>
            <CurrentOverview />
          </OverviewContainer> */}

          <ContentContainer>
            <TabContext value={tab}>
              <Tabs
                variant="fullWidth"
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
              >
                <Tab
                  label={<TabLabel isSelected={tab === 'today'}>Today</TabLabel>}
                  value="today"
                />
                <Tab
                  label={(<TabLabel isSelected={tab === 'todo'}>To Do</TabLabel>)}
                  value="todo"
                />
              </Tabs>

              <StyledTabPanel
                value="today"
                index={0}
              >
                <TodayTab />
              </StyledTabPanel>
              <StyledTabPanel
                value="todo"
                index={1}
              >
                <ToDoTab />
              </StyledTabPanel>
            </TabContext>
          </ContentContainer>
        </Grid>
      </Root>
    </Theme>
  );
};

export default Today;
