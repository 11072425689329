import React, { createContext, useContext, useState } from 'react';
import useThreads from 'refactored/hooks/useThreads';
import useIntegrations from 'sections/Integrations/hooks/useIntegrations';

const CommsHubContext = createContext();

export const CommsHubProvider = ({ children }) => {
  const {
    threads,
    count,
    isLoading,
    error,
    getThreads,
    readedEmails,
    unreadedEmails,
    inProgressEmails,
    closedEmails,
    sentEmails,
    deletedEmails,
    businessEmails,
    personalEmails,
    threadsMetrics,
    threadsTypesMetrics,
    sendThreadToClosed,
    deleteEmailFromThread,
    filters,
    showTenMore,
    emailRowCount
  } = useThreads()

  const [selectedFilter, setSelectedFilter] = useState("new")
  const {
    integrationsByScope,
    isLoading: isLoadingIntegrations
  } = useIntegrations();

  const [view, setView] = useState("grid")

  const closedThreads = threads?.closed || []
  const inProgressThreads = threads?.inProgress || []
  const newThreads = threads?.unread || []
  const readThreads = threads?.read || []

  const sentEmailsSorted = sentEmails.sort((a, b) => b.date - a.date);
  const deletedEmailsSorted = deletedEmails.sort((a, b) => b.date - a.date);

  const onToggle = (key) => {
    setSelectedFilter(key)
  }

  return (
    <CommsHubContext.Provider value={{
      error,
      integrationsByScope,
      isLoading,
      threads,
      closedThreads,
      inProgressThreads,
      newThreads,
      readThreads,
      readedEmails,
      unreadedEmails,
      inProgressEmails,
      closedEmails,
      sentEmailsSorted,
      deletedEmailsSorted,
      businessEmails,
      personalEmails,
      threadsMetrics,
      threadsTypesMetrics,
      selectedFilter,
      onToggle,
      filters,
      showTenMore,
      sendThreadToClosed,
      deleteEmailFromThread,
      view,
      setView,
      emailRowCount
    }}>
      {children}
    </CommsHubContext.Provider>
  );
};

export const useCommsHub = () => {
  return useContext(CommsHubContext);
};
