import React from 'react';
import EmailCompanyMailIcon from '../emailCompanyMailIcon/EmailCompanyMailIcon';
import SenderPillImageDetailed from './SenderPillImageDetailed';
import styles from './senderPillDetailed.module.css';

const SenderPillDetailed = ({ onlyImage = false, contact }) => {
    return (
        <div className={styles['detailed-sender-pill-wrapper']}>
            <SenderPillImageDetailed imageUrl={contact?.avatar} altText={contact?.fullName || contact?.name} />
            {
                !onlyImage && <div className={styles['detailed-sender-pill-details']}>
                    <span> {`${contact?.fullName || contact?.name}`}</span>
                    <span> <EmailCompanyMailIcon /> {`${contact?.email}`}</span>
                </div>
            }
        </div>
    );
};

export default SenderPillDetailed;