import React from 'react';
import PropTypes from 'prop-types';

import { Popconfirm } from 'antd';

import { currency } from 'refactored/utils/numbers';
import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';

import Typography from 'ui/Typography';

import {
  Root,
} from './styledItems';

const AcceptedBlock = (props) => {
  const {
    amount,
    onUndoAcceptance,
  } = props;

  return (
    <Root>
      <div>
        <Typography
          variant="h4"
          tag="span"
          color="contrast"
          weight={500}
          noMargin
        >
          {`offer accepted — ${currency.full(amount)}`}
        </Typography>
      </div>

      <Popconfirm
        title="Are you sure to undo?"
        onConfirm={onUndoAcceptance}
      >
        <NakedButton>
          <Typography
            variant="subtitle2"
            tag="span"
            noMargin
            textDecoration="underline"
            weight={600}
          >
            Undo
          </Typography>
        </NakedButton>
      </Popconfirm>
    </Root>
  );
};

const {
  func,
  number,
} = PropTypes;

AcceptedBlock.propTypes = {
  amount: number.isRequired,
  onUndoAcceptance: func.isRequired,
};

export default AcceptedBlock;
