import React from 'react';
import Toolbar from './toolbar/Toolbar';
import styles from './board.module.css'
import ThreadModal from 'refactored/components/shared/modals/threadModal/ThreadModal';
import ChangeProfilePictureModal from 'refactored/components/shared/modals/changeProfilePictureModal/ChangeProfilePictureModal';
import GridView from './views/GridView';
import ListView from './views/ListView';
import { useCommsHub } from 'refactored/components/shared/providers/data/CommsHub.provider';

const Board = () => {
  const { threadsMetrics, threadsTypesMetrics, selectedFilter, onToggle, view } = useCommsHub();
  return (
    <div className={styles['board']}>
      <Toolbar filters={{ threadsMetrics, threadsTypesMetrics }} selectedFilter={selectedFilter} onToggle={onToggle} />
      {view == "grid" && <GridView selectedFilter={selectedFilter} />}
      {view == "list" && <ListView selectedFilter={selectedFilter} />}
      <ThreadModal />
      <ChangeProfilePictureModal />
    </div>
  )
}

export default Board
