exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".senderPillDetailed_detailed-sender-pill-wrapper__3wDkL {\n  width: -webkit-max-content;\n  width: max-content;\n  display: flex;\n  align-items: center;\n  grid-gap: 4px;\n  gap: 4px;\n  padding: 4px;\n  border-radius: 100px;\n  background-color: #ffffff;\n  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.senderPillDetailed_extra-count__1xASa {\n  width: 30px;\n  height: 30px;\n  display: flex;\n  place-items: center;\n  justify-content: center;\n}\n\n.senderPillDetailed_profile-image__1qgD6 {\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  overflow: hidden;\n  margin-right: 12px;\n}\n\n.senderPillDetailed_profile-image__1qgD6 img {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n\n.senderPillDetailed_initials__2gfwO {\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 14px;\n  background-color: #ddd;\n  color: #555;\n}\n\n.senderPillDetailed_detailed-sender-pill-details__3rGWY {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  max-width: 130px;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.senderPillDetailed_detailed-sender-pill-details__3rGWY > span {\n  max-width: 100%;\n  color: #333;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.senderPillDetailed_detailed-sender-pill-details__3rGWY > :first-child {\n  font-family: \"Hind-SemiBold\";\n  font-size: 15px;\n  line-height: 15px;\n  font-weight: 500;\n}\n\n.senderPillDetailed_detailed-sender-pill-details__3rGWY > :last-child {\n  font-family: \"Hind-Regular\";\n  font-size: 13px;\n  font-weight: 400;\n  letter-spacing: 0px;\n}", ""]);

// exports
exports.locals = {
	"detailed-sender-pill-wrapper": "senderPillDetailed_detailed-sender-pill-wrapper__3wDkL",
	"extra-count": "senderPillDetailed_extra-count__1xASa",
	"profile-image": "senderPillDetailed_profile-image__1qgD6",
	"initials": "senderPillDetailed_initials__2gfwO",
	"detailed-sender-pill-details": "senderPillDetailed_detailed-sender-pill-details__3rGWY"
};