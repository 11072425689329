exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".navbar_navbar__1sp4f {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  padding: 12px 24px;\n  position: -webkit-sticky;\n  position: sticky;\n}\n\n.navbar_navbar-symplete-title__1KJnm {\n  border-radius: 5px;\n  width: min(30vw, 280px);\n  display: flex;\n  justify-content: center;\n  align-items: flex-end;\n  margin-left: auto;\n  margin-right: auto;\n  text-transform: uppercase;\n  font-family: \"Hind-Bold\";\n  font-size: 34px;\n  font-weight: 700;\n  line-height: 16px;\n  letter-spacing: 0.13em;\n  color: black;\n}\n\n.navbar_navbar-icon-btns-area__3vXvF {\n  width: min(30vw, 280px);\n  display: flex;\n  justify-content: flex-end;\n  grid-gap: 8px;\n  gap: 8px;\n}\n\n.navbar_navbar-profile-thumbnail-area__1ZRq8 {\n  width: min(30vw, 280px);\n}", ""]);

// exports
exports.locals = {
	"navbar": "navbar_navbar__1sp4f",
	"navbar-symplete-title": "navbar_navbar-symplete-title__1KJnm",
	"navbar-icon-btns-area": "navbar_navbar-icon-btns-area__3vXvF",
	"navbar-profile-thumbnail-area": "navbar_navbar-profile-thumbnail-area__1ZRq8"
};