import React from 'react';
import styles from './threadModalResponseMailForm.module.css';
import SendEmailForm from '../../../forms/SendEmailForm';

const ThreadModalResponseMail = ({ initialTo, initialCC, msgReplyId, responseSubject, sugestedBody, replyMode }) => {
    return <div className={styles['thread-modal-response-mail']}>
        <SendEmailForm
            initialTo={initialTo}
            initialCC={initialCC}
            msgReplyId={msgReplyId}
            responseSubject={responseSubject}
            replyMode={replyMode}
            sugestedBody={sugestedBody}
        />
    </div>
}

export default ThreadModalResponseMail