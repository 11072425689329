import React from 'react';
import Tooltip from 'ui/Tooltip';
import EmailFileIcon from 'assets/images/email-file-icon.png';
import EmailLinkIcon from 'assets/images/email-link-icon.png';
import Attachment from 'refactored/components/shared/attachmentPill/Attachment';
import EmailTypeIcons from 'sections/Communications/components/EmailTypeIcons/EmailTypeIcons';
import styles from './emailCardFooter.module.css';

export const EmailCardFooter = ({ email }) => {
    const biggestFileName = email?.clean_sympleteAnalysis?.filename || null;
    const emailFilesArray = email?.files || [];
    const sympleteToken = email?.sympleteTokenId || null;
    let fileForAttachment = null;
    if (biggestFileName && emailFilesArray.length > 0) {
        fileForAttachment = emailFilesArray.find(
            file => file.filename === biggestFileName
        );
    }
    return <div className={styles['email-card-footer']} >
        <div className={styles['email-card-link']}>
            {email?.clean_sympleteAnalysis?.link ? (
                <Tooltip title={email?.clean_sympleteAnalysis?.link}>
                    <div className={styles['email-card-link-container']} >
                        {' '}
                        <img
                            src={EmailLinkIcon}
                            width={20}
                            height={20}
                        />
                        <a className={styles['email-card-link-a-tag']}
                            href={email?.clean_sympleteAnalysis?.link}
                            target="_blank"
                            rel="noopener noreferer"
                        >
                            {email?.clean_sympleteAnalysis?.link}
                        </a>
                    </div>
                </Tooltip>
            ) : (
                <div></div>
            )}
            {email?.clean_sympleteAnalysis?.filename ? (
                <Attachment
                    tokenId={sympleteToken}
                    attachment={fileForAttachment}
                    EmailFileIcon={EmailFileIcon}
                    filename={email?.clean_sympleteAnalysis?.filename}
                />
            ) : null}
        </div>
        <div
            className={styles['email-card-types-container']}
            onClick={() => { }}
        >
            {email?.clean_sympleteAnalysis?.type ? (
                <Tooltip title={email?.clean_sympleteAnalysis?.type}>
                    {EmailTypeIcons(email?.clean_sympleteAnalysis?.type)}
                </Tooltip>
            ) : (
                <></>
            )}
            {email?.clean_sympleteAnalysis?.to_do_items.length > 0 ? (
                <Tooltip title="To Do Items Completed">
                    <div className={styles['email-card-items-to-complete']} >
                        <div
                            className={styles['email-card-items-to-complete-text']}
                        >
                            {email?.clean_sympleteAnalysis?.to_do_items.length}
                        </div>
                    </div>
                </Tooltip>
            ) : null}
        </div>
    </div>
}

export default EmailCardFooter