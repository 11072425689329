import React from 'react';
import styles from './cardsList.module.css';
import EmailListItem from '../emailListItem/EmailListItem';

const CardsList = (props) => {
    const { emails, hasNumber } = {
        emails: [],
        hasNumber: true,
        ...props
    }
    return (
        <div className={styles['cards-list']}>
            {emails?.map((email, index) => (
                <EmailListItem
                    key={email?.id}
                    hasNumber={hasNumber}
                    index={index + 1}
                    email={email}
                />
            ))}
        </div>
    );
};

export default CardsList;