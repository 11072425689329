import {
  createAttachment,
} from 'store/actions/attachments';
import { getUserOptimized } from 'store/actions/users';

import { resetNewContactForm } from 'store/actions/crm/newContactForm';

import dataUrlToFile from 'shared/utils/attachments/dataUrlToFile';

import createContact from './createContact';

const splitFullName = (fullName) => {
  const nameParts = fullName.split(' ');
  const firstName = nameParts[0];
  const lastName = nameParts[1]
    ? nameParts.slice(1, nameParts.length).join(' ')
    : null;

  return {
    firstName,
    lastName,
  };
};

const createContactFromNewContactForm = () => async (dispatch, getState) => {
  const {
    newContactForm,
  } = getState();

  const {
    firstName,
    lastName,
  } = splitFullName(newContactForm.contactName);

  const contactData = {
    firstName,
    lastName,
    MobilePhone: newContactForm.workPhone,
    Email: newContactForm.workEmail,
    Role: newContactForm.jobRole,
    company: newContactForm.company,
    owners: {
      employees: newContactForm.assignedUsers,
    },
    permission_type: newContactForm.permission_type,
    permissions: {
      employees: newContactForm.permissions.employees,
      teams: newContactForm.permissions.teams,
      offices: newContactForm.permissions.offices,
    },
  };

  try {
    const contactId = await dispatch(createContact(contactData));

    if (newContactForm.avatar) {
      const {
        name,
        type,
        fileUrl,
      } = newContactForm.avatar;

      const appModule = 'contact-leads-logo';
      const fileMeta = {
        SalesContactsID: contactId,
      };

      const file = await dataUrlToFile(fileUrl, name, type);

      await dispatch(createAttachment(appModule)(contactId)(file, fileMeta));

      URL.revokeObjectURL(fileUrl);
      getUserOptimized('salesContact', contactId);
    }

    dispatch(resetNewContactForm());

    return contactId;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default createContactFromNewContactForm;
