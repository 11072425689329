import React from 'react';
import styles from './cardsColumn.module.css';
import EmailCard from '../emailCard/EmailCard';
import PrimaryButton from '../../../shared/buttons/primaryButton/PrimaryButton';

const CardsColumn = (props) => {
  const { title, emails, showTenMore } = props

  return (
    <div className={styles['cards-column']}>
      <span className={styles['cards-column-title']}>{title}</span>
      <div className={styles['cards-column-cards-scrolleable']}>
        {emails?.map((email, index) => (
          <EmailCard
            key={'email-card' + index}
            index={index}
            email={email}
          />
        ))}
        {(emails?.length > 9) &&
          <PrimaryButton onClick={showTenMore}> Show 10 More </PrimaryButton >
        }
      </div>
    </div>
  );
};

export default CardsColumn;
