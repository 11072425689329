import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';
import PropertyDetailsFull from 'sections/Properties/modules/PropertyDetailsFull';

const PropertyDetailsFullExpandable = (props) => {
  const {
    isExpanded,
    propertyId,
  } = props;

  const [expanded, setExpanded] = useState(isExpanded);

  const open = () => setExpanded(true);
  const close = () => setExpanded(false);

  return (
    <>
      <Button
        onClick={expanded ? close : open}
        fullWidth
        variant="secondary"
        icon="list"
      >
        {expanded ? 'Hide full details' : 'Show full details'}
      </Button>

      {expanded && (
        <PropertyDetailsFull
          propertyId={propertyId}
        />
      )}
    </>
  );
};

PropertyDetailsFullExpandable.defaultProps = {
  isExpanded: false,
};

const {
  bool,
  number,
} = PropTypes;

PropertyDetailsFullExpandable.propTypes = {
  isExpanded: bool,
  propertyId: number.isRequired,
};

export default PropertyDetailsFullExpandable;
