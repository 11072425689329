import openNewClientModal from './openNewClientModal';
import closeNewClientModal from './closeNewClientModal';
import setNewClientModalProperty from './setNewClientModalProperty';
import setNewClientModalAttachments from './setNewClientModalAttachments';

export {
  openNewClientModal,
  closeNewClientModal,
  setNewClientModalProperty,
  setNewClientModalAttachments,
};
