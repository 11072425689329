import styled from 'styled-components';

import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';

export const StyledButton = styled(NakedButton)`
&& {
  margin-top: auto;
  margin-bottom: auto;
}
`;

export default {
  StyledButton,
};
