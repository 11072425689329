import styled from 'styled-components';
import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';

// eslint-disable-next-line import/prefer-default-export
export const StyledButton = styled(NakedButton)`
  && {
    background: ${({
  theme,
  backgroundColor,
}) => (theme?.palette?.[backgroundColor] || 'transparent')};
    border-radius: ${(props) => (props.round ? '50%' : '10px')};
    width: 70px;
    height: 70px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 10px 0px;

    ${({ isDown }) => isDown && 'span { color: lightgrey; }'}

    &&:hover {
      span {
        font-weight: 400;
      }
    }
  }
`;
