import React from 'react';
import getInitialsFromString from 'refactored/utils/getInitialsFromString';
import styles from './profileImage.module.css';

const ProfileImage = ({ imageUrl, altText, onClick }) => {
    const bgStyle = { backgroundImage: `url(${imageUrl})`, };

    return (
        <div style={bgStyle} className={styles['profile-image']} onClick={onClick}>
            <span className={styles['profile-image-text']}>
                {imageUrl ? null : altText ? getInitialsFromString(altText) : null}
            </span>
        </div>
    );
};

export default ProfileImage;
