import {
  SET_DRAGGED_WAREHOUSE_LINE_ITEMS,
} from 'store/actionTypes/warehouse';

const setDragged = (ids) => ({
  type: SET_DRAGGED_WAREHOUSE_LINE_ITEMS,
  payload: ids,
});

export default setDragged;
