import React from "react";
import { receiveLogout } from 'store/actions/auth';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import styles from './profileDropdown.module.css';
import isSectionUnderConstruction from 'shared/utils/isSectionUnderConstruction';
import { triggerChangeModal } from 'store/actions/auth';

const ProfileDropdown = ({ isOpen, onClose, anchorElement }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const redirectToSettings = () => history.push('./settings');
    const logout = () => dispatch(receiveLogout());
    const openChangePassword = () => {
        onClose()
        dispatch(triggerChangeModal())
    };

    return (
        <Menu
            open={isOpen}
            anchorEl={anchorElement}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            className={styles['profile-dropdown-menu']}
        >
            <div className={styles['profile-dropdown-menu-item']} onClick={openChangePassword}>
                Change Password
            </div>
            {!isSectionUnderConstruction('settings') && (
                <div className={styles['profile-dropdown-menu-item']} onClick={redirectToSettings}>
                    Settings
                </div>
            )}
            <div className={styles['profile-dropdown-border-box']}></div>
            <div className={styles['profile-dropdown-menu-item']}>
                <a href="mailto:support@symplete.com">Contact Support</a>
            </div>
            <div className={styles['profile-dropdown-menu-item']} onClick={logout}>
                Log out
            </div>
        </Menu>
    );
}

export default ProfileDropdown;
