import {
  CLOSE_MOVE_PACKAGE_LINE_ITEM_MODAL,
  OPEN_MOVE_PACKAGE_LINE_ITEM_MODAL,
} from 'store/actionTypes/warehouse';

export const openMovePackageLineItemModal = () => ({
  type: OPEN_MOVE_PACKAGE_LINE_ITEM_MODAL,
});

export const closeMovePackageLineItemModal = () => ({
  type: CLOSE_MOVE_PACKAGE_LINE_ITEM_MODAL,
});
