export const customerView = {
  STATUSES: [
    {
      id: [1],
      TITLE: 'New Ticket',
    },
    {
      id: [2],
      TITLE: 'Assigned',
    },
    {
      id: [3],
      TITLE: 'In Progress',
    },
    {
      id: [4, 5],
      TITLE: 'Pending',
    },
    {
      id: [6, 7, 8],
      TITLE: 'Resolved',
    },
  ],
};
