import React from 'react';
import useSendEmailForm from './SendEmail.hook';
import SenderPill from '../senderPill/SenderPill';
import SquarePrimaryButton from 'refactored/components//shared/buttons/squarePrimaryButton/SquarePrimaryButton'
import ContactPillPicker from '../contactPillPicker/ContactPillPicker';
import styles from './sendEmailForm.module.css';
import RichTextEditor from 'refactored/components/shared/richTextEditor/RichTextEditor';
import { RecipientInput } from './recipientInput/RecipientInput';

const SendEmailForm = ({ onSendConfirm = () => { }, initialTo = [], initialCC = [], msgReplyId, responseSubject, sugestedBody }) => {

    const {
        setTo,
        setCarbonCopy,
        setBlindCarbonCopy,
        subject, setSubject,
        body, setBody,
        sendForm,
        isTextAreaFocus,
        setIsTextAreaFocus
    } = useSendEmailForm({ onSendConfirm, initialTo, initialCC, msgReplyId, responseSubject, sugestedBody });


    return (
        <div className={styles['send-email-form']}>
            <div className={styles['send-email-form-header']}>
                <h1 title={responseSubject ? responseSubject : 'New email'}>{responseSubject ? responseSubject : 'New email'}</h1>
                <SquarePrimaryButton onClick={sendForm}>Send</SquarePrimaryButton>
            </div>
            <div className={styles['send-email-form-recipients']}>
                <ContactPillPicker
                    className={styles['recipient-input']}
                    placeholder="To:"
                    initialSelectedContacts={initialTo}
                    setContactsCallback={setTo}
                />
                <ContactPillPicker
                    className={styles['recipient-input']}
                    placeholder="Cc:"
                    initialSelectedContacts={initialCC}
                    setContactsCallback={setCarbonCopy}
                />
                <ContactPillPicker
                    placeholder="Bcc:"
                    className={styles['recipient-input']}
                    setContactsCallback={setBlindCarbonCopy}
                />
            </div>
            <div className={styles['send-email-form-subject-sender-area']}>
                <RecipientInput
                    className={styles['recipient-input']}
                    placeholder="Subject:"
                    key={responseSubject}
                    initialValue={responseSubject}
                    setInputCallback={setSubject}
                />
                <span className={styles['send-email-form-sender']}>From: <SenderPill /></span>
            </div>
            <div className={styles['send-email-form-body-area']}>
                <RichTextEditor
                    className={isTextAreaFocus ? styles['send-email-form-body-input-clicked'] : styles['send-email-form-body-input']}
                    placeholder="Ready, Set, Start typing..."
                    onContentChange={(c) => {
                        setBody(c)
                    }}
                    initialContent={sugestedBody}
                    onMouseDown={() => {
                        setIsTextAreaFocus(true)
                    }}
                />
            </div>
        </div>
    );
};

export default SendEmailForm;