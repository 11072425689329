import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    notificationsDrawerShowAction,
    notificationsDrawerHideAction,
} from 'store/actions/notifications';

const useCommsHubNavbar = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const closeAllModals = () => {
        dispatch(notificationsDrawerHideAction())
    }
    const showTodos = () => { }
    const showNotifications = () => {
        closeAllModals()
        dispatch(notificationsDrawerShowAction())
        console.log('show notifications executed')
    }


    return {
        location,
        closeAllModals,
        showTodos,
        showNotifications
    }
}

export default useCommsHubNavbar