import React from 'react';
import PropTypes from 'prop-types';

import { currency } from 'refactored/utils/numbers';

import Typography from 'ui/Typography';
import Tag from 'ui/Tag';
import Flex from 'ui/Flex';

const OfferDetailsHeader = (props) => {
  const {
    userRole,
    amount,
    propertyPrice,
    isAllCash,
    isNoContingency,
  } = props;

  const showDelta = amount && propertyPrice && (amount - propertyPrice) !== 0;

  return (
    <Flex spacing={1} flexWrap="wrap">
      <Flex spacing={1} fullWidth>
        <Typography
          variant="title2"
          style={{
            textTransform: 'capitalize',
          }}
          noMargin
        >
          {`${userRole} | ${userRole === 'buyer' ? 'Offer' : 'Counter'}`}
        </Typography>

        {
          isAllCash ? (
            <Tag variant="new">
              ALL CASH
            </Tag>
          ) : null
        }
        {
          isNoContingency ? (
            <Tag variant="new">
              NO CONTINGENCY
            </Tag>
          ) : null
        }
      </Flex>
      <Typography
        variant="title2"
        tag="span"
        color="selected"
        noMargin
        noWarp
        style={{
          marginTop: '5px',
        }}
      >
        {amount && currency.full(amount)}
        {
          showDelta && (
            <Typography
              variant="subtitle2"
              tag="span"
              color={(amount - propertyPrice) > 0 ? 'selected' : 'alert'}
              noMargin
              style={{
                marginLeft: '5px',
                verticalAlign: 'middle',
              }}
            >
              {
                `${(amount - propertyPrice) > 0 ? '+' : ''}
                ${propertyPrice && currency.short(amount - propertyPrice)}`
              }
            </Typography>
          )
        }
      </Typography>
    </Flex>
  );
};

OfferDetailsHeader.defaultProps = {
  userRole: null,
  amount: 0,
  propertyPrice: 0,
  isAllCash: false,
  isNoContingency: false,
};

const {
  bool,
  number,
  string,
} = PropTypes;

OfferDetailsHeader.propTypes = {
  userRole: string,
  amount: number,
  propertyPrice: number,
  isAllCash: bool,
  isNoContingency: bool,
};

export default OfferDetailsHeader;
