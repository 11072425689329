import styled from 'styled-components';

const InlineInput = styled.input`
  border: none;
  outline: none;
  &::hover,
  &::focus,
  &::active {
    border: none;
    outline: none;
  }
  &:placeholder-shown {
    border-bottom: 1px solid ${(props) => props.theme?.palette?.empty || 'black'}
  }

  /* flex-basis: 100%; */
  min-width: 0;
  max-width: ${(props) => props.maxWidth || 'auto'};
  color: ${(props) => props.theme?.palette?.['main-text'] || 'black'};

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${(props) => props.theme?.palette?.empty || 'rgba(0, 0, 0, 0.5)'};
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: ${(props) => props.theme?.palette?.empty || 'rgba(0, 0, 0, 0.5)'};
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: ${(props) => props.theme?.palette?.empty || 'rgba(0, 0, 0, 0.5)'};
  }
`;

export default InlineInput;
