import {
  SET_REALTOR_LEAD_VIEWERS,
} from 'store/actionTypes/realtorLeads';

const setLeadViewers = (leadId) => (viewers) => ({
  type: SET_REALTOR_LEAD_VIEWERS,
  payload: {
    leadId,
    viewers,
  },
});

export default setLeadViewers;
