exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".profileImage_profile-image__2d3A6 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n  height: min(91px, 6vw);\n  min-width: min(91px, 6vw);\n  background-color: #1e9896;\n  border-radius: 100%;\n  align-self: flex-end;\n  border: solid 3px white;\n  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);\n  cursor: pointer;\n}\n\n.profileImage_profile-image-text__2nlJD {\n  color: white;\n  height: 10px;\n  font-size: 30px;\n  line-height: 10px;\n  font-weight: 700;\n  font-family: \"Hind-Bold\";\n}", ""]);

// exports
exports.locals = {
	"profile-image": "profileImage_profile-image__2d3A6",
	"profile-image-text": "profileImage_profile-image-text__2nlJD"
};