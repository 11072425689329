import {
  SET_ADDRESSES,
} from 'store/actionTypes/addresses';

const set = (payload) => ({
  type: SET_ADDRESSES,
  payload,
});

export default set;
