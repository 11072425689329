import React, {
  useEffect,
  useState,
} from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import DateTimeInput from 'shared/inputs/DateTimeInput';
import Typography from 'ui/Typography';
import Textfield from 'ui/Textfield';
import DropdownTextfield from 'ui/DropdownTextfield';
import Icon from 'ui/Icon';
import Button from 'ui/Button';

import Property from 'sections/MLS/modules/PropertiesPreviewSelector';

import Contacts from './Contacts';
import NewContactArea from './NewContactArea';
import Textarea from 'components/Sales/PitchDetailsModal/WonLostModal/subcomponents/Textarea';
import Create from 'sections/MLS/modules/CreateProperty';

const NewViewingForm = (props) => {
  const {
    handleChange,
    handleChangeNested,
    dateTime,
    showPropertyField,
    showContactField,
    selectedPropertyId,
    minTime,
    maxTime,
    location,
    title,
    description,
    eventType
  } = props;

  const [selectedEntryType, setSelectedEntryType] = useState('followup');
  


  // const contactsWithoutRoles = !showBuyerField && !showBuyerAgentField && !showSellerAgentField;
  const contactsWithoutRoles = true;

  useEffect(() => {
    handleChange('date_time')(dateTime);
  }, {
    handleChange,
    dateTime,
  });

  const entryTypes = [
    {
      value: 'followup',
      label: 'Follow Up',
    },
    {
      value: 'walkthrough',
      label: 'Walkthrough',
    },
    {
      value: 'contingency',
      label: 'Contingency Removal',
    },
    {
      value: 'pitch',
      label: 'Listing Pitch',
    },
    {
      value: 'property',
      label: 'Property Viewing',
    },
    {
      value: 'prospect',
      label: 'Prospect Viewing',
    },
    {
      value: 'meeting',
      label: 'Meeting',
    },
    {
      value: 'escrow',
      label: 'Escrow Closing',
    },
  ];

  return (
    <form>
      <Flex
        spacing={3}
        flexWrap="wrap"
      >
        <Flex
          fullWidth
          flexDirection="column"
          spacing={2}
        >
          <Flex spacing={2}>
            <FlexItem>
              <Icon
                name="check-circle"
                color="focus"
              />
            </FlexItem>
            <FlexItem>
              <Typography
                variant="h3"
                noMargin
              >
                Event Details
              </Typography>
            </FlexItem>
          </Flex>
        </Flex>
        <Flex
          fullWidth
          spacing={2}
        >
          <Flex
            fullWidth
            spacing={2}
            flexDirection="column"
          >
            <FlexItem fullWidth>
              <Textfield
                label="Event title"
                onChange={handleChange('title')}
              />
            </FlexItem>
            <FlexItem fullWidth>
              <DateTimeInput
                value={dateTime}
                label="Date and time"
                handleChange={handleChange('date_time')}
                min={minTime}
                max={maxTime}
              />
            </FlexItem>
          </Flex>
          <Flex
            fullWidth
            spacing={2}
            flexDirection="column"
          >
            <FlexItem fullWidth>
              <DropdownTextfield
                onChange={(value) => {setSelectedEntryType(value); handleChange('eventType')}}
                value={selectedEntryType}
                options={entryTypes}
              />
            </FlexItem>
          </Flex>
        </Flex>
        <Flex
            fullWidth
            spacing={2}
            flexDirection="column"
        >
          <FlexItem fullWidth>
            <Textfield
              label="Event location or meeting link"
              onChange={handleChange('location')}
              value={location}

            />
          </FlexItem>
          <FlexItem fullWidth>
          <Textfield
              label="Event notes/description"
              onChange={handleChange('description')}

            />
          </FlexItem>
        </Flex>
        <Flex
          fullWidth
          flexDirection="column"
          spacing={2}
        >
          <Flex spacing={2}>
            <FlexItem>
              <Icon
                name="home"
                color="focus"
              />
            </FlexItem>
            <FlexItem>
              <Typography
                variant="h3"
                noMargin
              >
                Related Property
              </Typography>
            </FlexItem>
          </Flex>
        </Flex>
        <Flex
            fullWidth
            spacing={2}
            flexDirection="column"
        >
          <FlexItem fullWidth>
            <Property
              onSelect={handleChange('property_id')}
              selected={selectedPropertyId}
            />
          </FlexItem>
        </Flex>
        <Flex
          fullWidth
          flexDirection="column"
          spacing={2}
        >
          <Flex spacing={2}>
            <FlexItem>
              <Icon
                name="user"
                color="focus"
              />
            </FlexItem>
            <FlexItem>
              <Typography
                variant="h3"
                noMargin
              >
                Guests/Related Contacts
              </Typography>
            </FlexItem>
          </Flex>
        </Flex>
        <Flex
            fullWidth
            spacing={2}
        >
          <FlexItem fullWidth>
            <Contacts
              handleChange={handleChange('contacts')}
            />
          </FlexItem>
          <Create
              onSuccess={() => {}}
            />
        </Flex>
      </Flex>
    </form>
  );
};

NewViewingForm.defaultProps = {
  dateTime: null,
  showBuyerField: false,
  showBuyerAgentField: false,
  showSellerAgentField: false,
  showPropertyField: false,
  showContactField: true,
};

const {
  func,
  string,
  bool,
} = PropTypes;

NewViewingForm.propTypes = {
  handleChange: func.isRequired,
  handleChangeNested: func.isRequired,
  dateTime: string,
  showBuyerField: bool,
  showBuyerAgentField: bool,
  showSellerAgentField: bool,
  showPropertyField: bool,
  showContactField: bool,
};

export default NewViewingForm;
