exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".emailListItem_email-listitem__BH9xR {\n  background-color: #ffffff;\n  cursor: pointer;\n  max-width: 365px;\n  overflow: hidden;\n  padding: 12px 24px;\n  width: 100%;\n  min-width: 100%;\n  border-bottom: solid 1px #ddd;\n  font-family: \"Hind-Regular\";\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.emailListItem_email-listitem__BH9xR > :first-child {\n  width: 3.3333333333%;\n}\n\n.emailListItem_email-listitem__BH9xR > :nth-child(2) {\n  width: 16.6666666667%;\n}\n\n.emailListItem_email-listitem__BH9xR > :last-child {\n  width: 26.3157894737%;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  grid-gap: 6px;\n  gap: 6px;\n  flex-wrap: wrap;\n  padding: 0px 6px;\n}\n\n.emailListItem_email-listitem__BH9xR > :not(:first-child):not(:nth-child(2)):not(:last-child) {\n  flex: 1 1;\n}\n\n.emailListItem_email-listitem-placeholder__3t2sc {\n  display: flex;\n  justify-content: center;\n  align-content: center;\n  align-items: center;\n  min-width: 100%;\n  min-height: 180px;\n  margin-bottom: 12px;\n  background-color: #ffffff;\n  border-radius: 20px;\n  font-family: \"Hind-Regular\";\n}\n\n.emailListItem_email-listitem-spinner__28g1v {\n  max-height: 64px;\n}\n\n.emailListItem_email-listitem__BH9xR:hover {\n  background: #F6F6F6;\n}\n\n.emailListItem_email-listitem-summary__2ESpC {\n  font-weight: 400;\n  font-size: 16px;\n  max-height: 44px;\n  color: #423f3f;\n  max-width: 100%;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n}\n\n.emailListItem_bold__36cHP {\n  font-weight: bold;\n}\n\n.emailListItem_email-listitem-index__29GhO {\n  font-size: 36px;\n  font-weight: 400;\n  line-height: 34px;\n  letter-spacing: 0px;\n  text-align: left;\n  color: #DDDDDD;\n  width: 40px;\n  margin: 0px 8px;\n}\n\n.emailListItem_email-listitem-clock__FVIm4 {\n  width: 24px;\n  height: 24px;\n  margin-right: 4px;\n}", ""]);

// exports
exports.locals = {
	"email-listitem": "emailListItem_email-listitem__BH9xR",
	"email-listitem-placeholder": "emailListItem_email-listitem-placeholder__3t2sc",
	"email-listitem-spinner": "emailListItem_email-listitem-spinner__28g1v",
	"email-listitem-summary": "emailListItem_email-listitem-summary__2ESpC",
	"bold": "emailListItem_bold__36cHP",
	"email-listitem-index": "emailListItem_email-listitem-index__29GhO",
	"email-listitem-clock": "emailListItem_email-listitem-clock__FVIm4"
};