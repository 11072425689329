import styled from 'styled-components';

export const BgWrapper = styled.div`
  background: white;
  height: inherit;
`;

export const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* max-width: 1598px; */
  height: inherit;
  margin: 0;
  background: white;
  background-color: ${(props) => props?.theme?.palette?.['background-app'] || 'white'};
`;

export const AppBody = styled.section`
  flex: 1;
  display: flex;
  /* max-height: 100vh; */
  background-color: ${(props) => props?.theme?.palette?.navColorPrimary || 'white'};
  overflow-y: hidden;
  `;

export const AppMain = styled.main`
  overflow-y: auto;
  display: flex;
  max-height: 100%;
  width: 100%;
  background-color: ${(props) => props?.theme?.palette?.background || 'white'}; 
  margin-top: 0px;
  padding: 0px;
`;

export const WidthLimit = styled.div`
  width: 100%; 
  margin: 0 auto;
`;

export default {
  AppWrapper,
  AppBody,
  AppMain,
  BgWrapper,
};
