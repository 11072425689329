import requests from 'api/requests';

import getById from './getDisclosureById';

const addDisclosureRelations = (relationType) => (disclosureId) => (data) => async (dispatch) => {
  try {
    await requests.realtor.disclosures.addRelations(relationType)(disclosureId)(data);

    dispatch(getById(disclosureId));
  } catch (error) {
    console.error(error);
  }
};

export default addDisclosureRelations;
