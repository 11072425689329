import setFilterItem from './setFilterItem';

const setAll = () => (dispatch) => {
  dispatch(setFilterItem('status')([]));
  dispatch(setFilterItem('isOrphaned')(null));
  dispatch(setFilterItem('isClaimed')(null));
};

const setNewUnclaimed = () => (dispatch) => {
  dispatch(setFilterItem('status')([1]));
  dispatch(setFilterItem('isOrphaned')(null));
  dispatch(setFilterItem('isClaimed')(false));
};

const setRepitchUnclaimed = () => (dispatch) => {
  dispatch(setFilterItem('status')([2]));
  dispatch(setFilterItem('isOrphaned')(null));
  dispatch(setFilterItem('isClaimed')(false));
};

const setNewClaimed = () => (dispatch) => {
  dispatch(setFilterItem('status')([1]));
  dispatch(setFilterItem('isOrphaned')(null));
  dispatch(setFilterItem('isClaimed')(true));
};

const setRepitchClaimed = () => (dispatch) => {
  dispatch(setFilterItem('status')([2]));
  dispatch(setFilterItem('isOrphaned')(null));
  dispatch(setFilterItem('isClaimed')(true));
};

const setNotInterested = () => (dispatch) => {
  dispatch(setFilterItem('status')([5]));
  dispatch(setFilterItem('isOrphaned')(null));
  dispatch(setFilterItem('isClaimed')(null));
};

const setIsOrphaned = () => (dispatch) => {
  dispatch(setFilterItem('status')([]));
  dispatch(setFilterItem('isOrphaned')(true));
  dispatch(setFilterItem('isClaimed')(null));
};

const setArchived = () => (dispatch) => {
  dispatch(setFilterItem('status')([4]));
  dispatch(setFilterItem('isOrphaned')(null));
  dispatch(setFilterItem('isClaimed')(null));
};

const keyHandlers = {
  all: setAll,
  newUnclaimed: setNewUnclaimed,
  repitchUnclaimed: setRepitchUnclaimed,
  newClaimed: setNewClaimed,
  repitchClaimed: setRepitchClaimed,
  notInterested: setNotInterested,
  orphaned: setIsOrphaned,
  archived: setArchived,
};

const handleCustomFilter = (key) => (dispatch) => {
  if (keyHandlers[key]) dispatch(keyHandlers[key]());
};

export default handleCustomFilter;
