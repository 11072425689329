import React, {
  useState,
  // useCallback,
} from 'react';

import PropTypes from 'prop-types';

import Button from 'ui/Button';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Elevation from 'ui/Elevation';

import ContactsTableSearchFilter from 'sections/Contacts/modules/ContactsTableSearchFilter';
import ContactsTable from 'sections/Contacts/modules/ContactsTable';
import ExportAs from 'sections/Contacts/modules/ExportAs';

import { Root } from './styledItems';

const Contacts = (props) => {
  const {
    openNewContactModal,
  } = props;

  const [selectedContactIds, setSelectedContactIds] = useState([]);

  // const [isLeadTableFilterModalVisible, setLeadTableFilterModalVisibility] = useState(false);

  // const openLeadTableFilterModal = useCallback(() => {
  //   setLeadTableFilterModalVisibility(true);
  // }, [
  //   setLeadTableFilterModalVisibility,
  // ]);

  // const closeLeadTableFilterModal = useCallback(() => {
  //   setLeadTableFilterModalVisibility(false);
  // }, [
  //   setLeadTableFilterModalVisibility,
  // ]);

  return (
    <Root>
      <Flex spacing={2} flexWrap="wrap">
        <FlexItem fullWidth>
          <Flex
            justifyContent="space-between"
            alignItems="center"
          >
            <FlexItem xs={16}>
              <ContactsTableSearchFilter
                onFilterClick={() => { }} // eslint-disable-line react/jsx-no-bind
              />
            </FlexItem>
            <Flex spacing={2}>
              <ExportAs ids={selectedContactIds} />
              <Button
                onClick={openNewContactModal}
                variant="primary"
                icon="plus"
              >
                New Contact
              </Button>
            </Flex>
          </Flex>
        </FlexItem>
        <Elevation
          rounded="m"
          hideOverflow
        >
          <ContactsTable
            onRowSelect={setSelectedContactIds}
          />
        </Elevation>
      </Flex>
    </Root>
  );
};

const {
  func,
} = PropTypes;

Contacts.propTypes = {
  openNewContactModal: func.isRequired,
};

export default Contacts;
