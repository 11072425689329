/* eslint-disable camelcase, max-len */

import React from 'react';

import { datetime } from 'refactored/utils/datetime';
import { between } from 'refactored/utils/datetime';

import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import Checkbox from 'ui/Checkbox';

import CurrencyInput from 'ui/CurrencyInput/CurrencyInput';
import DropdownTextfield from 'ui/DropdownTextfield';
import Textfield from 'ui/Textfield';

// import Agreement from './Agreement';

import { FINANCING_TYPES_ARRAY } from 'constants/realtor.constants';
import OfferDateInput from './OfferDateInput/OfferDateInput';

import SubTitle from './SubTitle';

import { BackgroundHighlight, EqualityHighlight } from './styledItems';

const PreviousOfferForm = (props) => {
  const {
    previousNumber,
    representing,
    offerData,
    equality,
  } = props;

  return (
    <Flex
      fullWidth
      flexDirection="column"
      spacing={3}
    >
      <Flex
        fullWidth
      >
        <Typography variant="h2">

          {`${previousNumber ? 'Counter' : 'Initial Offer'} ${previousNumber || ''} | ${representing === 'Seller' ? 'Buyer' : 'Seller'}`}
        </Typography>
      </Flex>

      <Flex
        fullWidth
        spacing={3}
      >
        <Flex
          fullWidth
          flexDirection="column"
          spacing={2}
        >
          <EqualityHighlight
            show={equality.amount}
            redHighlight
          >
            <SubTitle>Purchase Price</SubTitle>
          </EqualityHighlight>
          <CurrencyInput
            disabled
            value={offerData?.amount}
          />
        </Flex>

        <Flex
          fullWidth
          flexDirection="column"
          spacing={2}
        >
          <SubTitle>Offer Exiprty</SubTitle>
          <OfferDateInput
            disabled
            defaultValue={between(datetime(), offerData?.expiry_date, 'hour')}
            presetDate={offerData?.expiry_date}
          />
        </Flex>
      </Flex>

      <Flex
        fullWidth
        spacing={3}
      >
        <Flex
          fullWidth
          flexDirection="column"
          spacing={2}
        >
          <EqualityHighlight
            show={equality.financing_type}
            redHighlight
          >
            <SubTitle>Financing</SubTitle>
          </EqualityHighlight>
          <DropdownTextfield
            disabled
            options={FINANCING_TYPES_ARRAY}
            value={offerData?.financing_type}
          />
        </Flex>

        <Flex
          fullWidth
          flexDirection="column"
          spacing={2}
        >
          <SubTitle>Closing Date</SubTitle>
          <BackgroundHighlight
            spacing={1}
            alignItems="center"
            fullWidth
          >
            <OfferDateInput
              disabled
              type="day"
              defaultValue={between(datetime(), offerData?.closing_date, 'day')}
              presetDate={offerData?.closing_date}
            />
          </BackgroundHighlight>
        </Flex>
      </Flex>

      <Flex fullWidth>
        <Flex
          fullWidth
          flexDirection="column"
          spacing={2}
        >
          <EqualityHighlight
            show={
              equality.contingency_appraisal
              || equality.contingency_inspection
              || equality.contingency_loan
            }
            redHighlight
          >
            <SubTitle>Contingencies</SubTitle>
          </EqualityHighlight>
          <Flex
            spacing={2}
            alignItems="center"
          >
            <Checkbox
              label="No Contingencies"
              disabled
            />
            <Flex
              alignItems="center"
              spacing={2}
            >
              <Checkbox
                label="Inspection"
                disabled
                checked={offerData.contingency_inspection}
              />
              <Checkbox
                label="Appraisal"
                disabled
                checked={offerData.contingency_appraisal}
              />
              <Checkbox
                label="Loan"
                disabled
                checked={offerData.contingency_loan}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex fullWidth>
        <Flex
          fullWidth
          flexDirection="column"
          spacing={2}
        >
          <SubTitle>Contingency Period</SubTitle>

          <OfferDateInput
            disabled
            type="day"
            defaultValue={between(datetime(), offerData?.contingency_period_end, 'day')}
            presetDate={offerData.contingency_period_end}
          />
        </Flex>
      </Flex>

      <Flex fullWidth>
        <Flex
          fullWidth
          flexDirection="column"
          spacing={2}
        >
          <SubTitle>Additional Offer Details</SubTitle>
          <Textfield
            fullWidth
            textarea
            placeholder="Notes"
            disabled
            value={offerData.note}
          />
        </Flex>
      </Flex>

    </Flex>
  );
};

const {
  number,
  string,
  shape,
} = PropTypes;

PreviousOfferForm.propTypes = {
  previousNumber: number.isRequired,
  representing: string.isRequired,
  offerData: shape({}).isRequired,
  equality: shape({}).isRequired,
};

export default PreviousOfferForm;
