import styled from 'styled-components';

import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';

const layouts = {
  horizontal: 'row',
  vertical: 'column',
};

const paddings = {
  horizontal: '5px 16px',
  vertical: '12px 24px',
};

const gaps = {
  horizontal: '8px',
  vertical: '12px',
};

export const Root = styled(NakedButton)`
  && {
    display: flex;
    gap: ${(props) => gaps[props.variant]};
    box-sizing: border-box;
    padding: ${(props) => paddings[props.variant]};
    cursor: ${(props) => (props.radio && props.isSelected ? 'default' : 'pointer')};
  
    flex-direction: ${(props) => layouts[props.variant]};
  
    align-items: center;
  
    ${({ isSelected, theme }) => (isSelected
    ? `
        border-radius: 10px;
        border: solid 1px ${theme.palette.focus};
      `
    : `
        border-radius: 10px;
        border: solid 1px ${theme.palette['background-flat']};
        background-color: ${theme.palette['background-flat']};
      `)
  }
  }
`;

export default {
  Root,
};
