import {
  RESET_CALL_LOGS_FILTER_ITEM,
} from 'store/actionTypes/calls';

const resetFilterItem = (key) => ({
  type: RESET_CALL_LOGS_FILTER_ITEM,
  payload: key,
});

export default resetFilterItem;
