import stableStringify from 'json-stable-stringify';

import requests from 'api/requests';

import setFollowUpReminders from './setFollowUpReminders';
import setFollowUpRemindersForFilter from './setFollowUpRemindersForFilter';
import setFollowUpRemindersForFilterCount from './setFollowUpRemindersForFilterCount';

const getFollowUpRemindersForFilter = filterId => filter => (
  offset = 0,
  limit = 50
) => async dispatch => {
  const ourFilterId = filterId || stableStringify(filter);

  try {
    const syncEvent = await requests.integrations.calendar.resyncCalendar()
    // ToDo: change name setFollowUpReminders to SyncedEvent in all reference, we are going to work only with one table with categories
    const response = await requests.syncedEvents.get({
      offset,
      limit,
      ...filter
    });    

    dispatch(setFollowUpReminders(response.data.items));
    dispatch(setFollowUpRemindersForFilter(ourFilterId)(response.data.items));
    dispatch(
      setFollowUpRemindersForFilterCount(ourFilterId)(response.data.itemsCount)
    );
  } catch (error) {
    console.error(error);
  }
};

export default getFollowUpRemindersForFilter;