import React, {
  useState,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import Button from 'ui/Button';
import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import PhoneNumberInput from 'ui/PhoneNumberInput';

import usePhoneValidationCode from 'sections/Calls/hooks/usePhoneValidationCode';

const OutgoingNumberValidator = (props) => {
  const {

  } = props;

  const [phoneNumber, setPhoneNumber] = useState('');

  const onPhoneChange = useCallback((phone) => {
    setPhoneNumber(phone);
  }, []);

  const {
    code,
    getCode,
    isLoading,
  } = usePhoneValidationCode(phoneNumber);

  return (
    <section>
      <Typography>
        We need to validate you phone number to enable in-browser phone calls.
      </Typography>
      <Typography>
        You are going to receive a call to do that.
      </Typography>
      <Typography>
        Please enter the 6-digit code on that will be shown here on your phone when asked to do so.
      </Typography>
      {
        !code && !isLoading && (
          <Flex flexDirection="column" spacing={2}>
            <Typography noMargin>
              Your mobile number:
            </Typography>
            <PhoneNumberInput
              style={{ width: '100%' }}
              value={phoneNumber}
              onChange={onPhoneChange}
            />
            <Button
              variant="primary"
              onClick={getCode}
              style={{ alignSelf: 'flex-end' }}
            >
              Request confirmation call
            </Button>
          </Flex>
        )
      }
      {
        code && !isLoading && (
          <>
            <Typography noMargin>
              Your confirmation code:
            </Typography>
            <Typography
              variant="title2"
              tag="p"
            >
              {code}
            </Typography>
            <Button
              style={{ alignSelf: 'flex-end' }}
              variant="primary"
              onClick={getCode}
            >
              Request another confirmation call
            </Button>
          </>
        )
      }
      {
        isLoading && (
          <Typography variant="title2" tag="p">
            Loading...
          </Typography>
        )
      }
    </section>
  );
};

OutgoingNumberValidator.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

OutgoingNumberValidator.propTypes = {

};

export default OutgoingNumberValidator;
