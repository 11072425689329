import React, { useState, useEffect } from 'react';
import { Editor, EditorState, RichUtils, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'draft-js/dist/Draft.css';
import styles from './richTextEditor.module.css';
import BoldIcon from 'assets/images/commsHub/bold.svg';
import UnderlineIcon from 'assets/images/commsHub/underline.svg';
import ListIcon from 'assets/images/commsHub/list.svg';
import ItalicIcon from 'assets/images/commsHub/italic.svg';

const TextEditor = ({ onContentChange = () => { }, initialContent = '', className }) => {
  const [editorState, setEditorState] = useState(
    initialContent
      ? EditorState.createWithContent(ContentState.createFromText(initialContent))
      : EditorState.createEmpty()
  );

  const editor = React.useRef(null);

  function focusEditor() {
    editor.current.focus();
  }

  useEffect(() => {
    focusEditor();
  }, []);

  useEffect(() => {
    onContentChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  }, [editorState, onContentChange]);

  const isActive = (style) => {
    const currentStyle = editorState.getCurrentInlineStyle();
    return currentStyle.has(style);
  };

  const StyleButton = (props) => {
    let onClickButton = (e) => {
      e.preventDefault();
      props.onToggle(props.style);
    };
    const isActiveStyle = isActive(props.style);
    return (
      <button className={`${styles['style-btn']} ${isActiveStyle ? styles['style-btn-active'] : ''}`} onMouseDown={onClickButton}>
        <img src={props.icon} alt={props.label} />
      </button>
    );
  };

  const BLOCK_TYPES = [
    { label: "UL", style: "unordered-list-item", icon: ListIcon },
  ];

  const BlockStyleControls = (props) => {
    return (
      <div>
        {BLOCK_TYPES.map((type) => (
          <StyleButton
            key={type.label}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
            icon={type.icon}
          />
        ))}
      </div>
    );
  };

  const INLINE_STYLES = [
    { label: "Bold", style: "BOLD", icon: BoldIcon },
    { label: "Italic", style: "ITALIC", icon: ItalicIcon },
    { label: "Underline", style: "UNDERLINE", icon: UnderlineIcon }
  ];

  const InlineStyleControls = (props) => {
    return (
      <div>
        {INLINE_STYLES.map((type) => (
          <StyleButton
            key={type.label}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
            icon={type.icon}
          />
        ))}
      </div>
    );
  };

  const onInlineClick = (e) => {
    let nextState = RichUtils.toggleInlineStyle(editorState, e);
    setEditorState(nextState);
  };

  const onBlockClick = (e) => {
    let nextState = RichUtils.toggleBlockType(editorState, e);
    setEditorState(nextState);
  };

  return (
    <div className={`${styles['editor-container']} ${className}`}>
      <div className={styles['btn-container']}>
        <span>
          <InlineStyleControls onToggle={onInlineClick} />
          <BlockStyleControls onToggle={onBlockClick} />
        </span>
      </div>
      <div className={styles['editor-wrapper']}>
        <Editor
          ref={editor}
          editorState={editorState}
          onChange={(editorState) => setEditorState(editorState)}
        />
      </div>
    </div>
  );
}

export default TextEditor;
