import React, {
  useContext,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';

import { isObject, isArray } from 'shared/utility';

import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { ThemeContext } from 'styled-components';

import Icon from 'ui/Icon';
import MenuItem from 'ui/MenuItem';
import Typography from 'ui/Typography';
import ChipList from 'ui/ChipList';
import ClickAwayListener from 'ui/ClickAwayListener';
import Tooltip from 'ui/Tooltip';

import {
  Root,
  Label,
  ChipRoot,
} from './styledItems';

const Placeholder = ({ children, ...rest }) => {
  const { isFocused } = rest;

  return (
    <Label
      {...rest}
      variant={isFocused ? 'caption' : 'body'}
      color={isFocused ? 'descriptor-text' : 'main-text'}
      fontStyle={isFocused ? 'initial' : 'italic'}
      active={isFocused}
    >
      {children}
    </Label>
  );
};

const buttonStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const ClearIndicator = (props) => {
  const { clearValue } = props;

  return (
    <Tooltip title="Clear all">
      <NakedButton
        {...props}
        onClick={clearValue}
        style={buttonStyles}
      >
        <Icon
          name="x"
          size="m"
          color="main-text"
        />
      </NakedButton>
    </Tooltip>
  );
};

const optionStyles = {
  textTransform: 'capitalize',
};

const Option = ({ children, ...rest }) => {
  const { selectOption, data } = rest;

  const onClick = useCallback(() => {
    selectOption(data);
  });

  return (
    <MenuItem
      {...rest}
      onClick={onClick}
      style={optionStyles}
    >
      {children}
    </MenuItem>
  );
};

const DropdownIndicator = (props) => {
  const {
    hasValue,
  } = props;

  if (hasValue) return null;

  return (
    <Icon
      name="chevron-down"
      size="m"
      color="main-text"
    />
  );
};

const MultiValueContainer = (props) => (
  <ChipRoot
    {...props}
    ref={props.innerRef}
  />
);

const MultiValueLabel = ({ children, ...rest }) => (
  <Typography
    {...rest}
    color="main-text"
    variant="chip"
    noMargin
    style={optionStyles}
  >
    {children}
  </Typography>
);

const removeStyles = {
  ...buttonStyles,
  marginLeft: 4,
  position: 'relative',
  backgroundColor: 'transparent',
};

const MultiValueRemove = (props) => (
  <NakedButton
    {...props.innerProps}
    style={removeStyles}
  >
    <Icon
      name="x"
      size="m"
      color="main-text"
    />
  </NakedButton>
);

const MultiselectTextfield = ({
  options,
  onChange,
  onBlur,
  label,
  value,
  creatable,
  onCreate,
  isLoading,
  defaultView,
  dense,
  flat,
  chipListProps,
}) => {
  let SelectComponent = Select;

  if (creatable) {
    SelectComponent = CreatableSelect;
  }

  const theme = useContext(ThemeContext);

  const styles = useMemo(
    () => ({
      container: (provided) => ({
        ...provided,
        position: 'relative',
      }),

      control: (provided, state) => ({
        ...provided,
        minHeight: dense ? 28 : 44,
        minWidth: 160,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '10px',
        backgroundColor: flat ? theme.palette['background-flat'] : 'transparent',
        borderColor: state.isFocused
          ? theme?.palette?.focus || 'blue'
          : theme?.palette?.['input-border'] || 'gray',
        boxShadow: 'none',
        '&:hover': {
          cursor: 'pointer',
        },
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 100000000000,
        boxShadow: theme.shadows.dropdown,
        borderRadius: 10,
      }),
      placeholder: (provided) => ({
        ...provided,
      }),
      valueContainer: (provided) => ({
        ...provided,
        overflow: 'initial',
      }),
      indicatorsContainer: (provided) => ({
        ...provided,
        marginRight: 6,
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
    }),
    [theme, dense],
  );

  const currentOptions = useMemo(() => {
    if (isArray(options)) {
      return options;
    }

    if (isObject(options)) {
      return Object.keys(options).map((key) => ({
        value: `${key}`,
        label: options[key],
      }));
    }

    return options;
  }, [
    options,
  ]);

  const [isFocused, setIsFocused] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);

  const onFocus = useCallback(() => {
    setIsFocused(true);
  });

  const handleBlur = useCallback(() => {
    setIsFocused(false);
    onBlur(currentValue);
  }, [
    onBlur,
    currentValue,
  ]);

  const handleChange = useCallback(
    (newValue, actionMeta) => {
      if (actionMeta.action === 'create-option') {
        onCreate(actionMeta.option);
      } else {
        onChange(newValue);
      }
      setCurrentValue(newValue);
    }, [
    onChange,
    onCreate,
  ],
  );

  useEffect(() => {
    setCurrentValue(value);
  }, [
    JSON.stringify(value),
  ]);

  const [view, setView] = useState(defaultView);

  if (defaultView === 'display' && view === 'display') {
    return (
      <div>
        {
          label && (
            <Typography variant="title3">
              {label}
            </Typography>
          )
        }
        <NakedButton onClick={() => setView('edit')}>
          <ChipList
            items={currentValue.map((item) => item.label)}
            onAdd={() => setView('edit')}
            {...chipListProps}
          />
        </NakedButton>
      </div>
    );
  }

  return (
    <ClickAwayListener onClickAway={() => {
      if (defaultView === 'display' && view === 'edit') {
        setView('display');
      }
    }}
    >
      <Root>
        <SelectComponent
          defaultValue={value}
          value={currentValue}
          options={currentOptions}
          onChange={handleChange}
          isMulti
          styles={styles}
          placeholder={null}
          onFocus={onFocus}
          onBlur={handleBlur}
          components={{
            DropdownIndicator,
            ClearIndicator,
            Option,
            MultiValueContainer,
            MultiValueLabel,
            MultiValueRemove,
          }}
          isLoading={isLoading}
        />
        {
          label && (
            <Placeholder
              isFocused={isFocused || (currentValue && currentValue.length > 0)}
            >
              {label}
            </Placeholder>
          )
        }
      </Root>
    </ClickAwayListener>
  );
};

const noop = () => { };

MultiselectTextfield.defaultProps = {
  options: [],
  onChange: noop,
  onBlur: noop,
  onCreate: noop,
  label: null,
  value: [],
  creatable: false,
  isLoading: false,
  defaultView: 'edit',
  dense: false,
  flat: false,
  chipListProps: {},
};

const {
  arrayOf,
  bool,
  func,
  number,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

MultiselectTextfield.propTypes = {
  options: oneOfType([
    arrayOf(shape({
      label: oneOfType([string, number]),
      value: oneOfType([string, number]),
    })),
    objectOf(string),
  ]),
  onChange: func,
  onBlur: func,
  label: string,
  value: arrayOf(shape({
    label: oneOfType([string, number]),
    value: oneOfType([string, number]),
  })),
  creatable: bool,
  onCreate: func,
  isLoading: bool,
  defaultView: oneOf(['edit', 'display']),
  dense: bool,
  flat: bool,
  chipListProps: shape(),
};

export default MultiselectTextfield;
