import styled from 'styled-components';

import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';

export const Root = styled(NakedButton)`
&& {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  padding: ${(props) => (props.biggerPaddings ? '20px 15px' : '10px')};
  background-color: ${({
  isSelected,
  inactiveItemBgc,
  selectedItemBgc,
  theme,
}) => (isSelected ? (
  theme?.palette?.[selectedItemBgc] || '#f6f8fa'
) : (
  theme?.palette?.[inactiveItemBgc] || 'transparent')
  )}
}
`;

export default {
  Root,
};
