/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Redirect, Route, Switch } from 'react-router-dom';

// import Cashflow from 'components/Cashflow';
// import CustomerDB from 'components/CustomerDB';
// import Dashboard from 'components/DashboardLegacy';
// import EmployeeDirectory from 'components/HR/EmployeeDirectory';
// import HR from 'components/HR';
// import MyCompany from 'components/MyCompany';
// import Sales from 'components/Sales';

// import Articles from 'components/Articles/Articles';
// import CRM from 'components/CRM';
// import Service from 'components/Service';

import NewLeadSuccesfullyAlert from 'components/Sales/NewLeadSuccesfullyAlert';

import Sidebar from 'components/Sidebar';
// import Surveys from 'components/Surveys';
// import Tasks from 'components/Tasks';
// import Tickets from 'components/Tickets';
import TaskReminder from 'components/Tasks/TaskReminder';
// import MoodDashboardContainer from 'components/Mood/MoodDashboardContainer';
// import MoodDetails from 'components/Mood/MoodDetails';

// import FeedbackBug from 'shared/components/FeedbackBug';
// eslint-disable-next-line max-len
import TicketKnowledgeBaseSearchModal from 'shared/components/TicketKnowledgeBaseSearchModal/TicketKnowledgeBaseSearchModalModule';

// import CreateCategory from 'shared/modals/CreateCategory';
// import CreateTask from 'shared/modals/CreateTask';
// import EditCategory from 'shared/modals/EditCategory';
import PermissionModal from 'shared/modals/PermissionModal';
import CredentialsModal from 'shared/modals/CredentialsModal';
import RoleModal from 'shared/modals/RoleModal';
import SearchFilter from 'shared/modals/SearchFilter';
// import Notifications from 'shared/drawer/Notifications';
/* import ToDoList from 'shared/drawer/ToDoList';
import ToDoListSettings from 'shared/drawer/ToDoList/ToDoListSettings'; */
import detectInactivity from 'shared/utils/detectInactivity';
import monitorUserAvailability from 'shared/utils/monitorUserAvailability';
import IntroModal from 'shared/modals/IntroModal';
// import DeletingSiteOwnerLicenseWarning from 'shared/modals/AddingLicensesWarningModal/DeletingSiteOwnerLicenseWarning';
// import DeleteYourLicensePermissionWarning from 'shared/modals/AddingLicensesWarningModal/DeleteYourLicensePermissionWarning';
// import OnlyOneSiteOwnerWarning from 'shared/modals/AddingLicensesWarningModal/OnlyOneSiteOwnerWarning';
import DeleteImageWarningModal from 'shared/modals/DeleteImageWarningModal';
// import DeleteEmployeeWarningModal from 'shared/modals/DeleteEmployeeWarningModal';
import ConfirmOfferAcceptModal from 'sections/Offers/modals/ConfirmOfferAcceptModal';

import Tutorials from 'components/Tutorials';

import StripeProvider from 'components/StripeProvider';

import Appbar from 'components/Appbar';

// import ChatDrawer from 'shared/drawer/Chat/ChatDrawer';

import VideoChat from 'components/VideoChat';

import Communications from 'refactored/pages/commsHub/CommsHub';
import Today from 'sections/Today';
// import MLS from 'sections/MLS';
// import Leads from 'sections/Leads';
// import Transactions from 'sections/Transactions';
import Contacts from 'sections/Contacts';
// import Prospects from 'sections/Prospects';
// import Clients from 'sections/Clients';
import Offers from 'sections/Offers';
import OffersV2 from 'sections/OffersV2';
// import Opportunities from 'sections/Opportunities';
// import InAppMarketing from 'sections/InAppMarketing';
import Calendar from 'sections/Calendar';
import Settings from 'sections/Settings';
import Calls from 'sections/Calls';
import Integrations from 'sections/Integrations';

// import OpportunitiesListing from 'sections/OpportunitiesListing';

import CallsProvider from 'sections/Calls/CallsProvider';

import { NAVIGATION_ROUTES } from 'constants/navigation.constants';

import RootModals from 'components/RootModals';

import isSectionUnderConstruction from 'shared/utils/isSectionUnderConstruction';

import getFeatureVersion from 'shared/utils/getFeatureVersion';
import OffersProvider from 'sections/OffersV2/OffersProvider/OffersProvider';
import ChangePassword from '../Login/ChangePassword';

import OnboardingWizard from '../OnboardingWizard';

import {
  AppWrapper,
  AppBody,
  AppMain,
  BgWrapper,
  WidthLimit
} from './styledItems';
import NavColor from './NavColor';
import Navbar from "refactored/components/commsHub/navbar/Navbar";
import GlobalProviders from 'refactored/components/shared/providers/index'

const INACTIVITY_BEFORE_LOGOUT_MINUTES = 30;

const OffersVersion = getFeatureVersion('offersV2');

// const OffersComponent = OffersVersion === 1
// ? Offers
// : OffersV2;

class MainAppView extends Component {
  componentDidMount() {
    const {
      getEmployees,
      getMe,
      isUserLogged,
      receiveLogout,
      rememberMe,
      getGeneral,
      getCustomizationSettings
    } = this.props;

    if (!isUserLogged) {
      getMe();
    }

    getGeneral();
    getCustomizationSettings();
    getEmployees();

    // if (!rememberMe) {
    //   window.addEventListener('beforeunload', (event) => {
    //     event.preventDefault();
    //     return localStorage.clear();
    //   });
    // }

    detectInactivity(INACTIVITY_BEFORE_LOGOUT_MINUTES, receiveLogout);
    this.handleUserAvailability();
  }

  handleUserAvailability() {
    const { setEmployeeAvailability } = this.props;

    monitorUserAvailability(setEmployeeAvailability);
  }

  render() {
    const {
      isUserLogged,
      showNewLeadAlert,
      location
      // hasAccessToCompanySettings,
      // appendChatMessage,
    } = this.props;

    const router = (
      <>
        <NavColor />
        {/* <VideoChat /> */}
        <CallsProvider>
          <OffersProvider>
            <StripeProvider>


              <GlobalProviders>
                <AppMain>
                  <WidthLimit>
                    <Navbar />
                    <Switch>
                      <Route exact path="/" component={Communications} />
                      {!isSectionUnderConstruction('communications') && (
                        <Route
                          path={NAVIGATION_ROUTES.communications}
                          component={Communications}
                        />
                      )}
                      <Route
                        path={NAVIGATION_ROUTES.today}
                        component={Today}
                      />
                      {/* <Route
                            path={NAVIGATION_ROUTES.mls}
                            component={MLS}
                          />
                          <Route
                            path={NAVIGATION_ROUTES.leads}
                            component={Leads}
                          />
                          <Route
                            path={NAVIGATION_ROUTES.transactions}
                            component={Transactions}
                          /> */}
                      <Route
                        path={NAVIGATION_ROUTES.contacts}
                        component={Contacts}
                      />
                      {/* <Route
                            path={NAVIGATION_ROUTES.opportunities}
                            component={Opportunities}
                          />
                          <Route
                            path={NAVIGATION_ROUTES.prospects}
                            component={Prospects}
                          />
                          <Route
                            path={NAVIGATION_ROUTES.clients}
                            component={Clients}
                          />
                          <Route
                            path={NAVIGATION_ROUTES.offers}
                            component={OffersComponent}
                          />
                          <Route
                            path={NAVIGATION_ROUTES.inAppMarketing}
                            component={InAppMarketing}
                          /> */}
                      <Route
                        path={NAVIGATION_ROUTES.calendar}
                        component={Calendar}
                      />
                      <Route
                        path={NAVIGATION_ROUTES.settings}
                        component={Settings}
                      />
                      {!isSectionUnderConstruction('calls') && (
                        <Route
                          path={NAVIGATION_ROUTES.calls}
                          component={Calls}
                        />
                      )}
                      {/* {
                          !isSectionUnderConstruction('opportunities') && (
                            <Route
                              path={NAVIGATION_ROUTES.opportunitiesListing}
                              component={OpportunitiesListing}
                            />
                          )
                        } */}
                      {!isSectionUnderConstruction('integrations') && (
                        <Route
                          path={NAVIGATION_ROUTES.integrations}
                          component={Communications}
                        />
                      )}
                      {/* <Route
                          path="/employee-directory"
                          component={EmployeeDirectory}
                        />
                        <Route
                          exact
                          path="/mood-dashboard"
                          component={MoodDashboardContainer}
                        />
                        <Route
                          path="/mood-dashboard/offices/:OfficeID?/:DepartmentID?/:EmployeeID?"
                          component={MoodDetails}
                        />
                        <Route
                          path="/mood-dashboard/departments/:DepartmentID?/:OfficeID?/:EmployeeID?"
                          component={MoodDetails}
                        /> */}
                      <Redirect from="/" to="/today" />
                      <Redirect to="/404" />
                    </Switch>
                  </WidthLimit>

                  {/* <ToDoListSettings />
                <ToDoList /> */}
                  <DeleteImageWarningModal />
                  {/* <Notifications /> */}

                  {/* <FeedbackBug /> */}

                  {/* <CreateTask /> */}
                  <TaskReminder />
                  <PermissionModal />
                  <CredentialsModal />
                  <RoleModal />
                  {/* <ChangePassword internal /> */}
                  {/* <CreateCategory />
                    <EditCategory /> */}
                  <SearchFilter />
                  <TicketKnowledgeBaseSearchModal />
                  <IntroModal />
                  {/* <NewLeadSuccesfullyAlert isShowAlert={showNewLeadAlert} /> */}
                  <Tutorials />
                  {/* <DeleteYourLicensePermissionWarning />
                    <DeletingSiteOwnerLicenseWarning />
                    <OnlyOneSiteOwnerWarning />
                    <DeleteEmployeeWarningModal /> */}

                  <ConfirmOfferAcceptModal />

                  {/* <ChatDrawer
                      userToken={localStorage.getItem('token')}
                      serverUrl={process.env.REACT_APP_SYMPLETE_CHAT_SERVER_URL}
                      callbacks={{
                        onMessageReceived: appendChatMessage,
                      }}
                    /> */}

                  <OnboardingWizard />
                </AppMain>
              </GlobalProviders>
              <RootModals />
            </StripeProvider>
          </OffersProvider>
        </CallsProvider>
      </>
    );

    // TODO: add preloader here (replace null)
    return isUserLogged ? router : null;
  }
}

MainAppView.defaultProps = {
  isUserLogged: false
  // hasAccessToCompanySettings: false,
};

const { bool, func, shape, string } = PropTypes;

MainAppView.propTypes = {
  getEmployees: func.isRequired,
  getMe: func.isRequired,
  // hasAccessToCompanySettings: bool,
  isUserLogged: bool,
  location: shape({
    pathname: string
  }).isRequired,
  receiveLogout: func.isRequired,
  setEmployeeAvailability: func.isRequired,
  showNewLeadAlert: bool.isRequired,
  rememberMe: bool.isRequired,
  // appendChatMessage: func.isRequired,
  getGeneral: func.isRequired,
  getCustomizationSettings: func.isRequired
};

export default MainAppView;
