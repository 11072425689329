import removeFulfillmentTopLevelLineItem from './removeFulfillmentTopLevelLineItem';

const removeSelectedFulfillmentTopLevelLineItem = (productId) => async (dispatch, getState) => {
  const {
    fulfillments: {
      selectedFulfillment,
    },
  } = getState();

  await dispatch(removeFulfillmentTopLevelLineItem(selectedFulfillment)(productId));
};

export default removeSelectedFulfillmentTopLevelLineItem;
