import {
  SET_SELECTED_WAREHOUSE_LINE_ITEMS,
} from 'store/actionTypes/warehouse';

const setSelected = (ids) => ({
  type: SET_SELECTED_WAREHOUSE_LINE_ITEMS,
  payload: ids,
});

export default setSelected;
