exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".emailListItemHeader_email-listitem-header__1cnMV {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  overflow: hidden;\n}\n\n.emailListItemHeader_email-circle-profile-picture__2qp0c {\n  min-width: 54px;\n  min-height: 54px;\n  border-radius: 50%;\n  object-fit: cover;\n  border: 2px solid #ffffff;\n  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);\n  margin-bottom: 10px;\n  color: #ffffff;\n  background-color: #1e9896;\n  position: relative;\n}\n\n.emailListItemHeader_email-listitem-profile-area__1_Taq {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  overflow: hidden;\n}\n\n.emailListItemHeader_email-listitem-profile-names__2QHSN {\n  display: inline-block;\n  padding-right: 6px;\n  padding-left: 4px;\n}\n\n.emailListItemHeader_email-listitem-sender__3cbAj {\n  font-weight: 500;\n  font-size: 16px;\n  color: #141726;\n  max-width: 120px;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n}\n\n.emailListItemHeader_email-listitem-time-received__363qp {\n  font-weight: 400;\n  font-size: 13px;\n  color: #81828c;\n}\n\n.emailListItemHeader_email-circle-profile-picture__2qp0c > :first-child {\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  -webkit-transform: translate(-50%, -50%);\n          transform: translate(-50%, -50%);\n}\n\n.emailListItemHeader_email-circle-profile-picture__2qp0c > :last-child {\n  position: absolute;\n  right: 0px;\n  bottom: 0px;\n}", ""]);

// exports
exports.locals = {
	"email-listitem-header": "emailListItemHeader_email-listitem-header__1cnMV",
	"email-circle-profile-picture": "emailListItemHeader_email-circle-profile-picture__2qp0c",
	"email-listitem-profile-area": "emailListItemHeader_email-listitem-profile-area__1_Taq",
	"email-listitem-profile-names": "emailListItemHeader_email-listitem-profile-names__2QHSN",
	"email-listitem-sender": "emailListItemHeader_email-listitem-sender__3cbAj",
	"email-listitem-time-received": "emailListItemHeader_email-listitem-time-received__363qp"
};