import requests from 'api/requests';

import getById from './getNotablePointById';

const addNotablePointRelations = (relationType) => (pointId) => (data) => async (dispatch) => {
  try {
    await requests.realtor.notablePoints.addRelations(relationType)(pointId)(data);

    dispatch(getById(pointId));
  } catch (error) {
    console.error(error);
  }
};

export default addNotablePointRelations;
