import {
  SET_CRM_LEAD_FILTER_ITEM,
} from 'store/actionTypes/crm';

const setFilterItem = (key) => (value) => ({
  type: SET_CRM_LEAD_FILTER_ITEM,
  payload: {
    key,
    value,
  },
});

export default setFilterItem;
