import {
  SET_CANCEL_TRANSACTION_MODAL_OPEN,
} from 'store/actionTypes/realtorTransactions';

const openModal = ({
  transactionId,
  onSave,
}) => ({
  type: SET_CANCEL_TRANSACTION_MODAL_OPEN,
  payload: {
    transactionId,
    onSave,
  },
});

export default openModal;
