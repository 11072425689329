import requests from 'api/requests';

const updateLead2Product = (relationId) => (updateData) => async (dispatch) => {
  try {
    await requests.crm.leads.updateLead2Product(relationId)(updateData);
  } catch (error) {
    console.error(error);
  }
};

export default updateLead2Product;
