import React from 'react';
import PropTypes from 'prop-types';

import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';
import Typography from 'ui/Typography';

const ShowFullProfileButton = (props) => {
  const { onClick } = props;

  return (
    <NakedButton
      onClick={onClick}
    >
      <Typography
        variant="body2"
        color="link"
        weight="bold"
        textDecoration="underline"
        noMargin
      >
        View full profile
      </Typography>
    </NakedButton>
  );
};

const { func } = PropTypes;

ShowFullProfileButton.propTypes = {
  onClick: func.isRequired,
};

export default ShowFullProfileButton;
