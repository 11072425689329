/* eslint-disable camelcase, no-nested-ternary */

import React from 'react';

import PropTypes from 'prop-types';

import { datetime } from 'refactored/utils/datetime';
import { between } from 'refactored/utils/datetime';

import { useForm } from 'react-hook-form';

import { noOp } from 'shared/utility';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Button from 'ui/Button';
import Divider from 'ui/Divider';

import useProperty from 'sections/Properties/hooks/useProperty';

import ViewingOptions from 'sections/Offers/modals/NewOfferModal/ViewingOptions/index';
import SubTitle from './SubTitle';

import OfferAmount from './OfferAmount';
import FinanceType from './FinanceType';

import Expiry from './Expiry';
import ClosingDate from './ClosingDate';

import Contingencies from './Contingencies';
import ContingencyDate from './ContingencyDate';

import KeyPeople from './KeyPeople';
import Notes from './Notes';

import Agreement from './Agreement';

import { BackgroundHighlight } from './styledItems';

const titles = {
  seller: 'Log New Offer',
  buyer: 'Make New Offer',
};

const NewOfferForm = (props) => {
  const {
    propertyId,
    representing,

    buyer,
    buyerAgent,
    seller,
    sellerAgent,

    onSubmit,

    updateOnly,
    offerData,
    currentCounter,
    totalCounters,
    offerId,
    closeModal,
    hideOptions,
    attachments,
  } = props;

  const property = useProperty(propertyId);

  const {
    expected_price,
  } = property;

  const {
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      // details
      offerType: offerData?.type || 1,
      offerAmount: offerData?.amount || expected_price || 0,
      expiresIn: between(datetime(), offerData?.expiry_date, 'hour') || 24,
      closesIn: between(datetime(), offerData?.closing_date, 'day') || 21,
      contingenciesIn: between(datetime(), offerData?.contingency_period_end, 'day') || 21,
      financeType: offerData?.financing_type || 1,
      notes: offerData?.note || null,

      // key people
      buyer: offerData?.buyer_contact_id || buyer,
      seller: offerData?.seller_contact_id || seller,
      sellerAgent: offerData?.seller_agent_contact_id || sellerAgent,
      buyerAgent: offerData?.buyer_agent_contact_id || buyerAgent,

      // contingencies
      contingencyInspection: offerData?.contingency_inspection || false,
      contingencyAppraisal: offerData?.contingency_appraisal || false,
      contingencyLoan: offerData?.contingency_loan || false,

      // agreement
      agreementFiles: attachments ? attachments?.url ? null : attachments : null,
      agreementUrl: attachments?.url || null,
    },
  });

  return (
    <Flex
      fullWidth
      flexWrap="wrap"
      spacing={1}
    >
      <Flex
        fullWidth
        justifyContent="space-between"
      >
        <Typography variant="h1">
          {

            updateOnly
              ? (
                `${currentCounter ? 'Counter' : 'Initial Offer'} 
                 ${currentCounter || ''} | 
                 ${representing === 'seller' ? 'Buyer' : 'Seller'}`
              )
              : (
                titles[representing]
              )
          }
        </Typography>

        <Flex spacing={2}>
          {
            updateOnly
              ? (
                <>
                  {
                    hideOptions
                      ? (
                        ''
                      )
                      : (
                        <>
                          <ViewingOptions
                            propertyId={propertyId}
                            offerId={offerId}
                            offerType={offerData.type}
                            totalCounters={totalCounters}
                            offerData={offerData}
                            closeModal={closeModal}
                          />
                          {/* <Button
                      variant="primary"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Update
                    </Button> */}
                        </>
                      )

                  }
                </>
              )
              : (
                <Button
                  variant="primary"
                  onClick={handleSubmit(onSubmit)}
                >
                  Log Offer
                </Button>
              )
          }
        </Flex>
      </Flex>

      <Flex
        fullWidth
        spacing={2}
        fullHeight
        alignItems="stretch"
      >
        <Flex
          fullWidth
          flexDirection="column"
          spacing={3}
        >
          <Flex
            fullWidth
            spacing={3}
          >
            <Flex
              fullWidth
              flexDirection="column"
            >
              <SubTitle>Offer Details</SubTitle>
              <OfferAmount
                control={control}
                disabled={updateOnly}
              />
            </Flex>

            <Flex
              fullWidth
              flexDirection="column"
            >
              <SubTitle>Offer Expiry</SubTitle>
              <Expiry control={control} />
            </Flex>
          </Flex>

          <Flex
            fullWidth
            spacing={3}
          >
            <Flex
              fullWidth
              flexDirection="column"
            >
              <SubTitle>Financing</SubTitle>
              <FinanceType control={control} />
            </Flex>

            <Flex
              fullWidth
              flexDirection="column"
            >
              <SubTitle>Closing Date</SubTitle>
              <BackgroundHighlight
                spacing={1}
                alignItems="center"
                fullWidth
              >
                <ClosingDate control={control} />
              </BackgroundHighlight>
            </Flex>
          </Flex>

          <Flex fullWidth>
            <Flex
              fullWidth
              flexDirection="column"
            >
              <SubTitle>Contingencies</SubTitle>
              <Flex
                spacing={2}
                alignItems="center"
              >
                <Contingencies control={control} />
              </Flex>
            </Flex>
          </Flex>

          <Flex fullWidth>
            <Flex
              fullWidth
              flexDirection="column"
            >
              <SubTitle>Contingency Period</SubTitle>

              <ContingencyDate control={control} />
            </Flex>
          </Flex>

          <Flex fullWidth>
            <Flex
              fullWidth
              flexDirection="column"
            >
              <SubTitle>Upload Purchase Agreement</SubTitle>
              <Agreement control={control} />
            </Flex>
          </Flex>
        </Flex>

        <FlexItem>
          <Divider vertical />
        </FlexItem>

        <Flex
          fullWidth
          flexDirection="column"
          spacing={3}
        >
          <Flex fullWidth>
            <Flex
              fullWidth
              flexDirection="column"
            >
              <SubTitle>Key People</SubTitle>
              <KeyPeople control={control} />
            </Flex>
          </Flex>

          <Flex fullWidth>
            <Flex
              fullWidth
              flexDirection="column"
            >
              <SubTitle>Additional Offer Details</SubTitle>
              <Notes control={control} />
            </Flex>
          </Flex>
        </Flex>

      </Flex>

    </Flex>
  );
};

NewOfferForm.defaultProps = {
  onSubmit: noOp,

  buyer: null,
  buyerAgent: null,
  seller: null,
  sellerAgent: null,

  updateOnly: false,
  offerData: null,
  currentCounter: null,
  totalCounters: null,
  offerId: null,
  closeModal: () => { },
  hideOptions: false,
  attachments: null,
};

const {
  func,
  number,
  oneOf,
  bool,
  shape,
} = PropTypes;

NewOfferForm.propTypes = {
  propertyId: number.isRequired,

  buyer: number,
  buyerAgent: number,
  seller: number,
  sellerAgent: number,

  representing: oneOf(['buyer', 'seller']).isRequired,
  onSubmit: func,
  updateOnly: bool,
  offerData: shape({}),
  currentCounter: number,
  totalCounters: number,
  offerId: number,
  closeModal: func,
  hideOptions: bool,
  attachments: shape({}),
};

export default NewOfferForm;
