exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".toggleButton_toggle-btn__3_bNA {\n  min-width: 32px;\n  height: 32px;\n  border-radius: 44px;\n  background: none;\n  border: none;\n  cursor: pointer;\n  background-color: rgba(255, 255, 255, 0);\n  color: #000;\n  font-size: 18px;\n  padding: 4px 12px;\n}\n\n.toggleButton_toggle-btn--active__2Nt5s {\n  min-width: 32px;\n  height: 32px;\n  border-radius: 44px;\n  cursor: pointer;\n  box-shadow: none;\n  border: none;\n  background-color: white;\n  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);\n  font-size: 18px;\n  color: #000;\n  padding: 4px 12px;\n}\n\n.toggleButton_toggle-btn__3_bNA:hover {\n  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);\n  background-color: rgba(255, 255, 255, 0.2);\n}", ""]);

// exports
exports.locals = {
	"toggle-btn": "toggleButton_toggle-btn__3_bNA",
	"toggle-btn--active": "toggleButton_toggle-btn--active__2Nt5s"
};