import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';

import PropTypes from 'prop-types';

import datetime from 'refactored/utils/datetime';

import Flex from 'ui/Flex';
import DropdownTextfield from 'ui/DropdownTextfield';

import DateInputButton from 'shared/inputs/DateInputButton';

import FollowUp from './FollowUp';
import Showing from './Showing';

const entryTypes = [
  {
    value: 'followup',
    label: 'Follow Up',
  },
  {
    value: 'walkthrough',
    label: 'Walkthrough',
  },
  {
    value: 'contingency',
    label: 'Contingency Removal',
  },
  {
    value: 'pitch',
    label: 'Listing Pitch',
  },
  {
    value: 'property',
    label: 'Property Viewing',
  },
  {
    value: 'prospect',
    label: 'Prospect Viewing',
  },
  {
    value: 'meeting',
    label: 'Meeting',
  },
  {
    value: 'escrow',
    label: 'Escrow Closing',
  },
];

const entryTypesComponents = {
  followup: FollowUp,
  showing: Showing,
};

const dateSelectionEnabled = new Set(['showing']);

const NewEvent = (props) => {
  const {
    defaultDate,
    onComplete,
  } = props;

  const [selectedDate, setSelectedDate] = useState(defaultDate ?? datetime());

  const [selectedEntryType, setSelectedEntryType] = useState('showing');

  useEffect(() => {
    setSelectedDate(defaultDate ?? datetime());
  }, [
    defaultDate,
  ]);

  const FormComponent = useMemo(() => entryTypesComponents[selectedEntryType], [
    selectedEntryType,
  ]);

  return (
    <Flex
      spacing={2}
      flexDirection="column"
      fullWidth
    >
      <FormComponent
        defaultDate={selectedDate}
        onComplete={onComplete}
      />
    </Flex>
  );
};

const {
  func,
  object,
} = PropTypes;

NewEvent.propTypes = {
  defaultDate: object.isRequired,
  onComplete: func.isRequired,
};

export default NewEvent;
