import requests from 'api/requests';

import getLeadById from './getLeadById';
import getLeadChangelogAllTime from './getLeadChangelogAllTime';

const updateLead = (leadId) => (updateData) => async (dispatch) => {
  try {
    await requests.crm.leads.update(leadId)(updateData);

    dispatch(getLeadById(leadId));
    dispatch(getLeadChangelogAllTime(leadId));
  } catch (error) {
    console.error(error);
  }
};

export default updateLead;
