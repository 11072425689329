/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import KPIItem from 'ui/KPIItem';

import { PROPERTY_TYPES } from 'constants/realtor.constants';

import { capitalizeSentence } from 'shared/utils/strings';

const PropertyAttributes = (props) => {
  const {
    attributes,
  } = props;

  const {
    bedrooms,
    bathrooms,
    parking,
    floor_area,
    plot_area,
    type,
  } = attributes;

  return (
    <>
      <KPIItem
        iconName="home"
        textVariant="caption"
        variant="flat"
        iconColor="black"
        title={capitalizeSentence(PROPERTY_TYPES[type])}
        tooltip="Property Type"
      />
      <KPIItem
        iconName="bed"
        textVariant="caption"
        iconType="custom"
        variant="flat"
        value={bedrooms}
        tooltip="Bedrooms"
      />
      <KPIItem
        iconName="bathroom"
        textVariant="caption"
        iconType="custom"
        variant="flat"
        value={bathrooms}
        tooltip="Bathrooms"
      />
      <KPIItem
        iconName="garage"
        textVariant="caption"
        iconType="custom"
        variant="flat"
        value={parking}
        tooltip="Parking Spaces"
      />
      <KPIItem
        iconName="height"
        textVariant="caption"
        iconType="custom"
        title="sqft"
        variant="flat"
        value={floor_area}
        tooltip="Floor Area"
      />
      <KPIItem
        iconName="maximize"
        textVariant="caption"
        iconColor="black"
        title="acre"
        variant="flat"
        value={plot_area}
        tooltip="Plot Area"
      />
    </>
  );
};

PropertyAttributes.defaultProps = {
  attributes: {},
};

const {
  shape,
} = PropTypes;

PropertyAttributes.propTypes = {
  attributes: shape({}),
};
export default PropertyAttributes;
