import requests from 'api/requests';

import getTaskAndRefreshRelatedSummaries from './getTaskAndRefreshRelatedSummaries';
import deleteTaskFromBacklogFiltered from './deleteTaskFromBacklogFiltered';

const assignTaskFromBacklog = ({
  taskId,
  newAssignees,
  deliveryDate = null,
}) => async (dispatch, getState) => {
  const {
    auth: {
      loggedUser: {
        Name,
        Surname,
        EmployeeID,
      },
    },
  } = getState();

  const userFullName = `${Name} ${Surname}`;

  const assigneesBody = {
    newAssignees,
    removedAssignees: [],
  };

  const statusChangeBody = {
    TaskID: taskId,
    StatusID: 2,
    UserFullName: userFullName,
    EmployeeID,
  };

  try {
    await requests.tasks.updateTaskAssignees(taskId)(assigneesBody);
    await requests.tasks.updateTaskStatus(statusChangeBody);
    if (deliveryDate) {
      await requests.tasks.updateTaskDeliveryDate(taskId, deliveryDate);
    }
  } catch (error) {
    console.error(error);
    return;
  }

  dispatch(getTaskAndRefreshRelatedSummaries(taskId));
  dispatch(deleteTaskFromBacklogFiltered(taskId));
};

export default assignTaskFromBacklog;
