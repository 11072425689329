import { createSelector } from 'reselect';

import {
  LEAD_TYPES,
  LEAD_STATUSES,
} from 'constants/realtor.constants';

import { isEmpty } from 'shared/utility';
import moment from 'moment';
import { currency } from 'refactored/utils/numbers';

const filterObj = (state) => state.realtorLeadsFilter;
const sourceTypes = (state) => state.crmSources.sourceTypes;

const temps = {
  null: 'Not selected',
  1: 'Cold',
  2: 'Warm',
  3: 'Hot',
};

const getTempChipValue = (temp = []) => temp.map((item) => temps[item]).join(', ');

const getValueRangeChipValue = (propertyValueRange = []) => `${currency.short(propertyValueRange[0])}–${currency.short(propertyValueRange[1])}`; // eslint-disable-line max-len

const getDateRangeChipValue = (range = []) => `${moment(range[0]).format('MMM D, YYYY')} to ${moment(range[1]).format('MMM D, YYYY')}`; // eslint-disable-line max-len

const getSourcesChipValue = (filterSources, sourceTypesList) => filterSources.map((item) => sourceTypesList.find((type) => type.id === item).name).join(', '); // eslint-disable-line max-len

const getLeadTypeChipValue = (leadType = []) => leadType.map((item) => LEAD_TYPES[item]).join(', ');

const getLeadStatusChipValue = (leadStatus = []) => leadStatus.map((item) => LEAD_STATUSES[item]).join(', ');

export default createSelector(
  filterObj,
  sourceTypes,
  (filter, sources) => {
    const chips = [];

    if (!isEmpty(filter.temp)) {
      chips.push({
        key: 'temp',
        title: 'Status',
        value: getTempChipValue(filter.temp),
      });
    }

    if (!isEmpty(filter.propertyValueRange)) {
      chips.push({
        key: 'propertyValueRange',
        title: 'Deal value',
        value: getValueRangeChipValue(filter.propertyValueRange),
      });
    }

    if (!isEmpty(filter.createdAtRange) && filter.createdAtRange[0] && filter.createdAtRange[1]) {
      chips.push({
        key: 'createdAtRange',
        title: 'Date created',
        value: getDateRangeChipValue(filter.createdAtRange),
      });
    }

    if (!isEmpty(filter.lastContactRange)) {
      chips.push({
        key: 'lastContactRange',
        title: 'Last contact',
        value: getDateRangeChipValue(filter.lastContactRange),
      });
    }

    if (!isEmpty(filter.source) && sources) {
      chips.push({
        key: 'source',
        title: 'Sources',
        value: getSourcesChipValue(filter.source, sources),
      });
    }

    if (!isEmpty(filter.leadType)) {
      chips.push({
        key: 'leadType',
        title: 'Type',
        value: getLeadTypeChipValue(filter.leadType),
      });
    }

    if (!isEmpty(filter.leadStatus)) {
      chips.push({
        key: 'leadStatus',
        title: 'Lead status',
        value: getLeadStatusChipValue(filter.leadStatus),
      });
    }

    return chips;
  },
);
