import React from 'react';
import styles from './thumbnailSelector.module.css';
import ContactThumbnail from '../contactThumbnail/ContactThumbnail';

const ContactThumbnailSelector = ({ contacts, onSelect, preSelectedContacts }) => {
    return (
        <div className={styles['contact-selector']} key={contacts.length}>
            {contacts?.map((contact, index) => (
                <ContactThumbnail key={index} contact={contact} onClick={() => onSelect(contact)} selected={preSelectedContacts.includes(contact)} />
            ))}
        </div>
    );
};

export default ContactThumbnailSelector;
