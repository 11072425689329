import stableStringify from 'json-stable-stringify';

import datetime from 'refactored/utils/datetime';

import {
  optimizedPropertyViewingActions,
} from 'store/actions/propertyViewings';

import {
  optimizedRealtorTransactionsActions,
} from 'store/actions/realtorTransactions';

import {
  optimizedFollowUpRemindersActions,
} from 'store/actions/followUpReminders';

const momentFormat = (date) => datetime(date).format('YYYY-MM-DD HH:mm:ss');

const startOfMonth = (date) => datetime(date).startOf('month').utc();
const endOfMonth = (date) => datetime(date).endOf('month').utc();

const filters = {
  showingsFilter: {
    contact_type: 'not prospects',
  },
  pitchesFilter: {
    contact_type: 'prospects',
  },
  closingEscrowsFilter: {
    no_offset: true,
    date_fields: [
      'close_escrow_expires_at',
    ],
  },
  contingenciesDueFilter: {
    no_offset: true,
    date_fields: [
      'initial_documents_expires_at',
      'seller_disclosure_expires_at',
      'inspections_expires_at',
      'appraisal_expires_at',
      'loan_expires_at',
      'final_walkthrough_expires_at',
      'move_out_date_expires_at',
    ],
  },
  followUpRemindersFilter: {
  },
};

export const getFilter = (filterName, date) => {
  const dateFrom = momentFormat(startOfMonth(date));
  const dateTo = momentFormat(endOfMonth(date));

  return {
    ...filters[filterName],
    date_from: dateFrom,
    date_to: dateTo,
    scheduledDate: [dateFrom, dateTo],
  };
};

export const getShowings = (date) => {
  const showingsFilter = getFilter('showingsFilter', date);
  optimizedPropertyViewingActions.getViewingsForFilter(stableStringify(showingsFilter), showingsFilter);
};

export const getPitches = (date) => {
  const pitchesFilter = getFilter('pitchesFilter', date);
  optimizedPropertyViewingActions.getViewingsForFilter(stableStringify(pitchesFilter), pitchesFilter);
};

export const getClosingEscrow = (date) => {
  const closingEscrowsFilter = getFilter('closingEscrowsFilter', date);

  optimizedRealtorTransactionsActions.getTransactionsForFilter(
    stableStringify(closingEscrowsFilter),
    closingEscrowsFilter,
  );
};

export const getContingenciesDue = (date) => {
  const contingenciesDueFilter = getFilter('contingenciesDueFilter', date);

  optimizedRealtorTransactionsActions.getTransactionsForFilter(
    stableStringify(contingenciesDueFilter),
    contingenciesDueFilter,
  );
};

export const getFollowUpReminders = (date) => {
  const remindersFilter = getFilter('followUpReminders', date);

  optimizedFollowUpRemindersActions.getFollowUpRemindersForFilter(
    stableStringify(remindersFilter),
    remindersFilter,
  );
};

export const getMonthData = (date) => {
  getShowings(date);
  getPitches(date);
  getClosingEscrow(date);
  getContingenciesDue(date);
  getFollowUpReminders(date);
};
