import apiConnector, { endpoints } from 'api';

const list = async () => {
  const endpoint = endpoints.client.integrations.items;
  try {
    const result = await apiConnector.client.get(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default list;
