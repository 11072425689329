exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".profileDropdown_profile-dropdown-menu-item__36vak {\n  width: 100%;\n  padding: 5px 15px;\n  justify-content: flex-start;\n  cursor: pointer;\n  font-family: \"Hind-Regular\";\n}\n\n.profileDropdown_profile-dropdown-menu-item__36vak:hover {\n  background-color: #eee;\n}\n\n.profileDropdown_profile-dropdown-menu-item__36vak:active {\n  background-color: #ddd;\n  transition: 0.2s;\n}\n\n.profileDropdown_profile-dropdown-border-box__wBiMh {\n  margin: 10px 15px;\n  border-bottom: solid 1px;\n  border-color: #ddd;\n}\n\n.profileDropdown_profile-dropdown-menu__2DZWz {\n  -webkit-transform: translate(-12px, 34px);\n          transform: translate(-12px, 34px);\n}", ""]);

// exports
exports.locals = {
	"profile-dropdown-menu-item": "profileDropdown_profile-dropdown-menu-item__36vak",
	"profile-dropdown-border-box": "profileDropdown_profile-dropdown-border-box__wBiMh",
	"profile-dropdown-menu": "profileDropdown_profile-dropdown-menu__2DZWz"
};