import React from 'react';
import SenderPillDetailed from './SenderPillDetailed';
import { Menu, Dropdown } from 'antd';
import styles from './senderPillDetailed.module.css';

const SenderPillDetailedExtraCount = ({ extraContacts, onContactRemove, }) => {
    const menuStyle = {
        backgroundColor: 'transparent',
        boxShadow: 'none'
    };
    const menu = (
        <Menu style={menuStyle}> {extraContacts?.map((contact, index) => (
            <Menu.Item key={'contact-menu-item' + index} style={menuStyle}>
                <SenderPillDetailed key={'contact-' + index} altText={contact.name} contact={contact} flat={true} onContactRemove={() => onContactRemove(contact)} />
            </Menu.Item>
        ))}
        </Menu>
    )
    return (
        <Dropdown
            overlay={menu}
        >
            <div className={styles['detailed-sender-pill-wrapper']}>
                <span className={styles['extra-count']}>
                    + {extraContacts?.length}
                </span>
            </div>
        </Dropdown>
    );
};

export default SenderPillDetailedExtraCount;
