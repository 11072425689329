import React from 'react';
import PropTypes from 'prop-types';

import { pretty } from 'refactored/utils/numbers';;

import ErrorBoundary from 'ui/ErrorBoundary';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Typography from 'ui/Typography';
import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';

import ImageCarousel from 'sections/MLS/modules/ImageCarousel';

import PropertyAttributes from '../PropertyAttributes';

const ExternalPropertyOverview = (props) => {
  const {
    propertyData,
    propertyImages,
  } = props;

  const {
    price,
    bedrooms,
    bathrooms,
    floorArea,
    parking,
    plotArea,
    address,
    type,
  } = propertyData;

  const attributes = {
    bedrooms,
    bathrooms,
    floor_area: floorArea,
    plot_area: plotArea,
    parking,
    type,
  };

  const images = propertyImages.map((image) => ({ src: image.url }));

  return (
    <ErrorBoundary>
      <Flex
        container
        spacing={2}
        alignItems="stretch"
        flexWrap="wrap"
      >
        <FlexItem
          xs={8}
        >
          <ImageCarousel
            editable={false}
            externalImages={images}
          />
        </FlexItem>

        <FlexItem
          xs={16}
        >
          <Flex
            fullWidth
            fullHeight
            flexDirection="column"
            spacing={4}
          >
            <FlexItem fullWidth>
              <DecoratedFieldWrapper
                iconName="map-pin"
                iconType="feather"
                variant="focus"
              >
                <Typography
                  variant="title1"
                  noMargin
                >
                  {address}
                </Typography>
              </DecoratedFieldWrapper>

            </FlexItem>

            <Flex
              fullWidth
              spacing={2}
            >
              <FlexItem flex={1}>

                <DecoratedFieldWrapper
                  iconName="dollar-sign"
                  iconType="feather"
                  variant="focus"
                >
                  <Typography
                    variant="heading"
                    noMargin
                    tag="p"
                    align="left"
                    ellipsis
                  >
                    {price && pretty.int(price)}
                  </Typography>
                </DecoratedFieldWrapper>
              </FlexItem>
            </Flex>

            <Flex
              fullWidth
              spacing={2}
              style={{ marginTop: 'auto' }}
            >
              <PropertyAttributes attributes={attributes} />
            </Flex>
          </Flex>
        </FlexItem>

      </Flex>
    </ErrorBoundary>
  );
};

ExternalPropertyOverview.defaultProps = {
  propertyImages: {},
};

const {
  shape,
} = PropTypes;

ExternalPropertyOverview.propTypes = {
  propertyData: shape({}).isRequired,
  propertyImages: shape({}),
};

export default ExternalPropertyOverview;
