import React, { Component } from 'react';
import PropTypes from 'prop-types';

import urlParser from 'url-parse';


class NewClientLogin extends Component {
  componentDidMount() {
    const {
      login,
      logout,
    } = this.props;

    const parsedUrl = urlParser(window.location.href, true);

    const {
      query,
    } = parsedUrl;

    const isAuthenticated = !!localStorage.getItem('token');
    const isNewClient = query.action === 'new_client';

    if (isAuthenticated) {
      logout();
    }

    if (isNewClient) {
      login({ email: query.user, password: query.tempPass });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isAuthenticated,
      history,
    } = this.props;

    if (isAuthenticated) {
      history.push('/');
    }
  }

  render() {
    return <div />;
  }
}


NewClientLogin.defaultProps = {
  isAuthenticated: false,
};

const {
  bool,
  func,
  shape,
} = PropTypes;

NewClientLogin.propTypes = {
  login: func.isRequired,
  logout: func.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  isAuthenticated: bool,
};

export default NewClientLogin;
