import React from "react";
import Menu from '@material-ui/core/Menu';
import styles from './emailListItemDropdown.module.css';
import ReplyIcon from 'assets/images/commsHub/reply-arrow.svg';
import ReplyAllIcon from 'assets/images/commsHub/reply-all-arrow.svg';
import ForwardIcon from 'assets/images/commsHub/forward-arrow.svg';
import ClosedIcon from 'assets/images/commsHub/check-icon.svg';
import TrashIcon from 'assets/images/commsHub/trash-can-icon.svg';
import { useThreadModal } from "refactored/components/shared/providers/modals/ThreadModal.provider";
import { useCommsHub } from "refactored/components/shared/providers/data/CommsHub.provider";

const EmailListItemDropdown = ({ isOpen, anchorElement, email, onClose }) => {
    const {
        openThreadModalByEmail,
        closeThreadByEmail,
        deleteEmail,
        setReply,
        setReplyAll,
        setForward
    } = useThreadModal(email);
    const { sendThreadToClosed, deleteEmailFromThread } = useCommsHub();
    return (
        <Menu
            open={isOpen}
            anchorEl={anchorElement}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            className={styles['email-list-item-menu']}
        >
            <div className={styles['email-list-item-menu-item']} onClick={async () => {
                await openThreadModalByEmail(email)
                setReply()
                onClose()
            }}>
                <img src={ReplyIcon} alt="icon" /><span>Reply</span>
            </div>
            <div className={styles['email-list-item-menu-item']} onClick={async () => {
                await openThreadModalByEmail(email)
                setReplyAll()
                onClose()
            }}>
                <img src={ReplyAllIcon} alt="icon" /><span>Reply to all</span>
            </div>
            <div className={styles['email-list-item-menu-item']} onClick={async () => {
                await openThreadModalByEmail(email)
                setForward()
                onClose()
            }}>
                <img src={ForwardIcon} alt="icon" /><span>Forward</span>
            </div>
            <div className={styles['email-list-item-menu-item']} onClick={async () => {
                sendThreadToClosed(await closeThreadByEmail(email))
                onClose()
            }}>
                <img src={ClosedIcon} alt="icon" /><span>Mark as closed</span>
            </div>
            <div className={styles['email-list-item-menu-item']} onClick={async () => {
                await deleteEmail(email)
                deleteEmailFromThread(email)
                onClose()
            }}>
                <img src={TrashIcon} alt="icon" /><span>Delete</span>
            </div>
        </Menu>
    );
}

export default EmailListItemDropdown;
