import React from 'react';
import emojis from 'refactored/constants/email/emojis';
import getTimeAgo from 'refactored/utils/getTimeAgo';
import Tooltip from 'ui/Tooltip';
import EmailIntentIcons from 'sections/Communications/components/EmailIntentIcons/EmailIntentIcons';
import styles from './emailCardHeader.module.css';
import getInitialsFromString from 'refactored/utils/getInitialsFromString';
import EmailCompanyIcon from 'refactored/components//shared/emailCompanyIcon/EmailCompanyIcon';

export const EmailCardHeader = ({ email }) => {
    const timeReceived = email?.clean_sympleteAnalysis?.time_received * 1000;
    const timeReceivedFormatted = getTimeAgo(timeReceived);
    const senderName = email?.clean_sympleteAnalysis?.sender_name;
    const senderNameFormatted = getInitialsFromString(senderName)
    return <div className={styles['email-card-header']}
        onClick={() => { }}>
        <div className={styles['email-card-profile-area']} >
            <div className={styles['email-circle-profile-picture']}>
                <span>{senderNameFormatted}</span>
                <EmailCompanyIcon />
            </div>
            <div className={styles['email-card-profile-names']}>
                <div className={styles['email-card-sender']} >
                    {senderName} | {' '}
                    {email?.clean_sympleteAnalysis?.relationship}
                </div>
                <div className={styles['email-card-time-received']}>
                    {timeReceivedFormatted}{' '}
                    {email?.clean_sympleteAnalysis?.sentiment ? (
                        <Tooltip title={email?.clean_sympleteAnalysis?.sentiment}>
                            {emojis[email?.clean_sympleteAnalysis?.sentiment]}
                        </Tooltip>
                    ) : (
                        <Tooltip title="Neutral">
                            {emojis['Neutral']}
                        </Tooltip>
                    )}
                </div>
            </div>
        </div>

        <div>
            {email?.clean_sympleteAnalysis?.intent ? (
                <Tooltip title={email?.clean_sympleteAnalysis?.intent}>
                    {EmailIntentIcons(email?.clean_sympleteAnalysis?.intent)}
                </Tooltip>
            ) : (
                <Tooltip title="Information">
                    {EmailIntentIcons('Information')}
                </Tooltip>
            )}
        </div>
    </div>
}

export default EmailCardHeader