import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import emptySchedule from 'assets/images/realtor/empty-schedule.svg';
import Typography from 'ui/Typography';

import { isEmpty } from 'shared/utility';
import moment from 'moment';
import { useDebounce } from 'use-debounce';

import TimeSlotRow from './TimeSlotRow';

import {
  Table,
  EmptyImage,
  EmptyItemsContainer,
  OverflowContainer,
} from './styledItems';

const ScheduleTable = (props) => {
  const {
    scheduleData,
    getShowings,
    getPitches,
    getClosingEscrow,
    getContingenciesDue,
    getFollowUpReminders,
    selectedDate,
    style,
    onItemClick,
    openAddEventModal,
  } = props;

  const [selectedDateDebounced] = useDebounce(selectedDate, 800);

  useEffect(() => {
    getShowings(selectedDateDebounced);
    getPitches(selectedDateDebounced);
    getClosingEscrow(selectedDateDebounced);
    getContingenciesDue(selectedDateDebounced);
    getFollowUpReminders(selectedDateDebounced);
  }, [
    selectedDateDebounced,
  ]);

  const startOfDay = moment(selectedDate).startOf('day').format('YYYY-MM-DD HH:mm:ss');

  const handleContainerClick = useCallback((event) => {
    openAddEventModal();
  }, [
    openAddEventModal,
  ]);

  const handleItemClick = useCallback((itemData, event) => {
    event.stopPropagation();
    if (onItemClick) {
      onItemClick(itemData);
    }
  }, [
    onItemClick,
  ]);

  return !isEmpty(scheduleData) ? (
    <OverflowContainer style={style} onClick={handleContainerClick}>
      <Table>
        {
          Array(24) // TODO: there must be a more elegant way to handle this
            .fill()
            .map((_, index) => {
              const hour = (index + 7) % 24;
              const time = moment(startOfDay).add(hour, 'hour').format('YYYY-MM-DD HH:mm:ss');

              if (!scheduleData[time]) return false;

              return (
                <TimeSlotRow
                  key={hour}
                  items={scheduleData[time] ? scheduleData[time] : []}
                  time={time}
                  onItemClick={handleItemClick}
                />
              );
            })
            .filter(Boolean)
        }
      </Table>
    </OverflowContainer>
  ) : (
    <EmptyItemsContainer onClick={handleContainerClick}>
      <EmptyImage
        src={emptySchedule}
        aria-hidden="true"
        alt=""
      />
      <Typography
        variant="title2"
        weight="normal"
        align="center"
        noMargin
      >
        No appointments arranged for the selected date
      </Typography>
    </EmptyItemsContainer>
  );
};

ScheduleTable.defaultProps = {
  style: {},
  onItemClick: null,
};

const {
  arrayOf,
  func,
  shape,
  string,
} = PropTypes;

ScheduleTable.propTypes = {
  scheduleData: arrayOf(shape()).isRequired,
  getShowings: func.isRequired,
  getPitches: func.isRequired,
  getClosingEscrow: func.isRequired,
  getContingenciesDue: func.isRequired,
  getFollowUpReminders: func.isRequired,
  selectedDate: string.isRequired,
  style: shape(),
  onItemClick: func,
};

export default ScheduleTable;
