import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';

import createHtmlMarkup from 'shared/utils/createHtmlMarkup';
import EditableTextField from 'shared/components/EditableTextField';

import Modal from 'ui/Modal';
import Typography from 'ui/Typography';
import Button from 'ui/Button';

import {
  TextWrapper,
  Extra,
  NoteText,
} from './styledItems';

const ViewingNotesModal = (props) => {
  const {
    content,
    onNoteChange,
    closeModal,
    isVisible,
  } = props;

  const [noteText, setNoteText] = useState(null);

  useEffect(() => {
    const text = content === '<p></p>' ? '' : content;
    setNoteText(text);
  }, [content, isVisible]);

  const onSave = useCallback(() => {
    onNoteChange(noteText);
    closeModal();
  }, [noteText]);

  const clearNote = useCallback(() => {
    setNoteText(null);
  }, []);

  const renderValue = useCallback((value) => (
    <NoteText>
      {createHtmlMarkup(value)}
    </NoteText>
  ), []);

  return (
    <Modal
      width={600}
      visible={isVisible}
      onCancel={closeModal}
      destroyOnClose
      title="Showing Notes"
      footer={[
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>,
        <Button variant="primary" onClick={onSave}>
          Save
        </Button>,
      ]}
    >
      <TextWrapper>
        <EditableTextField
          richTextEditor
          onConfirm={setNoteText}
          value={noteText}
          placeholder={'<p style="cursor: pointer;">Enter note text...</p>'}
          renderValue={renderValue}
        />
      </TextWrapper>

      <Extra>
        <NakedButton onClick={clearNote}>
          <Typography
            variant="subtitle2"
            textDecoration="underline"
            color="error"
          >
            Clear note
          </Typography>
        </NakedButton>
      </Extra>
    </Modal>
  );
};

ViewingNotesModal.defaultProps = {
  content: null,
  isVisible: false,
};

const {
  string,
  bool,
  func,
} = PropTypes;

ViewingNotesModal.propTypes = {
  content: string,
  isVisible: bool,
  onNoteChange: func.isRequired,
  closeModal: func.isRequired,
};

export default ViewingNotesModal;
