import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import prettysize from 'prettysize';

import Button from 'ui/Button';
import Tooltip from 'ui/Tooltip';

import useModal from 'ui/hooks/useModal';

import PreviewModal from 'shared/AttachmentsV4/PreviewModal/PreviewModal';

import downloadFile from 'shared/utils/downloadFile';

const getExtension = filename => filename.split('.').pop();

const apiUrl = process.env.REACT_APP_SYMPLETE_CLIENT_API;

const getSrc = tokenId => attachmentId =>
  `${apiUrl}/api/integrations/emailAttachments/${attachmentId}?tokenId=${tokenId}&auth=${localStorage.getItem(
    'token'
  )}`;

const Attachment = props => {
  const { tokenId, attachment, EmailFileIcon, filename, isWidthContent } = props;

  const { openModal, closeModal, isModalVisible } = useModal();

  const fileUrl = getSrc(tokenId)(attachment.id);

  const onAttachmentClick = useCallback(() => {
    openModal();
  }, [openModal, fileUrl]);

  return (
    <>
      {EmailFileIcon && filename && (
        <Tooltip title={filename}>
          <div
            style={{
              display: 'flex',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '20px',
              padding: isWidthContent ? '2px 8px' : '2px',
              maxWidth: isWidthContent ? 'fit-content' : '100px',
              maxHeight: '25px',
              overflow: 'hidden'
            }}
            onClick={onAttachmentClick}
          >
            <img
              src={EmailFileIcon}
              width={20}
              height={20}
              style={{ padding: '2px' }}
            />
            <p style={{ color: '#141736', fontWeight: 500, fontSize: '15px' }}>
              {filename}
            </p>
          </div>
        </Tooltip>

      )}

      <PreviewModal
        isVisible={isModalVisible}
        onCancel={closeModal}
        previewFileUrl={fileUrl}
        previewFileExtension={getExtension(attachment.filename)}
        footer={[
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>,
          <Button
            variant="primary"
            icon="download"
            iconPosition="right"
            onClick={() => {
              downloadFile(fileUrl);
            }}
          >
            Download {prettysize(attachment.size)}
          </Button>
        ]}
      />
    </>
  );
};

const { number, shape, string } = PropTypes;

Attachment.propTypes = {
  tokenId: number.isRequired,
  attachment: shape({
    id: string,
    filename: string
  }).isRequired,
  EmailFileIcon: string,
  filename: string
};

export default Attachment;
