import importances from "refactored/constants/email/importance";
const getImportanceLabel = (percentage) => {
    if (percentage < 20) {
        return importances[0];
    } else if (percentage < 40) {
        return importances[1];
    } else if (percentage < 60) {
        return importances[2];
    } else if (percentage < 80) {
        return importances[3];
    } else {
        return importances[4];
    }
};
export default getImportanceLabel