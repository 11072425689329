import requests from 'api/requests';

import getTaskAndRefreshRelatedSummaries from './getTaskAndRefreshRelatedSummaries';
import deleteEmployeeTaskFromTasksBoards from './deleteEmployeeTaskFromTasksBoards';

const updateTaskAssignees = ({
  taskId,
  newAssignees,
  removedAssignees,
}) => async (dispatch) => {
  const body = {
    newAssignees,
    removedAssignees,
  };

  try {
    await requests.tasks.updateTaskAssignees(taskId)(body);
  } catch (error) {
    console.error(error);
    return;
  }

  removedAssignees.forEach((employeeId) => {
    dispatch(deleteEmployeeTaskFromTasksBoards(taskId, employeeId));
  });
  dispatch(getTaskAndRefreshRelatedSummaries(taskId));
};

export default updateTaskAssignees;
