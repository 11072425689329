import {
  SHOW_DELETE_CHAT_WARNING,
  HIDE_DELETE_CHAT_WARNING,
} from 'store/actionTypes/chat';

export const showDeleteChatWarning = () => (dispatch) => {
  dispatch({
    type: SHOW_DELETE_CHAT_WARNING,
  });
};

export const hideDeleteChatWarning = () => (dispatch) => {
  dispatch({
    type: HIDE_DELETE_CHAT_WARNING,
  });
};
