import React from 'react';
import emojis from 'refactored/constants/email/emojis';
import getTimeAgo from 'refactored/utils/getTimeAgo';
import Tooltip from 'ui/Tooltip';
import styles from './emailListItemHeader.module.css';
import getInitialsFromString from 'refactored/utils/getInitialsFromString';
import EmailCompanyIcon from 'refactored/components//shared/emailCompanyIcon/EmailCompanyIcon';

export const EmailListItemHeader = ({ email, index }) => {
    const timeReceived = email?.clean_sympleteAnalysis?.time_received * 1000;
    const timeReceivedFormatted = getTimeAgo(timeReceived);
    const senderName = email?.clean_sympleteAnalysis?.sender_name;
    const senderNameFormatted = getInitialsFromString(senderName);
    return <div className={styles['email-listitem-header']}
        onClick={() => { }}>
        <div className={styles['email-listitem-profile-area']} >
            <div className={styles['email-circle-profile-picture']}>
                <span>{senderNameFormatted}</span>
                <EmailCompanyIcon />
            </div>
            <div className={styles['email-listitem-profile-names']}>
                <div className={styles['email-listitem-sender']} >
                    {senderName}
                </div>
                <div className={styles['email-listitem-time-received']}>
                    {timeReceivedFormatted}{' '}
                    {email?.clean_sympleteAnalysis?.sentiment ? (
                        <Tooltip title={email?.clean_sympleteAnalysis?.sentiment}>
                            {emojis[email?.clean_sympleteAnalysis?.sentiment]}
                        </Tooltip>
                    ) : (
                        <Tooltip title="Neutral">
                            {emojis['Neutral']}
                        </Tooltip>
                    )}
                </div>
            </div>
        </div>
    </div>
}

export default EmailListItemHeader