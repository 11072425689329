import React, { useContext, useState, useCallback, useEffect } from 'react';
// TODO - clean up current implementation with custom hook
import useCustomGoogleIntegrations from 'sections/Integrations/hooks/useCustomGoogleIntegrations';

import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { Steps } from 'antd';
const { Step } = Steps;

import GoogleImg from 'assets/icons/Google.svg';
import GmailLogo from 'assets/images/gmail-logo.png';
import CalendarLogo from 'assets/images/google-calendar-logo.png';
import ContactsLogo from 'assets/images/google-contacts-logo.png';
import noOp from 'shared/utility/noOp';

import {
  ItemWrapper,
  ItemWrapperDisabled,
  StyledModal,
  ModalText,
  Label,
  ModalTitleText,
  ModalInnerTitle,
  ModalSubtitleText,
  StyledButton,
  Grid
} from './styledItems';
import { NextButton } from '../styledItems';

const useStyles = makeStyles({
  label: {
    textTransform: 'none',
    fontWeight: 400,
    color: '#757575'
  }
});

const mockSteps = [
  { title: 'step1' },
  { title: 'step2' },
  { title: 'step3' },
  { title: 'step4' },
  { title: 'step5' }
];

// NB: Clicking on checkbox adds/removes scope from the google scopes we choose.
// only once we click @ connect we make the API call to useGoogleAuth
const EmailConnectModal = ({
  name = 'back',
  changeCurrentOnboardingStep = noOp
}) => {
  const classes = useStyles();

  // gmail
  const GMAIL_SCOPES = [
    'https://mail.google.com/',
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/userinfo.email'
  ];
  // contacts
  const CONTACTS_SCOPES = 'https://www.googleapis.com/auth/contacts';
  // calendar
  const CALENDAR_SCOPES = 'https://www.googleapis.com/auth/calendar';

  // NB: array of scopes we want to request from google when we click connect
  const [GOOGLE_SCOPES, SET_GOOGLE_SCOPES] = useState([
    ...GMAIL_SCOPES,
    CONTACTS_SCOPES,
    CALENDAR_SCOPES
  ]);
  const [hasContactsScope, setHasContactsScope] = useState(true);
  const [hasCalendarScope, setHasCalendarScope] = useState(true);

  // useEffect(() => {
  //   console.log('----------------------');
  //   console.log('a change in GOOGLE_SCOPES was detected!');
  //   console.log('GOOGLE_SCOPES: ', GOOGLE_SCOPES);
  //   console.log('hasContactsScope: ', hasContactsScope);
  //   console.log('hasCalendarScope: ', hasCalendarScope);
  //   console.log('----------------------');
  // }, [GOOGLE_SCOPES, hasContactsScope, hasCalendarScope]);

  const handleContactsClick = useCallback(() => {
    const newScopes = [...GOOGLE_SCOPES];
    const isContactsIncluded = newScopes.includes(CONTACTS_SCOPES);
    if (isContactsIncluded) {
      // remove contacts scope
      const index = newScopes.indexOf(CONTACTS_SCOPES);
      if (index > -1) {
        newScopes.splice(index, 1);
      }
      SET_GOOGLE_SCOPES(newScopes);
      setHasContactsScope(false);
    } else {
      // add contacts scope
      newScopes.push(CONTACTS_SCOPES);
      SET_GOOGLE_SCOPES(newScopes);
      setHasContactsScope(true);
    }
  }, [GOOGLE_SCOPES, hasContactsScope]);

  const handleCalendarClick = useCallback(() => {
    const newScopes = [...GOOGLE_SCOPES];
    const isCalendarIncluded = newScopes.includes(CALENDAR_SCOPES);
    if (isCalendarIncluded) {
      // remove calendar scope
      const index = newScopes.indexOf(CALENDAR_SCOPES);
      if (index > -1) {
        newScopes.splice(index, 1);
      }
      SET_GOOGLE_SCOPES(newScopes);
      setHasCalendarScope(false);
    } else {
      // add calendar scope
      newScopes.push(CALENDAR_SCOPES);
      SET_GOOGLE_SCOPES(newScopes);
      setHasCalendarScope(true);
    }
  }, [GOOGLE_SCOPES, hasCalendarScope]);

  // try constructing the URL in here if all else fails
  const GOOGLE_OAUTH_AUTH_URL = 'https://accounts.google.com/o/oauth2/v2/auth';
  console.log('Google Modal, NODE_ENV: ', process.env.NODE_ENV)
  const redirectUrl = process.env.NODE_ENV === 'local'
    ? 'http://localhost:7000'
    : process.env.REACT_APP_SYMPLETE_CLIENT_API;

  const config = {
    googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    redirectUri: `${redirectUrl}/api/google`
  };
  console.log('Google Modal, define config: ', config)

  const handleConnectClick = () => {
    const gmailAuthData = {
      response_type: 'code',
      access_type: 'offline',
      client_id: config.googleClientId,
      redirect_uri: config.redirectUri,
      // Note: this is only for testing to ensure a refresh token is
      // passed every time, but requires the user to approve offline
      // access every time. You should remove this if you don't want
      // your user to have to approve access each time they connect
      prompt: 'consent'
    };

    const params = new URLSearchParams(gmailAuthData);
    // console.log('EC - 1 params before manipulation: ', params);

    const joinedScopes = GOOGLE_SCOPES.join(' ');
    const token = localStorage.getItem('token') ?? 'NO TOKEN FOUND';
    // NB: to insert multiple scopes on our nylas_tokens table, we need to pass in oneOf (email, emailAndContacts, emailAndCalendar, emailAndContactsAndCalendar)
    // otherwise we will have a mismatch on what we show on Settings/Integrations bubbles
    const customScope =
      hasContactsScope && hasCalendarScope
        ? 'emailAndContactsAndCalendar'
        : hasContactsScope
          ? 'emailAndContacts'
          : hasCalendarScope
            ? 'emailAndCalendar'
            : 'email';
    const customDataString = JSON.stringify({
      auth: token,
      scope: customScope
    });
    params.set('state', customDataString);
    // console.log('EC - setting state to be: ', customDataString);
    params.set('scope', joinedScopes);
    // console.log('EC - setting scope to be: ', joinedScopes);
    // console.log('EC - 2 params after manipulation: ', params.toString());

    const googleAuthUri = `${GOOGLE_OAUTH_AUTH_URL}?${params.toString()}`;
    // console.log('EC - constructed googleAuthUri on SUCCESS: ', googleAuthUri); // DEBUGGING - it is at this point that the app calls api/client-activity/user-logout
    window.location.href = googleAuthUri;
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      spacing={3}
    >
      <ModalTitleText>WELCOME {name.toUpperCase()}</ModalTitleText>
      <Flex flexDirection="row" alignItems="center" justifyContent="center">
        <ModalSubtitleText>
          We're so glad you joined us. Let's get you set up so you can{' '}
          <b> focus on being awesome.</b>
        </ModalSubtitleText>
      </Flex>
      <StyledModal>
        <ModalInnerTitle>
          Connect your{' '}
          <img src={GoogleImg} width={123} height={49} alt="Google logo" />{' '}
          account
        </ModalInnerTitle>

        <Typography variant="title2" weight="normal" align="center" noMargin>
          Select which Google services you would like to connect.
        </Typography>

        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          spacing={3}
          style={{ marginTop: 40 }}
        >
          <Flex justifyContent="center" spacing={2} alignItems="stretch">
            <Flex flexDirection="column">
              <ItemWrapperDisabled>
                <Flex
                  fullWidth
                  alignItems="center"
                  justifyContent="space-between"
                  flexDirection="row"
                >
                  <Flex flexDirection="row" alignItems="center">
                    <img
                      src={GmailLogo}
                      width={48}
                      height={48}
                      alt="Gmail logo"
                    />
                    <Typography
                      variant="secondary"
                      weight="normal"
                      align="center"
                      noMargin
                    >
                      <ModalText>Email</ModalText>
                    </Typography>
                  </Flex>
                  <Flex flexDirection="column">
                    <Checkbox
                      color="secondary"
                      checked={true}
                      onChange={() => { }}
                      value={'idk'}
                      label="Email is a requirement"
                      className={classes.label}
                      disabled
                    />
                    <Label>Email is a requirement</Label>
                  </Flex>
                </Flex>
              </ItemWrapperDisabled>
              <ItemWrapper onClick={handleContactsClick}>
                <Flex
                  fullWidth
                  alignItems="center"
                  justifyContent="space-between"
                  flexDirection="row"
                >
                  <Flex flexDirection="row" alignItems="center">
                    <img
                      src={ContactsLogo}
                      width={48}
                      height={48}
                      alt="Google contacts logo"
                    />
                    <Typography
                      variant="secondary"
                      weight="normal"
                      align="center"
                      noMargin
                    >
                      <ModalText>Contacts</ModalText>
                    </Typography>
                  </Flex>
                  <Flex flexDirection="column">
                    <Checkbox
                      color="primary"
                      checked={hasContactsScope}
                      onChange={() => { }}
                      value={'contacts'}
                    />
                  </Flex>
                </Flex>
              </ItemWrapper>
              <ItemWrapper onClick={handleCalendarClick}>
                <Flex
                  fullWidth
                  alignItems="center"
                  justifyContent="space-between"
                  flexDirection="row"
                >
                  <Flex flexDirection="row" alignItems="center">
                    <img
                      src={CalendarLogo}
                      width={48}
                      height={48}
                      alt="Google calendar logo"
                    />
                    <Typography
                      variant="primary"
                      weight="normal"
                      align="center"
                      noMargin
                    >
                      <ModalText>Calendar</ModalText>
                    </Typography>
                  </Flex>
                  <Flex flexDirection="column">
                    <Checkbox
                      color="primary"
                      checked={hasCalendarScope}
                      onChange={() => { }}
                      value={'calendar'}
                    />
                  </Flex>
                </Flex>
              </ItemWrapper>
            </Flex>
          </Flex>
          <Flex justifyContent="center">
            <NextButton onClick={handleConnectClick}>Next</NextButton>
          </Flex>

          <Typography
            variant="subtitle"
            weight="normal"
            align="center"
            noMargin
          >
            We will only import the last 7 days of data and anything in the
            future.
          </Typography>
        </Flex>
      </StyledModal>
    </Flex>
  );
};

EmailConnectModal.defaultProps = {
  items: [],
  className: null,
  activeFilter: '',
  sameFontWeight: false,
  onSelect: () => () => { },
  inactiveItemBgc: '#f6f8fa',
  selectedItemBgc: 'transparent',
  itemClassName: null,
  valueProps: {
    variant: 'h1',
    tag: 'span',
    weight: 700
  },
  titleProps: {
    variant: 'h4'
  },
  isInvesedTextSelectedColor: false
};

const {
  arrayOf,
  number,
  shape,
  func,
  bool,
  oneOfType,
  string,
  oneOf
} = PropTypes;

EmailConnectModal.propTypes = {
  name: string.isRequired,
  iconName: string.isRequired,
  title: string.isRequired,
  description: oneOf([string]).isRequired,
  // children: element.isRequired,
  onSelect: func,
  className: string,
  activeFilter: string,
  items: arrayOf(
    shape({
      key: string,
      title: string,
      value: oneOfType([number, string])
    })
  ),
  inactiveItemBgc: string,
  selectedItemBgc: string,
  itemClassName: string,
  sameFontWeight: bool,
  valueProps: shape(),
  titleProps: shape(),
  isInvesedTextSelectedColor: bool
};

export default EmailConnectModal;
