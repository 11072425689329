import {
  RESET_PROPERTIES_FILTER_ITEM,
} from 'store/actionTypes/properties';

const resetFilterItem = (key) => ({
  type: RESET_PROPERTIES_FILTER_ITEM,
  payload: key,
});

export default resetFilterItem;
