import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
} from 'antd';
import { auth } from 'constants';
import validation from 'constants/validation.constants';

import Typography from 'ui/Typography';
import Modal from 'ui/Modal';
import Button from 'ui/Button';
import Textfield from 'ui/Textfield';

import { noopFn } from 'shared/utils/index';

const getChangePasswordMessage = (changeType) => {
  const messages = {
    [auth.FIRST_TIME_LOGIN]: auth.FIRST_TIME_LOGIN_MSG,
    [auth.RESTORE_AFTER_LOCKOUT]: auth.RESTORE_AFTER_LOCKOUT_MSG,
  };

  const message = messages[changeType] || auth.CHANGE_DEFAULT;

  return message;
};

const ChangePassword = (props) => {
  const {
    form,
    internal,
    changePassword,
    restoreAccessAndChangePassword,
    changeType,
    triggerChangeModal,
    changeModal,
    userReset,
  } = props;

  /**
   * @todo This function call changes global store.
   * Try to call this function in react useEffect hook.
   */
  if (changeType === auth.RESTORE_AFTER_LOCKOUT && !userReset.email) {
    triggerChangeModal();
  }

  const change = useCallback(() => {
    form.validateFields((err, values) => {
      if (!err) {
        if (changeType === auth.RESTORE_AFTER_LOCKOUT) {
          restoreAccessAndChangePassword(values.password, userReset);
        } else {
          changePassword(values.password, internal);
          /* triggerChangeModal(); */
        }
      }
    });
  }, [
    form,
    changeType,
    restoreAccessAndChangePassword,
    changePassword,
    triggerChangeModal,
    userReset,
    internal,
  ]);

  const compareToFirstPassword = useCallback((rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      callback(validation.PASSWORD_DIFF);
    } else {
      callback();
    }
  }, [form, validation]);

  const validateToNextPassword = useCallback((rule, value, callback) => {
    if (value) {
      form.validateFields(['confirmPasword'], { force: true });
    }
    callback();
  }, [form]);

  const footer = (
    <Button variant="primary" onClick={change}>
      Confirm
    </Button>
  );

  const changePasswordMessage = getChangePasswordMessage(changeType);

  const closable = internal && changeType !== auth.FIRST_TIME_LOGIN;

  return (
    <Modal
      width={450}
      title="Change Password"
      centered
      footer={footer}
      closable={closable}
      onCancel={closable ? triggerChangeModal : noopFn}
      visible={changeModal}
      afterClose={form.resetFields}
      zIndex={1001}
    >
      <Typography
        style={{ marginBottom: 30 }}
      >
        {changePasswordMessage}
      </Typography>

      <Form layout="vertical">
        <Form.Item>
          {form.getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: 'Please input your Password!',
              },
              {
                validator: validateToNextPassword,
              },
            ],
          })(
            <Textfield.Password
              name="password"
              label="Password"
              icon={null}
            />,
          )}
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          {form.getFieldDecorator('confirmPasword', {
            rules: [
              {
                required: true,
                message: 'Please confirm your Password!',
              },
              {
                validator: compareToFirstPassword,
              },
            ],
          })(
            <Textfield.Password
              name="confirmPasword"
              label="Confirm Password"
              icon={null}
            />,
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

ChangePassword.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  changePassword: PropTypes.func.isRequired,
  restoreAccessAndChangePassword: PropTypes.func.isRequired,
  triggerChangeModal: PropTypes.func.isRequired,
  internal: PropTypes.bool.isRequired,
  changeType: PropTypes.string.isRequired,
  changeModal: PropTypes.bool.isRequired,
  userReset: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
};

export default Form.create()(ChangePassword);
