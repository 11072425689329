import React, { useState } from 'react';
import PrimaryButton from '../../../shared/buttons/primaryButton/PrimaryButton';
import ToolbarFiltersContainer from './ToolbarFiltersContainer';
import ToolbarDisplayOptions from './ToolbarDisplayOptions';
import SendEmailForm from '../../../shared/forms/SendEmailForm';
import styles from './toolbar.module.css';
import StyledAntdModal from '../../../shared/styledAntd/styledAntdModal';

const Toolbar = ({ filters, selectedFilter, onToggle }) => {
    const threadsMetrics = filters.threadsMetrics
    const threadsTypesMetrics = filters.threadsTypesMetrics
    const [isOpenModal, setIsOpenModal] = useState(false)
    const toggleModal = () => {
        setIsOpenModal(!isOpenModal);
    }
    return (
        <div className={styles['toolbar']}>
            <ToolbarFiltersContainer filters={threadsMetrics} selectedFilter={selectedFilter} onToggle={onToggle} />
            <ToolbarFiltersContainer filters={threadsTypesMetrics} selectedFilter={selectedFilter} onToggle={onToggle} />
            <PrimaryButton onClick={toggleModal}>  + New </PrimaryButton >
            <ToolbarDisplayOptions />
            <StyledAntdModal visible={isOpenModal} onCancel={toggleModal}>
                <SendEmailForm onSendConfirm={toggleModal} />
            </StyledAntdModal >
        </div>
    );
};

export default Toolbar;