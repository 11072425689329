import React from 'react';
import ContactPill from '../contactPill/ContactPill';
import ContactPillExtraCount from '../contactPill/ContactPillExtraCount';
import styles from './contactPillList.module.css'

const ContactPillList = ({ selectedContacts, onContactRemove, onClick }) => {
    return (
        <div onClick={onClick} className={styles['contact-pill-list']}>
            {selectedContacts?.length > 0 && (
                <ContactPill key={0} contact={selectedContacts[0]} altText={selectedContacts[0].name} onContactRemove={onContactRemove} />
            )}
            {selectedContacts?.length > 1 && (
                <ContactPill key={1} contact={selectedContacts[1]} altText={selectedContacts[1].name} showName={false} onContactRemove={onContactRemove} />
            )}
            {selectedContacts?.length > 2 && (
                <ContactPillExtraCount
                    extraContacts={selectedContacts.slice(2)}
                    onContactRemove={onContactRemove}
                />
            )}
        </div>
    );
};

export default ContactPillList;
