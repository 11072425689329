import React from 'react';
import PropTypes from 'prop-types';

import { currency } from 'refactored/utils/numbers';

import { Tooltip } from 'antd';

import Typography from 'ui/Typography';

import {
  Root,
  Image,
} from './styledItems';

const SearchedProperty = (props) => {
  const {
    image,
    address,
    price,
    onClick,
  } = props;

  return (
    <Root onClick={onClick}>
      <Image
        shape="square"
        icon="picture"
        src={image?.url}
      />

      <div>
        <Tooltip title={address}>
          <Typography
            ellipsis
            style={{
              maxWidth: 300,
              marginBottom: 7,
            }}
            variant="body"
            weight={500}
            noMargin
          >
            {address}
          </Typography>
        </Tooltip>

        <Typography variant="body" noMargin>
          {currency.full(price)}
        </Typography>
      </div>
    </Root>
  );
};

const {
  shape,
  func,
  string,
} = PropTypes;

SearchedProperty.propTypes = {
  onClick: func.isRequired,
  image: shape({
    url: string,
  }).isRequired,
  address: string.isRequired,
  price: string.isRequired,
};

export default SearchedProperty;
