import React from 'react';
import { useCommsHub } from 'refactored/components/shared/providers/data/CommsHub.provider';
import CardsList from '../cardsList/CardsList';
import styles from './listView.module.css';
import { sortByImportance, sortByDate } from 'refactored/utils/emailSorts';

const ListView = ({ selectedFilter }) => {
    const { unreadedEmails, readedEmails, sentEmailsSorted, deletedEmailsSorted,
        businessEmails, personalEmails, inProgressEmails, closedEmails } = useCommsHub();

    return (
        <div className={
            `${styles['list-view']} ${selectedFilter === "new" ? styles['short-list-view'] : null}`
        }>
            {selectedFilter === "sent" &&
                <CardsList emails={sentEmailsSorted} />
            }
            {selectedFilter === "deleted" &&
                <CardsList emails={deletedEmailsSorted} />
            }
            {selectedFilter === "business" &&
                <CardsList emails={businessEmails} />
            }
            {selectedFilter === "personal" &&
                <CardsList emails={personalEmails} />
            }
            {selectedFilter === "new" &&
                <CardsList emails={
                    [
                        ...sortByImportance(unreadedEmails),
                        ...sortByImportance(readedEmails)
                    ]
                } />
            }
            {
                selectedFilter === "new" &&
                <div className={styles['in-progress-closed-section']}>
                    <CardsList
                        hasNumber={false}
                        emails={
                            [
                                ...sortByDate(inProgressEmails?.map((email) => {
                                    email.inProgress = true;
                                    return email
                                })),
                                ...sortByDate(closedEmails)
                            ]
                        } />
                </div>
            }
        </div>
    );
};

export default ListView;