import { callAlert } from 'ui/Alert';
// TODO - fix dependency cycles
import { // eslint-disable-line import/no-cycle
  apiConnector,
  getConnector,
  customerToUrlConnector,
} from 'shared/API';

import requests from 'api/requests';

import { common } from 'constants';

import {
  SET_FILE_COMPRESSION_PROGRESS,
  CLEAR_FILE_COMPRESSION_PROGRESS,
  SHOW_DELETE_IMAGE_WARNING_MODAL,
  HIDE_DELETE_IMAGE_WARNING_MODAL,
} from 'store/actionTypes/attachments';

import createAttachmentFormData from 'shared/utils/attachments/createAttachmentFormData';
// import isAllowedFormat from 'shared/utils/attachments/isAllowedFormat';

import { resolveLeadDetails } from './leads'; // eslint-disable-line import/no-cycle

/**
 * Returns a list of files by specific workspace and ID
 * @param {string} appModule
 * @param {string|number} id
 * @param {boolean} [isStandard=true]
 * @param {function} refresh
 * @return {Function}
 */
export const getFileList = (
  appModule,
  id,
  isStandard = true,
  refresh = () => { },
  needClearFiles = true,
) => (dispatch) => {
  if (!id) {
    return;
  }

  const connector = isStandard ? getConnector : customerToUrlConnector;
  dispatch(filePreloader(true));

  if (needClearFiles) {
    // we need to clear all previous data
    dispatch(getFileListSuccess([], appModule));
  }

  if (appModule && id) {
    connector(`/api/attachments/workspace/${appModule}/${id}`)
      .then((res) => {
        dispatch(getFileListSuccess(res, appModule));
        refresh(res, true);
        dispatch(filePreloader(false));
      })
      .catch((err) => {
        dispatch(getFileListError(err));
        dispatch(filePreloader(false));
      });
  }
};

export const GET_FILE_LIST_SUCCESS = 'GET_FILE_LIST_SUCCESS';
export const getFileListSuccess = (list, appModule) => ({
  type: GET_FILE_LIST_SUCCESS,
  list,
  appModuleList: {
    [appModule]: list,
  },
});

export const CREATE_ATTACHMENT = 'CREATE_ATTACHMENT';
export const createAttachment = (objectType) => (objectId) => (
  attachment,
  attachmentMeta,
  showSuccessMessage = true,
  isPublic = false,
) => async (dispatch, getState) => {
  try {
    /* const {
      myCompany: {
        general: {
          DatabaseName,
        },
      },
    } = getState(); */
    console.log('creating Attachment...')
    const attachmentFormData = await createAttachmentFormData(
      attachment,
      attachmentMeta,
      (progress) => {
        dispatch(setFileCompressionProgress(progress));
      },
    );

    dispatch(clearFileCompressionProgress());

    const rawResponse = await requests.attachments.uploadAttachment(objectType)(objectId)(attachmentFormData, isPublic);
    const result = rawResponse.data;

    /* if (objectType === 'company-logo') {
      requests.attachments.createCompanyLogoInGlobal(result, DatabaseName);
    } */

    dispatch({ type: CREATE_ATTACHMENT });
    if (showSuccessMessage) {
      callAlert.success('The file was uploaded successfully!');
    }
  } catch (error) {
    callAlert.error(common.ENTITY_TOO_LARGE.MSG);
    console.log('uploading file error', error);
  }
};

export const uploadFile = (data) => async (dispatch, getState) => {
  const {
    attachments: {
      file,
    },
    id,
    appModule,
  } = data;

  try {
    // if (isAllowedFormat('all')(file)) {
    dispatch(filePreloader(true));

    const attachmentMeta = {};

    if (data.appModule === 'ticket') {
      attachmentMeta.ticketId = id;
      const { Name = '', Surname = '' } = getState().auth.loggedUser;
      attachmentMeta.userFullName = `${Name} ${Surname}`;
    }

    const attachmentFormData = await createAttachmentFormData(
      file,
      attachmentMeta,
      (progress) => {
        dispatch(setFileCompressionProgress(progress));
      },
    );

    dispatch(clearFileCompressionProgress());

    await requests.attachments.uploadAttachment(appModule)(id)(attachmentFormData);

    dispatch(getFileList(appModule, id));

    dispatch(filePreloader(false));

    callAlert.success('The file was uploaded successfully!');
    // } else {
    //   callAlert.warning('Acceptable file formats: png, jpeg, gif, csv, pdf, mp4, webm');
    // }
  } catch (err) {
    console.error(err);

    callAlert.error(common.ENTITY_TOO_LARGE.MSG);
    dispatch(deleteFileError(err));
    dispatch(filePreloader(false));
  }
};

export const uploadFileV2 = (data) => async (dispatch) => {
  const {
    file,
    objectId,
    objectType,
    attachmentType,
  } = data;

  try {
    dispatch(filePreloader(true));

    const attachmentMeta = {};

    const attachmentFormData = await createAttachmentFormData(
      file,
      attachmentMeta,
      (progress) => {
        dispatch(setFileCompressionProgress(progress));
      },
    );

    dispatch(clearFileCompressionProgress());

    const result = await requests.attachments
      .uploadAttachmentV2(attachmentType)(objectType)(objectId)(attachmentFormData);

    dispatch(getFileList(common.OBJECT_TYPES[objectType], objectId));

    dispatch(filePreloader(false));

    callAlert.success('The file was uploaded successfully!');

    return result;
  } catch (err) {
    console.error(err);

    callAlert.error(common.ENTITY_TOO_LARGE.MSG);
    dispatch(deleteFileError(err));
    dispatch(filePreloader(false));
    return null;
  }
};

export const setFileCompressionProgress = (progress) => ({
  type: SET_FILE_COMPRESSION_PROGRESS,
  payload: progress,
});

export const clearFileCompressionProgress = () => ({
  type: CLEAR_FILE_COMPRESSION_PROGRESS,
});

export const GET_FILE_LIST_ERROR = 'GET_FILE_LIST_ERROR';
export const getFileListError = (err) => ({
  type: GET_FILE_LIST_ERROR,
  err,
});

export const deleteFile = (
  data,
  isStandard = true,
  refresh = () => { },
) => async (dispatch, getState) => {
  const {
    leads: {
      LeadID,
    },
  } = getState();

  const connector = isStandard ? apiConnector : customerToUrlConnector;
  dispatch(filePreloader(true));

  try {
    if (data.appModule === 'company-logo') {
      requests.attachments.deleteCompanyLogoInGlobal(data.fileId);
    }

    await connector(
      `/api/attachments/workspace/${data.appModule}/${data.id}/${data.fileId}`,
      'DELETE',
    );

    callAlert.success('Image deleted!');
  } catch (e) {
    dispatch(deleteFileError(e));
  }

  await dispatch(getFileList(data.appModule, data.id));
  refresh(data.id, {}, true);
  dispatch(filePreloader(false));

  if (LeadID) {
    dispatch(resolveLeadDetails(LeadID));
  }
};

export const deleteFileV2 = (objectType, objectId, attachmentId) => async (dispatch) => {
  dispatch(filePreloader(true));

  try {
    await requests.attachments.deleteAttachmentV2(objectType)(objectId)(attachmentId);

    callAlert.success('File deleted!');
  } catch (e) {
    dispatch(deleteFileError(e));
  }

  await dispatch(getFileList(common.OBJECT_TYPES[objectType], objectId));
  dispatch(filePreloader(false));
};

export const DELETE_FILE_ERROR = 'DELETE_FILE_ERROR';
export const deleteFileError = (err) => ({
  type: DELETE_FILE_ERROR,
  err,
});

export const updateFile = (
  data,
  isStandard = true,
  needClearFiles = true,
) => async (dispatch) => {
  const connector = isStandard
    ? apiConnector
    : customerToUrlConnector;

  try {
    await connector(
      `/api/attachments/workspace/${data.appModule}/${data.id}`,
      'PUT',
      data,
    );
  } catch (e) {
    dispatch(deleteFileError(e));
  }

  dispatch(getFileList(data.appModule, data.id, isStandard, null, needClearFiles));
  dispatch(filePreloader(false));
};

export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';
export const uploadFileError = (err) => ({
  type: UPLOAD_FILE_ERROR,
  err,
});

export const FILE_PRELOADER = 'FILE_PRELOADER';
export const filePreloader = (status) => ({
  type: FILE_PRELOADER,
  preloader: status,
});

export const TRIGGER_AVATAR_CROP_MODAL = 'TRIGGER_AVATAR_CROP_MODAL';
export const triggerAvatarCropModal = (
  cropModalVisible = false,
  cropAppModule = 'employee-logo',
  cb,
) => (dispatch) => {
  dispatch({
    type: TRIGGER_AVATAR_CROP_MODAL,
    cropModalVisible,
    cropAppModule,
  });
  cb && cb(); // eslint-disable-line chai-friendly/no-unused-expressions
};

export const showDeleteImageWarningModal = (appModule, imageObjectId, fileId) => ({
  type: SHOW_DELETE_IMAGE_WARNING_MODAL,
  openedImageModalInformation: {
    appModule,
    imageObjectId,
    fileId,
  },
});

export const hideDeleteImageWarningModal = () => ({
  type: HIDE_DELETE_IMAGE_WARNING_MODAL,
});
