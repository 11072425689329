exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".emailCard_email-card__32JMW {\n  background-color: #ffffff;\n  border-radius: 20px;\n  cursor: pointer;\n  max-width: 365px;\n  overflow: hidden;\n  padding: 12px 12px;\n  width: 100%;\n  min-width: 100%;\n  min-height: 180px;\n  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1411764706);\n  font-family: \"Hind-Regular\";\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n\n.emailCard_email-card-placeholder__1eWDl {\n  display: flex;\n  justify-content: center;\n  align-content: center;\n  align-items: center;\n  min-width: 100%;\n  min-height: 180px;\n  max-height: 180px;\n  background-color: #ffffff;\n  border-radius: 20px;\n  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1411764706);\n  font-family: \"Hind-Regular\";\n}\n\n.emailCard_email-card-spinner__sTGXE {\n  max-height: 64px;\n}\n\n.emailCard_email-card__32JMW:hover {\n  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.0784313725);\n}\n\n.emailCard_email-card-summary__3s6AZ {\n  font-weight: 400;\n  font-size: 16px;\n  max-width: 100%;\n  max-height: 44px;\n  color: #423f3f;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  margin: 2px 0px 10px 0px;\n}", ""]);

// exports
exports.locals = {
	"email-card": "emailCard_email-card__32JMW",
	"email-card-placeholder": "emailCard_email-card-placeholder__1eWDl",
	"email-card-spinner": "emailCard_email-card-spinner__sTGXE",
	"email-card-summary": "emailCard_email-card-summary__3s6AZ"
};