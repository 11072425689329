import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import requests from 'api/requests';
import { useSelector } from 'react-redux';
import useIntegrations from 'sections/Integrations/hooks/useIntegrations';
import currentUserSelector from 'store/selectors/users/currentUser';
import { REPLY_MODES } from 'refactored/constants/email/replyModes';

const ThreadModalContext = createContext();


export const ThreadModalProvider = ({ children }) => {
    const user = useSelector(currentUserSelector);
    const { integrationsByScope } = useIntegrations();

    const [thread, setThread] = useState(null);
    const threadRef = useRef(null);

    const [emails, setEmails] = useState([]);
    const emailsRef = useRef([]);

    const [emailIndex, setEmailIndex] = useState(0);
    const emailIndexRef = useRef(0);

    const [tokenId, setTokenId] = useState(null);
    const [isThreadModalOpen, setIsThreadModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const responseMode = useRef(REPLY_MODES.REPLY_TO_ALL)
    const [responseModeState, setResponseModeState] = useState(REPLY_MODES.REPLY_TO_ALL)
    const [initialTo, setInitialTo] = useState([]);
    const [initialCC, setInitialCC] = useState([]);

    useEffect(() => {
        setResponseModeState(responseMode.current)
    }, [responseMode.current])
    useEffect(() => {
        setThread(threadRef.current)
    }, [threadRef.current])

    useEffect(() => {
        setEmails(emailsRef.current)
    }, [emailsRef.current])

    useEffect(() => {
        setEmailIndex(emailIndexRef.current)
    }, [emailIndexRef.current])

    useEffect(() => {
        if (!integrationsByScope?.email) return
        setTokenId(integrationsByScope.email[0]?.id || null);
    }, [integrationsByScope]);

    useEffect(() => {
        setInitialsToCC()
    }, [emails, emailIndex, responseMode.current]);

    const setInitialsToCC = () => {
        if (responseMode.current == REPLY_MODES.REPLY) {
            setInitialTo(emailsRef?.current[emailIndexRef.current]?.from || []);
            setInitialCC([]);
        }
        else if (responseMode.current == REPLY_MODES.FORWARD || !responseMode.current) {
            setInitialTo([]);
            setInitialCC([]);
        }
        else if (responseMode.current == REPLY_MODES.REPLY_TO_ALL) {
            const froms = emailsRef?.current[emailIndexRef.current]?.from || [];
            const tos = emailsRef?.current[emailIndexRef.current]?.to || [];
            const allToAnswer = [...froms, ...tos];
            const myEmail = user?.email;
            const filteredToAnswer = allToAnswer.filter((item, index, self) =>
                index === self.findIndex((t) => (
                    t.email === item.email && t.email !== myEmail
                ))
            );

            setInitialTo(filteredToAnswer);
            setInitialCC(emailsRef?.current[emailIndexRef.current]?.cc || []);
        }
    }

    const setReply = () => {
        responseMode.current = REPLY_MODES.REPLY
        setInitialsToCC()
    }

    const setReplyAll = () => {
        responseMode.current = REPLY_MODES.REPLY_TO_ALL
        setInitialsToCC()
    }

    const setForward = () => {
        responseMode.current = REPLY_MODES.FORWARD
        setInitialsToCC()
    }

    const goToNextMailInThread = () => {
        setEmailIndex(prevIndex => prevIndex + 1);
    };

    const goToPrevMailInThread = () => {
        setEmailIndex(prevIndex => prevIndex - 1);
    };

    const fetchThreadByEmail = async (email) => {
        const response = await requests.integrations.emails.thread(email?.thread_id)(tokenId);
        threadRef.current = response.data.thread;
    }

    const fetchMessages = async (messageIds) => {
        const promises = messageIds.map(async (messageId) => {
            const response = await requests.integrations.emails.message(messageId)(tokenId);
            return response.data?.message;
        });

        try {
            const results = await Promise.all(promises);
            results.sort((a, b) => a.date - b.date);

            emailsRef.current = results
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching emails:", error);
        }
    };

    const openThreadModalByEmail = async (email) => {
        responseMode.current = REPLY_MODES.REPLY_TO_ALL
        setIsThreadModalOpen(true);
        setIsLoading(true);
        try {
            await fetchThreadByEmail(email)
            await fetchMessages(threadRef.current.message_ids);
            setEmailIndex(threadRef.current.message_ids.length - 1);
        } catch (error) {
            console.error('Error fetching thread:', error);
        }
    };

    const deleteCurrentEmailFromThread = async () => {
        await deleteEmail(emails[emailIndexRef.current])
        emailsRef.current = emailsRef.current.filter(email => email.id != emails[emailIndexRef.current].id)
        emailIndexRef.current -= 1;
        if (emailIndexRef.current < 0) emailIndexRef.current = 0
        if (emailsRef.current.length < 1) {
            closeThreadModal()
        }
        setEmails(emailsRef.current)
        setEmailIndex(emailIndexRef.current)
    }

    const deleteEmail = async (email) => {
        try {
            const response = await requests.integrations.emails.deleteEmail(email.id)(tokenId);
            return response
        }
        catch (error) {
            console.error('Error deleting  message:', error);
        }
        return email
    }

    const closeThreadModal = () => {
        setIsThreadModalOpen(false);
        setThread(null);
    };

    const closeThread = async () => {
        const response = await requests.integrations.emails.closeThread(thread?.id)(tokenId);
        threadRef.current.isClosed = true;
        setIsThreadModalOpen(false);
        return response.data
    }

    const closeThreadByEmail = async (email) => {
        const responseThread = await requests.integrations.emails.thread(email?.thread_id)(tokenId);
        threadRef.current = responseThread.data.thread
        threadRef.current.isClosed = true;
        setIsThreadModalOpen(false);
        return responseThread.data.thread
    }

    return (
        <ThreadModalContext.Provider value={{
            isThreadModalOpen,
            thread,
            emails,
            emailIndex,
            isLoading,
            closeThread,
            closeThreadByEmail,
            deleteEmail,
            deleteCurrentEmailFromThread,
            openThreadModalByEmail,
            goToNextMailInThread,
            goToPrevMailInThread,
            closeThreadModal,
            setReply,
            setReplyAll,
            setForward,
            responseModeState,
            initialTo,
            initialCC
        }}>
            {children}
        </ThreadModalContext.Provider>
    );
};

export const useThreadModal = () => {
    return useContext(ThreadModalContext);
};
