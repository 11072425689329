import React from 'react';
import styles from './cardsGrid.module.css';
import EmailCard from '../emailCard/EmailCard';

const CardsGrid = (props) => {
  const { emails } = {
    emails: [],
    ...props
  }
  return (
    <div className={styles['cards-grid']}>
      {emails?.map((email, index) => (
        <EmailCard
          key={email?.id}
          index={index}
          email={email}
        />
      ))}
    </div>
  );
};

export default CardsGrid;
