import LicenseRepository from 'shared/repositiores/myCompany/license';
import { noopFn } from 'shared/utils/index';
import checkEmployeePermissions from 'shared/utils/checkEmployeePermissions';
import checkLoggedEmployeePermissions from 'shared/utils/checkLoggedEmployeePermissions';

import { getMe } from 'store/actions/auth';

import {
  getEmployees,
  getLicenses,
} from 'store/actions/MyCompany';

import {
  getLicensesToBeRevoked,
} from 'store/actions/invoices';

import {
  SET_TRIGGERED_LICENSE_ITEM,
  CLEAR_TRIGGERED_LICENSE_ITEM,
  SHOW_DELETE_YOUR_LICENSE_PERMISSION_WARNING,
  HIDE_DELETE_YOUR_LICENSE_PERMISSION_WARNING,
  SHOW_DELETING_SITE_OWNER_LICENSE_WARNING,
  HIDE_DELETING_SITE_OWNER_LICENSE_WARNING,
  HIDE_ONLY_ONE_SITE_OWNER_WARNING,
  SHOW_ONLY_ONE_SITE_OWNER_WARNING,
} from 'store/actionTypes/license-management';

export const hideOnlyOneSiteOwnerWarning = () => ({
  type: HIDE_ONLY_ONE_SITE_OWNER_WARNING,
});

export const showOnlyOneSiteOwnerWarning = () => ({
  type: SHOW_ONLY_ONE_SITE_OWNER_WARNING,
});

export const hideDeletingSiteOwnerLicenseWarning = () => ({
  type: HIDE_DELETING_SITE_OWNER_LICENSE_WARNING,
});

export const showDeletingSiteOwnerLicenseWarning = () => ({
  type: SHOW_DELETING_SITE_OWNER_LICENSE_WARNING,
});

export const hideDeleteYourLicensePermissionWarning = () => ({
  type: HIDE_DELETE_YOUR_LICENSE_PERMISSION_WARNING,
});

export const showDeleteYourLicensePermissionWarning = () => ({
  type: SHOW_DELETE_YOUR_LICENSE_PERMISSION_WARNING,
});

export const setTriggeredLicenseItem = ({ licenseTypeId, count }) => ({
  type: SET_TRIGGERED_LICENSE_ITEM,
  payload: {
    licenseTypeId,
    count,
  },
});

export const clearTriggeredLicenseItem = () => ({
  type: CLEAR_TRIGGERED_LICENSE_ITEM,
});

export const assignLicenseToEmployee = (
  employee,
  license,
  type,
  isShowMessage = true,
) => async (dispatch, getState) => {
  try {
    const {
      auth: {
        loggedUser: {
          EmployeeID,
        },
      },
    } = getState();

    const loggedEmployeeId = EmployeeID;
    const targetEmployeeId = employee.EmployeeID;
    const isChangesItself = loggedEmployeeId === targetEmployeeId;
    const isLicensesDeleted = type === 0;
    const isTargetEmployeeSiteOwner = checkEmployeePermissions.isOwner()(employee);
    const isUserSiteOwner = checkLoggedEmployeePermissions.isOwner();

    if (isTargetEmployeeSiteOwner && isLicensesDeleted && license.id === 1) {
      dispatch(showDeletingSiteOwnerLicenseWarning());
      return;
    }

    if (isTargetEmployeeSiteOwner && isLicensesDeleted && !isUserSiteOwner) {
      dispatch(showDeletingSiteOwnerLicenseWarning());
      return;
    }

    if (isChangesItself && isLicensesDeleted && !isUserSiteOwner) {
      dispatch(showDeleteYourLicensePermissionWarning());
      return;
    }

    await LicenseRepository.setLicense(employee, license, type, isShowMessage);
  } catch (error) {
    noopFn();
    return;
  }

  dispatch(getMe());
  dispatch(getLicenses());
  dispatch(getEmployees());
  dispatch(getLicensesToBeRevoked());
};

export default {
  setTriggeredLicenseItem,
  clearTriggeredLicenseItem,
  assignLicenseToEmployee,
  hideOnlyOneSiteOwnerWarning,
  hideDeletingSiteOwnerLicenseWarning,
  hideDeleteYourLicensePermissionWarning,
  showOnlyOneSiteOwnerWarning,
  showDeletingSiteOwnerLicenseWarning,
  showDeleteYourLicensePermissionWarning,
};
