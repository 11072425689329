import setOnlineUsers from './setOnlineUsers';
import setCallee from './setCallee';
import setCaller from './setCaller';
import setIsCallDeclined from './setIsCallDeclined';
import setInvitedEmails from './setInvitedEmails';
import createInvitations from './createInvitations';
import setMeetingForInvitations from './setMeetingForInvitations';

export {
  setOnlineUsers,
  setCallee,
  setCaller,
  setIsCallDeclined,
  setInvitedEmails,
  createInvitations,
  setMeetingForInvitations,
};

export default {
  setOnlineUsers,
  setCallee,
  setCaller,
  setIsCallDeclined,
  setInvitedEmails,
  createInvitations,
  setMeetingForInvitations,
};
