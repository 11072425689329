import React from 'react';
import getInitialsFromString from 'refactored/utils/getInitialsFromString';
import styles from './contactPill.module.css';

const ContactPill = ({ contact, onContactRemove, showName = true, flat = false, altText }) => {
    const pillClass = showName ? styles['pill'] : styles['small-pill'];
    const additionalClass = flat ? styles['flat-pill'] : '';
    const bgStyle = { backgroundImage: `url(${contact.image})`, };

    return (
        <div className={`${pillClass} ${additionalClass}`}>
            <span className={styles['pill-image']} style={bgStyle}>
                {contact?.image ? null : altText ? getInitialsFromString(altText).slice(-2) : null}
            </span>
            {showName && (
                <span className={styles['pill-name']}>{contact?.name || contact?.email}</span>
            )}
            <button className={styles['pill-close-btn']} onClick={() => onContactRemove(contact)}></button>
        </div>
    );
};

export default ContactPill;

