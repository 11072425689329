import test from 'assets/icons/test.svg';
import branching from 'assets/icons/branching.svg';
import cube from 'assets/icons/cube.svg';
import development from 'assets/icons/development.svg';
import idea from 'assets/icons/idea.svg';
import intersection from 'assets/icons/intersection.svg';
import profit from 'assets/icons/profit.svg';
import hot from 'assets/icons/hot.svg';
import cold from 'assets/icons/cold.svg';
import warm from 'assets/icons/warm.svg';
import bed from 'assets/icons/bed.svg';
import bathroom from 'assets/icons/bathroom.svg';
import garage from 'assets/icons/garage.svg';
import height from 'assets/icons/height.svg';
import exchange from 'assets/icons/exchange.svg';
import melting from 'assets/icons/melting.svg';
import savings from 'assets/icons/savings.svg';
import investment from 'assets/icons/investment.svg';
import realtorLogo from 'assets/icons/realtor-logo.svg';
import newViewing from 'assets/icons/new-viewing.svg';
import handshake from 'assets/icons/handshake.svg';
import property from 'assets/icons/property.svg';
import profile from 'assets/icons/profile.svg';

import greetingsStep from 'assets/icons/greetings-step.svg';
import passwordStep from 'assets/icons/password-step.svg';
import informationStep from 'assets/icons/information-step.svg';
import searchStep from 'assets/icons/search-step.svg';
import overviewStep from 'assets/icons/overview-step.svg';
import noteStep from 'assets/icons/note-step.svg';
import finalStep from 'assets/icons/final-step.svg';
import brokerPlatform from 'assets/icons/broker-platform.svg';
import rentalProperties from 'assets/icons/rental-properties.svg';
import team from 'assets/icons/team.svg';
import socialMedia from 'assets/icons/social-media.svg';

// integrations
import integrationsGoogle from 'assets/icons/integrations-google.svg';
import integrationsYahoo from 'assets/icons/integrations-yahoo.svg';
import integrationsOutlook from 'assets/icons/integrations-outlook.svg';
import integrationsIcloud from 'assets/icons/integrations-icloud.svg';

export default {
  property,
  profile,
  handshake,
  test,
  branching,
  cube,
  development,
  idea,
  intersection,
  profit,
  hot,
  cold,
  warm,
  bed,
  bathroom,
  garage,
  height,
  exchange,
  melting,
  savings,
  investment,
  realtorLogo,
  newViewing,
  greetingsStep,
  passwordStep,
  informationStep,
  searchStep,
  overviewStep,
  noteStep,
  finalStep,
  brokerPlatform,
  rentalProperties,
  team,
  socialMedia,
  integrationsGoogle,
  integrationsYahoo,
  integrationsOutlook,
  integrationsIcloud,
};
