import claimLead from './claimLead';

const claimSelectedLead = () => async (dispatch, getState) => {
  const {
    crmLeads: {
      selectedLead,
    },
  } = getState();

  dispatch(claimLead(selectedLead));
};

export default claimSelectedLead;
