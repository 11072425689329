exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".thumbnailSelector_contact-selector__1Xzce {\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-wrap: wrap;\n  max-height: 31vh;\n  min-height: 31vh;\n  grid-gap: 4px;\n  gap: 4px;\n  overflow-y: auto;\n}\n\n.thumbnailSelector_contact-selector__1Xzce::-webkit-scrollbar {\n  width: 6px;\n}\n\n.thumbnailSelector_contact-selector__1Xzce::-webkit-scrollbar-thumb {\n  background-color: #a0a0a0;\n  border-radius: 3px;\n}\n\n.thumbnailSelector_contact-selector__1Xzce::-webkit-scrollbar-track {\n  background-color: transparent;\n}", ""]);

// exports
exports.locals = {
	"contact-selector": "thumbnailSelector_contact-selector__1Xzce"
};