/* eslint-disable */
// Exposit
// WHOLE FILE IGNORED, TOO MANY PRE-EXISTING ERRORS
// TODO - fix lint errors

import { common } from 'constants';
import { Form } from 'antd';
import { callAlert } from 'ui/Alert';
import { fromPairs, find, isEmpty } from 'shared/utility';

import { url } from 'paths';
// TODO - fix dependency cycles
import store from 'store'; // eslint-disable-line import/no-cycle
import {
  receiveLogout,
  triggerCredentialsModal,
  triggerPermissionModal,
  triggerRoleModal,
} from 'store/actions/auth';
// eslint-disable-line import/no-cycle, max-len
import { SYMPLETE_ID } from 'constants/index';
import { DELETED_STATUS } from 'components/HR/constants';
import { STATUS_KEY } from 'components/HR/EmployeeDirectory/constants';

import getContext from 'shared/utils/getDatabaseName'
import noOp from 'shared/utils/noOp'

export { getContext }

// eslint-disable-next-line consistent-return
export const alertPermissions = (permissions, fn, ...args) => (event) => {
  if (!permissions) {
    preventPropagation(event);

    return callAlert.error(common.FORBIDDEN.MSG);
  }

  typeof fn === 'function' && fn(...args);
};

export const propagation = (e) => e.stopPropagation();
export const prevent = (e) => e.preventDefault();

export const preventPropagation = (e) => {
  propagation(e);
  prevent(e);
};

export const checkStatus = async (res) => {
  if (res.status >= common.BAD_REQUEST.CODE) {
    let body;

    try {
      body = await res.json();
    } catch (e) {
      console.error(e);
    }

    const custom = body ? body.symplete_error : null;
    const errors = common.CUSTOM_ERRORS;
    let msg;

    switch (res.status) {
      case common.BAD_REQUEST.CODE:
        // msg = custom 
        //   ? (body.message || body.msg) 
        //   : common.BAD_REQUEST.MSG;
        
        break;
      case common.UNAUTHORIZED.CODE: {
        const state = store.getState();

        // 401 is normal if user is not authenticated, no additional message required.
        if (!state.auth.isAuthenticated) {
          break;
        }

        msg = custom ? (body.message || body.msg) : common.UNAUTHORIZED.MSG;

        if (custom === errors.E401_2_LICENSE) {
          store.dispatch(triggerPermissionModal(true));
          break;
        }

        if (custom === errors.E401_6_CREDENTIALS) {
          store.dispatch(triggerCredentialsModal(true));
          break
        }

        if (custom === errors.E401_3_USER_ROLE) {
          store.dispatch(triggerRoleModal(true));
          break;
        }

        store.dispatch(receiveLogout());
        break;
      }
      case common.FORBIDDEN.CODE:
        msg = custom ? body.msg : common.FORBIDDEN.MSG;
        msg = custom ? body.message : common.FORBIDDEN.MSG;
        redirectToPermissionErrorPage();
        break;
      case common.NOT_FOUND.CODE:
        msg = custom ? body.msg : common.NOT_FOUND.MSG;
        msg = custom ? body.message : common.NOT_FOUND.MSG;
        break;
      case common.ENTITY_TOO_LARGE.CODE:
        msg = custom ? body.msg : common.ENTITY_TOO_LARGE.MSG;
        msg = custom ? body.message : common.ENTITY_TOO_LARGE.MSG;
        break;
      case common.INTERNAL_ERROR.CODE:
        msg = custom ? body.msg : common.INTERNAL_ERROR.MSG;
        msg = custom ? body.message : common.INTERNAL_ERROR.MSG;
        redirectToFailPage();
        break;
      default:
        msg = common.ERROR_MSG;
    }

    if (msg) {
      callAlert.error(msg);
    }


    // throw new Error(msg);
  }

  return res;
};

/**
 * 
 * @param {string} imageUrl 
 * @param {Boolean} isExternal set true if you use it in the customer's view
 * @param {string} token 
 */
export const getImage = (imageUrl, isExternal = false, token = null) => {
  const tokenKey = 'token';
  const tokenUrl = `?auth=${token || localStorage.getItem(tokenKey)}`;
  const URL = url + imageUrl + tokenUrl;

  return URL;
};

/**
 * Checks employee avatars and return avatar link
 * @param {object} employee
 * @return {string}
 */
export const getEmployeeAvatar = (employee) => {
  if (!employee || !employee.Avatars) return '';

  return getImage(employee.Avatars.Location);
};

export const reverseComments = (comments) => comments.reverse().map((comment) => ({
  ...comment,
  replies: comment.replies.reverse(),
}));

export const mapFormFields = (formFields) => {
  const fields = formFields
    ? Object.entries(formFields).map(([key, value]) => ({
      [key]: Form.createFormField({ value }),
    }))
    : [];

  return Object.assign({}, ...fields);
};

export const setInputVal = (value, apiValue) => JSON.stringify({ value, apiValue });

export const mapCounters = (counters, countersList) => {
  const mappedCounters = countersList.map((counter) => {
    const mapped = counters[counter.key];
    const query = Object.entries(mapped.query)
      .map(([key, val]) => [key, decodeURIComponent(val)].join('='))
      .join('&');

    return {
      name: counter.name,
      key: counter.key,
      value: mapped.count,
      query: `&${query}`,
    };
  });

  return mappedCounters;
};

export const getActiveFields = (obj) => {
  const activeFileds = Object.entries(obj).filter(([_, val]) => {
    if (typeof val === 'object') {
      return !isEmpty(val);
    }

    return val !== null && val !== undefined;
  });

  return fromPairs(activeFileds);
};

const noopFn = noOp

export {
  noOp,
  noopFn  
}

export const filterByLicense = (employees, licenseId) => {
  if (!licenseId) {
    return employees.filter((employee) => employee.Licenses.length);
  }

  return employees.filter((employee) => find(employee.Licenses, (license) => (
    license.LicenceID === SYMPLETE_ID || license.LicenceID === licenseId
  )));
};

export const mapEmployeesFromId = (idList, employees) => idList.map(
  (id) => employees.find((employee) => employee.EmployeeID === id) || {},
);

export const getNotDeletedStatusesQuery = (statuses) => {
  const statusFilter = statuses
    .map((status) => status.StatusID)
    .filter((status) => status !== DELETED_STATUS)
    .join(',');

  return `&${STATUS_KEY}=${statusFilter}`;
};

export const redirectToFailPage = () => {
  /* window.history.pushState({}, null, '/fail');
  window.location.reload(); */
};

export const redirectToPermissionErrorPage = () => {
  /* window.history.pushState({}, null, '/forbidden-file');
  window.location.reload(); */
};

export const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }

  return e && e.fileList;
};

export const compareText = (text = '', search = '') => {
  if (!text) {
    return false;
  }

  return text.toLowerCase().includes(search.toLowerCase());
};
