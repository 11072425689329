import {
  SET_LEAD_INTO_PROSPECT_MODAL_OPEN,
} from 'store/actionTypes/realtorLeads';

/**
* @param {number} leadId
* @param {('properties'|'property_requirements')} connectedItemType
* @param {number} connectedItemId
*/
const openModal = (leadId, connectedItemType, connectedItemId) => ({
  type: SET_LEAD_INTO_PROSPECT_MODAL_OPEN,
  leadId,
  connectedItemType,
  connectedItemId,
});

export default openModal;
