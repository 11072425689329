import requests from 'api/requests';

import getTaskAndRefreshRelatedSummaries from './getTaskAndRefreshRelatedSummaries';
import deleteTaskFromBacklogFiltered from './deleteTaskFromBacklogFiltered';

const updateTaskStatus = (
  taskId,
  newStatusId,
  newRank = null,
  employeeId = null,
) => async (dispatch, getState) => {
  const {
    auth: {
      loggedUser: {
        Name,
        Surname,
        EmployeeID,
      },
    },
  } = getState();

  const userFullName = `${Name} ${Surname}`;

  const body = {
    TaskID: taskId,
    StatusID: newStatusId,
    UserFullName: userFullName,
    EmployeeID: employeeId || EmployeeID,
    Rank: newRank,
  };

  try {
    await requests.tasks.updateTaskStatus(body);
  } catch (error) {
    console.error(error);
    return;
  }

  dispatch(getTaskAndRefreshRelatedSummaries(taskId, employeeId));
  dispatch(deleteTaskFromBacklogFiltered(taskId));
};

export default updateTaskStatus;
