const colors = {
  hr: {
    primary: '#1584FF',
  },
  tickets: {
    primary: '#FFBA4A',
  },
  tasks: {
    primary: '#2A5684',
  },
  sales: {
    primary: '#2ECC71',
  },
};

export default colors;
