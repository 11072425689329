exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".toolbar_toolbar__35t5z {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  grid-gap: 12px;\n  gap: 12px;\n  padding: 0px 60px;\n  height: 100%;\n}\n\n.toolbar_toolbar__35t5z > :first-child {\n  margin-right: auto;\n}", ""]);

// exports
exports.locals = {
	"toolbar": "toolbar_toolbar__35t5z"
};