import React, {
  useState,
  useCallback,
  useEffect,
  forwardRef,
  useRef,
} from 'react';

import PropTypes from 'prop-types';

import classnames from 'classnames';

import { round } from 'shared/utility';

import Icon from 'ui/Icon';
import UpDownButtons from 'ui/UpDownButtons';

import forceFocus from 'ui/utils/forceFocus';
import useFocusOnRender from 'ui/hooks/useFocusOnRender';

import {
  Root,
  Label,
  InputWithMask,
  InputNoMask,
  InputTextArea,
  InputCurrency,
  IconWrapper,
  HintMessage,
  ControlButtonsWrapper,
} from './styledItems';

const masks = {
  phone: ['+', /\d/, ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  zip: [/\d/, /\d/, /\d/, /\d/, /\d/],
};

// const forceFocus = (inputElement) => {
//   if (inputElement?.current) {
//     if (inputElement?.current?.input) { // works with react-numeric
//       inputElement.current.input.focus();
//     } else if (inputElement?.current?.inputElement) {
//       // eslint-disable-next-line chai-friendly/no-unused-expressions
//       inputElement?.current?.inputElement.focus(); // works with react-text-mask
//     } else if (inputElement?.current?.focus) {
//       inputElement.current.focus();
//     }
//   }
// };

const Textfield = forwardRef((props, ref) => {
  const {
    disabled,
    error,
    fullWidth,
    autoWidth,
    hint,
    icon,
    inputProps,
    label,
    mask,
    max,
    min,
    onBlur,
    onChange,
    onKeyPress,
    onClick,
    onFocus,
    showMask,
    style,
    textarea,
    type,
    value,
    currency,
    iconPosition,
    onEnter,
    iconType,
    iconColor,
    iconSize,
    step,
    flat,
    focusOnRender,
    className,
    placeholder,
    ControlComponent,
    preventAutoresize,
    hideNumberArrows,
    dense,
    transparent,
    textColor,
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    if (value || value === '' || value === 0) {
      setCurrentValue(value);
    }

    // workaround for material autocomplete
    inputProps?.value && setCurrentValue(inputProps?.value); // eslint-disable-line chai-friendly/no-unused-expressions
  }, [
    value,
    inputProps?.value,
  ]);

  const inputEl = useRef(null);

  useEffect(() => {
    // resize textarea; setTimeout is required for correct rendering
    setTimeout(() => {
      if (textarea) {
        if (inputEl?.current) {
          inputEl.current.style.height = 'auto';
          inputEl.current.style.height = `${inputEl.current.scrollHeight}px`;
        }
      }
    }, 0);
  }, [
    value,
    textarea,
  ]);

  useFocusOnRender(inputProps.ref || inputEl, focusOnRender);

  const handleChange = useCallback((event, inputValue) => {
    const actualValue = currency
      ? inputValue
      : event.target.value;

    setCurrentValue(actualValue);
    onChange(actualValue, event);

    // workaround for material autocomplete
    inputProps?.onChange && inputProps.onChange(event); // eslint-disable-line chai-friendly/no-unused-expressions

    // auto resize textarea
    if (textarea && !preventAutoresize) {
      if (inputEl?.current) {
        inputEl.current.style.height = 'auto';
        inputEl.current.style.height = `${inputEl.current.scrollHeight}px`;
      }
    }
    //
  }, [
    onChange,
    textarea,
    inputProps?.onChange,
    currency,
    preventAutoresize,
  ]);

  const handleFocus = useCallback((event) => {
    if (!disabled) {
      setIsFocused(true);
      onFocus(event);

      // workaround for material autocomplete
      inputProps?.onFocus && inputProps.onFocus(event); // eslint-disable-line chai-friendly/no-unused-expressions
    }
  }, [
    onFocus,
    disabled,
    inputProps?.onFocus,
  ]);

  const handleBlur = useCallback((event) => {
    if (event.relatedTarget?.className.includes('up-down-buttons')) {
      const inputElement = inputProps.ref || inputEl;
      forceFocus(inputElement);
      return;
    }
    setIsFocused(false);
    onBlur(event);
    inputProps?.onBlur && inputProps.onBlur(event); // eslint-disable-line chai-friendly/no-unused-expressions
  }, [
    onBlur,
    inputProps?.onBlur,
    inputEl,
    inputProps.ref,
  ]);

  const isLabelActive = isFocused || !!currentValue || showMask;

  let Input = InputNoMask;

  if (currency) {
    Input = InputCurrency;
  }

  if (mask) {
    Input = InputWithMask;
  }

  if (textarea) {
    Input = InputTextArea;
  }

  const handleKeyPress = useCallback((event) => {
    if (event.key === 'Enter' && onEnter) {
      onEnter(event);
    } else if (onKeyPress) {
      onKeyPress(event);
    }
  }, [
    onEnter,
    onKeyPress,
  ]);

  const increaseValue = useCallback((event) => {
    event.preventDefault();
    const newValue = round(Number(currentValue) + step, 2);

    if (max || max === 0) {
      if (newValue <= max) {
        setCurrentValue(newValue);
        onChange(newValue);
      }
    } else {
      setCurrentValue(newValue);
      onChange(newValue);
    }
  }, [
    onChange,
    step,
    currentValue,
    max,
  ]);

  const decreaseValue = useCallback((event) => {
    event.preventDefault();
    const newValue = round(Number(currentValue) - step, 2);

    if (min || min === 0) {
      if (newValue >= min) {
        setCurrentValue(newValue);
        onChange(newValue);
      }
    } else {
      setCurrentValue(newValue);
      onChange(newValue);
    }
  }, [
    onChange,
    step,
    currentValue,
    min,
  ]);

  return (
    <Root
      ref={ref}
      className={classnames({
        [className]: className,
      })}
      fullWidth={fullWidth}
      disabled={disabled}
      transparent={transparent}
    >
      {label && (
        <Label
          className={isLabelActive ? 'label active' : 'label'}
          icon={icon}
          iconPosition={iconPosition}
          active={isLabelActive}
          variant={isLabelActive ? 'caption' : 'body'}
          color={error // eslint-disable-line no-nested-ternary
              ? 'error'
              : isLabelActive
                ? 'descriptor-text'
                : 'main-text'}
          fontStyle={isLabelActive ? 'initial' : 'italic'}
        >
          {label}
        </Label>
      )}
      {
        type === 'number' ? (
          <div>
            <Input
              {...inputProps} // eslint-disable-line react/jsx-props-no-spreading
              autoWidth={autoWidth}
              ref={inputProps?.ref || inputEl}
              textColor={textColor}
              style={style}
              error={error}
              focused={isFocused}
              mask={masks[mask] || false}
              showMask={showMask || false}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleChange}
              value={currentValue}
              type={type || 'text'}
              min={min}
              max={max}
              placeholder={mask === 'phone' ? '+1' : placeholder}
              guide={false}
              onClick={onClick}
              disabled={disabled}
              icon={!!icon}
              iconPosition={iconPosition}
              onKeyPress={handleKeyPress}
              step={step}
              flat={flat}
              isDense={dense}
            />
            {
              !hideNumberArrows && (
                <ControlButtonsWrapper>
                  <UpDownButtons
                    onIncrease={increaseValue}
                    onDecrease={decreaseValue}
                  />
                </ControlButtonsWrapper>
              )
            }
          </div>
        ) : (
          <div>
            <Input
              {...inputProps} // eslint-disable-line react/jsx-props-no-spreading
              autoWidth={autoWidth}
              ref={inputProps?.ref || inputEl}
              textColor={textColor}
              style={style}
              error={error}
              focused={isFocused}
              mask={masks[mask] || false}
              showMask={showMask || false}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleChange}
              value={currentValue}
              type={type || 'text'}
              min={min}
              max={max}
              placeholder={mask === 'phone' ? '+1' : placeholder}
              guide={false}
              onClick={onClick}
              disabled={disabled}
              icon={!!icon}
              iconPosition={iconPosition}
              withControl={!!ControlComponent}
              onKeyPress={handleKeyPress}
              step={step}
              flat={flat}
              isDense={dense}
            />
            {
              ControlComponent && (
                <ControlButtonsWrapper>
                  {ControlComponent}
                </ControlButtonsWrapper>
              )
            }
          </div>
        )
      }
      {
        icon && (
          <IconWrapper
            iconPosition={iconPosition}
          >
            <Icon
              name={icon}
              type={iconType}
              size={iconSize}
              color={
                error // eslint-disable-line no-nested-ternary
                  ? 'error'
                  : disabled
                    ? 'disabled'
                    : iconColor || 'main-text'
              }
            />
          </IconWrapper>
        )
      }
      {
        error && (
          <HintMessage
            variant="caption2"
            color="error"
          >
            {error}
          </HintMessage>
        )
      }
      {
        !error && hint && (
          <HintMessage
            variant="caption2"
            color="descriptor-text"
          >
            {hint}
          </HintMessage>
        )
      }
    </Root>
  );
});

const noOp = () => { };

Textfield.defaultProps = {
  autoWidth: false,
  currency: false,
  disabled: false,
  error: null,
  fullWidth: false,
  hint: null,
  icon: null,
  iconPosition: 'right',
  iconType: null,
  iconColor: null,
  iconSize: 'm',
  inputProps: {},
  label: null,
  mask: null,
  max: null,
  min: null,
  onBlur: noOp,
  onChange: noOp,
  onKeyPress: noOp,
  onClick: noOp,
  onFocus: noOp,
  onEnter: noOp,
  showMask: false,
  step: 1,
  style: {},
  textarea: false,
  type: null,
  value: null,
  flat: false,
  className: null,
  placeholder: null,
  ControlComponent: null,
  focusOnRender: false,
  preventAutoresize: false,
  hideNumberArrows: false,
  dense: false,
  transparent: false,
  textColor: 'main-text',
};

const {
  bool,
  func,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
  element,
} = PropTypes;

Textfield.propTypes = {
  autoWidth: bool,
  currency: bool,
  disabled: bool,
  error: oneOfType([string, bool]),
  fullWidth: bool,
  hint: string,
  icon: string,
  iconPosition: oneOf('left', 'right'),
  iconType: oneOf(['feather', 'fontawesome', 'custom']),
  iconColor: string,
  iconSize: string,
  inputProps: shape,
  label: string,
  mask: oneOf(['phone']),
  max: number,
  min: number,
  onBlur: func,
  onChange: func,
  onClick: func,
  onKeyPress: func,
  onFocus: func,
  onEnter: func,
  showMask: bool,
  step: number,
  style: shape(),
  textarea: bool,
  type: string,
  value: oneOfType([string, number]),
  flat: bool,
  className: string,
  placeholder: string,
  ControlComponent: element,
  focusOnRender: bool,
  preventAutoresize: bool,
  hideNumberArrows: bool,
  dense: bool,
  transparent: bool,
  textColor: string,
};

export default Textfield;
