import React from 'react';
import IconButton from '../../../shared/buttons/iconButton/IconButton';
import GridIcon from 'assets/images/commsHub/grid-icon.svg';
import ListIcon from 'assets/images/commsHub/list-icon.svg';
import styles from './toolbarDisplayOptions.module.css';
import { useCommsHub } from 'refactored/components/shared/providers/data/CommsHub.provider';

const ToolbarDisplayOptions = () => {
    const { view, setView } = useCommsHub()
    return (
        <div className={styles['toolbar-display-options-container']}>
            <IconButton
                isActive={view == "grid"}
                variant={"white"}
                icon={GridIcon} onClick={() => {
                    setView("grid")
                }} />
            <IconButton
                isActive={view == "list"}
                variant={"white"}
                icon={ListIcon} onClick={() => {
                    setView("list")
                }} />
        </div>
    );
};

export default ToolbarDisplayOptions;
