import React, {
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import NavIcons from 'shared/components/NavIcons';
import RealtorMasterSearch from 'shared/modules/RealtorMasterSearch';

import Flex from 'ui/Flex';

import UserMenu from 'shared/modules/UserMenu';
import Logo from './Logo';
import New from './New';
import PageTitle from './PageTitle';

import {
  RootWrapper,
  Border,
} from './styledItems';

const Appbar = (props) => {
  const [isSearchInputVisible, setIsSearchInputVisible] = useState(false);

  const openSearchInput = useCallback((event) => {
    setIsSearchInputVisible(true);
  }, []);

  const closeSearchInput = useCallback(() => {
    setIsSearchInputVisible(false);
  }, []);

  return (
    <RootWrapper>
      <Flex
        fullWidth
        spacing={2}
        style={{
          flex: 1,
        }}
        alignItems="center"
      >
        <Logo />
        {
          !isSearchInputVisible && <PageTitle />
        }
        <Flex
          fullWidth
          spacing={2}
          style={{
            flex: 8,
          }}
          alignItems="center"
          justifyContent="flex-end"
        >
          <RealtorMasterSearch
            onClose={closeSearchInput}
            isSearchInputVisible={isSearchInputVisible}
            focusOnRender
            withPrependButton
            onPrependButtonClick={openSearchInput}
          />

          {/* <New /> */}
        </Flex>
      </Flex>
      <Border />
      <NavIcons />
      <Border />
      <UserMenu />
    </RootWrapper>
  );
};

Appbar.defaultProps = {
  // showSectionTools: false,
};

const {
  // bool,
  // oneOf,
} = PropTypes;

Appbar.propTypes = {
  // section: oneOf(sections).isRequired,
  // showSectionTools: bool,
};

export default Appbar;
