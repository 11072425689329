import {
  RESET_REALTOR_CONTACTS_FILTER_ITEM,
} from 'store/actionTypes/realtorContacts';

const resetFilterItem = (key) => ({
  type: RESET_REALTOR_CONTACTS_FILTER_ITEM,
  payload: key,
});

export default resetFilterItem;
