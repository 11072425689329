/* eslint-disable camelcase */
import { connect } from 'react-redux';

import {
  createContactFromNewPropertyViewingForm,
} from 'store/actions/propertyViewings';

import Button from 'ui/Button';

const mapStateToProps = (state) => {
  const {
    newPropertyViewingForm: {
      newContact: {
        first_name,
        last_name,
      },
    },
  } = state;

  const hasRequiredFields = first_name && last_name;
  const buttonDisabled = !hasRequiredFields;

  return {
    disabled: buttonDisabled,
    variant: 'secondary',
    children: 'Create Contact',
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: (event) => {
    event.preventDefault();
    dispatch(createContactFromNewPropertyViewingForm(ownProps.setContactAfterCreation));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Button);
