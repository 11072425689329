import {
  SET_CUSTOMER_INFORMED_ATTACHMENTS,
} from 'store/actionTypes/customerInformed';

const setAttachments = (id, attachments) => ({
  type: SET_CUSTOMER_INFORMED_ATTACHMENTS,
  payload: {
    id,
    attachments,
  },
});

export default setAttachments;
