import React from 'react';

import PropTypes from 'prop-types';

import ErrorBoundary from 'ui/ErrorBoundary';

import Divider from 'ui/Divider';
import Flex from 'ui/Flex';

import ContactInfo from '../ContactInfo';
import Links from '../Links';

const sectionStyle = {
  padding: 10,
};

const ExternalContactOverview = (props) => {
  const {
    contactData,
  } = props;

  const { links } = contactData;

  return (
    <ErrorBoundary>
      <>
        <div style={sectionStyle}>
          <ContactInfo contactData={contactData} />
        </div>
        <Divider />
        <div style={sectionStyle}>
          <Flex
            fullWidth
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Links links={links} />
          </Flex>
        </div>
      </>
    </ErrorBoundary>
  );
};

const {
  shape,
} = PropTypes;

ExternalContactOverview.propTypes = {
  contactData: shape({}).isRequired,
};

export default ExternalContactOverview;
