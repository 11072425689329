import requests from 'api/requests';
import {
  GET_LABELS_SUCCESS,
  POST_LABEL_SUCCESS,
  EDIT_LABEL_SUCCESS,
  POST_RELATION_SUCCESS,
  DELETE_RELATION_SUCCESS,
} from 'store/actionTypes/labels';

import {
  getTaskById,
} from 'store/actions/tasksV2';

export const getLabels = () => async (dispatch) => {
  const result = await requests.labels.getLabels();
  dispatch(getLabelsSuccess(result.data));
};

export const post = (label) => async (dispatch) => {
  const result = await requests.labels.post(label);
  dispatch(postLabelSuccess(result.data));
};

export const edit = (labelId) => (labelName) => (labelDescription) => async (dispatch) => {
  const result = await requests.labels.edit(labelId)(labelName)(labelDescription);
  dispatch(editLabelSuccess(result.data));
};

export const postRelation = (labelId) => (labeledObjectType) => (labeledObjectId) => async (dispatch) => {
  const result = await requests.labels.postRelation(labelId)(labeledObjectType)(labeledObjectId);
  dispatch(postRelationSuccess(result.data));

  // TODO - improve, this is a makeshift solution
  if (labeledObjectType === 'task') {
    dispatch(getTaskById(labeledObjectId));
  }
};

export const deleteRelation = (relationId) => async (dispatch) => {
  const result = await requests.labels.deleteRelation(relationId);
  dispatch(deleteRelationSuccess(result.data));
};

export const deleteRelationV2 = (labelId) => (labeledObjectType) => (labeledObjectId) => async (dispatch) => {
  const result = await requests.labels.deleteRelationV2(labelId)(labeledObjectType)(labeledObjectId);
  dispatch(deleteRelationSuccess(result.data));

  // TODO - improve, this is a makeshift solution
  if (labeledObjectType === 2) {
    dispatch(getTaskById(labeledObjectId));
  }
};


export const getLabelsSuccess = (payload) => ({
  type: GET_LABELS_SUCCESS,
  payload,
});

export const postLabelSuccess = (payload) => ({
  type: POST_LABEL_SUCCESS,
  payload,
});

export const editLabelSuccess = (payload) => ({
  type: EDIT_LABEL_SUCCESS,
  payload,
});

export const postRelationSuccess = (payload) => ({
  type: POST_RELATION_SUCCESS,
  payload,
});

export const deleteRelationSuccess = (payload) => ({
  type: DELETE_RELATION_SUCCESS,
  payload,
});
