import requests from 'api/requests';

// eslint-disable-next-line consistent-return
const createFulfillment = (fulfillmentData) => async (dispatch) => {
  try {
    const response = await requests.fulfillments.create(fulfillmentData);

    return response.data.fulfillmentId;
  } catch (error) {
    console.info(error);
  }
};

export default createFulfillment;
