exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".listView_list-view__3lbcw {\n  /* viewport height - navbar - toolbar */\n  max-height: calc(100vh - 115px - 46px);\n  overflow-y: scroll;\n}\n\n.listView_short-list-view__dHOck {\n  /* viewport height - navbar - toolbar - in progess section*/\n  max-height: calc(100vh - 115px - 46px - 25vh);\n  overflow-y: scroll;\n}\n\n.listView_list-view__3lbcw:hover {\n  scrollbar-color: auto auto;\n}\n\n.listView_list-view__3lbcw::-webkit-scrollbar {\n  width: 6px;\n}\n\n.listView_list-view__3lbcw::-webkit-scrollbar-thumb {\n  background-color: transparent;\n  border-radius: 5px;\n}\n\n.listView_list-view__3lbcw:hover::-webkit-scrollbar-thumb {\n  background-color: transparent;\n}\n\n.listView_list-view__3lbcw::-webkit-scrollbar-track {\n  background-color: transparent;\n  border-radius: 5px;\n}\n\n.listView_list-view__3lbcw::-webkit-scrollbar-track-piece {\n  background-color: transparent;\n  border-radius: 5px;\n}\n\n.listView_in-progress-closed-section__1AWp1 {\n  position: fixed;\n  bottom: 0px;\n  left: 0px;\n  height: 25vh;\n  width: 100%;\n  overflow-y: auto;\n  background: white;\n  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.0784313725);\n}\n\n.listView_in-progress-closed-section__1AWp1::-webkit-scrollbar {\n  width: 6px;\n}\n\n.listView_in-progress-closed-section__1AWp1::-webkit-scrollbar-thumb {\n  background-color: transparent;\n  border-radius: 5px;\n}\n\n.listView_in-progress-closed-section__1AWp1:hover::-webkit-scrollbar-thumb {\n  background-color: transparent;\n}\n\n.listView_in-progress-closed-section__1AWp1::-webkit-scrollbar-track {\n  background-color: transparent;\n  border-radius: 5px;\n}\n\n.listView_in-progress-closed-section__1AWp1::-webkit-scrollbar-track-piece {\n  background-color: transparent;\n  border-radius: 5px;\n}", ""]);

// exports
exports.locals = {
	"list-view": "listView_list-view__3lbcw",
	"short-list-view": "listView_short-list-view__dHOck",
	"in-progress-closed-section": "listView_in-progress-closed-section__1AWp1"
};