import { mapValues } from 'shared/utility';

import { callAlert } from 'ui/Alert';

import requests from 'api/requests';

import setChat from './setChat';

const addEmployeeInChat = (employeesId) => async (dispatch, getState) => {
  try {
    const {
      chat: {
        activeChatId,
        chatRooms,
      },
    } = getState();

    const chat = chatRooms[activeChatId];

    await Promise.all(
      employeesId.map(async (employeeId) => {
        await requests.chat.addChatMember(activeChatId)({
          userType: 1,
          userId: employeeId,
        });
      }),
    );

    if (chat.type === 1) {
      await requests.chat.editChatType(activeChatId, 2);
    }

    const newChatData = mapValues(chat, (value, key) => {
      if (key === 'members') {
        const newMembers = employeesId.map((employeeId) => ({
          user_type: 1,
          user_id: employeeId,
        }));

        if (chat.type === 1) {
          newMembers.push(value);
          return newMembers;
        }
        return newMembers.concat(value);
      } if (key === 'type') {
        return 2;
      }
      return value;
    });

    dispatch(setChat(newChatData));
  } catch {
    callAlert.error('Error!');
  }
};

export default addEmployeeInChat;
