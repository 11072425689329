import React from 'react';
import PropTypes from 'prop-types';

import { currency } from 'refactored/utils/numbers';

import Typography from 'ui/Typography';

const PriceText = (props) => {
  const {
    value,
    variant,
    color,
    textDecoration,
    tag,
  } = props;

  return (
    <Typography
      variant={variant}
      color={color}
      textDecoration={textDecoration}
      noMargin
      tag={tag}
      weight="bold"
    >
      {
        value && currency.full(value)
      }
    </Typography>
  );
};

PriceText.defaultProps = {
  value: null,
  variant: 'h1',
  color: 'selected',
  textDecoration: null,
  tag: 'p',
};

const {
  string,
} = PropTypes;

PriceText.propTypes = {
  value: string,
  /** Typography component props */
  variant: string,
  color: string,
  textDecoration: string,
  tag: string,
};

export default PriceText;
