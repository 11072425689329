import styled from 'styled-components';

import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';

export const Root = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const IncreaseButton = styled(NakedButton)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 18px;
    height: 19px;
    svg {
      position: relative;
      margin-top: 8px;
    }
  }
`;

export const DecreaseButton = styled(NakedButton)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 18px;
    height: 19px;
    svg {
      position: relative;
      margin-bottom: 8px;
    }
  }
`;
