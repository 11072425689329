/* eslint-disable camelcase */

import React from 'react';

import PropTypes from 'prop-types';

import { datetime } from 'refactored/utils/datetime';

import { Tooltip } from 'antd';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';

import ContactAvatar from 'shared/modules/RealtorContactAvatar';

import useContactName from 'shared/hooks/contacts/useContactName';

const ContactCell = (props) => {
  const {
    entry,
  } = props;

  const {
    created_at,
    seller_agent_contact_id,
    buyer_agent_contact_id,
  } = entry;

  const getContactId = (sellerAgent, buyerAgent) => {
    if (sellerAgent === 0 && buyerAgent) return buyerAgent;
    if (buyerAgent === 0 && sellerAgent) return sellerAgent;
    return null;
  };

  const contactId = getContactId(seller_agent_contact_id, buyer_agent_contact_id);

  const contactName = useContactName(contactId);

  if (!contactName) return '—';

  return (
    <Flex
      spacing={2}
      alignItems="center"
    >
      <ContactAvatar
        contactId={contactId}
        size="sm"
      />
      <Flex spacing={1} flexDirection="column">
        <Tooltip title={contactName}>
          <Typography
            ellipsis
            variant="cell"
            noWrap
          >
            {contactName}
          </Typography>
        </Tooltip>
        <Flex spacing={1}>

          <Typography
            variant="cell2"
          >
            {datetime(created_at).format('MMMM D, YYYY')}
          </Typography>

        </Flex>
      </Flex>
    </Flex>
  );
};

const {
  shape,
} = PropTypes;

ContactCell.propTypes = {
  entry: shape({}).isRequired,
};

export default ContactCell;
