/* eslint-disable camelcase */
import { callAlert } from 'ui/Alert';
import { isEmpty } from 'shared/utility';

import getPropertyById from 'store/actions/properties/getPropertyById';
import getProspectById from 'store/actions/realtorProspects/getProspectById';
import { resetNewPropertyViewingForm } from 'store/actions/newRealtorPropertyViewingForm';
import {
  closeNewPropertyViewingModal,
} from 'store/actions/newRealtorPropertyViewingModal';

import createPropertyViewing from './createPropertyViewing';
import getPropertyViewingById from './getPropertyViewingById';
import createSyncedEvent from 'api/requests/syncedEvents/create';
import moment from 'moment';



const createPropertyViewingFromNewPropertyViewingForm = () => async (dispatch, getState) => {
  try {

    const {
      newPropertyViewingForm,
      newPropertyViewingModal,
      properties,
      realtorProspects,
    } = getState();

    const {
      selectedProperty: defaultSelectedPropertyId,
    } = properties;

    const {
      propertyId: propertyIdModal,
    } = newPropertyViewingModal;

    const {
      property_id: propertyIdForm,
      date_time,
      contacts,
      buyer,
      buyerAgent,
      sellerAgent,
      title,
      location,
      description,
      eventType
    } = newPropertyViewingForm;
    
    const eventToCreate = {
      description: description,
      title: title,
      scheduled_date: new Date(date_time).toISOString().slice(0,19),
      location: location
    }
    const createEvent = await dispatch(createSyncedEvent(eventToCreate));
    window.location.reload();


    const selectedPropertyId = propertyIdForm || propertyIdModal || defaultSelectedPropertyId;

    if (!selectedPropertyId || (isEmpty(contacts) && (!buyer && !buyerAgent && !sellerAgent))) {
      callAlert.error('Some fields data is missing');
      return;
    }

    const viewingId = await dispatch(createPropertyViewing({
      property_id: selectedPropertyId,
      date_time,
      contacts,
      buyer,
      buyerAgent,
      sellerAgent,
    }));

    callAlert.success('Created successfully');

    dispatch(getPropertyViewingById(viewingId));
    dispatch(getPropertyById(selectedPropertyId));
    if (realtorProspects.selectedProspect) {
      dispatch(getProspectById(realtorProspects.selectedProspect));
    }
    dispatch(closeNewPropertyViewingModal());
    dispatch(resetNewPropertyViewingForm());
  } catch (error) {
    console.error(error);
    callAlert.error('Could not create');
    throw error;
  }
};

export default createPropertyViewingFromNewPropertyViewingForm;
