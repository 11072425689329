var escape = require("../../../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".contactPill_pill__3ZAjV {\n  background-color: white;\n  min-width: 135px;\n  height: 30px;\n  border-radius: 70px;\n  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1098039216);\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0px 3px;\n  grid-gap: 4px;\n  gap: 4px;\n}\n\n.contactPill_small-pill__2AAFa {\n  background-color: white;\n  min-width: 15px;\n  height: 30px;\n  border-radius: 70px;\n  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1098039216);\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0px 3px;\n  grid-gap: 4px;\n  gap: 4px;\n}\n\n.contactPill_flat-pill__1OZIH {\n  box-shadow: none;\n  background-color: transparent;\n}\n\n.contactPill_extra-count__Q-JJk {\n  font-weight: bold;\n  height: 26px;\n  min-width: 26px;\n  display: flex;\n  place-content: center;\n  place-items: center;\n}\n\n.contactPill_pill-name__BYlFx {\n  max-width: 100px;\n  max-height: 100%;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.contactPill_pill-image__2pJm8 {\n  height: 26px;\n  width: 26px;\n  background-color: #d9d9d9;\n  border-radius: 70px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n}\n\n.contactPill_pill-close-btn__1LSDr {\n  height: 26px;\n  width: 26px;\n  font-size: 16px;\n  border: none;\n  border-radius: 70px;\n  background: #f2f2f2 url(" + escape(require("../../../../../assets/images/commsHub/close-x.svg")) + ") no-repeat center;\n  background-size: 14px 14px;\n  cursor: pointer;\n  transition: background-color 0.3s, color 0.3s;\n}\n\n.contactPill_pill-close-btn__1LSDr:hover {\n  background-color: #e2e2e2;\n}\n\n.contactPill_pill-close-btn__1LSDr:active {\n  background-color: #d9d9d9;\n}", ""]);

// exports
exports.locals = {
	"pill": "contactPill_pill__3ZAjV",
	"small-pill": "contactPill_small-pill__2AAFa",
	"flat-pill": "contactPill_flat-pill__1OZIH",
	"extra-count": "contactPill_extra-count__Q-JJk",
	"pill-name": "contactPill_pill-name__BYlFx",
	"pill-image": "contactPill_pill-image__2pJm8",
	"pill-close-btn": "contactPill_pill-close-btn__1LSDr"
};