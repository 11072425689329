import React from 'react';

import PropTypes from 'prop-types';

import numeral, { pretty } from 'refactored/utils/numbers';

import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';

import ClickAwayListener from 'ui/ClickAwayListener';
import Textfield from 'ui/Textfield';
import Typography from 'ui/Typography';

import useEditMode from 'ui/hooks/useEditMode';

const min = '0';
const max = '10000000000000';

const displayWrapperStyle = {
  height: 44,
  width: '100%',
};

const CurrencyInput = (props) => {
  const {
    value,
    onChange,
    defaultView,
    minimumValue,
    maximumValue,
    focusOnRender,
  } = props;

  const {
    mode,
    setEditMode,
    setDisplayMode,
  } = useEditMode(defaultView);

  return (
    <>
      {
        mode === 'edit' && (
          <ClickAwayListener onClickAway={setDisplayMode}>
            <div>
              <Textfield
                {...props}
                onChange={onChange}
                value={numeral(value).value() || 0}
                currency
                iconPosition="left"
                icon="dollar-sign"
                inputProps={{
                  decimalPlaces: 0,
                  outputFormat: 'number',
                  minimumValue: minimumValue || min,
                  maximumValue: maximumValue || max,
                }}
                focusOnRender={focusOnRender || defaultView === 'display'}
              />
            </div>
          </ClickAwayListener>
        )
      }
      {
        mode === 'display' && (
          <NakedButton
            onClick={setEditMode}
            style={displayWrapperStyle}
          >
            <Typography
              variant="heading"
              noMargin
              tag="p"
              align="left"
              ellipsis
            >
              {value && pretty.int(value)}
            </Typography>
          </NakedButton>
        )
      }
    </>
  );
};

CurrencyInput.defaultProps = {
  minimumValue: min,
  maximumValue: max,
  value: 0,
  defaultView: 'edit',
  focusOnRender: false,
};

const {
  bool,
  func,
  oneOf,
  oneOfType,
  number,
  string,
} = PropTypes;

CurrencyInput.propTypes = {
  minimumValue: number,
  maximumValue: number,
  value: oneOfType([number, string]),
  onChange: func.isRequired,
  defaultView: oneOf(['edit', 'display']),
  focusOnRender: bool,
};

export default CurrencyInput;
