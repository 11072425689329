export const relationships = [
    'Acquaintance',
    'C-Suite - Mine',
    'C-Suite - Other Company',
    'Celebrity',
    'CEO/President - Mine',
    'Client - Current',
    'Client - Previous',
    'Client - VIP',
    'Client - Celebrity',
    'Classmate',
    'Cold Email',
    'Colleague',
    'Colleague - Mine Manager/Supervisor',
    'Consultant',
    'Doctor (Dentist/etc)',
    'Event Organizer',
    'Family',
    'Friend',
    'Government Official',
    'Investor',
    'Landlord',
    'Lead',
    'Manager/Supervisor',
    'Media/Press',
    'Neighbor',
    'Other',
    'Supplier',
    'Supporter',
    'Volunteer/intern',
    'Warm Email'
];

export default relationships