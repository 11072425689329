import removeFulfillmentLineItem from './removeFulfillmentLineItem';

const removeSelectedFulfillmentLineItem = (lineItemId) => async (dispatch, getState) => {
  const {
    fulfillments: {
      selectedFulfillment,
    },
  } = getState();

  await dispatch(removeFulfillmentLineItem(selectedFulfillment)(lineItemId));
};

export default removeSelectedFulfillmentLineItem;
