import {
  SET_CANCELLED_ORDERS,
} from 'store/actionTypes/ordersCancelled';

const set = (payload) => ({
  type: SET_CANCELLED_ORDERS,
  payload,
});

export default set;
