import {
  SET_SELECTED_CUSTOMER_INVOICE,
} from 'store/actionTypes/customerInvoices';

const setSelectedInvoice = (id) => ({
  type: SET_SELECTED_CUSTOMER_INVOICE,
  payload: id,
});

export default setSelectedInvoice;
