import React from 'react';
import TypePersonalIcon from 'assets/images/email-type-personal.png';
import TypeBusinessIcon from 'assets/images/email-type-business.png';

const EmailTypeIcons = type => {
  // console.log('received email type: ', type);
  switch (type) {
    case 'Personal':
      return (
        <div
          style={{
            paddingLeft: '0.6rem',
            paddingRight: '0.6rem',
            paddingTop: '0.2rem',
            paddingBottom: '0.2rem',
            display: 'flex',
            backgroundColor: '#f6f6f6',
            borderRadius: '20px',
            width: 'fit-content',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '22px',
              height: '22px'
            }}
          >
            <img src={TypePersonalIcon} width={22} height={22} />
          </div>
        </div>
      );
    case 'Business':
      return (
        <div
          style={{
            paddingLeft: '0.6rem',
            paddingRight: '0.6rem',
            paddingTop: '0.2rem',
            paddingBottom: '0.2rem',
            display: 'flex',
            backgroundColor: '#f6f6f6',
            borderRadius: '20px',

            width: 'fit-content',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '22px',
              height: '22px'
            }}
          >
            <img src={TypeBusinessIcon} width={22} height={22} />
          </div>
        </div>
      );
    case 'Both':
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div
            style={{
              paddingLeft: '0.6rem',
              paddingRight: '0.6rem',
              paddingTop: '0.2rem',
              paddingBottom: '0.2rem',
              display: 'flex',
              backgroundColor: '#f6f6f6',
              borderRadius: '20px',

              width: 'fit-content',
              alignItems: 'center',
              marginRight: '3px'
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '22px',
                height: '22px'
              }}
            >
              <img src={TypePersonalIcon} width={22} height={22} />
            </div>
          </div>
          <div
            style={{
              paddingLeft: '0.6rem',
              paddingRight: '0.6rem',
              paddingTop: '0.2rem',
              paddingBottom: '0.2rem',
              display: 'flex',
              backgroundColor: '#f6f6f6',
              borderRadius: '20px',

              width: 'fit-content',
              alignItems: 'center'
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '22px',
                height: '22px'
              }}
            >
              <img src={TypeBusinessIcon} width={22} height={22} />
            </div>
          </div>
        </div>
      );
    case "Don't know":
      return <></>;
    default:
      return <></>;
  }
};

export default EmailTypeIcons;
