import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import Button from 'ui/Button';
import NewContactModal from 'sections/Contacts/modals/NewContactModal';

import useModal from 'ui/hooks/useModal';

const AddContact = (props) => {
  const {
    onSuccess,
  } = props;

  const {
    isModalVisible,
    openModal,
    closeModal,
  } = useModal();

  const handleSuccess = useCallback((newContactId) => {
    onSuccess(newContactId);
    closeModal();
  }, [
    onSuccess,
    closeModal,
  ]);

  return (
    <>
      <Button
        variant="primary"
        noWrap
        onClick={openModal}
        icon="plus"
      >
        Create New
      </Button>

      <NewContactModal
        isVisible={isModalVisible}
        closeModal={closeModal}
        onSuccess={handleSuccess}
      />
    </>
  );
};

const {
  func,
} = PropTypes;

AddContact.propTypes = {
  onSuccess: func.isRequired,
};

export default AddContact;
