/* eslint-disable */
// Exposit
// WHOLE FILE IGNORED, TOO MANY PRE-EXISTING ERRORS
// TODO - fix lint errors
import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import { callAlert } from 'ui/Alert';

import { url } from 'paths';
import {
  apiConnector,
  getConnector,
  noContentTypeFetch as fetch
} from 'shared/API';
import TeamRepository from 'shared/repositiores/myCompany/team';
import constants from 'components/HR/constants';
import requests from 'api/requests';

import { getTeamById, getAllTeams, removeTeam } from 'store/actions/teamsV2';

import { SET_BASIC_COMPANY_INFO } from 'store/actionTypes/companyInfo';

import { getEmployees } from './common';

export const getBasicCompanyDetails = () => async dispatch => {
  try {
    const basicCompanyInfo = await requests.companyDetails.get();
    dispatch({
      type: SET_BASIC_COMPANY_INFO,
      payload: basicCompanyInfo
    });
  } catch (error) {
    console.error(error);
  }
};

export const GET_GENERAL = 'GET_GENERAL';
export const getGeneral = (isExternal = null) => dispatch => {
  // dispatch({ type: GET_GENERAL });
  // getConnector('/company/info', isExternal)
  //   .then((res) => {
  //     dispatch(getGeneralSuccess(res));
  //   })
  //   .catch((err) => {
  //     dispatch(getGeneralError());
  //   });
};

export const GET_GENERAL_SUCCESS = 'GET_GENERAL_SUCCESS';
export const getGeneralSuccess = general => ({
  type: GET_GENERAL_SUCCESS,
  general
});

export const GET_GENERAL_ERROR = 'GET_GENERAL_ERROR';
export const getGeneralError = () => ({ type: GET_GENERAL_ERROR });

export const SAVE_COMPANY_LOGO = 'SAVE_COMPANY_LOGO';
export const saveCompanyLogo = file => dispatch => {
  const newUrl = `${url}/company/logo`;
  const formData = new FormData();
  formData.append('attachment', file);
  fetch(newUrl, {
    method: 'POST',
    body: formData
  })
    .then(() => {
      dispatch(saveCompanyLogoSuccess());
    })
    .catch(() => {
      dispatch(saveCompanyLogoError());
    });
};

export const SAVE_COMPANY_LOGO_SUCCESS = 'SAVE_COMPANY_LOGO_SUCCESS';
export const saveCompanyLogoSuccess = () => dispatch => {
  dispatch({ type: SAVE_COMPANY_LOGO_SUCCESS });
  dispatch(getGeneral());
};

export const SAVE_COMPANY_LOGO_ERROR = 'SAVE_COMPANY_LOGO_ERROR';
export const saveCompanyLogoError = () => ({ type: SAVE_COMPANY_LOGO_ERROR });

export const DELETE_COMPANY_LOGO = 'DELETE_COMPANY_LOGO';
export const deleteCompanyLogo = () => (dispatch, getState) => {
  dispatch({ type: DELETE_COMPANY_LOGO });
  apiConnector('/company/logo', 'DELETE')
    .then(() => dispatch(deleteCompanyLogoSuccess()))
    .catch(() => dispatch(deleteCompanyLogoError()));
};

export const DELETE_COMPANY_LOGO_SUCCESS = 'DELETE_COMPANY_LOGO_SUCCESS';
export const deleteCompanyLogoSuccess = () => dispatch => {
  dispatch({ type: DELETE_COMPANY_LOGO_SUCCESS });
  dispatch(getGeneral());
};

export const DELETE_COMPANY_LOGO_ERROR = 'DELETE_COMPANY_LOGO_ERROR';
export const deleteCompanyLogoError = () => ({
  type: DELETE_COMPANY_LOGO_ERROR
});

export const EDIT_GENERAL = 'EDIT_GENERAL';
export const editGeneral = company => async (dispatch, getState) => {
  try {
    const {
      myCompany: {
        general: { CompanyName, DatabaseName }
      }
    } = getState();

    const isCompanyNameChanged = company.CompanyName !== CompanyName;

    if (isCompanyNameChanged) {
      requests.company.changeCompanyNameInGlobal(
        company.CompanyName,
        DatabaseName
      );
    }

    dispatch({ type: EDIT_GENERAL });
    await apiConnector('/company/info', 'POST', company);
    dispatch(editGeneralSuccess());
  } catch (error) {
    dispatch(editGeneralError());
  }
};

export const EDIT_GENERAL_SUCCESS = 'EDIT_GENERAL_SUCCESS';
export const editGeneralSuccess = () => dispatch => {
  dispatch({ type: EDIT_GENERAL_SUCCESS });
  dispatch(getGeneral());
};

export const EDIT_GENERAL_ERROR = 'EDIT_GENERAL_ERROR';
export const editGeneralError = () => ({ type: EDIT_GENERAL_ERROR });

export const GET_KEY_MEMBERS = 'GET_KEY_MEMBERS';
export const getKeyMembers = () => dispatch => {
  dispatch({ type: GET_KEY_MEMBERS });
  getConnector('/company/keyMembers')
    .then(res => dispatch(getKeyMembersSuccess(res.reverse())))
    .catch(err => dispatch(getKeyMembersError()));
};

export const GET_KEY_MEMBERS_SUCCESS = 'GET_KEY_MEMBERS_SUCCESS';
export const getKeyMembersSuccess = keyMembers => ({
  type: GET_KEY_MEMBERS_SUCCESS,
  keyMembers
});

export const GET_KEY_MEMBERS_ERROR = 'GET_KEY_MEMBERS_ERROR';
export const getKeyMembersError = () => ({ type: GET_KEY_MEMBERS_ERROR });

export const ADD_KEY_MEMBER = 'ADD_KEY_MEMBER';
export const addKeyMember = (keyMember, form) => dispatch => {
  dispatch({ type: ADD_KEY_MEMBER });
  apiConnector('/company/keyMembers', 'POST', keyMember)
    .then(res => dispatch(addKeyMemberSuccess(form)))
    .catch(err => dispatch(addKeyMemberError()));
};

export const ADD_KEY_MEMBER_SUCCESS = 'ADD_KEY_MEMBER_SUCCESS';
export const addKeyMemberSuccess = form => dispatch => {
  dispatch({ type: ADD_KEY_MEMBER_SUCCESS });
  dispatch(getKeyMembers());
  form.resetFields();
};

export const ADD_KEY_MEMBER_ERROR = 'ADD_KEY_MEMBER_ERROR';
export const addKeyMemberError = () => ({ type: ADD_KEY_MEMBER_ERROR });

export const DELETE_KEY_MEMBER = 'DELETE_KEY_MEMBER';
export const deleteKeyMember = keyMemberId => dispatch => {
  dispatch({ type: DELETE_KEY_MEMBER });
  apiConnector('/company/delete/keyMembers', 'POST', { keyMemberId })
    .then(res => dispatch(deleteKeyMemberSuccess()))
    .catch(err => dispatch(deleteKeyMemberError()));
};

export const DELETE_KEY_MEMBER_SUCCESS = 'DELETE_KEY_MEMBER_SUCCESS';
export const deleteKeyMemberSuccess = () => dispatch => {
  dispatch({ type: DELETE_KEY_MEMBER_SUCCESS });
  dispatch(getKeyMembers());
};

export const DELETE_KEY_MEMBER_ERROR = 'DELETE_KEY_MEMBER_ERROR';
export const deleteKeyMemberError = () => ({ type: DELETE_KEY_MEMBER_ERROR });

export const GET_OFFICES = 'GET_OFFICES';
export const getOffices = () => dispatch => {
  dispatch({ type: GET_OFFICES });
  getConnector('/company/office')
    .then(res =>
      dispatch(getOfficesSuccess(res.sort((a, b) => b.OfficeID - a.OfficeID)))
    )
    .catch(err => dispatch(getOfficesError()));
};

export const GET_OFFICES_SUCCESS = 'GET_OFFICES_SUCCESS';
export const getOfficesSuccess = offices => ({
  type: GET_OFFICES_SUCCESS,
  offices
});

export const GET_OFFICES_ERROR = 'GET_OFFICES_ERROR';
export const getOfficesError = () => ({ type: GET_OFFICES_ERROR });

export const ADD_OFFICE = 'ADD_OFFICE';
export const addOffice = (office, form) => dispatch => {
  dispatch({ type: ADD_OFFICE });
  apiConnector('/company/office', 'POST', office)
    .then(res => dispatch(addOfficeSuccess(form)))
    .catch(err => dispatch(addOfficeError()));
};

export const ADD_OFFICE_SUCCESS = 'ADD_OFFICE_SUCCESS';
export const addOfficeSuccess = form => dispatch => {
  dispatch({ type: ADD_OFFICE_SUCCESS });
  dispatch(triggerOffice());
  form.resetFields();
  dispatch(getOffices());
};

export const ADD_OFFICE_ERROR = 'ADD_OFFICE_ERROR';
export const addOfficeError = () => ({ type: ADD_OFFICE_ERROR });

export const EDIT_OFFICE = 'EDIT_OFFICE';
export const editOffice = office => async (dispatch, getState) => {
  try {
    const {
      myCompany: {
        general: { DatabaseName },
        offices
      }
    } = getState();

    requests.company.changeMainOfficeInformationInGlobal(
      offices,
      office,
      DatabaseName
    );
    await apiConnector('/company/office', 'POST', office);
    dispatch(editOfficeSuccess());
  } catch (error) {
    dispatch(editOfficeError());
  }
};

export const EDIT_OFFICE_SUCCESS = 'EDIT_OFFICE_SUCCESS';
export const editOfficeSuccess = () => dispatch => {
  dispatch({ type: EDIT_OFFICE_SUCCESS });
  dispatch(getOffices());
};

export const EDIT_OFFICE_ERROR = 'EDIT_OFFICE_ERROR';
export const editOfficeError = () => ({ type: EDIT_OFFICE_ERROR });

export const DELETE_OFFICE = 'DELETE_OFFICE';
export const deleteOffice = officeId => dispatch => {
  dispatch({ type: DELETE_OFFICE });
  apiConnector('/company/remove/office', 'POST', { officeId })
    .then(res => dispatch(deleteOfficeSuccess()))
    .catch(err => dispatch(deleteOfficeError()));
};

export const DELETE_OFFICE_SUCCESS = 'DELETE_OFFICE_SUCCESS';
export const deleteOfficeSuccess = () => dispatch => {
  dispatch({ type: DELETE_OFFICE_SUCCESS });
  dispatch(getOffices());
};

export const DELETE_OFFICE_ERROR = 'DELETE_OFFICE_ERROR';
export const deleteOfficeError = () => ({ type: DELETE_OFFICE_ERROR });

export const TRIGGER_OFFICE = 'TRIGGER_OFFICE';
export const triggerOffice = () => ({ type: TRIGGER_OFFICE });

export const GET_DEPARTMENTS = 'GET_DEPARTMENTS';

export const getDepartments = () => dispatch => {
  dispatch({ type: GET_DEPARTMENTS });
  getConnector('/company/departments')
    .then(res => dispatch(getDepartmentsSuccess(res)))
    .catch(err => dispatch(getDepartmentsError()));
};

export const GET_DEPARTMENTS_SUCCESS = 'GET_DEPARTMENTS_SUCCESS';
export const getDepartmentsSuccess = departments => ({
  type: GET_DEPARTMENTS_SUCCESS,
  departments
});

export const GET_DEPARTMENTS_ERROR = 'GET_DEPARTMENTS_ERROR';
export const getDepartmentsError = () => ({ type: GET_DEPARTMENTS_ERROR });

export const ADD_DEPARTMENT = 'ADD_DEPARTMENT';
export const addDepartment = department => async dispatch => {
  dispatch({ type: ADD_DEPARTMENT });
  const { managers = [], ...data } = department;

  try {
    const { DepartmentID } = await apiConnector(
      '/company/departments',
      'POST',
      data
    );

    if (managers.length) {
      await apiConnector(
        `/company/departments/employees/${DepartmentID}/DepartmentManager`,
        'PUT',
        { userList: managers }
      );
    }

    dispatch(addDepartmentSuccess());
  } catch (err) {
    dispatch(addDepartmentError());

    throw new Error(err);
  }
};

export const setDepartmentManagersApi = (
  departmentId,
  managers = []
) => async dispatch => {
  try {
    await apiConnector(
      `/company/departments/employees/${departmentId}/DepartmentManager`,
      'PUT',
      { userList: managers }
    );
    dispatch(getDepartments());
  } catch (error) {
    console.error(error);
  }
};

export const setDepartmentMembersApi = (
  departmentId,
  members = []
) => async dispatch => {
  try {
    await apiConnector(
      `/company/departments/employees/${departmentId}/DepartmentMember`,
      'PUT',
      { userList: members }
    );
    dispatch(getDepartments());
  } catch (error) {
    console.error(error);
  }
};

export const ADD_DEPARTMENT_SUCCESS = 'ADD_DEPARTMENT_SUCCESS';
export const addDepartmentSuccess = () => dispatch => {
  dispatch({ type: ADD_DEPARTMENT_SUCCESS });
  dispatch(getDepartments());
};

export const ADD_DEPARTMENT_ERROR = 'ADD_DEPARTMENT_ERROR';
export const addDepartmentError = () => ({ type: ADD_DEPARTMENT_ERROR });

export const EDIT_DEPARTMENT = 'EDIT_DEPARTMENT';
export const editDepartament = department => dispatch => {
  dispatch({ type: EDIT_DEPARTMENT });
  apiConnector(
    `/company/departments/${department.DepartmentID}`,
    'PATCH',
    department
  )
    .then(res => dispatch(editDepartamentSuccess()))
    .catch(err => dispatch(editDepartamentError()));
};

export const EDIT_DEPARTMENT_SUCCESS = 'EDIT_DEPARTMENT_SUCCESS';
export const editDepartamentSuccess = () => dispatch => {
  dispatch({ type: EDIT_DEPARTMENT_SUCCESS });
  dispatch(getDepartments());
};

export const EDIT_DEPARTMENT_ERROR = 'EDIT_DEPARTMENT_ERROR';
export const editDepartamentError = () => ({ type: EDIT_DEPARTMENT_ERROR });

export const DELETE_DEPARTMENT = 'DELETE_DEPARTMENT';
export const deleteDepartment = departmentId => dispatch => {
  dispatch({ type: DELETE_DEPARTMENT });
  apiConnector(`/company/departments/${departmentId}`, 'DELETE', {})
    .then(res => dispatch(deleteDepartmentSuccess()))
    .catch(err => dispatch(deleteDepartmentError()));
};

export const DELETE_DEPARTMENT_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS';
export const deleteDepartmentSuccess = () => dispatch => {
  dispatch({ type: DELETE_DEPARTMENT_SUCCESS });
  dispatch(getDepartments());
};

export const DELETE_DEPARTMENT_ERROR = 'DELETE_DEPARTMENT_ERROR';
export const deleteDepartmentError = () => ({ type: DELETE_DEPARTMENT_ERROR });

export const TRIGGER_DEPARTMENT = 'TRIGGER_DEPARTMENT';
export const triggerDepartment = () => ({ type: TRIGGER_DEPARTMENT });

export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const setPermissions = () => ({ type: SET_PERMISSIONS });

export const SET_PERMISSIONS_SUCCESS = 'SET_PERMISSIONS_SUCCESS';
export const setPermissionsSuccess = () => dispatch => {
  try {
    dispatch({ type: SET_PERMISSIONS_SUCCESS });
    dispatch(getEmployees());
    callAlert.success('The list of administrators has been changed');
  } catch {
    callAlert.error('An error occurred when changing administrators');
  }
};

export const SET_PERMISSIONS_ERROR = 'SET_PERMISSIONS_ERROR';
export const setPermissionsError = () => ({ type: SET_PERMISSIONS_ERROR });

export const SET_DEPARTMENT_MANAGERS = 'SET_DEPARTMENT_MANAGERS';
export const setDepartmentManagers = () => ({ type: SET_DEPARTMENT_MANAGERS });

export const SET_DEPARTMENT_MANAGERS_SUCCESS =
  'SET_DEPARTMENT_MANAGERS_SUCCESS';
export const setDepartmentManagersSuccess = () => dispatch => {
  dispatch({ type: SET_DEPARTMENT_MANAGERS_SUCCESS });
  dispatch(getDepartments());

  callAlert.success(constants.ADD_DEPARTMENT_MANAGER);
};

export const SET_DEPARTMENT_MANAGERS_ERROR = 'SET_DEPARTMENT_MANAGERS_ERROR';
export const setDepartmentManagersError = () => ({
  type: SET_DEPARTMENT_MANAGERS_ERROR
});

export const GET_TEAMS = 'GET_TEAMS';
export const getTeams = () => dispatch => {
  dispatch({ type: GET_TEAMS });
  TeamRepository.getTeams()
    .then(teams => dispatch(getTeamsSuccess(teams)))
    .catch(() => dispatch(getTeamsError()));
};

export const GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS';
export const getTeamsSuccess = teams => ({
  type: GET_TEAMS_SUCCESS,
  teams
});

export const GET_TEAMS_ERROR = 'GET_TEAMS_ERROR';
export const getTeamsError = () => ({ type: GET_TEAMS_ERROR });

export const ADD_TEAM = 'ADD_TEAM';
export const addTeam = team => async dispatch => {
  dispatch({ type: ADD_TEAM });
  const { managers = [], members = [], ...data } = team;

  try {
    const { TeamID } = await TeamRepository.addTeam(data);

    if (managers.length) {
      await TeamRepository.updateManagers(TeamID, { userList: managers });
    }

    if (members.length) {
      await TeamRepository.updateMembers(TeamID, { userList: members });
    }

    dispatch(addTeamSuccess());
  } catch (err) {
    dispatch(addTeamError());
    throw new Error(err);
  }
};

export const setTeamManagersApi = (teamId, managers = []) => async dispatch => {
  try {
    await TeamRepository.updateManagers(teamId, { userList: managers });
    dispatch(getAllTeams());
  } catch (error) {
    console.error(error);
  }
};

export const setTeamMembersApi = (teamId, managers = []) => async dispatch => {
  try {
    await TeamRepository.updateMembers(teamId, { userList: managers });
    dispatch(getAllTeams());
  } catch (error) {
    console.error(error);
  }
};

export const ADD_TEAM_SUCCESS = 'ADD_TEAM_SUCCESS';
export const addTeamSuccess = () => dispatch => {
  dispatch(getAllTeams());
  dispatch(getDepartments());
  dispatch({ type: ADD_TEAM_SUCCESS });
};

export const ADD_TEAM_ERROR = 'ADD_TEAM_ERROR';
export const addTeamError = () => ({ type: ADD_TEAM_ERROR });

export const EDIT_TEAM = 'EDIT_TEAM';
export const editTeam = (teamId, team) => dispatch => {
  dispatch({ type: EDIT_TEAM });
  TeamRepository.editTeam(teamId, team)
    .then(() => dispatch(editTeamSuccess(teamId)))
    .catch(() => dispatch(editTeamError()));
};

export const EDIT_TEAM_SUCCESS = 'EDIT_TEAM_SUCCESS';
export const editTeamSuccess = id => dispatch => {
  dispatch({ type: EDIT_TEAM_SUCCESS });
  dispatch(getTeamById(id));
};

export const EDIT_TEAM_ERROR = 'EDIT_TEAM_ERROR';
export const editTeamError = () => ({ type: EDIT_TEAM_ERROR });

export const DELETE_TEAM = 'DELETE_TEAM';
export const deleteTeam = teamId => dispatch => {
  dispatch({ type: DELETE_TEAM });
  TeamRepository.deleteTeam(teamId)
    .then(() => dispatch(deleteTeamSuccess(teamId)))
    .catch(() => dispatch(deleteTeamError()));
};

export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const deleteTeamSuccess = teamId => dispatch => {
  dispatch({ type: DELETE_TEAM_SUCCESS });
  dispatch(getDepartments());
  dispatch(removeTeam(teamId));
  dispatch(getAllTeams());
};

export const DELETE_TEAM_ERROR = 'DELETE_TEAM_ERROR';
export const deleteTeamError = () => ({ type: DELETE_TEAM_ERROR });

export const SET_TEAM_MANAGERS = 'SET_TEAM_MANAGERS';
export const setTeamManagers = () => ({ type: SET_TEAM_MANAGERS });

export const SET_TEAM_MANAGERS_SUCCESS = 'SET_TEAM_MANAGERS_SUCCESS';
export const setTeamManagersSuccess = () => dispatch => {
  dispatch({ type: SET_TEAM_MANAGERS_SUCCESS });
  dispatch(getAllTeams());
};

export const SET_TEAM_MANAGERS_ERROR = 'SET_TEAM_MANAGERS_ERROR';
export const setTeamManagersError = () => ({ type: SET_TEAM_MANAGERS_ERROR });

export const SET_TEAM_MEMBERS = 'SET_TEAM_MEMBERS';
export const setTeamMembers = () => ({ type: SET_TEAM_MEMBERS });

export const SET_TEAM_MEMBERS_SUCCESS = 'SET_TEAM_MEMBERS_SUCCESS';
export const setTeamMembersSuccess = () => dispatch => {
  dispatch({ type: SET_TEAM_MEMBERS_SUCCESS });
  dispatch(getAllTeams());
};

export const SET_TEAM_MEMBERS_ERROR = 'SET_TEAM_MEMBERS_ERROR';
export const setTeamMembersError = () => ({ type: SET_TEAM_MEMBERS_ERROR });

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false
};

export const optimizedMyCompanyInformationActions = {
  getOffices: memoizeDebounce(
    () => store.dispatch(getOffices()),
    debounceDelay,
    debounceOptions
  )
};
