import apiConnector from 'api';
import requests from 'api/requests';

import {
  SET_INVOICES_LIST,
  SET_INVOICES_TOTAL,
  SET_INVOICES_LOADING_STATUS,
  SET_INVOICES_NEXT_MONTHLY_PAYMENT,
  SET_INVOICES_NEXT_YEARLY_PAYMENT,
  SET_INVOICES_PREVIOUS_MONTHLY_PAYMENT,
  SET_INVOICES_LICENSES_TO_BE_REVOKED,
} from 'store/actionTypes/invoices';

export const setInvoicesList = (payload) => ({
  type: SET_INVOICES_LIST,
  payload,
});

export const setInvoicesTotal = (payload) => ({
  type: SET_INVOICES_TOTAL,
  payload,
});

export const setInvoicesLoadingStatus = (payload) => ({
  type: SET_INVOICES_LOADING_STATUS,
  payload,
});

export const setInvoicesNextMonthlyPayment = (payload) => ({
  type: SET_INVOICES_NEXT_MONTHLY_PAYMENT,
  payload,
});

export const setInvoicesNextYearlyPayment = (payload) => ({
  type: SET_INVOICES_NEXT_YEARLY_PAYMENT,
  payload,
});

export const setInvoicesPreviousMonthlyPayment = (payload) => ({
  type: SET_INVOICES_PREVIOUS_MONTHLY_PAYMENT,
  payload,
});

export const setLicensesToBeRevoked = (payload) => ({
  type: SET_INVOICES_LICENSES_TO_BE_REVOKED,
  payload,
});

export const getNextMonthlyPayment = () => async (dispatch) => {
  try {
    const response = await requests.billing.getNextMonthlyPayment();

    const {
      total,
      details,
      billingDate,
    } = response.data;

    dispatch(setInvoicesNextMonthlyPayment({
      total,
      details,
      billingDate,
    }));
  } catch (error) {
    console.error(error);
  }
};

export const getPreviousMonthlyPayment = () => async (dispatch) => {
  try {
    const response = await requests.billing.getPreviousMonthlyPayment();

    const {
      total,
      billingDate,
      status,
    } = response.data;

    dispatch(setInvoicesPreviousMonthlyPayment({
      total,
      billingDate,
      status,
    }));
  } catch (error) {
    console.error(error);
  }
};

export const getInvoices = (pagination = {}) => async (dispatch) => {
  const {
    pageNumber = 1,
    rowsPerPage = 5,
  } = pagination;

  dispatch(setInvoicesLoadingStatus(true));

  try {
    const response = await requests.billing.getInvoices({
      pageNumber,
      rowsPerPage,
    });

    const {
      invoices,
      total,
    } = response.data;

    dispatch(setInvoicesList(invoices));
    dispatch(setInvoicesTotal(total));
    dispatch(setInvoicesLoadingStatus(false));
  } catch (error) {
    dispatch(setInvoicesLoadingStatus(false));
    console.error(error);
  }
};

export const getLicensesToBeRevoked = () => async (dispatch) => {
  const endpoint = 'company/revoked-licenses';

  try {
    const {
      data: {
        revokedLicenses,
      },
    } = await apiConnector.client.get(
      endpoint,
    );

    dispatch(setLicensesToBeRevoked(revokedLicenses));
  } catch (error) {
    console.error(error);
  }
};

export const getNextYearlyPayment = () => async (dispatch) => {
  const nextYearlyPayment = await requests.billing.getNextYearlyPayment();
  dispatch(setInvoicesNextYearlyPayment(nextYearlyPayment));
};

export default {
  setInvoicesList,
  setInvoicesTotal,
  setInvoicesLoadingStatus,
  setInvoicesNextMonthlyPayment,
  setInvoicesPreviousMonthlyPayment,

  getNextMonthlyPayment,
  getNextYearlyPayment,
  getPreviousMonthlyPayment,
  getInvoices,
  getLicensesToBeRevoked,
};
