exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".squarePrimaryButton_square-primary-btn__zt-UQ {\n  min-width: 120px;\n  height: 40px;\n  border-radius: 10px;\n  background: none;\n  border: none;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #3c2b84;\n  color: #fff;\n  font-size: 15px;\n  padding: 4px 12px;\n}\n\n.squarePrimaryButton_square-primary-btn__zt-UQ:hover {\n  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);\n  background-color: rgba(60, 43, 132, 0.9333333333);\n}\n\n.squarePrimaryButton_square-primary-btn__zt-UQ:active {\n  box-shadow: none;\n  background-color: #3c2b84;\n}\n\n.squarePrimaryButton_square-primary-btn-icon__2K-PK {\n  height: 24px;\n  max-height: 24px;\n  width: auto;\n  object-fit: contain;\n}", ""]);

// exports
exports.locals = {
	"square-primary-btn": "squarePrimaryButton_square-primary-btn__zt-UQ",
	"square-primary-btn-icon": "squarePrimaryButton_square-primary-btn-icon__2K-PK"
};