import unclaimLead from './unclaimLead';

const claimSelectedLead = () => async (dispatch, getState) => {
  const {
    crmLeads: {
      selectedLead,
    },
  } = getState();

  dispatch(unclaimLead(selectedLead));
};

export default claimSelectedLead;
