import {
  SET_SELECTED_SHIPPED_ITEM,
} from 'store/actionTypes/shippedItems';

const setSelected = (id) => ({
  type: SET_SELECTED_SHIPPED_ITEM,
  payload: id,
});

export default setSelected;
