import useGoogleAuth from 'sections/Integrations/hooks/useGoogleAuth';

// pass in whatever google scopes joined by a space
const useCustomGoogleIntegrations = (GOOGLE_SCOPES, token) => {
  const { auth } = useGoogleAuth(
    GOOGLE_SCOPES,
    JSON.stringify({
      auth: token,
      scope: 'email'
    })
  );

  return {
    auth
  };
};

export default useCustomGoogleIntegrations;
