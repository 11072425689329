import React from 'react';

import PropTypes from 'prop-types';

import { currency } from 'refactored/utils/numbers';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';

const Price = (props) => {
  const {
    bestPrice,
    value,
  } = props;

  return (
    <>
      {
        !bestPrice
          ? (
            <Typography
              variant="title1"
              noMargin
            >
              {currency.short(value)}
            </Typography>

          )
          : (
            <Flex
              flexDirection="column"
              spacing={1}
            >
              <Typography
                variant="cell2"
                color="success"
                weight="bold"
              >
                BEST PRICE!
              </Typography>
              <Typography
                variant="title1"
                noMargin
                color="success"
              >
                {currency.short(value)}
              </Typography>
            </Flex>
          )
      }

    </>
  );
};

Price.defaultProps = {
  bestPrice: null,
  value: null,
};

const {
  number,
  bool,
} = PropTypes;

Price.propTypes = {
  value: number,
  bestPrice: bool,
};

export default Price;
