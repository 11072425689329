import { find } from 'shared/utility';

import { callAlert } from 'ui/Alert';

import requests from 'api/requests';

import { SET_CHAT_THREAD } from 'store/actionTypes/chat';

import markMessageAsRead from './markMessageAsRead';

const getChatThread = (chatId) => (offset, limit) => async (dispatch, getState) => {
  try {
    const {
      auth: {
        loggedUser: {
          EmployeeID,
        },
      },
    } = getState();

    const response = await requests.chat.getThread(chatId)(offset, limit);

    const {
      messages,
      total,
    } = response.data;

    if (messages) {
      const loggedEmployeeSeen = find(messages.messageUsersSeen, { user_id: EmployeeID });

      if (!loggedEmployeeSeen) {
        dispatch(markMessageAsRead(messages.id));
      }
    }

    dispatch({
      type: SET_CHAT_THREAD,
      payload: {
        chatId,
        thread: messages,
        total,
      },
    });
  } catch {
    callAlert.error('Error!');
  }
};

export default getChatThread;
