import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NakedButton } from 'refactored/components/shared/buttons/nakedButton';

const NoteCell = (props) => {
  const {
    onClick,
    hasContent,
  } = props;

  const handleClick = useCallback((event) => {
    event.stopPropagation();
    onClick();
  }, [
    onClick,
  ]);

  return (
    <NakedButton onClick={handleClick} style={{ margin: '0 auto' }}>
      <FontAwesomeIcon
        icon="file-alt"
        style={{
          color: hasContent ? '#1e2a37' : '#a1bdcb',
          fontSize: 18,
        }}
      />
    </NakedButton>
  );
};

const {
  bool,
  func,
} = PropTypes;

NoteCell.propTypes = {
  onClick: func.isRequired,
  hasContent: bool.isRequired,
};

export default NoteCell;
