import {
  SET_SELECTED_AGREEMENT,
} from 'store/actionTypes/agreements';

const setSelectedAgreement = (id) => ({
  type: SET_SELECTED_AGREEMENT,
  payload: id,
});

export default setSelectedAgreement;
