import { callAlert } from 'ui/Alert';

import { DELETE_CHAT } from 'store/actionTypes/chat';

const removeMemberFromChat = () => async (dispatch, getState) => {
  try {
    const {
      chat: {
        activeChatId,
      },
    } = getState();

    dispatch({
      type: DELETE_CHAT,
      payload: {
        chatId: activeChatId,
      },
    });
  } catch {
    callAlert.error('Error!');
  }
};

export default removeMemberFromChat;
