import { connect } from 'react-redux';

// import {
//   setNewLeadFormField,
// } from 'store/actions/newRealtorLeadForm';

import SalePriceInput from 'shared/inputs/SalePriceInput';

const mapStateToProps = (state) => ({
  // value: expectedSalePrice,
  label: 'Listed sale price',
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SalePriceInput);
