import requests from 'api/requests';

import {
  SET_TEAM,
  SET_TEAMS,
  SET_TEAMS_COUNT,
  REMOVE_TEAM,
} from 'store/actionTypes/teams';

export const getTeamById = (teamId) => async (dispatch) => {
  try {
    const teamResponse = await requests.teams.getTeamById(teamId);

    dispatch(setTeam(teamResponse.data));
  } catch (error) {
    console.error(error);
  }
};

export const getAllTeams = (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const teamsResponse = await requests.teams.getTeams({
      offset,
      limit,
    });

    dispatch(setTeamsCount(teamsResponse.data.teamsCount));
    dispatch(setTeams(teamsResponse.data.teams));
  } catch (error) {
    console.info(error);
  }
};

export const setTeam = (team) => ({
  type: SET_TEAM,
  payload: team,
});

export const setTeams = (teams) => ({
  type: SET_TEAMS,
  payload: teams,
});

export const setTeamsCount = (teamsCount) => ({
  type: SET_TEAMS_COUNT,
  payload: teamsCount,
});

export const removeTeam = (id) => ({
  type: REMOVE_TEAM,
  payload: id,
});

export default {
  getAllTeams,
  setTeams,
};
