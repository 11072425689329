

export const sortByImportance = (arr) => {
    return arr.sort((a, b) => {
        (b?.clean_sympleteAnalysis?.importance || 0) - (a?.clean_sympleteAnalysis?.importance || 0)
    })
}
export const sortByDate = (arr) => {
    return arr.sort((a, b) => {
        (b?.date || 0) - (a?.date || 0)
    })
}

