import {
  SET_SELECTED_PROPERTY_VIEWING,
} from 'store/actionTypes/propertyViewings';

const setSelectedPropertyViewing = (viewingId) => ({
  type: SET_SELECTED_PROPERTY_VIEWING,
  payload: viewingId,
});

export default setSelectedPropertyViewing;
