
import { REPLY_MODES } from 'refactored/constants/email/replyModes';
const sendEmail = async ({ token, from, subject, body, to, carbonCopy, blindCarbonCopy, msgReplyId, replyMode }) => {
    try {
        const reply_to_message_id = (replyMode == REPLY_MODES.REPLY || REPLY_MODES.REPLY_TO_ALL) ? msgReplyId : undefined
        const response = await fetch('https://api.nylas.com/send', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                from,
                subject,
                body,
                to,
                cc: carbonCopy,
                bcc: blindCarbonCopy,
                reply_to_message_id
            })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error('Failed to send email');
    }
};

export default sendEmail;
