import React from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';

import Search from 'sections/MLS/modules/PropertiesTableSearchFilter';
import Create from 'sections/MLS/modules/CreateProperty';
import Preview from 'sections/MLS/modules/PropertiesPreviews';

const PropertiesPreviewSelector = (props) => {
  const {
    onSelect,
    selected,
  } = props;

  return (
    <Flex
      spacing={2}
      flexDirection="column"
      fullWidth
    >
      <Flex fullWidth spacing={2}>
        <Search 
          withDropdownFilter={false}
          searchInputPlaceholder="Search existing properties"
        />
        <Create
          onSuccess={onSelect}
        />
      </Flex>
      {/* <Preview
        selected={selected}
        onItemClick={(id) => () => onSelect(id)}
      /> */}
    </Flex>
  );
};

PropertiesPreviewSelector.defaultProps = {
  selected: null,
};

const {
  func,
  number,
} = PropTypes;

PropertiesPreviewSelector.propTypes = {
  onSelect: func.isRequired,
  selected: number,
};

export default PropertiesPreviewSelector;
