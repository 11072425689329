import styled from 'styled-components';

export const EmptyElement = styled.div`
  width: 71.55px;
`;

export const InformationWrapper = styled.div`
  background-color: ${props => props.theme?.palette['background-flat']};
  padding: 24px;
  width: 470px;
`;
export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 1000;
`;

export const NextButton = styled.button`  
border: none; 
cursor: pointer;
outline: none;
width: 168px;
height: 40px; 
border-radius: 10px;
background-color: #3C2B84;
color:white;
`;

export default {
  EmptyElement,
  InformationWrapper,
  NextButton
};
