import {
  SHOW_ADD_EMPLOYEE_MODAL,
  HIDE_ADD_EMPLOYEE_MODAL,
} from 'store/actionTypes/chat';

export const showAddEmployeeModal = () => (dispatch) => {
  dispatch({
    type: SHOW_ADD_EMPLOYEE_MODAL,
  });
};

export const hideAddEmployeeModal = () => (dispatch) => {
  dispatch({
    type: HIDE_ADD_EMPLOYEE_MODAL,
  });
};
