import requests from 'api/requests';

import getById from './getLikeById';

const addLikeRelations = (relationType) => (likeId) => (data) => async (dispatch) => {
  try {
    await requests.likesRelations.addRelations(relationType)(likeId)(data);

    dispatch(getById(likeId));
  } catch (error) {
    console.error(error);
  }
};

export default addLikeRelations;
