/* eslint-disable */
// Exposit
// WHOLE FILE IGNORED, TOO MANY PRE-EXISTING ERRORS
// TODO - fix lint errors
// import publicIp from 'public-ip';
import { detect } from 'detect-browser';

import { callAlert } from 'ui/Alert';
import { get } from 'shared/utility';

import { common } from 'constants';
import { auth } from 'constants/auth.constants';
import { getContext } from 'shared/utils/index'; // eslint-disable-line import/no-cycle
import { getConnector, apiConnector } from 'shared/API'; // eslint-disable-line import/no-cycle

import isSectionUnderConstruction from 'shared/utils/isSectionUnderConstruction';

import {
  openModal,
} from 'store/actions/modals';

import { socket } from 'shared/API/sockets';

import requests from 'api/requests'

const {
  USER_AVAILABILITY,
} = common;

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const SHOW_TWO_FACTOR = 'SHOW_TWO_FACTOR';

const showTwoFactor = (twoFactor) => ({
  twoFactor,
  type: SHOW_TWO_FACTOR,
});

export const loginRequest = (credentials) => async (dispatch) => {
  dispatch({
    type: LOGIN_REQUEST,
    rememberMe: credentials.remember
  });
  dispatch(clearLoginError());
  dispatch(triggerFetching());

  credentials.meta = await getMeta(); // eslint-disable-line no-param-reassign

  try {
    const response = await requests.auth.checkCredentials(credentials)

    const {
      ip_banned, // eslint-disable-line camelcase
      ip_on_freeze, // eslint-disable-line camelcase
      message: errorMesage,
      showWarning,
      token: tempToken,
      twoFactor,
      workspaces,
    } = response;

    if (twoFactor) {
      dispatch(showTwoFactor(twoFactor));
    }

    if (tempToken) {
      dispatch(setTempToken(tempToken));
    }

    if (ip_banned) { // eslint-disable-line camelcase
      goToPermanentBanPage();
    } else if (ip_on_freeze) { // eslint-disable-line camelcase
      dispatch(triggerCaptchaModal());
    } else if (showWarning) {
      dispatch(loginError(errorMesage));
    } else if (workspaces && workspaces.length === 1) {
      dispatch(selectWorkspace(workspaces[0]));
    } else if (workspaces && workspaces.length > 1) {
      dispatch(showWorkspaces(workspaces));
    }
  }
  catch (error) {
    console.error(error.message);
    dispatch(loginError());
  }
  finally {
    dispatch(triggerFetching())
  }
};

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const logInSuccess = () => (dispatch, getState) => {
  dispatch({ type: LOGIN_SUCCESS });
  // callAlert.success(auth.MESSAGES.SUCCESS.LOGIN);

  const token = localStorage.getItem('token');
  socket.emit('login', token);
};

export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const loginError = (payload) => ({
  type: LOGIN_FAILURE,
  payload,
});

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const receiveLogout = () => async (dispatch, getState) => {
  dispatch(setUserAvailabilityOnLogout());
  localStorage.removeItem('token');
  localStorage.removeItem('commentsDrafts');

  const username = get(getState(), 'auth.loggedUser.Email', null);
  const database = get(getState(), 'auth.database', null);

  if (username && database) {
    const url = '/auth/logout';
    const config = { extended: true, global: true };
    const meta = await getMeta();
    const body = {
      ...meta,
      database,
      username,
    };

    socket.disconnect()

    apiConnector(url, 'POST', body, config);
  }

  dispatch({ type: LOGOUT_SUCCESS });
  // callAlert.success(auth.MESSAGES.SUCCESS.LOGOUT);
};

export const LOGIN_ERROR_CLEAR = 'LOGIN_ERROR_CLEAR';
export const clearLoginError = () => ({
  type: LOGIN_ERROR_CLEAR,
});
export const SET_TEMP_TOKEN = 'SET_TEMP_TOKEN';
export const setTempToken = (tempToken) => ({
  type: SET_TEMP_TOKEN,
  tempToken,
});

export const SHOW_WORKSPACES = 'SHOW_WORKSPACES';
export const showWorkspaces = (workspaces) => ({
  type: SHOW_WORKSPACES,
  activeTab: 2,
  workspaces,
});

export const HIDE_WORKSPACES = 'HIDE_WORKSPACES';
export const hideWorkspaces = () => (dispatch) => {
  dispatch({ type: HIDE_WORKSPACES });
};

export const SELECT_WORKSPACE = 'SELECT_WORKSPACE';
export const selectWorkspace = (workspace) => (dispatch, getState) => {
  const database = workspace.ClientDatabase;
  dispatch({ type: SELECT_WORKSPACE, database });

  if (workspace.accountExpired) {
    callAlert.error(auth.DISABLE_ACCOUNT);
  } else {
    dispatch(loginToApp());
  }
};

export const LOGIN_TO_APP = 'LOGIN_TO_APP';
export const loginToApp = () => async (dispatch, getState) => {
  const meta = await getMeta();
  const body = {
    ClientDatabase: getState().auth.database,
    meta
  };

  try {
    const response = await requests.auth.selectWorkspace(getState().auth.tempToken)(body)
    dispatch(getUserDataOnLogin(response));
    return response.token;
  }
  catch (error) {
    dispatch(loginToAppError())
  }
};

export const LOGIN_TO_APP_ERROR = 'LOGIN_TO_APP_ERROR';
export const loginToAppError = () => ({ type: LOGIN_TO_APP_ERROR });

export const TRIGGER_INTRO_MODAL = 'TRIGGER_INTRO_MODAL';
export const triggerIntroModal = (introModal) => ({
  type: TRIGGER_INTRO_MODAL,
  introModal,
});

export const TRIGGER_ONBOARDING_MODAL = 'TRIGGER_ONBOARDING_MODAL';
export const triggerOnboardingModal = (onboardingModal) => ({
  type: TRIGGER_ONBOARDING_MODAL,
  onboardingModal,
});

export const SAVE_USER_DATA = 'SAVE_USER_DATA';
export const getUserDataOnLogin = (user) => (dispatch) => {
  localStorage.setItem('token', user.token);

  dispatch({ type: SAVE_USER_DATA });
  dispatch(getMe());
  dispatch(logInSuccess());
};

export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const recoverPassword = (data) => (dispatch) => {
  dispatch({ type: RECOVER_PASSWORD });
  apiConnector('/password/reset', 'POST', data, { global: true })
    .then(() => dispatch(recoverPasswordSuccess()))
    .catch(() => dispatch(recoverPasswordError()));
};

export const RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS';
export const recoverPasswordSuccess = () => (dispatch) => {
  dispatch({ type: RECOVER_PASSWORD_SUCCESS });
  dispatch(triggerRecoverModal());
  callAlert.success(auth.RECOVER_SUCCESS);
};

export const RECOVER_PASSWORD_ERROR = 'RECOVER_PASSWORD_ERROR';
export const recoverPasswordError = () => ({ type: RECOVER_PASSWORD_ERROR });

export const TRIGGER_RECOVER_MODAL = 'TRIGGER_RECOVER_MODAL';
export const triggerRecoverModal = (email) => ({
  type: TRIGGER_RECOVER_MODAL,
  payload: email,
});

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const changePassword = (password, internal = false) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: CHANGE_PASSWORD });
  const url = '/password/change';

  const body = {
    database: internal
      ? getContext()
      : getState().auth.database,
    password,
  };

  const config = { global: true };

  if (!internal) {
    config.auth = getState().auth.tempToken;
  }

  try {
    const res = await apiConnector(url, 'POST', body, config)

    localStorage.setItem('token', res.token)
    dispatch(changePasswordSuccess(internal));
  } catch {
    dispatch(changePasswordError())
  }
};

export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const changePasswordSuccess = (internal) => (dispatch) => {
  dispatch({ type: CHANGE_PASSWORD_SUCCESS });
  const isOnboardingModalOpen = getState().auth.onboardingModal;
  if (!isOnboardingModalOpen) {
    dispatch(triggerChangeModal(null));
  }

  if (!internal) {
    dispatch(loginToApp());
  }


  callAlert.success(auth.CHANGE_PASSWORD);
};

export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const changePasswordError = () => ({ type: CHANGE_PASSWORD_ERROR });

export const TRIGGER_CHANGE_MODAL = 'TRIGGER_CHANGE_MODAL';
export const triggerChangeModal = (change) => ({
  type: TRIGGER_CHANGE_MODAL,
  change,
});

export const TRIGGER_FETCHING = 'TRIGGER_FETCHING';
export const triggerFetching = () => ({ type: TRIGGER_FETCHING });

export const TRIGGER_PERMISSION_MODAL = 'TRIGGER_PERMISSION_MODAL';
export const triggerPermissionModal = (permissionModal) => ({
  type: TRIGGER_PERMISSION_MODAL,
  permissionModal,
});

export const TRIGGER_CREDENTIALS_MODAL = 'TRIGGER_CREDENTIALS_MODAL';
export const triggerCredentialsModal = (credentialsModal) => ({
  type: TRIGGER_CREDENTIALS_MODAL,
  credentialsModal,
});

export const TRIGGER_ROLE_MODAL = 'TRIGGER_ROLE_MODAL';
export const triggerRoleModal = (roleModal) => ({
  type: TRIGGER_ROLE_MODAL,
  roleModal,
});

export const GET_ME = 'GET_ME';
export const getMe = () => (dispatch) => {
  dispatch({ type: GET_ME });

  getConnector('/auth/me')
    .then((user) => dispatch(getMeSuccess(user)))
    .catch(() => dispatch(getMeError()));
};

export const GET_EMPLOYEE_ACTIVITY = 'GET_EMPLOYEE_ACTIVITY';
export const getEmployeeActivity = (employeeUsername) => (dispatch) => {
  try {
    apiConnector(
      '/api/hr/employees/get-activities',
      'POST',
      {
        employeeUsername,
      },
    )
      .then(({ result = {} }) => {
        dispatch({
          type: GET_EMPLOYEE_ACTIVITY,
          result,
        });
      });
  } catch (e) {
    callAlert.error(`Get activity: ${e.message}`);
  }
};

export const GET_ME_SUCCESS = 'GET_ME_SUCCESS';
export const getMeSuccess = (user) => ({ type: GET_ME_SUCCESS, user });

export const GET_ME_ERROR = 'GET_ME_ERROR';
export const getMeError = () => ({ type: GET_ME_ERROR });

export const CHANGE_AUTH_STATUS_SUCCESS = 'CHANGE_AUTH_STATUS_SUCCESS';
export const changeLoggedUserStatus = (newStatus) => async (dispatch, getState) => {
  const {
    auth: {
      loggedUser,
    },
  } = getState();

  const {
    EmployeeID,
  } = loggedUser;

  const url = `/api/common/employees/${EmployeeID}`;

  try {
    await apiConnector(url, 'PUT', {
      useravailability: newStatus,
      SavedUserAvailability: newStatus,
    });
    dispatch({
      type: CHANGE_AUTH_STATUS_SUCCESS,
      payload: {
        useravailability: newStatus,
        EmployeeID,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

/* const companyEmployee = employees.find(employee => employee.EmployeeID === loggedUser.EmployeeID)
companyEmployee.useravailability = newStatus; */

/* dispatch(getEmployeesSuccess({
  employees: [
    ...employees,
    ...companyEmployee,
  ],
  total: employeesTotal,
})) */

export const TRIGGER_CAPTCHA_MODAL = 'TRIGGER_CAPTCHA_MODAL';
export const triggerCaptchaModal = () => ({
  type: TRIGGER_CAPTCHA_MODAL,
});

async function getMeta(obj) {
  const browserData = getBrowserData(obj);
  return {
    browserName: browserData.name,
    browserVersion: browserData.version,
    os: browserData.os,
  };
}

export const SET_USER_RESET = 'SET_USER_RESET';
export const setUserReset = (resetData) => async (dispatch) => {
  const url = '/auth/shouldReset';

  const config = {
    global: true,
  };

  const {
    email,
    databases,
    resetKey,
  } = resetData;

  const response = await apiConnector(
    url,
    'POST',
    {
      email,
      resetKey,
    },
    config,
  );

  if (response.shouldReset) {
    dispatch({
      type: SET_USER_RESET,
      payload: {
        email,
        databases,
        resetKey,
      },
    });

    dispatch({
      type: TRIGGER_CHANGE_MODAL,
      change: auth.RESTORE_AFTER_LOCKOUT,
    });
  }
};

export const restoreAccessAndChangePassword = (newPassword, resetUser) => (
  dispatch,
) => {
  const url = '/restoreAccess';

  const {
    email,
    resetKey,
  } = resetUser;

  const body = {
    email,
    newPassword,
    resetKey,
  };

  const config = { global: true };

  apiConnector(url, 'POST', body, config)
    .then(() => {
      dispatch(changePasswordSuccess());
    })
    .catch((e) => {
      console.error(e);
    });
};

function getBrowserData() {
  const browser = detect();
  return browser;
}

// async function getIP() {
//   let ipV4;
//   try {
//     ipV4 = await publicIp.v4();
//   } catch (e) {
//     console.error(e);
//   }
//   return ipV4;
// }

function goToPermanentBanPage() {
  window.history.pushState({}, null, '/ban');
  window.location.reload();
}

const setUserAvailabilityOnLogout = () => async (dispatch, getState) => {
  const {
    auth: {
      loggedUser,
    },
  } = getState();


  try {
    const {
      EmployeeID,
    } = loggedUser;

    const url = `/api/common/employees/${EmployeeID}`;

    await apiConnector(
      url,
      'PUT',
      {
        useravailability: USER_AVAILABILITY.Offline,
      },
    );
  } catch (error) {
    console.error(error);
  }
};

export const SET_EXTERNAL_CREDENTIALS = 'SET_EXTERNAL_CREDENTIALS';
export const setExternalCredentials = ({ login, password }) => ({
  type: SET_EXTERNAL_CREDENTIALS,
  login,
  password,
})
