import requests from 'api/requests';

const deleteLike = (likeId) => async (dispatch) => {
  try {
    await requests.likes.deleteLike(likeId);
  } catch (error) {
    console.error(error);
  }
};

export default deleteLike;
