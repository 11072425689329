import requests from 'api/requests';

// eslint-disable-next-line consistent-return
const createInvoice = (data) => async (dispatch) => {
  try {
    const response = await requests.customerInvoices.create(data);

    return response.data.invoiceId;
  } catch (error) {
    console.error(error);
  }
};

export default createInvoice;
