import React from 'react';
import getInitialsFromString from 'refactored/utils/getInitialsFromString';
import styles from './senderPillImageDetailed.module.css';

const SenderImage = ({ imageUrl, altText, onClick = () => { } }) => {
    const bgStyle = { backgroundImage: `url(${imageUrl})`, };

    return (
        <div style={bgStyle} className={styles['detailed-sender-image']} onClick={onClick}>
            <span className={styles['detailed-sender-image-text']}>
                {imageUrl ? null : altText ? getInitialsFromString(altText) : null}
            </span>
        </div>
    );
};

export default SenderImage;
