exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".emailListItemFooter_item-list-dropdown-btn__IDckZ {\n  opacity: 0;\n}\n\n*:hover > * > * > .emailListItemFooter_item-list-dropdown-btn__IDckZ {\n  opacity: 1;\n}\n\n.emailListItemFooter_email-listitem-link__19QqQ {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n}\n\n.emailListItemFooter_email-listitem-link-container__bZfyO {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  border-radius: 20px;\n  padding: 8px;\n  max-width: 130px;\n  max-height: 25px;\n  grid-gap: 4px;\n  gap: 4px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.1098039216);\n}\n\n.emailListItemFooter_email-listitem-link-a-tag__3dV_O {\n  max-width: 100%;\n  color: #141736;\n  font-weight: 500;\n  font-size: 12px;\n}\n\n.emailListItemFooter_email-listitem-types-container__34w6B {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  height: -webkit-fit-content;\n  height: -moz-fit-content;\n  height: fit-content;\n}\n\n.emailListItemFooter_email-listitem-items-to-complete__o8iJQ {\n  padding-left: 0.6rem;\n  padding-right: 0.6rem;\n  padding-top: 0.2rem;\n  padding-bottom: 0.2rem;\n  display: flex;\n  background-color: #f6f6f6;\n  border-radius: 20px;\n  width: -webkit-fit-content;\n  width: -moz-fit-content;\n  width: fit-content;\n  align-items: center;\n  margin-left: 3px;\n}\n\n.emailListItemFooter_email-listitem-items-to-complete-text__paYC2 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 22px;\n  height: 22px;\n  color: #367BED;\n  font-size: 15px;\n  font-weight: 500;\n}", ""]);

// exports
exports.locals = {
	"item-list-dropdown-btn": "emailListItemFooter_item-list-dropdown-btn__IDckZ",
	"email-listitem-link": "emailListItemFooter_email-listitem-link__19QqQ",
	"email-listitem-link-container": "emailListItemFooter_email-listitem-link-container__bZfyO",
	"email-listitem-link-a-tag": "emailListItemFooter_email-listitem-link-a-tag__3dV_O",
	"email-listitem-types-container": "emailListItemFooter_email-listitem-types-container__34w6B",
	"email-listitem-items-to-complete": "emailListItemFooter_email-listitem-items-to-complete__o8iJQ",
	"email-listitem-items-to-complete-text": "emailListItemFooter_email-listitem-items-to-complete-text__paYC2"
};