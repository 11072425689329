import {
  SET_CRM_SELECTED_LEAD,
} from 'store/actionTypes/crm';

const setSelectedLead = (leadId) => ({
  type: SET_CRM_SELECTED_LEAD,
  payload: leadId,
});

export default setSelectedLead;
