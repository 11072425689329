import { connect } from 'react-redux';

import datetime from 'refactored/utils/datetime';

import { createOffer } from 'store/actions/realtorOffers';

import {
  uploadAgreementFiles,
  getClientId,
  getProspectId,
} from 'sections/Offers/actions';

import CounterOfferForm from './CounterOfferForm';

const addDays = (days) => datetime().add(days, 'day').format();
const addHours = (hours) => datetime().add(hours, 'hour').format();

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: async (formData) => {
    const {
      propertyId,
      clientId,
      prospectId,
      representing,

      prevOfferType,
      prevOfferId,

      onSuccess,
      onError,
    } = ownProps;

    const {
      offerAmount,
      expiresIn,
      closesIn,
      contingenciesIn,
      financeType,
      notes,
      buyer,
      seller,
      sellerAgent,
      buyerAgent,
      contingencyInspection,
      contingencyAppraisal,
      contingencyLoan,
      agreementFiles,
      // agreementUrl,
    } = formData;

    const offerData = {
      parent_offer_id: prevOfferId,
      type: prevOfferType === 1 ? 2 : 1,

      buyer_contact_id: buyer,
      buyer_agent_contact_id: buyerAgent,

      realtor_client_id: representing === 'seller'
        ? clientId || await getClientId({
          propertyId,
          offerAmount,
          seller,
        })
        : null,

      seller_contact_id: seller,
      seller_agent_contact_id: sellerAgent,

      realtor_prospect_id: representing === 'buyer'
        ? prospectId || await getProspectId({
          buyer,
        })
        : null,

      realtor_property_id: propertyId,

      closing_date: addDays(closesIn),
      contingency_period_end: addDays(contingenciesIn),
      expiry_date: addHours(expiresIn),

      amount: offerAmount,

      contingency_inspection: contingencyInspection,
      contingency_appraisal: contingencyAppraisal,
      contingency_loan: contingencyLoan,

      financing_type: financeType,

      ex_submission_first_name: null,
      ex_submission_last_name: null,
      ex_submission_phone: null,
      ex_submission_email: null,
      ex_submission_broker: null,

      ex_submission_buyer_first_name: null,
      ex_submission_buyer_last_name: null,
      note: notes,
    };

    try {
      const offerId = await dispatch(createOffer(offerData));

      if (agreementFiles) {
        await uploadAgreementFiles(offerId)(agreementFiles);
      }

      if (onSuccess) {
        onSuccess(offerId);
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
    }
  },
});

export default connect(null, mapDispatchToProps)(CounterOfferForm);
