import {
  SET_DIGITAL_ACCOUNTS,
} from 'store/actionTypes/customerDigitalAccounts';

const setDigitalAccounts = (payload) => ({
  type: SET_DIGITAL_ACCOUNTS,
  payload,
});

export default setDigitalAccounts;
