import requests from 'api/requests';

import getLeadById from './getLeadById';
import getLeadChangelogAllTime from './getLeadChangelogAllTime';

const claimLead = (leadId) => async (dispatch) => {
  await requests.realtor.leads.claim(leadId);

  dispatch(getLeadById(leadId));
  dispatch(getLeadChangelogAllTime(leadId));
};

export default claimLead;
