import React from 'react';
import PropTypes from 'prop-types';

import { useController } from 'react-hook-form';

import CurrencyInput from 'ui/CurrencyInput';

const OfferAmount = (props) => {
  const {
    control,
    disabled,
  } = props;

  const {
    field: {
      onChange,
      value,
    },
  } = useController({
    name: 'offerAmount',
    control,
  });

  return (
    <CurrencyInput
      label="Purchase Price"
      onChange={onChange}
      value={value}
      disabled={disabled}
    />
  );
};

OfferAmount.defaultProps = {
  disabled: false,
};

const {
  shape,
  bool,
} = PropTypes;

OfferAmount.propTypes = {
  control: shape({}).isRequired,
  disabled: bool,
};

export default OfferAmount;
