import requests from 'api/requests';

import { callAlert } from 'ui/Alert';

import getLeadById from 'store/actions/realtorLeads/getLeadById';

const deletePropertyRequirement = (requirementId, leadId = null) => async (dispatch) => {
  try {
    await requests.realtor.propertyRequirements.deleteRequirement(requirementId);

    callAlert.success('Deleted successfully');

    if (leadId) {
      dispatch(getLeadById(leadId));
    }
  } catch (error) {
    console.error(error);
    callAlert.error('Could not delete');
  }
};

export default deletePropertyRequirement;
