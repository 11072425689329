import React from 'react';
import GoogleMailIcon from 'assets/images/commsHub/google-mail-icon.svg';
import MicrosoftMailIcon from 'assets/images/commsHub/microsoft-icon.svg';
import styles from './emailCompanyMailIcon.module.css';

const EmailCompanyMailIcon = ({ emailCompany = 'google', altText }) => {
    const companyIcon = emailCompany == 'google' ? GoogleMailIcon : MicrosoftMailIcon
    const bgStyle = { backgroundImage: `url(${companyIcon})`, };

    return (
        <span style={bgStyle} className={styles['email-company-icon']}>
        </span>
    );
};

export default EmailCompanyMailIcon;
