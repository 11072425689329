import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import currentUserSelector from 'store/selectors/users/currentUser';
import sendEmail from 'refactored/services/email/sendEmail';
import useIntegrations from 'sections/Integrations/hooks/useIntegrations';
import { useThreadModal } from '../providers/modals/ThreadModal.provider';

const useSendEmailForm = ({ onSendConfirm = () => { }, initialTo = [], initialCC = [], msgReplyId, responseSubject, sugestedBody, replyMode }) => {
    const user = useSelector(currentUserSelector);
    const [isTextAreaFocus, setIsTextAreaFocus] = useState(false)
    const [to, setTo] = useState([...initialTo]);
    const [carbonCopy, setCarbonCopy] = useState([...initialCC]);
    const [blindCarbonCopy, setBlindCarbonCopy] = useState([]);
    const [subject, setSubject] = useState(responseSubject || '');
    const [body, setBody] = useState(sugestedBody || '');
    const [token, setToken] = useState(null);
    const { integrationsByScope } = useIntegrations();
    const integrations = integrationsByScope?.email;
    const { closeThreadModal } = useThreadModal()

    const sendForm = async () => {
        try {
            onSendConfirm();
            closeThreadModal();
            await sendEmail({ replyMode, token, subject, msgReplyId, from: [{ name: user?.fullName, email: user?.email }], body, to, carbonCopy, blindCarbonCopy });
        } catch (error) {
            console.log('Failed to send email, we are working on it');
        }
    };
    useEffect(() => {
        if (integrations && integrations.length > 0) {
            setToken(integrations[0].token);
        }
    }, [integrations]);

    return {
        to,
        setTo,
        carbonCopy,
        setCarbonCopy,
        blindCarbonCopy,
        setBlindCarbonCopy,
        subject,
        setSubject,
        body,
        setBody,
        sendForm,
        isTextAreaFocus,
        setIsTextAreaFocus
    };
};

export default useSendEmailForm;
