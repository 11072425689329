import React, { useRef } from 'react';
import StyledAntdModal from '../../../shared/styledAntd/styledAntdModal';
import styles from './changeProfilePictureModal.module.css';
import { useChangeProfilePictureModal } from '../../providers/modals/ChangeProfilePictureModal.provider';
import PrimaryButton from '../../buttons/squarePrimaryButton/SquarePrimaryButton';
import { useSelector } from 'react-redux';
import currentUserSelector from 'store/selectors/users/currentUser';
import getInitialsFromString from 'refactored/utils/getInitialsFromString';

const ChangeProfilePictureModal = () => {
    const user = useSelector(currentUserSelector);
    const {
        isChangeProfilePictureModalOpen, toggleChangeProfilePictureModal,
        profilePicture, setNewProfilePictureFromFile,
        saveNewProfilePicture
    } = useChangeProfilePictureModal()
    const fileInputRef = useRef(null);

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    return <StyledAntdModal
        visible={isChangeProfilePictureModalOpen}
        onCancel={() => {
            toggleChangeProfilePictureModal(false)
        }}
        width="500px"
    >
        <div className={styles['change-profile-picture-modal']}>
            <div className={styles['current-profile-picture-title']}>
                Choose a profile picture
            </div> 
            <div className={styles['current-profile-picture']}>
                {(profilePicture || user?.avatar) &&
                    <img
                        src={profilePicture || user.avatar}
                        onClick={handleImageClick}
                        alt="Actual profile picture"
                        htmlFor="upload-profile-picture-btn"
                    />
                }
                {!(profilePicture || user?.avatar) &&
                    <div
                        onClick={handleImageClick}>
                        {getInitialsFromString(user?.fullName)}
                    </div>
                }
            </div>
            <div>
                <button className={styles['custom-upload-button']}
                    onClick={handleImageClick}>Select file</button>
                <input
                    type="file"
                    onChange={(e) => setNewProfilePictureFromFile(e.target.files[0])}
                    ref={fileInputRef}
                />
            </div>

            <div className={styles['button-container']}>
                <PrimaryButton onClick={() => { toggleChangeProfilePictureModal(false) }}>Cancel</PrimaryButton>
                <PrimaryButton onClick={() => {
                    saveNewProfilePicture()
                    toggleChangeProfilePictureModal(false)
                }}>Save</PrimaryButton>
            </div>
        </div>
    </StyledAntdModal>
}

export default ChangeProfilePictureModal;