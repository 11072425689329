import React, {
  useContext,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import Button from 'ui/Button';

import editorContext from 'ui/TextEditor/context';

const EmailSendButton = (props) => {
  const {
    onSend,
  } = props;

  const {
    editor,
  } = useContext(editorContext);

  const handleSend = useCallback((data) => {
    onSend(data);

    try {
      editor.commands.setContent('');
    } catch (error) {
      console.error(error);
    }
  }, [
    onSend,
    editor,
  ]);

  return (
    <Button
      variant="primary"
      icon="send"
      onClick={handleSend}
      disabled={!editor?.getText()}
    />
  );
};

EmailSendButton.defaultProps = {
  disabled: false,
};

const {
  bool,
  func,
} = PropTypes;

EmailSendButton.propTypes = {
  onSend: func.isRequired,
  disabled: bool,
};

export default EmailSendButton;
