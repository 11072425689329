exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".senderPill_sender-pill-wrapper__1xiav {\n  display: flex;\n  align-items: center;\n  grid-gap: 4px;\n  gap: 4px;\n  padding: 8px;\n  border-radius: 20px;\n  background-color: #ffffff;\n  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.senderPill_profile-image__X18c3 {\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  overflow: hidden;\n  margin-right: 12px;\n}\n\n.senderPill_profile-image__X18c3 img {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n\n.senderPill_initials__3bWK6 {\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 14px;\n  background-color: #ddd;\n  color: #555;\n}\n\n.senderPill_email-text__3Yixg {\n  font-size: 14px;\n  font-weight: bold;\n  color: #333;\n}", ""]);

// exports
exports.locals = {
	"sender-pill-wrapper": "senderPill_sender-pill-wrapper__1xiav",
	"profile-image": "senderPill_profile-image__X18c3",
	"initials": "senderPill_initials__3bWK6",
	"email-text": "senderPill_email-text__3Yixg"
};