import styled from 'styled-components';

export const NakedButton = styled.button`
  display: block;
  border: none;
  background: none;
  padding: 0;
  &:focus,
  &:active {
    outline: none;
  };
  cursor: ${(props) => ((!props.disabled && (props.onClick || props.htmlType === 'submit'))
    ? 'pointer'
    : 'default'
  )};
`;

export default NakedButton;
