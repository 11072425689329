import React from 'react';
import StyledAntdModal from '../../../shared/styledAntd/styledAntdModal';
import Spin from 'ui/Spin';
import { useThreadModal } from '../../providers/modals/ThreadModal.provider';
import ThreadModalTopBar from './threadModalTopbar/ThreadModalTopBar';
import ThreadModalMsgSelector from './threadModalMsgSelector/ThreadModalMsgSelector';
import ThreadModalCurrentMail from './threadModalCurrentMail/ThreadModalCurrentMail';
import ThreadModalResponseMailForm from './threadModalResponseMailForm/ThreadModalResponseMailForm';
import styles from './threadModal.module.css';

const ThreadModal = () => {
    const { isThreadModalOpen, closeThreadModal, emails, emailIndex, isLoading, goToNextMailInThread, goToPrevMailInThread, thread, closeThread, initialTo, initialCC, responseModeState } = useThreadModal();
    return <StyledAntdModal visible={isThreadModalOpen} onCancel={closeThreadModal}
        style={{ top: 'min(16px, 12vw)' }}>
        <div className={styles['thread-modal']}>
            {!isLoading && <ThreadModalTopBar thread={thread} email={emails[emailIndex]} emails={emails}
                openThreadClick={closeThread} closeThreadClick={closeThread}
            />}
            {!isLoading && <ThreadModalCurrentMail email={emails[emailIndex]} />}
            {!isLoading && <ThreadModalResponseMailForm
                key={JSON.stringify(initialTo) + JSON.stringify(initialCC) + emailIndex}
                initialTo={initialTo}
                initialCC={initialCC}
                msgReplyId={emails[emailIndex]?.id}
                replyMode={responseModeState}
                responseSubject={responseModeState == 'FORWARD' ? 'Fwd: ' + emails[emailIndex]?.subject : 'Re: ' + emails[emailIndex]?.subject}
                sugestedBody={emails[emailIndex]?.clean_sympleteAnalysis?.suggested_reply}
            />}
            {isLoading && <div className={styles['thread-modal-spinner-area']}>
                <Spin className={styles['email-card-spinner']} />
                <Spin className={styles['email-card-spinner']} />
            </div>}
        </div>
        <div>
            <ThreadModalMsgSelector
                currentMailIndex={emailIndex}
                maxMailIndex={emails.length - 1}
                onPrevClick={goToPrevMailInThread}
                onNextClick={goToNextMailInThread}
                isLoading={isLoading}
            />
        </div>
    </StyledAntdModal>
}

export default ThreadModal;