import React from 'react';
import ToggleButton from '../../../shared/buttons/toggleButton/ToggleButton';
import styles from './toolbarFiltersContainer.module.css';

const ToolbarFiltersContainer = ({ filters, selectedFilter, onToggle }) => {
    return (
        <div className={styles['toolbar-filters-container']}>
            {Object.keys(filters).map((key) => (
                <ToggleButton key={key} name={key} selectedFilter={selectedFilter} onClick={() => onToggle(key)}>
                    {filters[key].icon} <b className={styles['toolbar-count-filter']}>{filters[key].count}</b> {filters[key].label}
                </ToggleButton>
            ))}
        </div>
    );
};

export default ToolbarFiltersContainer;
