exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".primaryButton_primary-btn__IhYnW {\n  min-width: 44px;\n  height: 44px;\n  border-radius: 44px;\n  background: none;\n  border: none;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #3c2b84;\n  color: #fff;\n  font-size: 18px;\n  padding: 4px 12px;\n}\n\n.primaryButton_primary-btn__IhYnW:hover {\n  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);\n  background-color: rgba(60, 43, 132, 0.9333333333);\n}\n\n.primaryButton_primary-btn__IhYnW:active {\n  box-shadow: none;\n  background-color: #3c2b84;\n}\n\n.primaryButton_primary-btn-icon__16-05 {\n  height: 24px;\n  max-height: 24px;\n  width: auto;\n  object-fit: contain;\n}", ""]);

// exports
exports.locals = {
	"primary-btn": "primaryButton_primary-btn__IhYnW",
	"primary-btn-icon": "primaryButton_primary-btn-icon__16-05"
};