const getTimeAgo = timestamp => {
    const now = new Date().getTime();
    const secondsAgo = Math.floor((now - timestamp) / 1000);

    const intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1
    };

    for (const interval in intervals) {
        const count = Math.floor(secondsAgo / intervals[interval]);
        if (count >= 1) {
            return count === 1 ? `1 ${interval} ago` : `${count} ${interval}s ago`;
        }
    }

    return 'just now';
};

export default getTimeAgo;