import React from 'react';
import PropTypes from 'prop-types';

import Selection from 'ui/Selection';

const options = [
  {
    id: 0,
    label: 'My Listing',
    iconName: 'home',
  },
  {
    id: 1,
    label: 'Featured Property',
    iconName: 'star',
  },
];

const ListingTypeSelect = (props) => {
  const {
    value,
    onChange,
  } = props;

  return (
    <Selection
      radio
      value={value}
      onChange={onChange}
      options={options}
    />
  );
};

ListingTypeSelect.defaultProps = {
  value: null,
};

const {
  func,
  number,
} = PropTypes;

ListingTypeSelect.propTypes = {
  value: number,
  onChange: func.isRequired,
};

export default ListingTypeSelect;
