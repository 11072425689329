import React, {
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import validate from 'shared/utils/validators';

import Button from 'ui/Button';

import NewContactForm from 'sections/Contacts/forms/NewContactForm';

import Modal from 'ui/Modal';

const NewContactModal = (props) => {
  const {
    isVisible,
    createContact,
    closeModal,
    setNotValidField,
    mainEmail,
    personFirstName,
    doesPhoneExist,
    doesEmailExist,
    getCachedData,
  } = props;

  useEffect(() => {
    if (isVisible) {
      getCachedData();
    }
  }, [
    isVisible,
  ]);

  const saveContact = useCallback(async () => {
    const isEmailValid = mainEmail ? validate.email(mainEmail) : true;

    if (doesPhoneExist || doesEmailExist) {
      return;
    }

    if (!personFirstName) {
      setNotValidField('isFirstNameValid');
      return;
    }

    if (!isEmailValid) {
      setNotValidField('isEmailValid');
      return;
    }

    await createContact();

    closeModal(false);
  }, [
    createContact,
    mainEmail,
    personFirstName,
    doesPhoneExist,
    doesEmailExist,
  ]);

  return (
    <Modal
      width={720}
      visible={isVisible}
      okText="Save"
      onCancel={closeModal}
      destroyOnClose
      title="New contact"
      footer={[
        <Button
          variant="secondary"
          onClick={closeModal}
        >
          Cancel
        </Button>,
        <Button
          variant="primary"
          onClick={saveContact}
        >
          Save
        </Button>,
      ]}
    >
      <NewContactForm />
    </Modal>
  );
};

const {
  bool,
  func,
  string,
} = PropTypes;

NewContactModal.propTypes = {
  isVisible: bool.isRequired,
  createContact: func.isRequired,
  closeModal: func.isRequired,
  setNotValidField: func.isRequired,
  mainEmail: string.isRequired,
  personFirstName: string.isRequired,
  doesPhoneExist: bool.isRequired,
  doesEmailExist: bool.isRequired,
  getCachedData: func.isRequired,
};

export default NewContactModal;
