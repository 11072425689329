import requests from 'api/requests';

import getDealProducts from './getDealProducts';

const getProducts = (dealId) => (relationId) => (updateData) => async (dispatch) => {
  try {
    await requests.deals.updateProduct(relationId)(updateData);
    await dispatch(getDealProducts(dealId));
  } catch (error) {
    console.error(error);
  }
};

export default getProducts;
